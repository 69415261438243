'use strict';
class ControleEligibiliteService {
  constructor($http, $log, configuration) {
    this._http = $http;
    this.logger = $log;
    this._configuration = configuration;
  }

  /**
   * Used to check controle-eligibilite on a demande
   *
   * @param {object} demande
   * @returns {Promise}
   */
  check(demande) {
    return this._http
      .get(`${_.get(this._configuration, 'aides.url')}/demandes-financement/${demande.reference}/controle-eligibilite`)
      .then(({ data }) => {
        return data || [];
      })
      .catch(() => {
        const message = `An error occurred trying to fetch controle-eligibilite on demande ${demande.reference}`;
        this.logger.error(message);
        throw new Error(message);
      });
  }
}
ControleEligibiliteService.$inject = ['$http', '$log', 'configuration'];

ControleEligibiliteService.$inject = ['$http', '$log', 'configuration'];

angular.module('aides.services').service('controleEligibiliteService', ControleEligibiliteService);
