'use strict';
angular.module('aides.model').factory('AideSearch', [
  function () {
    'use strict';

    /**
     *
     * @param {object} data
     */
    function AideSearch(data) {
      data = data || {};
      this.text = data.text;
      this.status = data.status;
      this.teleservice = data.teleservice;
      this.operatorExercice = data.operatorExercice;
      this.dateExercice = data.dateExercice;
      this.idCpo = data.idCpo;
    }

    return AideSearch;
  },
]);
