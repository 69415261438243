'use strict'; // Module declaration
angular.module('portailDepotDemandeAide.depot').component('justificationPreambuleComponent', {
  templateUrl: 'depot/justification/preambule/justification-preambule.component.html',
  controller: /* @ngInject */ JustificationPreambuleController,
  bindings: {
    workflow: '<',
    validStep: '<',
    disableNavigation: '<',
  },
});

/**
 *
 */
function JustificationPreambuleController() {
  var ctrl = this;

  /**
   * Method for the justification-step-wizard-navigation to go to the next step
   */
  ctrl.nextStep = function () {
    ctrl.validStep();
  };
}
