// ng-bind-html removes "style" attribute if it's not sanitized first
angular.module('common.filters').filter('unsafe', [
  '$sce',
  function ($sce) {
    return function (input) {
      if (!input) return input;
      if (typeof input.$$unwrapTrustedValue === 'function') return input; // already sanitized
      return $sce.trustAsHtml(input);
    };
  },
]);
