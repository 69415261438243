'use strict';
/**
 * @module aides
 * @name aides
 * @description
 *
 *   The main module for all aides related services and directives for our portals
 */

angular.module('aides', [
  'common',
  'aides.model',
  'aides.services',
  'aides.recapitulatif',
  'form',
  'alerts',
  'document-fusion',
  'pascalprecht.translate',
  'kendo.directives',
  'mgcrea.ngStrap.modal',
  'mgcrea.ngStrap.tooltip',
]);

angular.module('aides.services', ['aides.model']);
