'use strict'; // Module declaration
angular.module('common.components').component('justificationStepWizardNavigation', {
  templateUrl: 'depot/justification/step-wizard-navigation/justification-step-wizard-navigation.component.html',
  controller: /* @ngInject */ JustificationStepsWizardNavigation,
  bindings: {
    previous: '<',
    next: '<',
    save: '<',
    finish: '<',
    upload: '<',
    idPrefix: '<',
    disableNavigation: '<',
  },
});

/**
 * Controller
 */
function JustificationStepsWizardNavigation() {
  var ctrl = this;

  ctrl.previousStep = function () {
    if (ctrl.previous) {
      ctrl.previous();
    }
  };

  ctrl.nextStep = function () {
    if (ctrl.next) {
      ctrl.next();
    }
  };

  ctrl.saveStep = function () {
    if (ctrl.save) {
      ctrl.save();
    }
  };

  ctrl.finishStep = function () {
    if (ctrl.finish) {
      ctrl.finish();
    }
  };

  ctrl.uploadStep = function () {
    if (ctrl.upload) {
      ctrl.upload();
    }
  };
}
