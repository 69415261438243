'use strict';
angular.module('portailDepotDemandeAide.dashboard').controller('dashboardDeposerDemandeAideController', [
  '$scope',
  '$window',
  'teleservices',
  'familles',
  'tiers',
  function ($scope, $window, teleservices, familles, tiers) {
    // Filter teleservice by date
    $scope.teleservices = (teleservices || []).filter((teleservice) => {
      const famillesLength = teleservice?.workflow?.familles?.length ?? 0;
      // Do not display teleservice with no famille
      return famillesLength > 0;
    });

    // If there is a tiers for the current user, we pick the familles matching with his famille
    const tiersFamille = tiers?.famille?.href;
    if (tiersFamille) {
      $scope.familles = (familles.raw || []).filter((famille) => tiersFamille === famille.id);
    }
    // otherwise, we pick the familles that exist in at least on teleservice
    else {
      $scope.familles = (familles.raw || []).filter((famille) => {
        return $scope.teleservices.some((teleservice) => {
          const familles = teleservice?.workflow?.familles ?? [];
          return familles.some(({ href }) => href === famille.id);
        });
      });
    }

    $scope.namespace = 'connected.dashboard.deposerDemandeAide';
    $window.scrollTo(0, 0);

    // Pagination
    $scope.pagerConfig = {
      totalItems: $scope.teleservices.length,
      itemsPerPage: 20,
      currentPage: 1,
    };
    $scope.pagerConfig.numPages = computePageNumber($scope.pagerConfig);

    $scope.refreshPagination = () => {
      // When pagination changes, we just bring the user back to page 1 to keep things simple
      $scope.pagerConfig.currentPage = 1;
      // update totalItems by applying filters
      $scope.pagerConfig.totalItems = $scope.teleservices.filter($scope.searchFilter).length;
      $scope.pagerConfig.numPages = computePageNumber($scope.pagerConfig);
    };

    // search options
    $scope.search = {
      text: '',
      famille: '',
    };

    // do not enable famille filter if the user is already attached to a tiers
    if (tiers) {
      $scope.disableFamillesFilter = true;
      $scope.search.familleHref = tiersFamille;
    }

    // Filter teleservice with text and familles options
    $scope.searchFilter = (teleservice) => {
      const latiniseValue = (value) => S((value || '').toLowerCase()).latinise();
      const searchedText = latiniseValue($scope.search.text);

      const libelleFilter = latiniseValue(teleservice.title).include(searchedText);
      const motsClesFilter = teleservice.motsCles?.some((motCle) => latiniseValue(motCle).include(searchedText));

      const familles = teleservice?.workflow?.familles ?? [];
      const familleHasTeleservice =
        !$scope.search.familleHref || familles.some((famille) => famille.href === $scope.search.familleHref);

      return (libelleFilter || motsClesFilter) && familleHasTeleservice;
    };

    /**
     * Update currentPage value on pagination change
     *
     * @param {number} currentPage value of the new current page
     * @returns {void}
     */
    $scope.onCurrentPageChange = (currentPage) => {
      $scope.pagerConfig.currentPage = currentPage;
    };

    /**
     * Computes page number from totalItems and itemsPerPage
     *
     * @param {object} pagerConfig pagination configuration
     * @returns {number} the number of pages
     */
    function computePageNumber(pagerConfig) {
      const pageNumber = Math.ceil(pagerConfig.totalItems / pagerConfig.itemsPerPage);
      return pageNumber === 0 ? 1 : pageNumber;
    }
  },
]);
