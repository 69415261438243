'use strict';
echangesService.$inject = ['$window', '$http', '$rootScope', '$timeout', 'jwtSessionService', 'socketService'];
/**
 * Service de gestions des contributions
 */
angular.module('components.echanges').factory('echangesService', echangesService);

/**
 * @param {object} $window - AngularJS wrapper for the window object.
 * @param {object} $http - AngularJS service for making HTTP requests.
 * @param {object} $rootScope - AngularJS service for sharing data between controllers and views.
 * @param {object} $timeout - AngularJS service for executing a function after a specified time has elapsed.
 * @param {object} jwtSessionService - Service for managing JSON Web Tokens (JWTs) related to user sessions.
 * @param {object} socketService - Service for managing WebSocket connections.
 * @returns {object} - Object containing methods for managing echanges.
 */
function echangesService($window, $http, $rootScope, $timeout, jwtSessionService, socketService) {
  'use strict';

  return {
    /**
     * Counts the number of unread echanges related to a demande.
     *
     * @param {string} tenantId - The ID of the tenant.
     * @param {string} demandeFinancementRef - The reference of the demande.
     * @returns {Promise<number>} - A promise that resolves to the number of unread echanges.
     */
    countUnreadEchangesByDemande: function (tenantId, demandeFinancementRef) {
      // EU backend used as middleware to Request
      return $http
        .get('/aides/api/tenants/' + tenantId + '/echanges/' + demandeFinancementRef)
        .then(function (results) {
          return _.get(results, 'data.count');
        });
    },

    /**
     * Sets up a WebSocket connection to track unread echanges related to a demande.
     *
     * @param {string} tenantId - The ID of the tenant.
     * @param {string} demandeFinancementRef - The reference of the demande.
     * @param {Function} callbackSetNb - The callback function to set the number of unread echanges.
     */
    socketUnreadEchanges: function (tenantId, demandeFinancementRef, callbackSetNb) {
      const jwtUser = jwtSessionService.user();
      if (tenantId && jwtUser?.echangesActif) {
        var that = this;

        const namespace = `${$window.location.origin}/echangesNonLusDemande`;
        const path = '/referentiel-echanges/api/socket.io/';
        const query = {
          tenantId: tenantId,
        };

        // If there is already a socket to track "echangesNonLusDemande", it is removed to keep only one
        if ($rootScope.socketEchange) {
          $rootScope.socketEchange.close();
          delete $rootScope.socketEchange;
        }
        $rootScope.socketEchange = socketService.open(path, namespace, query);
        $rootScope.socketEchange.on('connect', function () {
          // Join room to get unread echange by demande
          $rootScope.socketEchange.emit('connect-demandeFinancement', { demandeFinancement: demandeFinancementRef });
          $rootScope.socketEchange.on('refreshUnreadEchanges', function () {
            $timeout(function () {
              that.countUnreadEchangesByDemande(tenantId, demandeFinancementRef).then(function (nb) {
                callbackSetNb(nb);
                $rootScope.$broadcast('refresh-menu');
              });
            }, 1000);
          });
        });
      }
    },
  };
}
