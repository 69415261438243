'use strict';
/**
 * @module aides
 * @name minimisForm
 * @description
 *
 *   Form to write the list of funds the applicant has already benefited
 * @param financeurAssocieReferenciel {boolean} - wether to ask for funders or not
 * @param listeFinanceursAides {object} - list of funders
 * @param exercice {int} - specific year
 * @param viewConfiguration {object} - view configuration
 * @param minimis {array} - List of funds
 * @example
 *
 *  `<minimis-form
 *    financeur-associe-referenciel="minimisConfiguration.financeurAssocieReferenciel"
 *    liste-financeurs-aides="listeFinanceursAides"
 *    view-configuration="minimisConfiguration"
 *    minimis="demande.financementAnterieur">
 *  </minimis-form>`
 */

angular.module('aides').directive('minimisForm', [
  '$modal',
  '$translate',
  '$rootScope',
  '$timeout',
  function ($modal, $translate, $rootScope, $timeout) {
    'use strict';

    return {
      replace: 'true',
      transclude: false,
      templateUrl: 'aides/aides-directives/minimis-form/minimis-form.html',
      require: '^form',
      scope: {
        financeurAssocieReferenciel: '=',
        listeFinanceursAides: '=',
        exercice: '=',
        viewConfiguration: '=',
        minimis: '=',
        readOnly: '=?',
      },

      link: function ($scope, element, attrs, formController) {
        $scope.form = formController;
        _.set($scope.viewConfiguration, 'displayCharactersLeft', true);

        var templateToolBarAdd = [
          '<button id="minimis-add-line-button" ng-click="addLine()" title="{{viewConfiguration.ns + \'.add.tooltip\' | translate}}" ',
          ' class="btn btn-sm btn-primary" data-toggle="tooltip" type="button">',
          '   <span class="fa fa-plus" aria-hidden="true"></span>',
          "   {{viewConfiguration.ns + '.add.button' | translate}}",
          '</button>',
        ].join('');

        $scope.gridOptions = {
          dataSource: $scope.minimis,
          sortable: true,
        };

        if (!$scope.readOnly) {
          $scope.gridOptions.toolbar = [
            {
              name: 'custom',
              template: templateToolBarAdd,
            },
          ];
        }

        // Columns and their labels
        /**
         * Template of edition column, containing edit and delete buttons
         * Stored minimis don't have id nor reference, so we use kendo generated uid to keep unique ids
         *
         * @param {object} minimi
         * @returns {string}
         */
        var templateToolBarEdit = (minimi) => {
          return [
            `<button id="minimi-${minimi.uid}-edit-line-button" type="button" class="btn btn-sm btn-primary"`,
            ' data-toggle="tooltip" title="{{viewConfiguration.ns + \'.edit.tooltip\' | translate}}" ',
            ' ng-click="editLine(dataItem)">',
            '     <span class="fa fa-edit" aria-hidden="true"></span>',
            "     {{viewConfiguration.ns + '.edit.button' | translate}}",
            '</button>&nbsp;&nbsp;',

            `<button id="minimi-${minimi.uid}-delete-line-button" type="button" class="btn btn-sm btn-danger"`,
            ' data-toggle="tooltip" title="{{viewConfiguration.ns + \'.remove.tooltip\' | translate}}" ',
            ' ng-click="confirmRemoveLine(dataItem)">',
            '   <span class="fa fa-trash-o" aria-hidden="true"></span>',
            "   {{viewConfiguration.ns + '.remove.button' | translate}}",
            '</button>',
          ].join('');
        };

        $scope.gridOptions.columns = [
          {
            field: 'exercice',
            format: '{0:yyyy}',
          },

          {
            field: 'projet',
          },

          {
            field: 'montant',
            format: '{0:c}',
            attributes: {
              class: 'align-right',
            },
          },

          {
            field: 'financeur',
          },

          {
            field: 'commentaire',
          },
        ];

        // set columns titles
        $scope.gridOptions.columns.forEach((column) => {
          column.title = $translate.instant(`${$scope.viewConfiguration.ns}.${column.field}.label`);
        });

        if (!$scope.readOnly) {
          $scope.gridOptions.columns.push({
            title: '',
            template: function (minimi) {
              return templateToolBarEdit(minimi);
            },
            attributes: {
              class: 'align-center',
            },
          });
        }

        // When the translations aren't loaded yet, this allows to update
        $scope.kRefresh = false;
        var translateChangeSuccess = $rootScope.$on('$translateChangeSuccess', function () {
          _.each($scope.gridOptions.columns, function (column) {
            if (column.title) {
              column.title = $translate.instant($scope.viewConfiguration.ns + '.grid.columns.' + column.field);
            }
          });
          // Forces kendo to update its grid
          $scope.kRefresh = true;
          $timeout(function () {
            $scope.kRefresh = false;
          });
        });
        $rootScope.$on('$destroy', translateChangeSuccess);

        // bornes min et max ajoutées à la configuration de l'exercice field
        var limits = {};
        if ($scope.exercice) {
          limits.minInclusive = String(new Date($scope.exercice.toString()).getFullYear() - 3);
          limits.maxInclusive = String(new Date($scope.exercice.toString()).getFullYear() - 1);
        } else {
          limits.minInclusive = String(new Date().getFullYear() - 2);
          limits.maxInclusive = String(new Date().getFullYear());
        }
        if (!_.get($scope.viewConfiguration, 'fields.exercice.restrictons')) {
          _.set($scope, 'viewConfiguration.fields.exercice.restrictions', []);
        }
        $scope.viewConfiguration.fields.exercice.restrictions.push(limits);

        $scope.saveChanges = function (e) {
          var gridModel = e.model.parent();
          $scope.minimis = _.map(gridModel, function (line) {
            var lineMinimis = _.pick(line, 'exercice', 'projet', 'montant', 'financeur', 'commentaire');
            lineMinimis.type = 'MINIMIS';
            return lineMinimis;
          });
          $scope.$apply();
        };

        $scope.gridOptions.save = $scope.saveChanges;
        $scope.gridOptions.remove = function (e) {
          // dirty, dirty hack. When remove is called the item is not yet taken from the model
          // wait next clock tick and it will be ok.
          $timeout(function () {
            $scope.saveChanges(e);
          }, 0);
        };

        $scope.item = {};

        /**
         * Add or valid a line of data and refresh the grid
         *
         * @param {object} modal Modal to close after validation
         * @param {number} index Index of the line
         * @param {object} item  Line of data
         */
        $scope.validLine = function (modal, index, item) {
          item.type = 'MINIMIS';
          if (index === -1) {
            $scope.minimis.push(item);
          } else {
            var dataItem = $scope.minimis[index];
            _.each(item, function (value, key) {
              dataItem.set(key, value);
            });
            $scope.minimis[index] = dataItem;
          }
          $scope.minimisGrid.refresh();
          modal.$hide();
        };

        /**
         * Add a new line to the grid
         */
        $scope.addLine = function () {
          var scopeModal = $scope.$new();
          scopeModal.item = {
            exercice: limits.maxInclusive,
            projet: '',
            financeur: '',
            commentaire: '',
          };

          scopeModal.validLine = $scope.validLine;
          $modal({
            scope: scopeModal,
            template: 'aides/aides-directives/minimis-form/modal/add-line.html',
          });
        };

        /**
         * Edit the line
         *
         * @param  {object} item Data of the line
         */
        $scope.editLine = function (item) {
          var scopeModal = $scope.$new();
          scopeModal.item = item.toJSON();
          scopeModal.validLine = $scope.validLine;
          scopeModal.index = _.indexOf($scope.minimis, item);
          scopeModal.viewConfiguration = $scope.viewConfiguration;
          $modal({
            scope: scopeModal,
            template: 'aides/aides-directives/minimis-form/modal/edit-line.html',
          });
        };

        /**
         * Remove the line of data source and refresh the grid
         *
         * @param {object} modal      Modal window to close after we have delete the ligne
         * @param {object} item  Data of the line
         */
        $scope.removeLine = function (modal, item) {
          $scope.minimis.splice(_.indexOf($scope.minimis, item), 1);
          $scope.minimisGrid.refresh();
          modal.$hide();
        };

        /**
         * [confirmRemoveLine description]
         *
         * @param  {object} item Data of the line
         */
        $scope.confirmRemoveLine = function (item) {
          var scopeModal = $scope.$new();
          scopeModal.item = item;
          scopeModal.removeLine = $scope.removeLine;
          $modal({
            scope: scopeModal,
            template: 'aides/aides-directives/minimis-form/modal/remove-line.html',
          });
        };
      },
    };
  },
]);
