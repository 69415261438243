'use strict';
angular.module('common.helpers').factory('ComponentsHelper', [
  '$document',
  '$timeout',
  function ($document, $timeout) {
    'use strict';

    return {
      /**
       * Find a element and focus it if exists
       * If the element is not natively focusable, add a tabindex attribute
       *
       * @param {string} selector CSS selector. Element to focus
       * @param {object} $element If empty, will use $document to find element
       */
      focusElement(selector, $element = $document) {
        // $timeout is used to trigger the focus in a digest cycle
        $timeout(() => {
          const element = $element.find(selector)?.[0];
          if (element) element.focus();
        }, 0);
      },
    };
  },
]);
