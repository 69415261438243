'use strict';
angular.module('portailDepotDemandeAide.depot').controller('demandePaiementRecapitulatifController', [
  '$scope',
  '$log',
  '$state',
  'demandesPaiementService',
  'paiementsService',
  'alertsService',
  'piecesService',
  'viewsService',
  'indicateursService',
  '$timeout',
  function (
    $scope,
    $log,
    $state,
    demandesPaiementService,
    paiementsService,
    alertsService,
    piecesService,
    viewsService,
    indicateursService,
    $timeout
  ) {
    'use strict';

    $scope.initializeChild();

    // Page Options
    $scope.pageOptions = $scope.teleserviceConfiguration.workflow.pageRecapitulatif;

    // Navigation
    $scope.cleanNavigate();
    $scope.navigate.ns = $scope.recapitulatifConfiguration.ns;
    $scope.viewConfiguration = $scope.recapitulatifConfiguration;
    $scope.navigate.noform = true;
    $scope.navigate.blockValidation = false;
    $scope.activePage();

    // check controlesEligibilite if there is an url to call on the teleservice
    if ($scope.teleserviceConfiguration.urlApiExterneControleEligibilite) {
      paiementsService
        .controleEligibilite($scope.demandePaiement)
        .then((controlesEligibilite) => {
          $scope.demandePaiement.controlesEligibilite = controlesEligibilite;
          if (
            controlesEligibilite &&
            controlesEligibilite.length > 0 &&
            controlesEligibilite.some((controle) => controle.blocking)
          ) {
            $scope.navigate.blockValidation = true;
          }
        })
        .catch(function () {
          $scope.navigate.blockValidation = true;
          $scope.$broadcast(
            'alerts',
            alertsService.getAlertError('teleservice.recapitulatif.controlesEligibilite.error'),
            'recapitulatif-alerts'
          );
        });
    }

    // Steps Wizard
    $scope.stepsWizard.steps = $scope.getDemandePaiementSteps();
    $scope.stepsWizard.active = 'recapitulatif';

    // Pieces - Documents
    $scope.urlFileIcons = './resources/images/file-icons/';

    // Spécifiques
    $scope.demandePaiement.specifiques = $scope.demandePaiement.specifiques || [];

    //Gestion de l'affichage des pieces en fonction de la condition d'affichage
    $scope.pieces = _.map($scope.demandePaiement.pieces, function (piece) {
      piece.isVisible = piecesService.evalConditionPiece($scope, piece, 'conditionAffichage');
      return piece;
    });

    // Translate fieldsName into path in demande
    var fieldsProperties = {
      montantDemande: {
        path: 'montant.ttc',
        dataType: 'decimal',
        subType: 'currency',
      },

      commentaire: {
        path: 'commentaire',
        dataType: 'string',
      },

      typePaiement: {
        path: 'typePaiement.title',
        dataType: 'string',
      },

      operationTerminee: {
        path: 'operationTerminee',
        dataType: 'boolean',
      },

      dateReelleFinOperation: {
        path: 'dateReelleFinOperation',
        dataType: 'date',
      },

      montantDepensesJustifiees: {
        path: 'montantDepensesJustifiees.ttc',
        dataType: 'decimal',
        subType: 'currency',
      },
    };

    var fieldsHidden = [];

    // If dispositif is present in typesPaiementAvecDepensesJustifiees, hide the field
    if (
      _.get($scope.teleserviceConfiguration, 'workflow.pageInformationsGenerales.typesPaiementAvecDepensesJustifiees')
    ) {
      fieldsHidden['montantDepensesJustifiees'] = !_.includes(
        _.get(
          $scope.teleserviceConfiguration,
          'workflow.pageInformationsGenerales.typesPaiementAvecDepensesJustifiees'
        ),

        _.get($scope.demandePaiement, 'typePaiement.title')
      );

      // Check if a value is set, else, set the field hidden
      if (
        !fieldsHidden['montantDepensesJustifiees'] &&
        _.isEmpty(_.get($scope.demandePaiement, 'montantDepensesJustifiees'))
      ) {
        fieldsHidden['montantDepensesJustifiees'] = true;
      }
    }

    // Complete a field's configuration with teleservice's configuration, it's reference and value
    var writeField = function (field, reference, path) {
      var fieldConfig =
        _.find($scope.teleserviceConfiguration.demandePaiement.fields, {
          reference: reference,
        }) || {};
      field.hidden = fieldsHidden[reference] || fieldConfig.hidden;
      field.reference = reference;
      field.dataType = _.get(fieldsProperties, reference + '.dataType', 'string');
      field.subType = _.get(fieldsProperties, reference + '.subType');
      field.value = _.get($scope.demandePaiement, path);
    };

    // Update informationGeneraleConfiguration from teleservice definition
    _.each($scope.teleserviceConfiguration.demandePaiement.fields, function (field) {
      const reference = field.reference.replace('informations-generales.', '');
      _.set($scope, 'informationsGeneralesConfiguration.fields.' + reference + '.required', field.required);
      _.set($scope, 'informationsGeneralesConfiguration.fields.' + reference + '.hidden', field.hidden);
      _.set($scope, 'informationsGeneralesConfiguration.fields.' + reference + '.dataType', field.dataType);
    });
    $scope.informationsGeneralesFields = _.transform(
      $scope.informationsGeneralesConfiguration.fields,
      function (fieldsList, field, reference) {
        if (fieldsProperties[reference]) {
          writeField(field, reference, fieldsProperties[reference].path);
          fieldsList.push(field);
        }
      },
      []
    );

    $scope.iframeInformationsComplementairesPaiementSrc = viewsService.getPageViewsIframeUrl({
      entity: $scope.demandePaiement,
      page: 'pageInformationsComplementaires',
      theme: 'demandeur',
      readOnly: true,
      hideExplanation: true,
      topTitleLevel: 5,
      contextHeaders: {
        'referentiel-tiers': [
          'Accept:application/vnd.mgdis.tiers-3.19.0+json',
          'Content-Type:application/vnd.mgdis.tiers-3.19.0+json',
        ],
      },
    });

    $scope.iframeIndicateursRealisationPaiement = indicateursService.getIndicateursIframeUrl(
      $scope.aide,
      true,
      'realized',
      true,
      null,
      $scope.demandePaiement.id
    );

    $scope.displayDocumentComptable = !!demandesPaiementService.getPlanFinancementDepose($scope.demandePaiement);
    $scope.iframeDocumentComptablePaiementSrc = demandesPaiementService.getPlanFinancementIframeUrl(
      $scope.demandePaiement,
      true
    );

    /**
     * Validation: send the demande and go to confirmation
     */
    $scope.navigate.validate = function () {
      $scope.attempted = true;

      if ($scope.cguAcceptation) {
        // Reception date
        $scope.demandePaiement.dateReception = new Date();

        $scope.demandePaiement.statut = 'TRANSMIS';

        // Send demande paiement
        $scope
          .save({ catchEventualErrors: false })
          .then(function () {
            $scope.goToStep('confirmation');
          })
          .catch(function (error) {
            $log.debug('An error occurred during demande paiement depot - ', error);

            // Restore previous statut value, and remove dateReception field
            $scope.demandePaiement.statut = 'EN_COURS';
            delete $scope.demandePaiement.dateReception;

            // Redirect to to login screen
            if (error && error.status === 401) {
              $state.go('app.home', {
                loggedOut: true,
              });
            }
          });
      }
    };

    $timeout(function () {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },

        '#iframeInformationsComplementairesPaiement, #iframeDocumentComptablePaiement'
      );
    }, 0);

    $timeout(function () {
      iFrameResize(
        {
          heightCalculationMethod: 'lowestElement',
          checkOrigin: false,
          inPageLinks: true,
        },

        '#iframeIndicateursRealisationPaiement'
      );
    }, 0);
  },
]);
