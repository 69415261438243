'use strict';
/**
 * @module common
 * @name common
 * @description Module who share some models for the portal
 */
angular.module('common', [
  'domain',
  'common.model',
  'common.services',
  'common.directives',
  'common.filters',
  'common.helpers',
  'common.components',
  'pascalprecht.translate',
]);

// angular.module('common.components', []);
