'use strict';
typesCaracteristiquesController.$inject = ['$scope'];
/**
 * @description Composant permettant de saisir des caractéristiques sociales
 */
angular.module('aides').component('typeCaracteristiquesSociales', {
  require: {
    model: 'ngModel',
  },

  bindings: {
    name: '@',
    pageOptions: '<',
  },

  templateUrl: 'aides/aides-directives/types-caracteristiques-sociales/types-caracteristiques-sociales.html',
  controller: typesCaracteristiquesController,
});

typesCaracteristiquesController.$inject = ['$scope'];

/**
 *
 * @param {object} $scope
 */
function typesCaracteristiquesController($scope) {
  var vm = this;

  $scope.viewConfiguration = this.viewConfiguration;

  vm.$onInit = function () {
    initListTypes();

    vm.model.$render = function () {
      onModelChange();
    };
  };

  /**
   * Init the list of type from teleservice
   */
  function initListTypes() {
    vm.listeTypes = _.map(_.get(vm.pageOptions, 'typesCaracteristiqueSociale'), function (type) {
      return {
        typeCaracteristiques: {
          href: type.href,
          title: type.title,
        },

        caracteristiques: [],
      };
    });
  }

  /**
   * When type value have changed
   *
   * @param {object} type
   */
  vm.onTypeUpdated = function (type) {
    var editedType = _.find(vm.model.$viewValue, function (modelType) {
      return modelType.typeCaracteristiques.href === type.typeCaracteristiques.href;
    });
    if (editedType) {
      editedType.caracteristiques = type.caracteristiques;
    } else {
      var model = vm.model.$viewValue || [];
      model.push(type);
      updatedModel(model);
    }
  };

  /**
   * Update the component model when value change
   * Force change reference by cloning to fire change event
   *
   * @param {*} types
   */
  function updatedModel(types) {
    if (_.isEmpty(types)) {
      vm.model.$setViewValue(undefined);
    } else {
      vm.model.$setViewValue([].concat(types));
    }
  }

  /**
   * Called when the model change from outside
   * we change caracteristique value of each type
   */
  function onModelChange() {
    if (vm.model.$viewValue) {
      _.each(vm.model.$viewValue, function (typeWithCaracteristique) {
        var viewType = _.find(vm.listeTypes, function (type) {
          return type.typeCaracteristiques.href === typeWithCaracteristique.typeCaracteristiques.href;
        });
        viewType.caracteristiques = typeWithCaracteristique.caracteristiques;
      });
    } else {
      vm.model.$setViewValue([].concat(vm.listeTypes));
    }
  }
}
