'use strict';
angular.module('common.directives').directive('domiciliationFiche', [
  '$log',
  '$modal',
  'tiersService',
  'cmisService',
  function ($log, $modal, tiersService, cmisService) {
    'use strict';

    return {
      replace: true,
      templateUrl: 'common/common-directives/domiciliation-fiche/domiciliation-fiche.html',
      scope: {
        namespace: '=?',
        viewConfiguration: '=?',
        domiciliation: '=',
        selected: '=?',
        select: '&?',
        onUpdate: '=?',
        validationIbanFr: '=',
        tiers: '=',
        readOnly: '<', // disable edit and delete btns
        unselectable: '<', // disable select btn
        status: '=?',
        hideDocument: '<',
      },

      link: function (scope, element, attributes) {
        scope.select = attributes.select ? scope.select : false;

        scope.$watch(
          'tiers',
          function () {
            scope.indexDom = _.findIndex(_.get(scope, 'tiers.domiciliationsBancaires'), function (domLink) {
              if (_.find(_.get(scope, 'domiciliation.id'))) {
                return domLink.id === scope.domiciliation.id;
              }
            });
          },
          true
        );

        // Add allowedExtensions by default
        scope.allowedExtensions = [
          'pdf',
          'doc',
          'docx',
          'xls',
          'xlsx',
          'png',
          'jpg',
          'jpeg',
          'ppt',
          'pptx',
          'gif',
          'zip',
          'odt',
          'odp',
          'ods',
          'odg',
        ];

        scope.shouldDisplayDocument = !!(
          !scope.domiciliation.conforme ||
          scope.tiers?.domiciliationsBancaires?.some((domiciliation) => domiciliation.id === scope.domiciliation.id)
        );

        /**
         * Extract RIB documents from domiciliation pieces
         */
        function getRibDocumentsFromDomiciliationPieces() {
          const rib = scope.domiciliation.pieces.find((piece) => piece.reference === 'rib');

          if (rib) {
            scope.tabRibs = angular.copy(rib.documents);
          }
        }

        /**
         * Ensure that RIB documents are expanded (and expand them if they are not)
         *
         * @returns {Promise<void>}
         */
        function ensureThatRibDocumentsAreExpanded() {
          return cmisService
            .expandAllPiecesDocuments(scope.domiciliation.pieces, true)
            .catch((err) => $log.error('[domiciliation-fiche] Failed expand RIB documents', err));
        }

        ensureThatRibDocumentsAreExpanded().then(getRibDocumentsFromDomiciliationPieces);

        var saveDomiciliation = function (modal, domiciliation) {
          if (scope.indexDom > -1) {
            scope.tiers.domiciliationsBancaires[scope.indexDom] = domiciliation;
            tiersService.saveTiers(scope.tiers).then(function () {
              if (scope.selected) {
                // Only update parent if the domiciliation is selected
                scope.onUpdate(domiciliation);
              }
              modal.$hide();
            });
          }
        };

        scope.edit = function () {
          var scopeModal = scope.$new();
          scopeModal.saveDomiciliation = saveDomiciliation;
          scopeModal.domiciliation = angular.copy(scope.domiciliation);
          scopeModal.urlDocuments = '/gestion-depot-demandes/tiers/' + scope.tiers.reference + '/documents/';
          scopeModal.entity = scope.tiers;
          scopeModal.kind = 'tiers';

          $modal({
            scope: scopeModal,
            viewConfiguration: scope.viewConfiguration,
            template: 'common/common-directives/domiciliation-fiche/modal/edit-domiciliation.html',
            backdrop: 'static',
          });
        };

        var deleteDomiciliation = function (modal) {
          if (scope.indexDom > -1) {
            scope.tiers.domiciliationsBancaires.splice(scope.indexDom, 1);
            tiersService.saveTiers(scope.tiers).then(function () {
              // The parent must be updated even if the deleted domiciliation wasn't selected
              scope.onUpdate();
              modal.$hide();
            });
          }
        };

        scope.deleteDom = function () {
          var scopeModal = scope.$new();
          scopeModal.deleteDomiciliation = deleteDomiciliation;
          $modal({
            scope: scopeModal,
            viewConfiguration: scope.viewConfiguration,
            template: 'common/common-directives/domiciliation-fiche/modal/delete-domiciliation.html',
            backdrop: 'static',
          });
        };
      },
    };
  },
]);
