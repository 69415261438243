'use strict';
/**
 * @param {object} $http
 * @param {object} $log
 * @param {object} demandesPaiementService
 * @name common.services.paiementService
 * @description Manage a list of "paiement"
 * @returns {object}
 */
angular.module('common.services').factory('paiementService', [
  '$http',
  '$log',
  'demandesPaiementService',
  function ($http, $log, demandesPaiementService) {
    'use strict';

    let _tabPaiements = [];

    /**
     * get array of paiement line
     *
     * @returns {object[]} array of paiement line
     */
    const getTabPaiement = function () {
      return _tabPaiements;
    };

    /**
     * add a ligne to paiement's line array
     *
     * @param {string} lb paiement's libelle
     * @param {string} dateReal date realisation
     * @param {number} montant montant
     * @param {string} statut paiement's statut
     * @param {number} montantPrevisionnel montant previsionnel
     */
    const setTabPaiement = function (lb, dateReal, montant, statut, montantPrevisionnel) {
      _tabPaiements.push({
        libelle: lb,
        date: dateReal,
        montant: montant,
        statut: statut,
        montantPrevisionnel,
      });
    };

    /**
     * Function to add demande paiement with transmitted status on tab
     *
     * @param {object} dp demande paiement
     * @returns {void}
     */
    const addDPTransmis = function (dp) {
      const mt = _.get(dp, 'montantPropose.ttc', _.get(dp, 'montant.ttc', 0));
      const dtReal = dp.dateReception;
      setTabPaiement(dp.title, dtReal, mt, dp.statut, _.get(dp, 'montantPrevisionnel.ttc', 0));
    };

    /**
     * Function to add demande paiement with proposed status on tab
     *
     * @param {object} dp demande paiement
     * @returns {void}
     */
    const addDPPropose = function (dp) {
      const mt = demandesPaiementService.getMontantPropose(dp);
      const dtReal = demandesPaiementService.getDateProposition(dp);
      setTabPaiement(dp.title, dtReal, mt, dp.statut, _.get(dp, 'montantPrevisionnel.ttc', 0));
    };

    /**
     * Function to add demande paiement with paid status on tab
     *
     * @param {object} dp demande paiement
     * @returns {void}
     */
    const addDPLiquidation = function (dp) {
      const montant = demandesPaiementService.getMostAdvancedMontantOnDemandePaiement(dp);
      const dateDepot = demandesPaiementService.getDateDepot(dp);
      setTabPaiement(dp.title, dateDepot, montant, dp.statut, _.get(dp, 'montantPrevisionnel.ttc', 0));
    };

    /**
     * Function to add demande paiement with financial rejected status on tab
     *
     * @param {object} dp demande paiement
     * @returns {void}
     */
    const addDPRejetFinancier = function (dp) {
      const mt = demandesPaiementService.getMostAdvancedMontantOnDemandePaiement(dp);
      const dtRejet = _.get(dp, 'paiement.liquidations.0.dateRejetFinancier');
      setTabPaiement(dp.title, dtRejet, mt, dp.statut, _.get(dp, 'montantPrevisionnel.ttc', 0));
    };

    /**
     * building a paiement line according to its status
     *
     * @param {object[]} demandesPaiement array of demandes paiement
     * @returns {object[]} array of paiement line
     */
    const buildTabPaiement = function (demandesPaiement = []) {
      // Reinit _tabPaiements
      _tabPaiements = [];
      demandesPaiement.forEach(function (demandePaiement) {
        const statut = demandePaiement.statut;
        switch (statut) {
          case 'TRANSMIS':
          case 'REJETE':
          case 'INSTRUCTION':
          case 'REFUSE':
          case 'ACCEPTABLE':
          case 'SUPPORTED':
            addDPTransmis(demandePaiement);
            break;
          case 'PROPOSE':
            addDPPropose(demandePaiement);
            break;
          case 'LIQUIDE':
          case 'LIQUIDE_PARTIELLEMENT':
          case 'MANDATE':
          case 'MANDATE_PARTIELLEMENT':
          case 'PAYE':
          case 'PAYE_PARTIELLEMENT':
            addDPLiquidation(demandePaiement);
            break;
          case 'REJETFINANCIER':
            addDPRejetFinancier(demandePaiement);
            break;
          case 'EN_COURS':
          case 'PREVISIONNEL':
            break;
          default:
            $log.error('Unrecognized statut [' + statut + '] ', demandePaiement);
            break;
        }
      });
      return getTabPaiement();
    };

    return {
      /**
       * Build paiements table
       *
       * @param {object[]} demandesPaiement array of demandes paiement
       * @returns {object[]} paiements table
       */
      buildPaiementsTable: function (demandesPaiement) {
        // Sort by dateReception
        demandesPaiement = _.sortBy(demandesPaiement || [], function (demandePaiement) {
          return new Date(demandePaiement.dateReception);
        });

        // build paiements tab
        return buildTabPaiement(demandesPaiement);
      },

      /**
       * get the types of paiement
       *
       * @param {string} teleservicePaiementId teleservice paiement identifier
       * @returns {Promise<object>} teleservice's types paiement
       */
      getTypesPaiement: function (teleservicePaiementId) {
        return $http.get(teleservicePaiementId).then(function (response) {
          const typesPaiement = _.get(
            response,
            'data.workflow.demandePaiement.pageInformationsGenerales.typesPaiement',
            []
          );

          return typesPaiement;
        });
      },
    };
  },
]);
