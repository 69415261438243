'use strict';
angular.module('tiers.model').factory('Individu', [
  'Noms',
  'Prenoms',
  'Naissance',
  function (Noms, Prenoms, Naissance) {
    'use strict';

    /**
     *
     * @param {object} data
     */
    function Individu(data) {
      data = data || {};
      this.Noms = new Noms(data.Noms);
      this.Prenoms = new Prenoms(data.Prenoms);
      this.Naissance = new Naissance(data.Naissance);
      this.Filiation = data.Filiation || undefined;
    }

    Individu.prototype.getCleanEntity = function () {
      var individuClone = _.cloneDeep(this);
      // identité
      if (!individuClone.Noms.NomUsage) {
        individuClone.Noms.NomUsage = '';
      }
      if (!individuClone.Noms.NomFamille) {
        individuClone.Noms.NomFamille = '';
      }
      if (!individuClone.Prenoms.PrenomUsuel) {
        individuClone.Prenoms.PrenomUsuel = '';
      }

      // Date de naissance
      if (_.get(individuClone, 'Naissance.DateNaissanceDisplay')) {
        delete individuClone.Naissance.DateNaissanceDisplay;
      }

      if (_.get(individuClone, 'Naissance.DateNaissance')) {
        individuClone.Naissance.DateNaissance = moment(individuClone.Naissance.DateNaissance).format('YYYY-MM-DD');
      } else {
        delete individuClone.Naissance.DateNaissance;
      }

      // Division territoriale
      if (
        _.get(individuClone, 'Naissance.LieuNaissance.DivisionTerritoriale') &&
        _.isEmpty(individuClone.Naissance.LieuNaissance.DivisionTerritoriale)
      ) {
        delete individuClone.Naissance.LieuNaissance.DivisionTerritoriale;
      }

      if (
        _.get(individuClone, 'Naissance.LieuNaissance.Localite') &&
        !individuClone.Naissance.LieuNaissance.Localite.value
      ) {
        delete individuClone.Naissance.LieuNaissance.Localite;
      }

      return individuClone;
    };

    return Individu;
  },
]);

angular.module('tiers.model').factory('Noms', [
  function () {
    'use strict';

    /**
     *
     * @param {object} data
     */
    function Noms(data) {
      data = data || {};
      this.NomUsage = data.NomUsage || '';
      this.NomFamille = data.NomFamille || '';
    }

    return Noms;
  },
]);

angular.module('tiers.model').factory('Prenoms', [
  function () {
    'use strict';

    /**
     *
     * @param {object} data
     */
    function Prenoms(data) {
      data = data || {};
      this.PrenomUsuel = data.PrenomUsuel || '';
    }

    return Prenoms;
  },
]);

angular.module('tiers.model').factory('Naissance', [
  'LieuNaissance',

  function (LieuNaissance) {
    'use strict';

    /**
     *
     * @param {object} data
     */
    function Naissance(data) {
      data = data || {};
      this.DateNaissance = data.DateNaissance;
      this.LieuNaissance = new LieuNaissance(data.LieuNaissance);
    }

    return Naissance;
  },
]);

angular.module('tiers.model').factory('LieuNaissance', [
  'Localite',

  function (Localite) {
    'use strict';

    /**
     *
     * @param {object} data
     */
    function LieuNaissance(data) {
      data = data || {};
      this.Localite = new Localite(data.Localite);
      this.DivisionTerritoriale = data.DivisionTerritoriale;
      this.Pays = data.Pays;
    }

    return LieuNaissance;
  },
]);
