'use strict';
DocumentComptableSousSectionAideFormController.$inject = [
  '$modal',
  '$timeout',
  '$element',
  '$scope',
  '$http',
  '$translate',
  '$q',
  'numberService',
  'detailsService',
  'documentService',
];
/**
 * @module aides
 * @name documentComptableSousSectionAideForm
 * @description
 *
 *   Sous section d'un plan de financement éditable d'une aide
 * @param {object} sousSection - section's model
 * @param {string} typeMontant - type to display after the financial values (ex: 'ttc')
 * @param {object} viewConfiguraton - view configuration
 * @param {object} configurationOptions - configuration options
 * @param {boolean} isFirst
 * @param {boolean} readOnly
 * @param {object} sousSectionTemplate
 * @example
 *
 *  `<document-comptable-sous-section-aide-form
 *    sous-section="documentComptable.depense"
 *    type-montant="typeMontant"
 *    view-configuration="depenseViewConfiguration"
 *    configuration-options="configurationOptions"
 *    is-first="isFirst"
 *    read-only="readOnly"
 *    sous-section-template="teleserviceConfiguration.workflow.pageDocumentComptable.typeDocumentComptable.planFinancement.depense"
 *    plan-financement-avance="teleserviceConfiguration.planFinancementAvance"
 * >
 *  </document-comptable-sous-section-aide-form>`
 */
angular.module('aides').component('documentComptableSousSectionAideForm', {
  templateUrl:
    'aides/aides-directives/document-comptable-sous-section-aide-form/document-comptable-sous-section-aide-form.html',
  controller: /* @ngInject */ DocumentComptableSousSectionAideFormController,
  bindings: {
    sousSection: '<',
    typeMontant: '<',
    displayTypeMontant: '<',
    viewConfiguration: '<',
    configurationOptions: '<',
    isFirst: '<',
    readOnly: '<',
    sousSectionTemplate: '<',
    calculateAutofinancement: '=',
    updateSousTotal: '=',
    planFinancementAvance: '<',
    postesPlanFinancement: '<',
    teleserviceConfiguration: '<',
    dateCreation: '<',
    demandeFinancement: '<',
  },

  require: {
    form: '?^^form',
  },
});

/**
 *
 * @param {object} $modal
 * @param {object} $timeout
 * @param {object} $element
 * @param {object} $scope
 * @param {object} $http
 * @param {object} $translate
 * @param {object} $q
 * @param {object} numberService
 * @param {object} detailsService
 * @param {object} documentService documentService
 */
function DocumentComptableSousSectionAideFormController(
  $modal,
  $timeout,
  $element,
  $scope,
  $http,
  $translate,
  $q,
  numberService,
  detailsService,
  documentService
) {
  const ctrl = this;

  // store details panels statuses
  ctrl.detailsPanel = {};

  ctrl.detailsConfiguration = {};

  ctrl.shadowSousSection = {};

  ctrl.decimals = 2;
  ctrl.postesVisibles = {};
  ctrl.precisions = {}; // Indicates for each line and post if precisions are displayed or not
  ctrl.lignesOptionnelles = {};
  ctrl.lignesFormConfiguration = {};
  ctrl.displayAddPoste = {}; // Display the section that manages new poste adding
  ctrl.newLigneEntry = {};
  ctrl.canCreateLignes = {};
  ctrl.posteGenerique = null;
  ctrl.enableEnterNewLigneButton = {};
  ctrl.isDuplicateLine = {};
  ctrl.parentPostes = {};

  ctrl.$onInit = () => {
    ctrl.numberService = numberService;
    // Get configuration options
    ctrl.hidePrecisions = ctrl.configurationOptions['hide-precisions'];
    ctrl.precisionObligatoire = ctrl.configurationOptions['precision-obligatoire'];
    ctrl.negativeAmounts = ctrl.configurationOptions['negative-amounts'];

    ctrl.displayTypeMontant = ctrl.displayTypeMontant || false;

    ctrl.planFinancementType = ctrl.viewConfiguration.ns.split('.').pop();
    ctrl.planFinancementAvance = ctrl.planFinancementAvance || false;

    // Check if at least one ligne as detailActif activated with one of the details visible
    ctrl.hasDetails =
      ctrl.planFinancementAvance &&
      !_.isEmpty(
        JSONPath(
          "$.[?(@.lignes || @.sousPostes)][*][?(@.detailActif == true)]..[?(@.visibilite == 'VISIBLE')]",
          ctrl.sousSectionTemplate
        )
      );

    ctrl.shouldDisplayPrecisionButton = !ctrl.hidePrecisions && !ctrl.precisionObligatoire;
    ctrl.precisionRequired = ctrl.precisionObligatoire && !ctrl.hidePrecisions;

    ctrl.configurationPrecision = {
      required: ctrl.precisionRequired,
      noExtendField: true,
      fieldWidth: 8,
      label: $translate.instant(ctrl.viewConfiguration.ns + '.colHeader.commentaire'),
    };

    ctrl.montantOptions = {
      format: 'c',
      spinners: false,
      change: () => ctrl.calculateAmounts(),
      min: ctrl.negativeAmounts ? undefined : 0,
    };

    // Indexes by reference postes with allowLignCreation
    ctrl.canCreateLignes = _.get(ctrl.sousSectionTemplate, 'postes', []).reduce((acc, poste) => {
      acc[poste.reference] = poste.allowLigneCreation;
      return acc;
    }, {});

    ctrl.initPlanFinancement();

    //as we need ng-model (for form validation) AND k-ng-model (for data manipulation), this object is bound to unique
    //references to ensure no mutual conflicts between the 2 directives.
    const lines = JSONPath('$..[?(@.montant)]', ctrl.sousSection);
    _.forEach(lines, (line) => {
      _.set(ctrl.shadowSousSection, line.reference, line.montant[ctrl.getTypeMontant(line.reference)]);
    });
    ctrl.oldNbLignesWithMontant = ctrl.getNbLignesWithMontant();

    ctrl.watchSousSection();
    return;
  };
  /**
   * Indicates if booleanValue is empty
   *
   * @param {object} value
   * @returns {boolean}
   */
  ctrl.booleanValueIsNotEmpty = (value) => {
    return typeof value === 'boolean' || typeof value === 'string';
  };
  /**
   * Indicates if detail is disabled for a line
   *
   * @param {object} ligne
   * @returns {boolean}
   */
  ctrl.detailDisabled = (ligne) => {
    const disabled = !_.get(ligne, `montant.${ctrl.getTypeMontant(ligne.reference)}`);
    _.set(ctrl.detailsPanel, `${ligne.reference}.disabled`, disabled);
    return disabled;
  };

  /**
   * Get details teleservice configuration
   *
   * @param {object} ligne
   * @param {object} sousPoste
   * @returns {object}
   */
  ctrl.getDetailsConfiguration = (ligne, sousPoste) => {
    // Set object
    const detailsConfiguration = {};
    // if detailActif and one detail visible
    const reference = !sousPoste ? ligne.reference : sousPoste.reference;
    let template;
    if (ligne.createdByUser) {
      template = _.find(ctrl.sousSectionTemplate.postes, (poste) => poste.reference === reference);
      ctrl.hasDetails = ctrl.hasDetails || _.has(template, 'details');
    } else {
      [template] = JSONPath(
        `$.[?(@.lignes || @.sousPostes)][*][?(@.reference == '${reference}')]`,
        ctrl.sousSectionTemplate
      );
    }

    const ligneHasDetailActif = _.get(template, 'detailActif', false);
    const ligneHasDetailVisible = !_.isEmpty(JSONPath("$.[?(@.visibilite == 'VISIBLE')]", _.get(template, 'details')));
    const ligneHasDocuments = JSONPath('$.pieces..documents[*]', ligne).length > 0;

    detailsConfiguration.hasDetails =
      (ctrl.hasDetails && ligneHasDetailActif && ligneHasDetailVisible) || ligneHasDocuments;

    if (ligneHasDetailActif) {
      // get configuration for each details
      detailsConfiguration.details = detailsService.getDetailsConfiguration(_.get(template, 'details', {}));
      _.forEach(detailsConfiguration.details, (detail, key) => {
        if (detail.fieldType === 'select') {
          detailsService.getMasterData(key).then((masterdata) => {
            detailsConfiguration.details[key].defaultValues = masterdata;
            detail.model = _.find(masterdata.array, (value) => {
              return value.expand.reference === _.get(ligne, `details.${key}.href`);
            });
          });
        } else if (detail.fieldType === 'boolean') {
          detailsConfiguration.details[key].configuration.valueTrue = $translate.instant(
            `${ctrl.viewConfiguration.ns}.details.${key}.yes`
          );

          detailsConfiguration.details[key].configuration.valueFalse = $translate.instant(
            `${ctrl.viewConfiguration.ns}.details.${key}.no`
          );
        }
        ctrl.setDefaultValues(ligne, key, detail);
      });
    }
    return detailsConfiguration;
  };

  /**
   * Set the default value for a line's detail
   *
   * @param {object} ligne
   * @param {string} key
   * @param {object} conf
   * @returns {void}
   */
  ctrl.setDefaultValues = (ligne, key, conf) => {
    // Set default value to ligne details
    if (conf.valeurParDefaut && !_.has(ligne, `details.${key}`)) {
      _.set(ligne, `details.${key}`, conf.valeurParDefaut);
    }
  };

  /**
   * expand/hide all details panels (if not disabled)
   *
   * @param {boolean} expand
   * @returns {void}
   */
  ctrl.expandAllDetails = (expand) => {
    _.forEach(ctrl.detailsPanel, (value, key) => {
      ctrl.detailsPanel[key].expanded = !ctrl.detailsPanel[key].disabled && expand;
    });
  };

  /**
   * define if ligne reference has error
   *
   * @param {string} reference
   * @returns {void}
   */
  ctrl.hasError = (reference) => {
    const hasError =
      !_.has(ctrl.form, 'valid') && angular.element(document).find(`#details-${reference} .has-error`).length > 0;
    _.set(ctrl.detailsPanel, `${reference}.expanded`, hasError);
  };

  /**
   * function to know if all details panels are opens
   *
   * @param {boolean} expand
   * @returns {boolean}
   */
  ctrl.allPanelsExpanded = (expand) => {
    return _.isEmpty(_.find(ctrl.detailsPanel, ['expanded', !expand]));
  };

  /**
   * function to return details panels ids
   *
   * @returns {string}
   */
  ctrl.detailsPanelIds = () => {
    return _.map(_.keys(ctrl.detailsPanel), (detail) => `details-${detail}`).join(' ');
  };

  /**
   * Set ligne details values
   *
   * @param {object} ligne
   * @param {string} key
   * @param {object} value
   * @returns {void}
   */
  ctrl.setDetailValue = (ligne, key, value) => {
    _.set(ligne, `details.${key}`, {
      title: _.get(value, `expand.libelle.value`),
      href: _.get(value, `expand.reference`),
    });
  };

  ctrl.$onChanges = (changes) => {
    if (changes.typeMontant && !_.isEmpty(changes.typeMontant.previousValue)) {
      ctrl.initPlanFinancement();
      ctrl.calculateAmounts();
    }
    ctrl.lignes = JSONPath('$..lignes[?(@.reference)]', ctrl.sousSection);
  };

  ctrl.$doCheck = () => {
    const currentNbLignesWithMontant = ctrl.getNbLignesWithMontant();
    if (currentNbLignesWithMontant !== ctrl.oldNbLignesWithMontant) {
      _.forEach(ctrl.lignes, (ligne) => {
        // Set precisions
        // remove null montant
        if (ligne.montant && _.isNull(ligne.montant[ctrl.getTypeMontant(ligne.reference)]))
          delete ligne.montant[ctrl.getTypeMontant(ligne.reference)];
        ctrl.precisions[ligne.reference] = ctrl.getPrecision(ligne);
      });

      ctrl.oldNbLignesWithMontant = currentNbLignesWithMontant;
    }
  };

  /**
   * Get number of lines with a montant
   *
   * @returns {number}
   */
  ctrl.getNbLignesWithMontant = () =>
    _.filter(JSONPath('$[?(@.montant)]', ctrl.lignes), (line) => !!line.montant[ctrl.getTypeMontant(line.reference)])
      .length;

  /**
   * Update montant event
   *
   * @param {object} event
   * @returns {void}
   */
  ctrl.montantOnKeyUp = (event) => {
    if (!event.key.match(/[0-9]|Backspace|Delete|ArrowUp|ArrowDown/)) return;
    const el = angular.element(event.currentTarget);
    const kendoNumericTextBox = el.data('kendoNumericTextBox');
    // override the internal _adjut method for Kendo NumericTextBox.
    kendoNumericTextBox._adjust = (value) => {
      return value;
    };
    const nativeValue = kendoNumericTextBox.element.val();
    // prevent trailling 0 overwrite (ex : 10,0 becomes 10) for positive and negative numbers
    if (nativeValue.match(/^-?\d+,0{1,2}$/)) return;
    // Update Kendo model
    kendoNumericTextBox.value(nativeValue);
    // Trigger Kendo change event
    kendoNumericTextBox.trigger('change');
  };

  /**
   * Used as filter to display only visible sousPoste
   *
   * @param {object} elem
   * @returns {boolean}
   */
  ctrl.sousPosteIsVisible = (elem) => {
    return elem.visible;
  };

  /**
   * Indicates if the line is in a financeur mode
   *
   * @param {object} ligne
   * @returns {boolean}
   */
  ctrl.isLigneFinanceur = (ligne) => {
    return ['MULTIPLE_FINANCEUR', 'PRINCIPAL_FINANCEUR'].includes(ligne.mode);
  };

  /**
   * display precision zone on first loaded page
   *
   * @param {string} reference
   * @returns {void}
   */
  ctrl.showPrecision = (reference) => {
    const keyPath = `precisions.${reference}.displayDescription`;
    let currentValue = _.get(ctrl, keyPath, false);
    if (!_.isBoolean(currentValue)) currentValue = false;
    _.set(ctrl, keyPath, !currentValue);
  };

  /**
   * Init Plan Financement
   *
   * @returns {void}
   */
  ctrl.initPlanFinancement = () => {
    if (!ctrl.sousSection) return;
    _.forEach(ctrl.sousSection.postes, (poste) => {
      _.forEach(poste.lignes, (ligne) => {
        // Set the initial visibility of the ligne
        if (_.isUndefined(ligne.optionnel)) {
          ligne.optionnel = !ligne.visible && !ligne.obligatoire;
        }
        // Get ligne (min/max) configuration
        ctrl.lignesFormConfiguration[ligne.reference] = ctrl.getLineConfiguration(ligne);
        // Get details configuration
        ctrl.detailsConfiguration[ligne.reference] = ligne.createdByUser
          ? ctrl.getDetailsConfiguration(ligne, poste)
          : ctrl.getDetailsConfiguration(ligne);
        // Set precisions
        ctrl.precisions[ligne.reference] = ctrl.getPrecision(ligne);
      });
      _.forEach(poste.sousPostes, (sousPoste) => {
        _.forEach(sousPoste.lignes, (ligne) => {
          // Fix ligne.obligatoire
          if (ligne.obligatoire === true && !sousPoste.obligatoire) {
            ligne.obligatoire = false;
          }
          // Get ligne (min/max) configuration
          ctrl.lignesFormConfiguration[ligne.reference] = ctrl.getLineConfiguration(ligne);
          // Get details configuration
          ctrl.detailsConfiguration[ligne.reference] = ctrl.getDetailsConfiguration(ligne, sousPoste);
          // Set precisions
          ctrl.precisions[ligne.reference] = ctrl.getPrecision(ligne);
        });
      });
    });

    ctrl.manageLignesOptionnelles();
  };

  /**
   * get line view configuration
   *
   * @param {object} line
   * @returns {object}
   */
  ctrl.getLineConfiguration = (line) => {
    const linePF = ctrl.getPosteFromArray(line.reference, ctrl.postesPlanFinancement);
    let montantMin;
    let montantMax;
    const typeMontant = _.has(line, 'montant.ttc') ? 'ttc' : 'ht';

    // Only set the min and max if we must do the control locally
    if (!ctrl.teleserviceConfiguration.controleCompletudeDepot) {
      montantMin = linePF?.montantMin;
      montantMax = linePF?.montantMax;

      // Set montantMin to minInclusive if not set
      if (montantMin === undefined && ctrl.montantOptions.min !== null) {
        montantMin = ctrl.montantOptions.min;
      }
    }

    // Affect configuration attribute to line
    return {
      montantMin,
      montantMax,
      error: {
        max: $translate.instant(`${ctrl.viewConfiguration.ns}.montantPoste.error.max`, { montant: montantMax }),
        min: $translate.instant(`${ctrl.viewConfiguration.ns}.montantPoste.error.min`, { montant: montantMin }),
      },
      typeMontant,
    };
  };

  /**
   * Check if line respect Min Max conditions
   *
   * @param {object} ligne
   * @returns {void}
   */
  ctrl.checkMinMax = (ligne) => {
    // If teleserviceConfiguration.controleCompletudeDepot is enabled
    // the control is made at the end of the demandeFinancement
    // so we don't need to check the amounts and display errors
    if (ctrl.teleserviceConfiguration.controleCompletudeDepot) {
      return;
    }

    const lineConf = ctrl.lignesFormConfiguration[ligne.reference];
    let montantLigne = _.get(ctrl, `shadowSousSection.${ligne.reference}`);

    // By default, everything is valid
    let checkMax = true;
    let checkMin = true;

    // Make min and max comparisons
    if (montantLigne !== undefined) {
      // parse montantLigne to get value with .
      montantLigne = parseFloat(montantLigne.replace(',', '.'));
      // checks
      checkMax =
        !_.isNaN(montantLigne) && lineConf.montantMax !== undefined ? montantLigne <= lineConf.montantMax : true;
      checkMin =
        !_.isNaN(montantLigne) && lineConf.montantMin !== undefined ? montantLigne >= lineConf.montantMin : true;
    }

    // Update form validity
    if (ctrl.form) {
      ctrl.form[ligne.reference].$setValidity('max', checkMax);
      ctrl.form[ligne.reference].$setValidity('min', checkMin);
    }
  };

  /**
   * Create the precision object for a ligne
   *
   * @param {object} ligne
   * @returns {object}
   */
  ctrl.getPrecision = (ligne) => {
    const precision = {
      displayDescription: false,
      displayOnlyLabel: false,
    };

    const hasAmount = ctrl.hasAmount(ligne);
    precision.displayDescription = hasAmount && (ctrl.precisionObligatoire || !_.isEmpty(ligne.commentaire));

    if (
      ligne.commentaire &&
      ((ctrl.readOnly && !ctrl.hidePrecisions) || (ctrl.shouldDisplayPrecisionButton && !hasAmount))
    ) {
      precision.displayOnlyLabel = true;
      precision.displayDescription = true;
    }
    return precision;
  };

  /**
   * Hide/show lines
   * Fill/unfill the lignesOptionnelles object
   * Fill/unfill the postesVisibles object
   *
   * @returns {void}
   */
  ctrl.manageLignesOptionnelles = () => {
    ctrl.lignesOptionnelles = {};
    ctrl.postesVisibles = {};

    ctrl.sousSection.postes.forEach((poste) => {
      const lignes = poste.lignes || [];
      const sousPostes = poste.sousPostes || [];

      // Get optional postes and sousPostes
      const postesOptionnels = lignes.filter(
        (ligne) => !ligne.agentOnly && ligne.optionnel && !ligne.visible && !ligne.reference.match('FICTIVE')
      );

      const sousPostesOptionnels = sousPostes.filter(
        (sousPoste) => sousPoste.optionnel && !sousPoste.visible && !sousPoste.agentOnly
      );

      const lignesOptionnelles = _.cloneDeep(postesOptionnels.concat(sousPostesOptionnels));
      lignesOptionnelles.forEach((ligne) => (ctrl.parentPostes[ligne.reference] = poste));

      // Index by poste.reference
      ctrl.lignesOptionnelles[poste.reference] = lignesOptionnelles;

      // Determines if the "poste" object contains at least one visible line
      const postHasVisibleLines = lignes.some(
        (ligne) => (!ligne.optionnel || ligne.visible) && ligne.agentOnly !== true
      );
      const postHasVisibleSousPostes = sousPostes.some(
        (sousPoste) => (!sousPoste.optionnel || sousPoste.visible) && sousPoste.agentOnly !== true
      );
      ctrl.postesVisibles[poste.reference] = postHasVisibleLines || postHasVisibleSousPostes;
    });
  };

  /**
   * Returns lines with matching libelle
   *
   * @param {string} libelle
   * @param {object[]} lignes
   * @returns {object[]}
   */
  ctrl.getLignesWithLibelle = (libelle, lignes) => {
    return lignes.filter(
      (ligne) => _.get(ligne, 'libelle.value', '').toUpperCase().trim() === libelle.toUpperCase().trim()
    );
  };

  /**
   * Indicates if a libelle is already present in poste's lines
   *
   * @param {string} libelle
   * @param {object} poste
   * @returns {boolean}
   */
  ctrl.isLibelleInPoste = (libelle, poste) => {
    const lignes = JSONPath(`$..lignes[*]`, poste);
    return !!ctrl.getLignesWithLibelle(libelle, lignes).length;
  };

  /**
   * Fired by the kendo combobox on filtering
   *
   * @param {object} event filtering event containing the current input value
   * @param {object} poste
   * @returns {void}
   */
  ctrl.onNewLigneEntryFiltering = (event, poste) => {
    const allowLigneCreation = ctrl.getAllowLigneCreationForPoste(poste);
    const filteredValue = _.get(event, 'filter.value', '');

    // If filtered value match exactly an existing libelle in optional lines
    // we simulate a selection
    const [optionalLineWithSameLibelle] = ctrl.getLignesWithLibelle(
      filteredValue,
      ctrl.lignesOptionnelles[poste.reference]
    );

    // if we don't $apply, the following values are not always taken into account in the UI
    $scope.$apply(() => {
      if (optionalLineWithSameLibelle) {
        ctrl.enableEnterNewLigneButton[poste.reference] = true;
        ctrl.isDuplicateLine[poste.reference] = false;
      } else {
        ctrl.enableEnterNewLigneButton[poste.reference] = allowLigneCreation && !!filteredValue;
        ctrl.isDuplicateLine[poste.reference] = ctrl.isLibelleInPoste(filteredValue, poste);
      }
    });
  };

  /**
   * Fired by the kendo combobox on change
   *
   * @param {object} event
   * @param {object} poste
   * @returns {void}
   */
  ctrl.onNewLigneEntryChange = (event, poste) => {
    if (!event.sender.text()) {
      ctrl.enableEnterNewLigneButton[poste.reference] = false;
      return;
    }
    const dataItem = event.sender.dataItem();
    ctrl.enableEnterNewLigneButton[poste.reference] = !!(dataItem || ctrl.getAllowLigneCreationForPoste(poste));

    // If a line has been selected
    // it can't be a duplication
    if (dataItem) {
      ctrl.isDuplicateLine[poste.reference] = false;
    }

    // if we don't $apply, enableEnterNewLigneButton and isDuplicateLine are not taken into account in the UI
    $scope.$apply();
  };

  /**
   * Wrap the list of optional lignes in Kendo combobox datasource
   * As the kendo k-data-text-field has been changed to "ligne (poste)" format,
   * the regular ligne.libelle.value has to be forced by using a template to display the list of options.
   *
   * @param {object} poste
   * @returns {object}
   */
  ctrl.getKendoComboOptions = (poste) => {
    return {
      template: '{{dataItem.libelle.value}}',
      dataSource: {
        data: ctrl.lignesOptionnelles[poste.reference],
      },

      filtering: (e) => ctrl.onNewLigneEntryFiltering(e, poste),
      change: (e) => ctrl.onNewLigneEntryChange(e, poste),
    };
  };

  /**
   * Calculate total/subtotal amounts
   *
   * @returns {void}
   */
  ctrl.calculateAmounts = () => {
    if (!ctrl.typeMontant || !ctrl.sousSection) {
      return;
    }

    /**
     
     * Calculates a total object from montants array
     
     *
     
     * @param {object[]} montants
     * @param {boolean} [shouldKeepTotal=false] if total should be kept in response
     * @returns {object}
     */

    const calculateMontants = (montants, shouldKeepTotal = false) => {
      const amounts = montants.filter((montant) => montant !== undefined);

      if (!amounts?.length) {
        return;
      }

      // Get sums
      const sumHt = _.sumBy(amounts, 'ht');
      const sumTtc = _.sumBy(amounts, 'ttc');

      // create response object

      const totals = {};

      if (shouldKeepTotal) {
        const total = (sumHt || 0) + (sumTtc || 0);
        totals.total = ctrl.numberService.round(total, 2);
      }

      if (!_.isNil(sumHt)) {
        totals.ht = ctrl.numberService.round(sumHt, 2);
      }

      if (!_.isNil(sumTtc)) {
        totals.ttc = ctrl.numberService.round(sumTtc, 2);
      }

      return totals;
    };

    const total = [];

    _.forEach(ctrl.sousSection.postes, (poste) => {
      // "Sous-poste" amount is the sum of all amounts of its cumulable lines

      _.forEach(poste.sousPostes, (sousPoste) => {
        const lignesCumulablesSousPoste = _.filter(sousPoste.lignes, 'cumulable');

        const montantsLignesSousPoste = _.map(lignesCumulablesSousPoste, 'montant');

        _.set(sousPoste, 'montant', calculateMontants(montantsLignesSousPoste, true));
      });

      // "Poste" amount is the sum of all amounts of its cumulable lines and its "sous-postes"

      const lignesCumulablesPoste = _.filter(poste.lignes, 'cumulable');

      const montantsLignesPoste = _.map(lignesCumulablesPoste, 'montant');

      const montantsSousPostes = _.map(poste.sousPostes, 'montant');

      const montantsPoste = montantsLignesPoste.concat(montantsSousPostes);

      _.set(poste, 'montant', calculateMontants(montantsPoste));

      total.push(poste.montant);
    });

    // Total amount is the sum of the "postes" totals

    _.set(ctrl.sousSection, 'montant', calculateMontants(total, true));
  };

  /**
   * Adds a ligne with a recherche tiers
   *
   * @param {object} sousPoste
   * @returns {void}
   */
  ctrl.addRechercheTiers = (sousPoste) => {
    sousPoste.recherche = true;
  };

  /**
   * Retourne si un champ de recherche existe déjà sur le poste
   *
   * @param {object} sousPoste
   * @returns {boolean}
   */
  ctrl.isRechercheOpen = (sousPoste) => {
    return !!sousPoste.recherche;
  };

  /**
   * Ajout de la ligne de tiers
   *
   * @param {object} tiers
   * @param {object} sousPoste
   * @returns {void}
   */
  ctrl.callbackRechercheTiers = (tiers, sousPoste) => {
    /**
     * check if financeur is financeur privilegie
     *
     * @param {object} tiers
     * @returns {boolean} true if tiers is financeur privilegie
     */
    const checkFinanceurType = (tiers) => {
      if (!tiers) throw new Error('isFinanceurPrivilegier - tiers must be a non empty object');

      const typeFinanceur = _.get(tiers, 'thematiquesLiees.financeur.0.typeFinanceur', '');
      return typeFinanceur === 'FINANCEURPRIVILEGIE';
    };

    if (!ctrl.indexLigneSousPoste) {
      ctrl.indexLigneSousPoste = 0;
    }
    const ligne = {
      commentaire: '',
      cumulable: true,
      libelle: {
        value: tiers.libelleCourt,
      },

      montant: {},
      optionnel: true,
      reference: shortid.gen(),
      visible: true,
      obligatoire: false,
      financement: {
        financeur: {
          href: tiers.id,
          title: tiers.libelleCourt, //this property is only used on the client and will be auto-deleted by the server :
          isFinanceurPrivilegier: checkFinanceurType(tiers), //? used by the parent directive to check the plan de financement
        },
        autorisationSuppressionFinancement: true, // on rend actif le bouton 'removeLigne'
      },
    };

    // multi-fin synchro case: add organisation only if it exists
    const organisation = _.get(tiers, 'thematiquesLiees.financeur.0.organisation');
    if (organisation) {
      ligne.financement.financeur.organisation = organisation;
    }

    if (!sousPoste.lignes) {
      sousPoste.lignes = [];
    }

    sousPoste.lignes.push(ligne);
    ctrl.lignes.push(ligne);

    ctrl.detailsConfiguration[ligne.reference] = ctrl.getDetailsConfiguration(ligne, sousPoste);

    delete sousPoste.recherche;
  };

  /**
   * Opens the modal to remove a line from the section
   *
   * @param {Array}  lignes Array of lines which contains the ligne to remove
   * @param {object} ligne ligne to remove
   * @param {string} sousPosteMode
   * @returns {void}
   */
  ctrl.removeLigne = (lignes, ligne, sousPosteMode) => {
    const scopeModal = $scope.$new();

    scopeModal.lignes = lignes;
    scopeModal.ligne = ligne;
    scopeModal.isMultipleFinanceur = sousPosteMode === 'MULTIPLE_FINANCEUR';
    scopeModal.viewConfiguration = ctrl.viewConfiguration;
    scopeModal.confirmRemoveLigne = ctrl.confirmRemoveLigne;
    $modal({
      scope: scopeModal,
      template: 'common/common-modal/confirm-delete-modal.html',
    });
  };

  /**
   * Removes a line
   *
   * @param {Array} lignes Array of lines which contains the ligne to remove
   * @param {object} ligne ligne to remove
   * @param {boolean} isMultipleFinanceur
   * @returns {void}
   */
  ctrl.confirmRemoveLigne = (lignes, ligne, isMultipleFinanceur) => {
    // we check if we can remove a line with autorisationSuppressionFinancement and if there's a financeur
    if (
      (_.get(ligne, 'financement.autorisationSuppressionFinancement', false) && isMultipleFinanceur) ||
      ligne.createdByUser
    ) {
      // Remove a line (Only on a financeur or if the poste has been added)
      _.remove(lignes, ligne);
    } else {
      // Hide line
      _.set(ligne, 'visible', false);
      _.unset(ligne, 'montant');
      _.unset(ctrl, `shadowSousSection.${ligne.reference}`);
      _.unset(ligne, 'commentaire');
      _.unset(ligne, 'details');
    }
    // Init liste of optional lignes.
    ctrl.manageLignesOptionnelles();
    ctrl.calculateAmounts();
  };

  /**
   * Adds a line to the section
   *
   * @param {object} addedLigne selected optional ligne to add
   * @returns {void}
   */
  ctrl.addLigne = (addedLigne) => {
    if (!addedLigne) return;
    const [ligne] = JSONPath(
      `$.[?(@.lignes || @.sousPostes)][*][?(@.reference == '${addedLigne.reference}')]`,
      ctrl.sousSection.postes
    );

    if (!ligne) return;

    // Make it visible
    ligne.visible = true;

    // Refresh view
    ctrl.initPlanFinancement();
    ctrl.calculateAmounts();
    // focus on the new montant field. But only after visibles[ligne.reference] is applied to the scope.
    $timeout(function () {
      var elt = $element.find('.ligne-field.' + addedLigne.reference + ' input')[0];
      if (elt) {
        elt.focus();
      }
    }, 100);
  };

  /**
   * Add/create a ligne to the section
   *
   * @param {object} poste
   * @returns {void}
   */
  ctrl.enterNewLigne = (poste) => {
    const ligneEntry = ctrl.newLigneEntry[poste.reference];

    // Early return if the field is empty
    if (!ligneEntry) return;

    // If the ligne has been selected, then it should be in the ligne list
    const ligne = ctrl.getPosteFromArray(ligneEntry, ctrl.lignesOptionnelles[poste.reference]);

    // Add the existing optional ligne
    if (ligne) {
      // hide addPoste section
      ctrl.resetPosteCreationSection(poste);
      // Add the poste
      ctrl.addLigne(ligne);
      return;
    }

    ctrl.createNewLigne(poste);
  };

  /**
   * Create a new ligne in the target poste
   *
   * @param {object} targetPoste
   * @returns {void}
   */
  ctrl.createNewLigne = (targetPoste) => {
    const poste = ctrl.getPosteFromSousSection(targetPoste.reference);
    // If the poste has been selected, it then should be in the poste list
    if (!poste) return;

    // Init the new ligne
    const ligne = {
      reference: shortid.gen(),
      libelle: { value: ctrl.newLigneEntry[poste.reference] },
      cumulable: true,
      obligatoire: false,
      optionnel: true,
      visible: true,
      createdByUser: true,
    };

    // Init details from generique poste
    ctrl.detailsConfiguration[ligne.reference] = ctrl.getDetailsConfiguration(ligne, targetPoste);
    ctrl.parentPostes[ligne.reference] = targetPoste;

    // Add line to parent poste
    (poste.lignes || (poste.lignes = [])).push(ligne);
    (ctrl.lignes || (ctrl.lignes = [])).push(ligne);

    // Refresh Add ligne option
    ctrl.resetPosteCreationSection(targetPoste);
    // Refresh view
    ctrl.initPlanFinancement();
    ctrl.calculateAmounts();
  };

  /**
   * Cancel button on create/add new ligne
   *
   * @param {object} poste
   * @returns {void}
   */
  ctrl.cancelNewLigne = (poste) => {
    // Refresh Add ligne option
    ctrl.resetPosteCreationSection(poste);
    // Refresh view
    ctrl.initPlanFinancement();
  };

  /**
   * Retrieve a poste from an array of post from its reference
   *
   * @param {string} reference
   * @param {object[]} postes
   * @returns {object}
   */
  ctrl.getPosteFromArray = (reference, postes) => {
    return _.find(postes, (p) => p.reference === reference);
  };

  /**
   * Retrieve a poste from sousSectionTemplate
   *
   * @param {string} reference
   * @returns {object}
   */
  ctrl.getPosteFromSousSectionTemplate = (reference) => {
    return ctrl.getPosteFromArray(reference, _.get(ctrl, 'sousSectionTemplate.postes', []));
  };

  /**
   * Retrieve a poste from sousSection
   *
   * @param {string} reference
   * @returns {object}
   */
  ctrl.getPosteFromSousSection = (reference) => {
    return ctrl.getPosteFromArray(reference, _.get(ctrl, 'sousSection.postes', []));
  };

  /**
   * Get allowLigneCreation from a poste
   *
   * @param {object} poste
   * @returns {boolean}
   */
  ctrl.getAllowLigneCreationForPoste = (poste) => {
    if (typeof poste.allowLigneCreation !== 'undefined') {
      return poste.allowLigneCreation;
    }
    const posteSousSection = ctrl.getPosteFromSousSectionTemplate(poste.reference);
    return posteSousSection ? posteSousSection.allowLigneCreation : false;
  };

  /**
   * Reset poste creation section
   *
   * @param {object} poste
   * @returns {void}
   */
  ctrl.resetPosteCreationSection = (poste) => {
    ctrl.enableEnterNewLigneButton[poste.reference] = false;
    ctrl.isDuplicateLine[poste.reference] = false;
    ctrl.newLigneEntry[poste.reference] = '';
    ctrl.displayAddPoste[poste.reference] = false;
  };

  /**
   * Return if a given ligne can be deleted
   *
   * @param {object} ligne
   * @returns {boolean}
   */
  ctrl.canDeleteLigne = (ligne) =>
    !ctrl.readOnly && ctrl.isFirst && !!(ligne.createdByUser || (!ligne.obligatoire && ligne.optionnel));

  /**
   * Check if ligne has amount
   *
   * @param {object} ligne
   * @returns {boolean}
   */
  ctrl.hasAmount = (ligne) => {
    const amount = _.get(ligne, `montant.${ctrl.getTypeMontant(ligne.reference)}`);
    return ctrl.negativeAmounts ? _.isNumber(amount) && amount !== 0 : amount > 0;
  };

  /**
   * in mixte mode, we get type montant from line's typeMontant
   * else we get it from ctrl.typeMontant
   *
   * @param {string} reference line reference
   * @returns {string}
   */
  ctrl.getTypeMontant = (reference) => {
    return ctrl.typeMontant !== 'mixte'
      ? ctrl.typeMontant
      : _.get(ctrl, `lignesFormConfiguration.${reference}.typeMontant`, 'ht');
  };

  /**
   * Update the type montant of a line
   *
   * @param {object} ligne
   * @returns {void}
   */
  ctrl.updateTypeMontant = (ligne) => {
    const montant = _.get(ligne, 'montant.ht') || _.get(ligne, 'montant.ttc');
    const typeMontant = ctrl.lignesFormConfiguration[ligne.reference].typeMontant;
    ligne.montant = {
      [typeMontant]: montant,
    };

    _.set(ctrl.shadowSousSection, ligne.reference, montant);
    ctrl.calculateAmounts();
  };

  /**
   * Check if object values are all undefined
   *
   * @param {object} object
   * @returns {boolean}
   */
  ctrl.hasDefinedProperties = (object) => {
    return (
      !!object.commentaire ||
      _.values(object.details).some((prop) => prop !== undefined) ||
      (object.pieces || []).some((piece) => !_.isEmpty(piece.documents))
    );
  };

  /**
   * Remove a document from line pieces
   *
   * @param {object} ligne
   * @param {number} pieceIndex
   * @param {number} documentIndex
   * @param {object} poste
   * @returns {void}
   */
  ctrl.removeAssociation = (ligne, pieceIndex, documentIndex, poste) => {
    // Get all demande lines with this reference
    const lignes = JSONPath(
      `planFinancement[*].depense.postes..lignes[?(@.reference == "${ligne.reference}")]`,
      ctrl.demandeFinancement
    );

    // Initialize modal scope
    const scopeModal = $scope.$new();
    const document = ctrl.getDocumentPieceFromLine(ligne, pieceIndex, documentIndex) ?? {};
    scopeModal.filename = documentService.getDocumentName(document);

    // When user confirms the modal, we unlink the document
    scopeModal.onConfirm = () => ctrl.deleteDocumentPiece(lignes, poste, pieceIndex, documentIndex);

    // Display modal
    $modal({
      scope: scopeModal,
      viewConfiguration: $scope.viewConfiguration,
      template: 'aides/aides-directives/document-comptable-sous-section-aide-form/modal/remove-related-expenses.html',
      backdrop: 'static',
    });
  };

  /**
   * Delete a document on a line piece ad update details conf of the line
   * If the piece don't has documents after the document deletion, we delete the piece too
   *
   * @param {Array<object>} lignes Lines
   * @param {object} poste The poste
   * @param {number} pieceIndex The index of the piece where the document is contained
   * @param {number} documentIndex The index of the document
   */
  ctrl.deleteDocumentPiece = (lignes, poste, pieceIndex, documentIndex) => {
    lignes.forEach((ligne) => {
      if (ctrl.getDocumentPieceFromLine(ligne, pieceIndex, documentIndex)) {
        // Remove document from ligne
        const ligneDocuments = ligne.pieces[pieceIndex].documents;
        ligneDocuments.splice(documentIndex, 1);

        // Remove pieces if there is no more documents or pieces
        _.remove(ligne.pieces, (p) => _.isEmpty(p.documents));
        if (!ligne.pieces.length) {
          delete ligne.pieces;
        }
      }

      // Update the detailsConfiguration for the ligne
      ctrl.detailsConfiguration[ligne.reference] = ligne.createdByUser
        ? ctrl.getDetailsConfiguration(ligne, poste)
        : ctrl.getDetailsConfiguration(ligne);
    });
  };

  /**
   * Get document from the line's piece
   *
   * @param {object} ligne The line
   * @param {number} pieceIndex The index of the piece
   * @param {number} documentIndex The index of the document
   * @returns {object} The document
   */
  ctrl.getDocumentPieceFromLine = (ligne, pieceIndex, documentIndex) => {
    return ligne?.pieces?.[pieceIndex]?.documents?.[documentIndex];
  };

  /**
   * Returns the full translation key for the input
   * allowing to create or search a line to add
   *
   * @param {object} poste
   * @returns {string}
   */
  ctrl.getAddLineInputPlaceholder = (poste) => {
    let translateKey;

    const hasPostesToAdd = !_.isEmpty(ctrl.lignesOptionnelles[poste.reference]);
    const isCreationAuthorized = ctrl.canCreateLignes[poste.reference];

    if (hasPostesToAdd) {
      translateKey = isCreationAuthorized ? 'enterOrSearch' : 'selectOrSearch';
    } else if (isCreationAuthorized) {
      translateKey = 'enterOnly';
    }

    return `${ctrl.viewConfiguration.ns}.addLigne.placeholders.${translateKey}`;
  };

  /**
   * Toggle the "add new poste" area for a poste
   * And reset the search input if we hide it
   *
   * @param {object} poste
   * @returns {void}
   */
  ctrl.toggleDisplayAddPoste = (poste) => {
    const isDisplayingAddPoste = (ctrl.displayAddPoste[poste.reference] = !ctrl.displayAddPoste[poste.reference]);
    if (!isDisplayingAddPoste) {
      ctrl.cancelNewLigne(poste);
    }
  };

  /**
   * Returns true if the user can add or create lines for the poste
   *
   * @param {object} poste
   * @returns {boolean}
   */
  ctrl.canAddOrCreateLinesForPoste = (poste) => {
    const hasLignesOptionnelles = !_.isEmpty(ctrl.lignesOptionnelles[poste.reference]);
    const canCreateLignes = !!ctrl.canCreateLignes[poste.reference];
    return hasLignesOptionnelles || canCreateLignes;
  };

  /**
   * Returnes true if the "add poste" button should be displayed for the poste
   *
   * @param {object} poste
   * @returns {boolean}
   */
  ctrl.displayButtonAddPoste = (poste) => {
    return !ctrl.readOnly && ctrl.isFirst && ctrl.canAddOrCreateLinesForPoste(poste);
  };

  /**
   * Define if the precisions button has to be filled
   *
   * @param {object} ligne Line
   * @returns {boolean} True if precisions button has to be filled
   */
  ctrl.displayFilledPrecisionsButton = (ligne) => {
    return !!ligne.commentaire && ctrl.hasAmount(ligne);
  };

  ctrl.watchSousSection = () => {
    $scope.$watch(
      '$ctrl.sousSection',
      () => {
        ctrl.calculateAutofinancement();
        ctrl.updateSousTotal();
        ctrl.calculateAmounts();
      },
      true
    );
  };

  /**
   * Condition to display component table depending on postes number
   *
   * @returns {boolean}
   */
  ctrl.showTable = () => {
    const postes = ctrl?.sousSection?.postes;
    return postes && postes.length > 0;
  };

  /**
   * Condition to display component table depending on lignes number
   *
   * @param {object} poste
   * @returns {boolean}
   */
  ctrl.showLines = (poste) => {
    const lignes = poste?.lignes;
    return lignes && lignes.length > 0;
  };
}
