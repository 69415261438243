'use strict';
angular.module('common.model').factory('Link', [
  function () {
    'use strict';

    /**
     *
     * @param {object} data
     */
    function Link(data) {
      data = data || {};
      this.id = data.id;
      this.href = data.href || data.id;
      this.title = data.title;
      this.origin = data.origin;
      this.rel = data.rel;
      this.type = data.type;
      this.media = data.media;
      this.method = data.method;
      this.expand = data.expand || {};
      this.email = data.email;
      this.new = data.new;
      this.error = data.error;
    }

    Link.prototype.getCleanEntity = function () {
      const linkClone = structuredClone(this);
      delete linkClone.expand;
      return linkClone;
    };

    return Link;
  },
]);
