angular.module('common.services').factory('translateService', ['$translate', translateService]);

/* @ngInject */
/**
 * Translate service
 * ! This service is used to deal with mg-components rendering problems on AngularJS
 *
 * @param {object} $translate ng-translate service
 * @returns {object} app translate service
 */
function translateService($translate) {
  return {
    /**
     * Function to apply the translate service on an attribute
     *
     * @param {string} key translate key
     * @param {string} attr attribute
     * @returns {string} translated attribute
     */
    translateAttribute: (key, attr) => {
      return $translate.instant(key, attr);
    },

    /**
     * Function to apply the translate service with no attributes
     *
     * @param {string} key translate key
     * @returns {string} translated text
     */
    translateContent: (key) => {
      return $translate.instant(key);
    },
  };
}
