'use strict';
angular.module('common.model').factory('Document', function () {
  'use strict';

  /**
   *
   * @param {object} data
   */
  function Document(data) {
    data = data || {};

    // Custom properties
    this.title = data.title;
    this.href = data.href;
    this.progress = data.progress || 0;
    this.isUploading = data.isUploading || false;
    this.isUploaded = _.get(data, 'isUploaded', true);
    this.isDeletable = _.get(data, 'isDeletable', true);
    this.properties = data.properties || {};

    // Temporary fix of a serialization or coyping error that sometimes sets a description to "null"
    var description = _.get(this.properties, 'cmis:description.value');
    if (description === 'null') {
      _.set(this.properties, 'cmis:description.value', '');
    } else if (description) {
      _.set(this.properties, 'cmis:description.value', description);
    }
  }

  Document.prototype.getCleanEntity = function () {
    var cleanDocument = _.cloneDeep(this);
    delete cleanDocument.progress;
    delete cleanDocument.isUploading;
    delete cleanDocument.isUploaded;
    delete cleanDocument.isDeletable;
    return cleanDocument;
  };

  return Document;
});
