'use strict';
class PaiementsService {
  constructor($http, $log, configuration) {
    this._http = $http;
    this.logger = $log;
    this._configuration = configuration;
  }

  /**
   * Used to check controle-eligibilite on a demande
   *
   * @param {object} demande
   * @returns {Promise}
   */
  controleEligibilite(demande) {
    return this._http
      .post(`${_.get(this._configuration, 'aides.url')}/demandes-paiement/${demande.reference}/controle-eligibilite`)
      .then(({ data }) => {
        return data || [];
      })
      .catch((err) => {
        const message = `An error occurred trying to fetch controle-eligibilite on demande ${
          demande.reference
        } : ${JSON.stringify(err)}`;
        this.logger.error(message);
        throw new Error(message);
      });
  }
}
PaiementsService.$inject = ['$http', '$log', 'configuration'];

PaiementsService.$inject = ['$http', '$log', 'configuration'];

angular.module('paiements.services').service('paiementsService', PaiementsService);
