'use strict';
class ShareEntityService {
  /* @ngInject */
  constructor($http, $log, $q) {
    this.$http = $http;
    this.$log = $log;
    this.$q = $q;
  }

  /**
   * Get sharings of given entity
   *
   * @param {object} entity
   * @returns {Promise<string[]>} email list
   */
  getSharings(entity) {
    if (!entity?.id) return this.$q.resolve([]);

    return this.$http
      .get(`${entity.id}/shared-with`)
      .then(({ data }) => {
        return data || [];
      })
      .catch((err) => {
        const message = `An error occurred trying to fetch sharings on entity ${entity.reference}`;
        this.$log.error(message, err);
        throw new Error(message);
      });
  }

  /**
   * Update sharings of a entity
   *
   * @param {object} entity
   * @param {string[]} emails
   * @returns {Promise<void>}
   */
  saveSharings(entity, emails) {
    if (!entity?.id) return this.$q.reject(new Error('saveSharings - id must be defined'));

    return this.$http
      .put(`${entity.id}/shared-with`, emails, {
        headers: {
          'X-No-Interceptor': true,
        },
      })
      .then(({ data }) => {
        return data || [];
      })
      .catch((err) => {
        const message = `An error occurred trying to save sharings on entity ${entity.reference}`;
        this.$log.error(message, err);
        throw new Error(err?.data?.message);
      });
  }

  /**
   * Check if entity is shared with some users
   *
   * @param {object} entity
   * @returns {Promise<boolean>} true if shared
   */
  isShared(entity) {
    if (!entity?.id) return this.$q.resolve(false);

    return this.$http
      .get(`${entity.id}/shared`)
      .then(({ data }) => {
        return data === 'true';
      })
      .catch((err) => {
        const message = `An error occurred trying to fetch shared state of entity ${entity.reference}`;
        this.$log.error(message, err);
        throw new Error(message);
      });
  }
}
ShareEntityService.$inject = ['$http', '$log', '$q'];

/**
 * @name shareEntityService
 * @class ShareEntityService
 * @description Helpers for sharing a entity
 */
angular.module('aides.services').service('shareEntityService', ShareEntityService);
