'use strict';
angular.module('common.model').factory('MasterDataRecord', function () {
  'use strict';

  /**
   *
   * @param {object} data
   */
  function MasterDataRecord(data) {
    data = data || {};
    this.reference = data.reference;
    this.libelle = data.libelle || {};
    this.actif = data.actif;
    this.expiration = data.expiration;
  }

  return MasterDataRecord;
});
