'use strict';
class DisplayMultiDossiersController {
  constructor($q, dossiersService, suiviFinancementService, aidePaiementAuthorizationService) {
    this.dossiersService = dossiersService;
    this.suiviFinancementService = suiviFinancementService;
    this.aidePaiementAuthorizationService = aidePaiementAuthorizationService;
    this.$q = $q;

    this.collapsed = true;
    this.dataLoaded = false;
    this.lignesWithDossier = [];
    this.paiementAuthorizations = new Map();
  }

  /**
   * Initialize component
   *
   * @returns {void}
   */
  $onInit() {
    const demandeFinancement = this.demande.aide;
    const isMultiFinanceur = demandeFinancement.multiFinanceur || false;

    if (isMultiFinanceur) {
      this.lignesWithDossier = this.suiviFinancementService.getLignesWithFinancement(demandeFinancement);
    } else {
      const lignesPF = this.suiviFinancementService.getLignesFictivesPlanFinancement(demandeFinancement);
      this.lignesWithDossier = lignesPF.filter((lignePF) => _.get(lignePF, 'financement.source.href'));
    }

    this.dossiersFinancement = this.lignesWithDossier.map((lignePF) => {
      const dossier = lignePF.financement.source;
      return {
        reference: dossier.href.split('/').pop(),
        referenceAdministrative: dossier.referenceAdministrative,
        libelle: dossier.title || _.get(lignePF, 'financement.financeur.title'),
        statut: this.dossiersService.getStatusToDisplay(lignePF),
      };
    });
    this.dossiersFinancementCount = this.dossiersFinancement.length;
  }

  /**
   * Load dossiers data
   *
   * @returns {void}
   */
  loadData() {
    if (this.dataLoaded) return;

    this.actionsLoading = true;
    return this.getlignesPaiementAuthorization(this.lignesWithDossier)
      .then((authorizations) => {
        this.lignesWithDossier.forEach((lignePF, index) => {
          const dossierReference = lignePF.financement.source.href.split('/').pop();

          this.paiementAuthorizations.set(dossierReference, {
            canCreatePaiement: _.get(authorizations[index], 'canCreatePaiement'),
            teleservicePaiement: _.get(
              authorizations[index],
              'teleservicePaiement',
              _.get(this.demande, 'teleservicePaiement')
            ),
          });
        });
      })
      .finally(() => {
        this.dataLoaded = true;
        this.actionsLoading = false;
      });
  }

  /**
   * Get paiement authorization for "lignes de plan de financement"
   *
   * @param {Array<object>} lignesPf
   * @returns {Promise<Array<{canCreatePaiement: object, teleservicePaiement: string}>>} authorizations
   */
  getlignesPaiementAuthorization(lignesPf) {
    return this.$q.all(
      lignesPf.map((lignePf) =>
        this.aidePaiementAuthorizationService.canCreateDemandePaiementMonoFinanceur(this.demande.aide, lignePf)
      )
    );
  }
}
DisplayMultiDossiersController.$inject = [
  '$q',
  'dossiersService',
  'suiviFinancementService',
  'aidePaiementAuthorizationService',
];

DisplayMultiDossiersController.$inject = [
  '$q',
  'dossiersService',
  'suiviFinancementService',
  'aidePaiementAuthorizationService',
];

angular.module('dossiers').component('displayMultiDossiers', {
  templateUrl: 'dossiers/dossiers-components/display-multi-dossiers/display-multi-dossiers.component.html',
  bindings: {
    demande: '<',
  },

  controller: DisplayMultiDossiersController,
});
