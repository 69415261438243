'use strict';
angular.module('common.services').factory('jwtUtilService', [
  '$location',
  'jwtSessionStorage',

  function ($location, jwtSessionStorage) {
    'use strict';

    return {
      defineJwtKey: function (tenantIdParam) {
        var tenantId = tenantIdParam;

        // Retrieve tenant present in the url because a this moment we don't have the stateParams 'tenantId'
        if (!tenantId) {
          var url = $location.url().split('/');
          tenantId = url[1];
        }

        if (jwtSessionStorage.getJwtKey() === 'jwt-session') {
          jwtSessionStorage.setJwtKey('jwt-' + tenantId + '-portail-depot-demande-aides');
        }
      },
    };
  },
]);
