'use strict';
angular.module('common.components').component('switchHtTtc', {
  templateUrl: 'common/common-components/switch/switch-ht-ttc.component.html',
  controller: switchHtTtcController,
  require: {
    model: 'ngModel',
  },

  transclude: true,
  bindings: {
    reference: '<',
    readOnly: '<',
    hasAmount: '<',
    disable: '<?',
  },
});

switchHtTtcController.$inject = [];

/**
 *
 */
function switchHtTtcController() {
  const ctrl = this;

  ctrl.values = { ht: 'ht', ttc: 'ttc' };
  let currentValue;

  ctrl.$onInit = () => {
    ctrl.reference = ctrl.reference || _.uniqueId();
    ctrl.readOnly = ctrl.readOnly || false;
    ctrl.hasAmount = ctrl.hasAmount || false;
    ctrl.disable = ctrl.disable || false;
    ctrl.model.$render = () => {
      ctrl.value = ctrl.model.$viewValue || ctrl.values[Object.keys(ctrl.values)[0]];
      currentValue = _.clone(ctrl.value);
    };
  };

  /**
   * set current value
   *
   * @param {string} value clicked value
   */
  ctrl.toggle = (value) => {
    // click on current value, should switch
    if (value === currentValue) {
      ctrl.value =
        value === ctrl.values[Object.keys(ctrl.values)[0]]
          ? ctrl.values[Object.keys(ctrl.values)[1]]
          : ctrl.values[Object.keys(ctrl.values)[0]];
    } else {
      ctrl.value = value;
    }
    currentValue = _.clone(ctrl.value);
    ctrl.model.$setViewValue(ctrl.value);
  };
}
