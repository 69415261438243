'use strict';
class JustificationsListDeleteModal {
  constructor($sce) {
    this._sce = $sce;
  }

  $onInit() {
    this.message = this._sce.trustAsHtml(this.resolve.message);
  }
}
JustificationsListDeleteModal.$inject = ['$sce'];

JustificationsListDeleteModal.$inject = ['$sce'];

angular.module('justifications.components').component('justificationsListDeleteModal', {
  controller: JustificationsListDeleteModal,
  templateUrl: 'justifications/justifications-components/modal/justifications-list-delete.modal.html',
  bindings: {
    dismiss: '&',
    close: '&',
    resolve: '<',
  },
});
