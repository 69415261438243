'use strict';
depotSimpleBeneficiaireThematiquesController.$inject = ['$scope', '$state', 'StoreService'];
angular
  .module('portailDepotDemandeAide.depot')
  .controller('depotSimpleBeneficiaireThematiquesController', depotSimpleBeneficiaireThematiquesController);

depotSimpleBeneficiaireThematiquesController.$inject = ['$scope', '$state', 'StoreService'];

/**
 *
 * @param {object} $scope
 * @param {object} $state
 * @param {object} StoreService
 */
function depotSimpleBeneficiaireThematiquesController($scope, $state, StoreService) {
  // Page initialisation
  $scope.pageOptions = $scope.teleserviceConfiguration.workflow.pageInformationsThematiquesBeneficiaire || {};
  $scope.beneficiaire = StoreService.beneficiaire.get();

  $scope.cleanNavigate();
  $scope.navigate.next = (forget) => {
    if (forget) {
      $scope.goToStep('beneficiaire-adresse', forget);
    }
    StoreService.beneficiaire.set($scope.beneficiaire);

    $scope.saveBeneficiaire().then(() => $scope.goToStep('beneficiaire-adresse', forget));
  };
  $scope.stepsWizard.steps = $scope.getSimpleSteps();
  $scope.stepsWizard.active = 'beneficiaire';

  // Create configuration from typeFamille
  $scope.typeFamille = _.get($scope, 'beneficiaire.famille.expand.typeFamille');
  // Namespace is defined according to typeFamille
  $scope.navigate.ns = $scope.thematiquesBeneficiaireConfiguration.ns + '.' + $scope.typeFamille;
  var depotStateData = $state.$current.data || {};
  depotStateData.title = $scope.navigate.ns + '.title';

  // List of fields and sections set in teleservice
  var teleserviceTypeTiersChamps = _.get($scope.pageOptions.champsParTypeTiers, $scope.typeFamille);
  // Page active if one configuration has been found for current tiers' type
  var pageActive = $scope.pageOptions.actif && teleserviceTypeTiersChamps;

  if (pageActive) {
    // Write sections configuration by merging standard config with teleserivce's one
    $scope.sectionsConfigurations = {};
    // List of sections to display is in standard config
    _.each($scope.thematiquesBeneficiaireConfiguration.types[$scope.typeFamille], function (sectionName) {
      var sectionConfig = {
        displayCharactersLeft: true,
        remoteValidation: _.get($scope, 'teleserviceConfiguration.controleCompletudeDepot', false),
        showErrors: $scope.showErrorsOnNavigate(),
        fields: {},
      };

      var activeSection = teleserviceTypeTiersChamps[sectionName];

      if (activeSection) {
        // Section must have at least one field to be truly active
        activeSection = false;
        // Read section's fields in standard configuration
        _.forIn(
          $scope.thematiquesBeneficiaireConfiguration.sections[sectionName].fields,
          function (standardField, fieldRef) {
            // Merge standard field with teleservice field
            var field = _.merge(standardField, _.find(teleserviceTypeTiersChamps.fields, { reference: fieldRef }));
            sectionConfig.fields[fieldRef] = field;

            activeSection = activeSection || !field.hidden;
          }
        );
      }

      // Add the section if active
      if (activeSection) {
        $scope.sectionsConfigurations[sectionName] = sectionConfig;
      }
    });

    // Page is not active if there are no sections to show
    pageActive = _.keys($scope.sectionsConfigurations).length > 0;

    // moyens-humains needs an extended configuration (can't keep generic code all the way!)
    if ($scope.sectionsConfigurations.sectionMoyensHumains) {
      // moyensHumains may use any field in teleservice
      _.merge($scope.sectionsConfigurations.sectionMoyensHumains.fields, teleserviceTypeTiersChamps.fields);
    }
  }

  // Page is skipped if inactive, or has no configuration for the tiers' type, or all sections are inactive
  if (!pageActive) {
    $scope.navigate.next(true);
  }
}
