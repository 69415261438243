'use strict';
angular.module('portailDepotDemandeAide.depot').controller('depotSimpleBeneficiaireRepresentantsController', [
  '$scope',
  'StoreService',
  'tiersService',
  function ($scope, StoreService, tiersService) {
    'use strict';

    $scope.beneficiaire = StoreService.beneficiaire.get();

    $scope.cleanNavigate();
    $scope.navigate.noform = true;

    tiersService.getPublicSettingsTiers().then((response) => {
      $scope.sectionAdresseContacts = response.sectionAdresseContacts;
    });

    $scope.navigate.next = () => {
      const nextPageOptions = $scope.teleserviceConfiguration.workflow.pageInformationsBeneficiaire;
      const infosComplementairesTypes = ['TIERS_ASSOCIATION', 'TIERS_ENTREPRISE'];
      const beneficiaireComplementaireActive =
        _.get(nextPageOptions, 'complementaires.actif') &&
        $scope.beneficiaire.famille.expand &&
        _.includes(infosComplementairesTypes, $scope.beneficiaire.famille.expand.typeFamille);
      $scope
        .saveBeneficiaire()
        .then(() =>
          $scope.goToStep(beneficiaireComplementaireActive ? 'beneficiaire-complementaire' : 'informations-generales')
        );
    };
    $scope.navigate.ns = $scope.representantsBeneficiaireConfiguration.ns;

    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'beneficiaire';

    // Fonctions may have been already set by a previous page
    if (!$scope.fonctionsRepresentants.beneficiaire) {
      $scope.setFonctionsRepresentants($scope.beneficiaire.famille.expand, 'beneficiaire');
    }
  },
]);
