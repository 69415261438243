'use strict';
recapitulatifRealisationEvaluationAide.$inject = ['$filter'];
angular.module('aides').component('recapitulatifRealisationEvaluationAide', {
  templateUrl:
    'aides/aides-components/recapitulatif/realisation-evaluation/recapitulatif-realisation-evaluation.component.html',
  bindings: {
    teleservice: '<',
    aide: '<',
  },

  controller: recapitulatifRealisationEvaluationAide,
});

/**
 *
 * @param {Function} $filter
 */
function recapitulatifRealisationEvaluationAide($filter) {
  this.$onInit = function () {
    if (_.has(this, 'aide.periodeRealisation')) {
      this.dateRealisation =
        $filter('date')(_.get(this, 'aide.periodeRealisation.dateDebut'), 'shortDate') +
        ' - ' +
        $filter('date')(_.get(this, 'aide.periodeRealisation.dateFin'), 'shortDate');
    } else if (_.has(this, 'aide.dateRealisation')) {
      this.dateRealisation = $filter('date')(_.get(this, 'aide.dateRealisation'), 'shortDate');
    }
  };
}
