'use strict';
angular.module('common.helpers').factory('IFrameCommunicationManager', [
  '$rootScope',
  '$q',
  '$window',
  '$document',
  function ($rootScope, $q, $window, $document) {
    'use strict';

    /**
     *
     * @param {string} iframeName
     */
    function IFrameCommunicationManager(iframeName) {
      this.listeners = [];
      this.iframeName = iframeName;
      this.listenerById = {};
    }

    IFrameCommunicationManager.prototype.sendEvent = function (data) {
      const iFrame = angular.element(this.iframeName);
      const iframes = $document[0].getElementsByTagName('iframe');

      if (iFrame && iFrame.length > 0) {
        iFrame[0].contentWindow.postMessage(data, '*');
        return this;
      } else if (iframes.length) {
        iframes.forEach((iframe) => iframe.contentWindow.postMessage(data, '*'));
        return this;
      }
    };

    IFrameCommunicationManager.prototype.manageEvent = function (callBack) {
      const listener = (msg) => {
        $rootScope.$apply(callBack(msg));
      };
      $window.addEventListener('message', listener);
      this.listeners.push(listener);
    };

    IFrameCommunicationManager.prototype.manageEventWithPromise = function (callBack) {
      return $q((resolve, reject) => {
        const listener = (msg) => {
          $rootScope.$apply(callBack(msg, resolve, reject));
        };
        this.listeners.push(listener);
        $window.addEventListener('message', listener);
      });
    };

    IFrameCommunicationManager.prototype.manageUniqueEventWithPromise = function ({ type, callback }) {
      return $q((resolve, reject) => {
        const listener = (msg) => {
          $rootScope.$apply(callback(msg, resolve, reject));
        };

        if (!this.listenerById[type]) {
          this.listenerById[type] = listener;
          $window.addEventListener('message', listener);
        }
      });
    };

    IFrameCommunicationManager.prototype.closeUniqueEvent = function ({ type }) {
      $window.removeEventListener('message', this.listenerById[type]);
      delete this.listenerById[type];
    };

    IFrameCommunicationManager.prototype.close = function () {
      // close standard listener
      this.listeners.forEach((listener) => {
        $window.removeEventListener('message', listener);
      });

      // close listener with id
      for (const [type, listener] of Object.entries(this.listenerById)) {
        $window.removeEventListener('message', listener);
        delete this.listenerById[type];
      }
    };

    return IFrameCommunicationManager;
  },
]);
