'use strict';
/**
 * @module offres-stage
 * @name offres-stage
 * @description
 *
 *   The main module for all offres-stage related services and directives for our portals
 */

angular.module('offresStage', ['common', 'offresStage.model', 'offresStage.services', 'offresStage.components']);

angular.module('offresStage.services', ['offresStage.model']);
angular.module('offresStage.components', ['offresStage.services']);
