angular.module('tiers').constant('tiersConstant', {
  linkedUsers: {
    form: {
      ADMINISTRATOR: 'ADMINISTRATOR',
      SIGNATAIRE: 'SIGNATAIRE',
      CONTRIBUTOR: 'CONTRIBUTOR',
      OWNER: 'OWNER',
    },
  },
});
