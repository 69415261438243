'use strict';
angular.module('common.helpers').factory('DateHelper', [
  function () {
    'use strict';

    return {
      /**
       * Function removes the time zone to select the correct day with the time change
       *
       * @param {string} date
       * @returns {string}
       */
      ignoreTimeZoneOffset: function (date) {
        const d = new Date(date);
        const newDate = new Date(
          d.getFullYear(),
          d.getMonth(),
          d.getDate(),
          d.getHours(),
          d.getMinutes() - d.getTimezoneOffset()
        ).toISOString();
        return newDate;
      },

      /**
       * Formats a date object to a string with the pattern 'YYYY-MM-DD'
       *
       * @param {object} date date to format
       * @returns {string} formatted date
       */
      getDateOnly: function (date) {
        return date.toISOString().split('T')[0];
      },
    };
  },
]);
