'use strict';
depotSimpleDemandeurRecapitulatifController.$inject = [
  '$scope',
  '$q',
  '$timeout',
  'configuration',
  'tiersService',
  'viewsService',
  '$window',
  'IFrameCommunicationManager',
  'StoreService',
  'jsonpatch',
  '$modal',
];
angular
  .module('portailDepotDemandeAide.depot')
  .controller('depotSimpleDemandeurRecapitulatifController', depotSimpleDemandeurRecapitulatifController);

/**
 *
 * @param {object} $scope
 * @param {object} $q
 * @param {object} $timeout
 * @param {object} configuration
 * @param {object} tiersService
 * @param {object} viewsService
 * @param {object} $window
 * @param {object} IFrameCommunicationManager
 * @param {object} StoreService
 * @param {object} jsonpatch
 * @param {Function} $modal
 */
function depotSimpleDemandeurRecapitulatifController(
  $scope,
  $q,
  $timeout,
  configuration,
  tiersService,
  viewsService,
  $window,
  IFrameCommunicationManager,
  StoreService,
  jsonpatch,
  $modal
) {
  const ficheRecapitulativeReady = $q.defer();
  const ressourceshumainesReady = $q.defer();
  const agrementstiersassociationReady = $q.defer();
  const unionsfederationsreseauxtiersassociationReady = $q.defer();

  // If the user hasn't any tiers, we have to go to the tiers creation workflow
  if (!$scope.tiers) {
    $scope.goToStep('demandeur-famille', true);
  }

  $scope.cleanNavigate();
  $scope.navigate.ns = 'teleservice.demandeur-recapitulatif';

  $scope.informationsComplementairesDemandeurConfiguration.showErrors = $scope.showErrorsOnNavigate();

  $scope.isAssociation = _.get($scope.tiers, 'famille.expand.typeFamille', '') === 'TIERS_ASSOCIATION';
  $scope.initialTiersViews = _.cloneDeep($scope.tiers.views);

  const iFrameCommunicationManagers = [];
  const viewsCommunicationManager = new IFrameCommunicationManager('#viewsInfosComps');
  iFrameCommunicationManagers.push(viewsCommunicationManager);
  const updateStateViewsId = 'updateStateViews';

  // rules:
  // https://jira.mgdis.fr/browse/PLAID-12175 (this screen)
  // https://jira.mgdis.fr/browse/PLAID-13443 (contract defaults)
  // https://jira.mgdis.fr/browse/PDA9-825, RG17
  const canEditTiersInDepot =
    (configuration.tiers?.modificationIdentificationTiersParComptes ?? true) &&
    (configuration.tiers?.modificationDeTiersParDepot ?? false) &&
    !$scope.isDemandeurReadOnly;

  let editTiersModal = null;
  $scope.hideEditTiersModal = function () {
    if (editTiersModal?.$scope?.$hide) {
      editTiersModal.$scope.$hide();
    }
  };
  $scope.showEditTiersModal = function () {
    const scopeModal = $scope.$new();
    scopeModal.editTiersIframeUrl = getIframeReferentielTiersUrl(
      $scope.tiers.reference,
      'ficherecapitulative/editTiers'
    );
    editTiersModal = $modal({
      scope: scopeModal,
      template: 'tiers/modal/tiers-edit-modal.html',
    });
  };

  /**
   * Iframe to referentiel-tiers
   *
   * @param {string} reference
   * @param {object} page
   * @returns {string}
   */
  function getIframeReferentielTiersUrl(reference, page) {
    var url = tiersService.getIframeUrl(reference, page, {
      readOnly: !canEditTiersInDepot,
      richtext: false,
      titleLevel: 3,
    });
    return url;
  }

  /**
   * @returns {Promise}
   */
  function loadFicheRecapitulative() {
    $scope.ficheRecapitulativeUrl = getIframeReferentielTiersUrl($scope.tiers.reference, 'ficherecapitulative');
    return ficheRecapitulativeReady.promise;
  }

  /**
   * This function allows to set the viewsIframeSrc proprety used to load data-schemas iframe for tiers
   * According the optin we load the iframe with tiers views only or merged with famille views
   *
   * @returns {void}
   */
  function loadDataSchemasIframeForTiers() {
    const familleHref = $scope.tiers?.famille?.href;
    const displaySaveButton = canEditTiersInDepot;

    // Get famille views and merge it with tiers views
    (displaySaveButton ? tiersService.getFamille(familleHref, ['views.schema']) : $q.resolve()).then(
      (familleConfig) => {
        // If the iframe is in editable mode, we merge tiers views with famille views
        // If not, we keep tiers views only
        if (displaySaveButton) {
          $scope.tiers.views = viewsService.mergeTiersViewsWithFamilleViews(
            familleConfig.views ?? [],
            $scope.tiers.views ?? []
          );
        }

        $scope.actifViews = tiersService.hasActifViews($scope.tiers);
        $scope.viewsIframeSrc = tiersService.getViewsIframeSrcFromTiers($scope.tiers, !canEditTiersInDepot, false, 3);

        $timeout(() => {
          setIframeResizer('#viewsInfosComps');
        });
      }
    );
  }

  /**
   * @returns {Promise}
   */
  function loadResourcesHumaines() {
    $scope.ressourcesHumainesUrl = getIframeReferentielTiersUrl($scope.tiers.reference, 'ressourceshumaines');
    return ressourceshumainesReady.promise;
  }

  /**
   * @returns {Promise}
   */
  function loadAgrementsTiersAssociation() {
    $scope.agrementsUrl = getIframeReferentielTiersUrl($scope.tiers.reference, 'agrementstiersassociation');
    return agrementstiersassociationReady.promise;
  }

  /**
   * @returns {Promise}
   */
  function loadUnionsFederationsReseauxTiersAssociation() {
    $scope.unionsFederationsReseauxUrl = getIframeReferentielTiersUrl(
      $scope.tiers.reference,
      'unionsfederationsreseauxtiersassociation'
    );

    return unionsfederationsreseauxtiersassociationReady.promise;
  }

  /**
   * @returns {Promise}
   */
  function loadAllIframes() {
    return loadFicheRecapitulative()
      .then(() => loadDataSchemasIframeForTiers())
      .then(() => {
        if ($scope.isAssociation) {
          // Additional pages for tiers association
          return loadAgrementsTiersAssociation()
            .then(loadUnionsFederationsReseauxTiersAssociation)
            .then(loadResourcesHumaines);
        }
      });
  }

  /**
   *
   * @param {string} id
   */
  function setIframeResizer(id) {
    iFrameResize(
      {
        heightCalculationMethod: 'taggedElement',
        checkOrigin: false,
        inPageLinks: true,
      },

      id || '.referentiel-tiers-iframe'
    );
  }

  /**
   * update the tiers context, the purpose if to compute the hidding condition with the new values
   */
  function updateInfosTiersContext() {
    const viewsIframe = angular.element('#viewsInfosComps');
    if (viewsIframe && viewsIframe[0]) {
      viewsIframe[0].contentWindow.postMessage(
        {
          action: 'updateContextExpressions',
          contextExpressions: JSON.parse(JSON.stringify($scope.tiers)),
        },

        '*'
      );
    }
  }

  /**
   * Check if the data set on the tiers views are valid and wait for them to be updated on the tiers in memory
   *
   * Done only if views are active and on first depot. If not on first depot, the tiers "views" infos comp are displayed on another screen
   *
   * @param {boolean} ignoreAllFields option to ignore errors on fields
   * @returns {Promise} promise that resolves when the views are updated or immediatly if they don't need to be updated
   */
  function validateViewsAndWaitForUpdate(ignoreAllFields) {
    if (!$scope.tiers || !$scope.actifViews) {
      return $q.resolve();
    }

    viewsCommunicationManager.sendEvent({
      action: 'validViews',
      options: { skipRequiredErrors: ignoreAllFields, showAllErrors: !ignoreAllFields },
    });

    return viewsCommunicationManager.manageUniqueEventWithPromise({
      type: updateStateViewsId,
      callback: (msg, resolve, reject) => {
        viewsService.updateStateViewsTiers($scope, msg, resolve, reject);
      },
    });
  }

  $scope.navigate.next = () => {
    // If tiers is editable
    if (canEditTiersInDepot) {
      // Get configuration options to the validation
      const infosCompsActif =
        $scope.teleserviceConfiguration?.workflow?.pageInformationsDemandeur?.complementaires?.actif ?? false;
      const controleCompletude = $scope.teleserviceConfiguration?.controleCompletudeDepot ?? false;
      // IgnoreAllFields in validation WHEN
      // - infoComp page is not actif in teleservice parameter
      // OR
      // - when the completude is checked only at the end of the depot
      const ignoreAllFields = !infosCompsActif || controleCompletude;

      // Send event to valid the data-schemas iframe content. We wait the response to update the tiers.
      return validateViewsAndWaitForUpdate(ignoreAllFields)
        .then(() => {
          const patches = StoreService.tiers.patches
            .get()
            .filter((patch) => !patch.path.startsWith('/views'))
            .concat(jsonpatch.compare({ views: $scope.initialTiersViews }, { views: $scope.tiers.views }));

          if (!_.isEmpty(patches)) {
            // When tiers infos-comp are active, referentiel-tiers will add the views property
            // according to the famille
            return tiersService.patchTiers($scope.tiers.reference, patches, $scope.mdm).then((tiers) => {
              StoreService.tiers.patches.clean();
              if ($scope.tiers) {
                $scope.tiers = tiers;
              }
            });
          }
          return $q.resolve();
        })
        .then(() => $scope.goToStep('demandeur-complementaire'))
        .catch(() => {
          viewsCommunicationManager.closeUniqueEvent({ type: updateStateViewsId });
        });
    } else {
      $scope.goToStep('demandeur-complementaire');
    }
  };
  $scope.stepsWizard.steps = $scope.getSimpleSteps();
  $scope.stepsWizard.active = 'tiers';

  // Display page content only when the first iframe is loaded
  /**
   *
   * @param {object} msg
   */
  function iframeListener(msg) {
    const action = msg?.data?.action;

    if (action === 'ready') {
      const route = msg?.data?.route;

      if (route === 'ficheRecapitulative') {
        $scope.$apply(function () {
          $scope.ficheRecapitulativeLoaded = true;
          ficheRecapitulativeReady.resolve();
          setIframeResizer();
        });
      } else if (route === 'ressourceshumaines') {
        ressourceshumainesReady.resolve();
      } else if (route === 'agrementstiersassociation') {
        agrementstiersassociationReady.resolve();
      } else if (route === 'unionsfederationsreseauxtiersassociation') {
        unionsfederationsreseauxtiersassociationReady.resolve();
      } else {
        // update the tiers context when ready, else hidding condition may not work properly
        updateInfosTiersContext();
      }
    } else if (action === 'savedTiers') {
      const tiers = msg.data?.tiers;
      if (tiers) {
        $scope.tiers = tiers;
        StoreService.demandeur.set(tiers);
        $scope.aide.demandeur = {
          href: tiers.href,
          title: tiers.title,
          expand: tiers,
        };
      }
      updateInfosTiersContext();
    }

    // Modal + iframe workaround
    // We need to passe iframe message from one iframe to another one (data refresh on update)
    tiersService.forwardEditEventsToIdentificationTiersIframe(msg);

    if (action === 'open-edit-tiers-modal') {
      $scope.$apply(function () {
        $scope.showEditTiersModal();
      });
    }
    if (action === 'close-edit-tiers-modal') {
      $scope.$apply(function () {
        $scope.hideEditTiersModal();
      });
    }
  }

  // Load all iframes
  loadAllIframes();

  $window.addEventListener('message', iframeListener, false);

  const updateViewsTiers = (msg) => {
    viewsService.updateViewsEntity($scope, msg, $scope.tiers, '#viewsInfosComps', {
      showAllErrors: $scope.informationsComplementairesDemandeurConfiguration.showErrors && canEditTiersInDepot,
    });
  };

  // Add data-schemas listeners
  viewsCommunicationManager.manageEvent(updateViewsTiers);

  $scope.$on('$destroy', () => {
    $window.removeEventListener('message', iframeListener, false);

    _.each(iFrameCommunicationManagers, (manager) => {
      manager.close();
    });
  });
}
