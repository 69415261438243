angular.module('common.services').service(
  'usersService',
  class UsersService {
    /**
     * Check if the given id matches an agent account
     *
     * @param {string} id user id
     * @returns {boolean} true if the user is an agent
     */
    isAgent(id) {
      const idParts = id.split('/');
      const tenant = idParts[2] ?? '';
      return tenant.endsWith('-agents');
    }
  }
);
