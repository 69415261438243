'use strict'; // ! should match with : https://gitlab.mgdis.fr/angular/portail-agent-aides/-/blob/v2.4.0/app/modules/common/common-directives/mg-tooltip/mg-tooltip.js

/**
 * This directive override ui-bootstrap tooltip to add accessibility features.
 * Use only as attribute.
 *
 * @param {string} old-mg-tooltip - Activate the tooltip with the text to display
 * @param {string} mg-tooltip-position - Position of the tooltip relative to the element (available positions in the array below)
 * @param {string} mg-tooltip-id - Id of the tooltip used for the aria-describedby
 * @example
 * `<a old-mg-tooltip="Text of the tooltip" mg-tooltip-position="bottom" mg-tooltip-id="my-tooltip-id"></a>
 */

// Allowed position for tooltip
const allowedPosition = [
  'top',
  'top-left',
  'top-right',
  'bottom',
  'bottom-left',
  'bottom-right',
  'left',
  'left-top',
  'left-bottom',
  'right',
  'right-top',
  'right-bottom',
  'auto top',
  'auto top-left',
  'auto top-right',
  'auto bottom',
  'auto bottom-left',
  'auto bottom-right',
  'auto left',
  'auto left-top',
  'auto left-bottom',
  'auto right',
  'auto right-top',
  'auto right-bottom',
];

angular.module('common.directives').directive('oldMgTooltip', [
  '$compile',
  '$timeout',
  ($compile, $timeout) => {
    return {
      restrict: 'A',
      scope: {
        mgContent: '@oldMgTooltip',
        mgTooltipPosition: '@mgTooltipPosition',
        mgTooltipId: '@mgTooltipId',
      },

      link($scope, $element) {
        // Check if tooltip already set for this element
        const attr = $element.attr('uib-tooltip-template');
        if (attr) return;
        // tooltip id (or element id if not provided)
        $scope.mgTooltipId = $scope.mgTooltipId || `${$element.attr('id')}-tooltip`;

        // Tooltip position
        if (!allowedPosition.includes($scope.mgTooltipPosition)) {
          $scope.mgTooltipPosition = 'bottom';
        }
        $element.attr('tooltip-placement', $scope.mgTooltipPosition);
        // Avoid shifted position
        $element.attr('tooltip-append-to-body', true);
        // Trigger mouseenter + focus
        $element.attr('tooltip-trigger', "'mouseenter focus'");

        // set the tooltip (text included in scope.mgContent)
        $element.attr('uib-tooltip-template', `'common/common-directives/mg-tooltip/mg-tooltip.template.html'`);

        // Link the btn to the description with aria-describedby
        $element.attr('aria-describedby', $scope.mgTooltipId);

        $element.attr('tabindex', '0');

        $timeout(() => $compile($element)($scope));
      },
    };
  },
]);
