/**
 * Aides access management service
 */
class AidesAccessManagementService {
  /**
   * Access management service
   *
   * @param {object} $q the $q service
   * @param {object} $http the http service
   * @param {object} $translate the translation service
   * @param {object} tiersConstant tiers constants
   * @param {object} tiersAccountsService tiers accounts service
   * @param {object} aidesService aides service
   */
  constructor($q, $http, $translate, tiersConstant, tiersAccountsService, aidesService) {
    this.$q = $q;
    this.$http = $http;
    this.$translate = $translate;
    this.tiersConstant = tiersConstant;
    this.tiersAccountsService = tiersAccountsService;
    this.aidesService = aidesService;
  }

  /**
   * Computes restrictions uri from demande href
   *
   * @param {string} hrefDemande the demande href
   * @returns {string} the restrictions endpoint uri for the given demande
   */
  getRestrictionsUri(hrefDemande) {
    return `${hrefDemande}/restrictions`;
  }

  /**
   * Get restrictions from accessManagement route
   *
   * @param {string} hrefDemande the demande href
   * @param {boolean} getMandatories true if mandatories restrictions need to be retrieved
   * @returns {Promise<{ 'isRestricted': boolean, 'restrictions': Array<object> }>} demande restrictions object
   */
  getDemandeRestrictions(hrefDemande, getMandatories) {
    const restrictionsHref = this.getRestrictionsUri(hrefDemande);

    const params = {
      getMandatories,
    };

    return this.$http.get(restrictionsHref, { params }).then((demandeRestrictions) => {
      const savedRestrictions = demandeRestrictions.data.restrictions?.restrictions;
      const mandatoriesRestrictions = demandeRestrictions.data.mandatoriesRestrictions;

      return {
        isRestricted: Boolean(savedRestrictions),
        restrictions: this.mergeRestrictions(mandatoriesRestrictions, savedRestrictions),
      };
    });
  }

  /**
   * Put restrictions of a demande
   *
   * @param {string} hrefDemande the demande href
   * @param {Array<object>} restrictions the list of restricted users
   * @returns {Promise<object>} the list of restricted users
   */
  putDemandeRestrictions(hrefDemande, restrictions) {
    const restrictionsHref = this.getRestrictionsUri(hrefDemande);

    return this.$http.put(restrictionsHref, { restrictions });
  }

  /**
   * Delete restrictions of a demande
   *
   * @param {string} hrefDemande the demande href
   * @returns {Promise<object>} the deleted restrictions
   */
  deleteDemandeRestrictions(hrefDemande) {
    const restrictionsHref = this.getRestrictionsUri(hrefDemande);

    return this.$http.delete(restrictionsHref);
  }

  /**
   * Transform a linkedUsers array to a restrictions array
   *
   * @param {Array<object>} linkedUsers the list of tiers linked users
   * @param {object} aide the associated demande
   * @param {Array<string>} [sollicitationsUsers=[]] the list of users href with opened sollicitations
   * @returns {Array<object>} linkedUsers transformed into restrictions objects
   */
  linkedUsersToRestrictions(linkedUsers, aide, sollicitationsUsers = []) {
    const translateRolePath = 'connected.dashboard.aides.accessManagement.modal.userItem.roles';

    const userDemandeurHref = aide?.history?.begin?.user?.href;
    const signataireHref = aide?.signataire?.href;

    const translateService = this.$translate;
    const tiersConstant = this.tiersConstant;

    return (
      linkedUsers?.map((linkedUser) => {
        // First compute roles translations
        const roles = [];

        if (userDemandeurHref && userDemandeurHref === linkedUser.href) {
          roles.push(translateService.instant(`${translateRolePath}.demandeur`));
        }

        if (tiersConstant.linkedUsers.form.ADMINISTRATOR === linkedUser.form) {
          roles.push(translateService.instant(`${translateRolePath}.administrator`));
        }

        if (signataireHref && signataireHref === linkedUser.href) {
          roles.push(translateService.instant(`${translateRolePath}.signataire`));
        }

        // Have "opened sollicitation" role when no other role is given
        if (roles.length === 0 && sollicitationsUsers.includes(linkedUser.href)) {
          roles.push(translateService.instant(`${translateRolePath}.sollicitation`));
        }

        // Then return userItem with computed roles
        return {
          givenName: linkedUser.expand.name.givenName,
          familyName: linkedUser.expand.name.familyName,
          href: linkedUser.href,
          deletable: roles.length <= 0,
          roles: roles,
        };
      }) || []
    );
  }

  /**
   * Merge restrictions arrays and avoid duplicates
   *
   * @param {Array<object>} restrictions the list of restricted users
   * @param {Array<object>} additionalRestrictions the list of additionnal restrictions
   * @returns {Array<object>} the list containing elements of both list, without duplicates
   */
  mergeRestrictions(restrictions, additionalRestrictions) {
    let mergedRestrictions;

    if (restrictions && additionalRestrictions) {
      mergedRestrictions = Array.from(restrictions);

      for (const additionalRestriction of additionalRestrictions) {
        const isAlreadyInRestrictions = mergedRestrictions.some(({ href }) => href === additionalRestriction.href);
        if (!isAlreadyInRestrictions) mergedRestrictions.push(additionalRestriction);
      }
    } else if (!additionalRestrictions) {
      mergedRestrictions = restrictions;
    } else {
      mergedRestrictions = additionalRestrictions;
    }

    return mergedRestrictions;
  }

  /**
   * Computes if the restrictions of the given aide can be edited
   *
   * @param {object} aide the aide which restrictions want to be edited
   * @param {object} tiers the tiers who wants to edit restrictions
   * @returns {boolean} true if aide can be edited
   */
  canRestrictionsBeEdited(aide, tiers) {
    const isAideTransmitted = this.aidesService.hasBeenTransmitted(aide);
    const isOwnedByCurrentUser = this.aidesService.isOwnedByCurrentUser(aide);
    const isCurrentUserTiersAdministrator = this.tiersAccountsService.isCurrentUserAdministrator(tiers);

    // true in one of the following cases :
    // aide is transmitted AND current user is demandeur
    // aide is transmitted AND current user is tiers ADMINISTRATOR
    return isAideTransmitted && (isOwnedByCurrentUser || isCurrentUserTiersAdministrator);
  }

  /**
   * Function trigerred when modal is showing
   *
   * @param {object} demande the demande object
   * @param {string} demandeurReference tiers demandeur reference
   * @param {string | null} [originalSignataireHref=null] user signataire id
   * @returns {Promise<{ isRestricted: boolean, linkedUsers: object[], restrictedUsers: object [] } | void>} result containing demande restrictions and demandeur linkedUsers
   */
  fetchUsersAndRestrictions(demande, demandeurReference, originalSignataireHref = null) {
    if (!demandeurReference) return this.$q.resolve();

    const getRestrictionsPromise = this.getDemandeRestrictions(demande?.id, true);
    const getLinkedUsersPromise = this.tiersAccountsService
      .getLinkedUsers({ reference: demandeurReference })
      .then(({ data }) => data);

    return this.$q.all([getRestrictionsPromise, getLinkedUsersPromise]).then(([demandeRestrictions, linkedUsers]) => {
      // Get Restrictions
      let restrictedUsers = demandeRestrictions.restrictions.map(({ href }) => href);

      const isRequested = this.aidesService.isRequested(demande);
      if (isRequested && originalSignataireHref)
        restrictedUsers = restrictedUsers.filter((restrictedUser) => restrictedUser !== originalSignataireHref);

      // We need to add manually signataire for depot, backend will not return it as restricted because not already saved
      const currentSignataireHref = demande?.signataire?.href;
      if (currentSignataireHref && !restrictedUsers.includes(currentSignataireHref))
        restrictedUsers.push(currentSignataireHref);

      // Get linked users
      const sollicitationsUsers = demandeRestrictions.restrictions
        .filter(({ origin }) => origin === 'sollicitations')
        .map(({ href }) => href);

      const adminOrContributorLinkedUsers = linkedUsers.filter(({ form }) =>
        [this.tiersConstant.linkedUsers.form.ADMINISTRATOR, this.tiersConstant.linkedUsers.form.CONTRIBUTOR].includes(
          form
        )
      );

      const userList = this.linkedUsersToRestrictions(adminOrContributorLinkedUsers, demande, sollicitationsUsers);

      return {
        isRestricted: demandeRestrictions.isRestricted,
        linkedUsers: adminOrContributorLinkedUsers,
        userList: userList,
        restrictedUsers,
      };
    });
  }
}

AidesAccessManagementService.$inject = [
  '$q',
  '$http',
  '$translate',
  'tiersConstant',
  'tiersAccountsService',
  'aidesService',
];

angular.module('aides.services').service('aidesAccessManagementService', AidesAccessManagementService);
