class FormSectionController {
  /** @type {string} */
  titleText;

  constructor($scope) {
    this.$scope = $scope;
  }

  $onInit() {
    this.$scope.titleText = this.titleText;
  }
}

FormSectionController.$inject = ['$scope'];

angular.module('common.components').component('formSection', {
  templateUrl: 'common/common-components/form-section/form-section.component.html',
  transclude: true,
  controller: FormSectionController,
  bindings: {
    titleText: '@',
  },
});
