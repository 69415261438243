'use strict';
angular.module('offresStage.components').component('siretFieldStructure', {
  templateUrl: 'offres-stage/offres-stage-components/siret-field-structure/siret-field-structure.html',
  controller: siretFieldStructureController,
  bindings: {
    field: '<',
    setValue: '&',
    value: '=',
    readOnly: '<',
    ns: '<',
    name: '@',
  },

  require: {
    form: '^^form',
  },
});

siretFieldStructureController.$inject = [];

/**
 *
 */
function siretFieldStructureController() {
  var ctrl = this;

  ctrl.$onInit = function () {
    if (!_.isEmpty(this.value)) {
      ctrl.siren = _.get(this, 'value.SIREN');
      ctrl.nic = _.get(this, 'value.NIC');
    }
  };

  /**
   * Méthode qui valide la saisie du SIRET
   *
   * @param {string} siren Siren
   * @param {string} nic Nic
   * @returns {boolean}
   */
  ctrl.verifySiret = function (siren, nic) {
    var validity = false;

    if (siren && nic && siren.length === 9 && nic.length === 5) {
      var siret = siren + nic;
      var somme = 0;
      var tmp;

      if (
        parseInt(siren) !== 356000000 ||
        (parseInt(siren) === 356000000 && parseInt(nic, 10) === parseInt('00048', 10))
      ) {
        for (var cpt = 0; cpt < siret.length; cpt++) {
          if (cpt % 2 === 0) {
            tmp = parseInt(siret.charAt(cpt)) * 2;
            if (tmp > 9) {
              tmp -= 9;
            }
          } else {
            tmp = parseInt(siret.charAt(cpt));
          }
          somme += parseInt(tmp);
        }
      } else {
        for (var i = 0; i < siret.length; i++) {
          somme += parseInt(siret.charAt(i));
        }
      }

      if (somme % 10 === 0 || (parseInt(siren) === 356000000 && somme % 5 === 0)) {
        validity = true;
      }
    }

    return validity;
  };

  /**
   * Méthode qui vérifie la validité du SIRET si Ok
   * on affecte la valeur au formulaire
   *
   * @param {string} siren
   * @param {string} nic
   */
  ctrl.isValid = function (siren, nic) {
    if (siren && nic) {
      var valid = this.verifySiret(siren, nic);
      this.form[ctrl.name].siren.$setValidity('siret', valid);
      if (valid) {
        _.set(this, 'value.SIREN', siren);
        _.set(this, 'value.NIC', nic);
      }
    }
  };

  /**
   * Méthode qui rempli les champs SIREN et NIC
   * lorsque l'utilisateur veut copier un SIRET complet
   *
   * @param {object} $event
   */
  ctrl.pasteSIREN = function ($event) {
    var pasted = $event.originalEvent.clipboardData.getData('text/plain') || '';
    ctrl.siren = pasted.replace(/(\W|_)+/g, '').substring(0, 9);
    if (pasted.replace(/(\W|_)+/g, '').length > 9) {
      ctrl.nic = pasted.replace(/(\W|_)+/g, '').substring(9);
    }
    this.form[ctrl.name].siren.$touched = true;
    this.form[ctrl.name].nic.$touched = true;
    ctrl.isValid(ctrl.siren, ctrl.nic);
  };
}
