'use strict';
angular.module('common.filters').filter('replaceregexp', function () {
  'use strict';
  return function (string, params) {
    if (!_.isEmpty(string) && _.isString(string) && params && angular.isObject(params)) {
      $.map(params, function (val, key) {
        string = string.replace(new RegExp(key), val);
      });
    }

    return string;
  };
});
