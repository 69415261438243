'use strict';
/**
 * @description
 * @example Directive who display a simple address. No actions for edit the adress is offered.
 * @returns {object}
 */
angular.module('tiers').directive('coordonneesFicheSimple', [
  function () {
    'use strict';

    return {
      transclude: true,
      replace: 'true',
      templateUrl: 'tiers/tiers-directives/coordonnees-fiche-simple/coordonnees-fiche-simple.html',
      scope: {
        coordonnees: '=',
      },
    };
  },
]);
