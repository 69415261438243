'use strict';
angular.module('portailDepotDemandeAide.depot').controller('demandePaiementInformationsComplementairesController', [
  'viewsService',
  '$scope',
  '$timeout',
  'demandesPaiementService',
  'IFrameCommunicationManager',
  function demandePaiementInformationsComplementairesController(
    viewsService,
    $scope,
    $timeout,
    demandesPaiementService,
    IFrameCommunicationManager
  ) {
    $scope.initializeChild();

    $scope.pageOptions = _.get($scope, 'teleserviceConfiguration.workflow.pageInformationsComplementaires', {
      actif: false,
    });

    $scope.cleanNavigate();

    $scope.navigate.ns = $scope.informationsComplementairesConfiguration.ns;

    $scope.stepsWizard.steps = $scope.getDemandePaiementSteps();
    $scope.stepsWizard.active = 'informationsGenerales';

    const iFrameCommunicationManagers = [];
    const viewsCommunicationManager = new IFrameCommunicationManager('#iframeInformationsComplementairesPaiement');
    iFrameCommunicationManagers.push(viewsCommunicationManager);

    // If page is not active we just go next
    if ($scope.pageOptions.actif) {
      $scope.activePage();
    } else {
      $scope.navigate.next(true);
    }

    this.$onInit = function () {
      // Get iframe's URL
      $scope.iframeInformationsComplementairesPaiement = viewsService.getPageViewsIframeUrl({
        entity: $scope.demandePaiement,
        page: 'pageInformationsComplementaires',
        theme: 'demandeur',
        readOnly: false,
        topTitleLevel: 5,
        contextHeaders: {
          'referentiel-tiers': [
            'Accept:application/vnd.mgdis.tiers-3.19.0+json',
            'Content-Type:application/vnd.mgdis.tiers-3.19.0+json',
          ],
        },
      });

      // Add iframe's listener to update demande
      viewsCommunicationManager.manageEvent(iframeEventListener);
    };

    $scope.$on('$destroy', function () {
      iFrameCommunicationManagers.forEach((manager) => manager.close());
    });

    /**
     * Add event process
     *
     * @param {object} msg event message
     */
    function iframeEventListener(msg) {
      const action = _.get(msg, 'data.action');

      // Update view's datas on current demande de paiement
      if (action === 'viewsUpdated') {
        const index = msg.data?.index;
        const values = msg.data?.values;
        const viewUpdated = $scope?.demandePaiement?.views.at(index);
        if (!viewUpdated.schema.href.includes('/forms-common/')) {
          _.set($scope, `demandePaiement.views.${index}.values`, values);
        }
      }
    }

    /**
     * Save views values
     * Send saveValidFieldValues event and wait to recieved filteredViews event to update views in scope
     *
     * @returns {Promise} promise that resolves when the views are updated
     */
    $scope.saveViewsValues = function () {
      return demandesPaiementService
        .getDemandePaiement($scope.demandePaiement.reference)
        .then(function (demandePaiement) {
          return viewsCommunicationManager
            .sendEvent({
              action: 'saveValidFieldValues',
            })
            .manageUniqueEventWithPromise({
              type: 'filteredViews',
              callback: (msg, resolve) => {
                // Listen to iframe to update indicateursSaisis on aide
                viewsService.manageFilteredViewsEvent(
                  $scope.demandePaiement.id,
                  msg,
                  resolve,
                  demandePaiement.views,
                  $scope.demandePaiement?.views,
                  '#iframeInformationsComplementairesPaiement'
                );
              },
            })
            .then(() => {
              viewsCommunicationManager.closeUniqueEvent({ type: 'filteredViews' });
            });
        });
    };

    /**
     * Function triggered when we naviguate to the next Step progress
     *
     * @param {object} forget
     * @returns {void}
     */
    $scope.navigate.next = function (forget) {
      const goToNextStep = () => {
        if ($scope.displayIndicateursRealisation) {
          $scope.goToStep('indicateurs', forget);
        } else if (
          _.get($scope, 'teleserviceConfiguration.workflow.pageDocumentComptable.actif') &&
          demandesPaiementService.getPlanFinancementDepose($scope.demandePaiement)
        ) {
          $scope.goToStep('document-comptable', forget);
        } else {
          $scope.goToStep('domiciliation-bancaire', forget);
        }
      };

      if (_.isEmpty(_.get($scope, 'demandePaiement.views', []))) {
        goToNextStep();
      } else {
        const viewsValidationPromise = viewsCommunicationManager
          .sendEvent({
            action: 'validViews',
            options: {
              showAllErrors: true,
              skipRequiredErrors: false,
            },
          })
          .manageUniqueEventWithPromise({
            type: 'updateStateViews',
            callback: (msg, resolve, reject) => {
              viewsService.updateStateViewsPaiement($scope, msg, resolve, reject);
            },
          });

        viewsValidationPromise
          .then(() => {
            goToNextStep();
          })
          .catch(() => {
            // iframe validation / user rejected
            viewsCommunicationManager.closeUniqueEvent({ type: 'updateStateViews' });
          });
      }
    };

    $timeout(function () {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },

        '#iframeInformationsComplementairesPaiement'
      );
    }, 0);

    $scope.sharedWithChild.automaticSaveActive = true;
    $scope.sharedWithChild.beforeAutomaticSave = function () {
      return $scope.saveViewsValues();
    };

    /**
     * Update the views values before we go back to the previous step
     *
     * @returns {Promise<void>}
     */
    $scope.sharedWithChild.beforePrevious = $scope.sharedWithChild.beforeAutomaticSave;
  },
]);
