('use strict');
angular.module('aides.services').factory('justificationService', [
  '$http',
  'configuration',
  '$log',
  'jsonpatch',
  function ($http, configuration, $log, jsonpatch) {
    'use strict';

    const tenant = configuration.tenant.id;
    const eTagCache = new Map();

    return {
      /**
       * get justification's teleservice
       *
       * @param {object} justification
       * @param {Array} filters
       * @returns {Promise}
       */
      getTeleservice: function (justification, filters) {
        const teleserviceHref = _.get(justification, 'teleservice.href').split('?')[0];
        const selects = filters && filters.length > 0 ? '?select=' + _.join(filters, ',') : '';
        return $http
          .get(teleserviceHref + selects)
          .then(function (response) {
            if (response.status !== 200) {
              throw new Error(response);
            }
            return response.data;
          })
          .catch(function (err) {
            $log.error(err);
            throw new Error(err);
          });
      },

      /**
       * get justification
       *
       * @param {string} justificationReference
       * @param {Array} filters
       * @param {Array} expand
       * @returns {Promise}
       */
      getJustification: function (justificationReference, filters, expand) {
        const config = {
          params: {
            select: filters && filters.length > 0 ? _.join(filters, ',') : null,
            expand: expand && expand.length > 0 ? _.join(expand, ',') : null,
          },
        };

        const url = `/referentiel-justification/api/tenants/${tenant}/justifications/${justificationReference}`;
        return $http
          .get(url, config)
          .then(function (response) {
            // store the etag header to be used in subsequent patch requests to avoid conflict
            eTagCache.set(url, response.headers('etag'));
            return response.data;
          })
          .catch(function (err) {
            $log.error(err);
            throw new Error(err);
          });
      },

      /**
       * Returns the justification without the expands
       * and the pieces with the expanded documents
       *
       * @param {string} justificationReference
       * @param {Array} filters
       * @returns {object} An object containing the justification and the expanded documents
       */
      getJustificationAndExpandedPieces: function (justificationReference, filters) {
        return this.getJustification(justificationReference, filters, ['pieces.documents']).then((justification) => {
          const pieces = _.get(justification, 'pieces', []);
          // Extract the expanded pieces from the justification
          const piecesExpanded = _.cloneDeep(pieces);
          // Documents that have expand are in GED and can therefore be removed
          piecesExpanded.forEach(({ documents }) => {
            documents.forEach(({ expand }) => {
              if (expand) expand.isDeletable = true;
            });
          });
          // Remove expand and error from the justification itself
          pieces.forEach((piece) => {
            if (piece.documents) {
              piece.documents.forEach((doc) => {
                delete doc.expand;
                delete doc.error;
              });
            }
          });
          return {
            justification,
            piecesExpanded,
          };
        });
      },

      /**
       * Create a justification
       *
       * @param {object} justification
       * @returns {Promise}
       */
      createJustification: function (justification) {
        return $http
          .post('/referentiel-justification/api/tenants/' + tenant + '/justifications', justification)
          .then(function (response) {
            return response.data;
          })
          .catch(function (err) {
            $log.error(err);
            throw new Error(err);
          });
      },

      /**
       * update a justification
       *
       * @param {object} oldJustification
       * @param {object} justification
       * @returns {Promise}
       */
      updateJustification: function (oldJustification, justification) {
        const patches = jsonpatch.compare(oldJustification, justification);
        return this.patch(justification.id, patches);
      },

      /**
       * save justification
       *
       * @param {object} oldJustification
       * @param {object} justification
       * @returns {Promise}
       */
      saveJustification: function (oldJustification, justification) {
        if (justification.reference) {
          return this.updateJustification(oldJustification, justification);
        }
        return this.createJustification(justification);
      },

      /**
       * patch justification
       *
       * @param {string} justificationHref
       * @param {Array} patches
       * @returns {Promise}
       */
      patch: function (justificationHref, patches) {
        // return the etag response header in the if-match request header to avoid conflict
        const config = {
          headers: {
            'If-Match': eTagCache.get(justificationHref),
          },
        };
        return $http
          .patch(justificationHref, patches, config)
          .then(function (response) {
            eTagCache.set(justificationHref, response.headers('etag'));
            return response.data;
          })
          .catch(function (err) {
            $log.error(err);
            throw new Error(err);
          });
      },

      /**
       * Get the justification ask date
       *
       * @param {object} aide The demande-aide
       * @returns {moment} a moment date
       */
      getAskDate: function (aide) {
        // Event READY_TO_JUSTIFY on demande-aide because justif not created yet.
        const events = _.get(aide, 'history.events', []);
        const event = _.find(events, ['reference', 'READY_TO_JUSTIFY']);
        if (event) {
          return new Date(event.date);
        }
        return null;
      },
      /**
       * Get the justification creation date
       *
       * @param {object} justification The justification
       * @returns {moment} a moment date
       */
      getCreationDate: function (justification) {
        const event = _.get(justification, 'history.begin');
        if (event) {
          return {
            date: new Date(event.date),
            userTitle: _.get(event, 'user.title'),
          };
        }
        return null;
      },
      /**
       * Get the justification deposit date
       *
       * @param {object} justification The justification
       * @returns {moment} a moment date
       */
      getDepositDate: function (justification) {
        const events = _.get(justification, 'history.events', []);
        const event = _.find(events, ['reference', 'JUSTIFIED']);
        if (event) {
          return {
            date: new Date(event.date),
            userTitle: _.get(event, 'user.title'),
          };
        }
        return null;
      },

      /**
       * get piece computed rules
       *
       * @param {string} justificationReference
       * @returns {object} Piece computed rules
       */
      getPieceComputedRules: (justificationReference) => {
        return $http
          .get(
            `/referentiel-justification/api/tenants/${tenant}/justifications/${justificationReference}/piece-computed-rules`
          )
          .then((response) => response.data)
          .catch((err) => {
            $log.error(err);
            throw new Error(err);
          });
      },

      /**
       * Projects teleservice with needed props for justification component
       *
       * @param {object} teleservice to format
       * @returns {object} formatted teleservice
       */
      formatTeleserviceForJustifications: (teleservice) => ({
        id: teleservice.id,
        reference: teleservice.reference,
        duplicationDemandeFinancement: teleservice.duplicationDemandeFinancement,
        justifications:
          teleservice.justification?.active && Array.isArray(teleservice.justification?.teleservices)
            ? teleservice.justification.teleservices.map(({ href, title, justificationType }) => ({
                href,
                title,
                type: justificationType,
              }))
            : [],
      }),
    };
  },
]);
