class SollicitationsController {
  constructor(
    $sce,
    $httpParamSerializer,
    $state,
    $stateParams,
    $q,
    $translate,
    $timeout,
    configuration,
    jwtSessionService,
    IFrameCommunicationManager,
    aidesService,
    justificationsService,
    $uibModal,
    $scope,
    contributionsService,
    alertsService,
    $modal,
    $rootScope,
    routeUtilsService
  ) {
    this._sce = $sce;
    this._httpParamSerializer = $httpParamSerializer;
    this._state = $state;
    this._stateParams = $stateParams;
    this._q = $q;
    this._translate = $translate;
    this._timeout = $timeout;
    this.configuration = configuration;
    this.jwtSessionService = jwtSessionService;
    this.IFrameCommunicationManager = IFrameCommunicationManager;
    this.aidesService = aidesService;
    this.justificationsService = justificationsService;
    this._uibModal = $uibModal;
    this._scope = $scope;
    this.contributionsService = contributionsService;
    this.alertsService = alertsService;
    this._modal = $modal;
    this._rootScope = $rootScope;
    this.routeUtilsService = routeUtilsService;
    this.activeTab = '';
    this.page = 1;
  }

  /**
   * Initialize component
   *
   * @returns {void}
   */
  $onInit() {
    this.activeTab = this._stateParams.activeTab || '';
    this.page = this._stateParams.page || 1;

    this.iFrameCommunicationManager = new this.IFrameCommunicationManager('#sollicitationsIframe');

    this.setIframeUrl();

    // Init the listener for the iframe
    // Listen for a successul response and go to next step
    this.iFrameCommunicationManager.manageEvent(({ data }) => {
      const { route, action, value } = data;

      if (route === 'sollicitations-view.ux') {
        switch (action) {
          case 'update-page':
            this.page = value?.toString();
            this._state.go('.', {
              activeTab: this.activeTab,
              page: value?.toString(),
            });
            break;
          case 'changed-tab':
            if (this.activeTab !== value) {
              this.activeTab = value;
              this.page = '1';
              this._state.go('.', { activeTab: this.activeTab, page: this.page });
            }
            break;
          case 'attestations-demandes-attested':
            this._q
              .all(value.demandes.map((demande) => this.aidesService.updateDemandeurGroupesGestion(demande)))
              .then((updatedDemandes) => {
                const attestations = updatedDemandes.map(({ reference }) => reference);
                this._state.go('app.connected.dashboard.attestations.confirmation', { attestations });
                this._rootScope.$broadcast('refresh-menu');
              });
            break;
        }
      }
    });
  }

  /**
   * Set the sollicitations iframe url
   *
   * @returns {void}
   */
  setIframeUrl() {
    const sollicitationsIframeQueryParams = this._httpParamSerializer({
      jwtKey: this.jwtSessionService.getJwtKey(),
      tiersKey: 'current-tiers-ref',
      activeTab: this.activeTab,
      page: this.page,
    });

    const templateSollicitationsIframeSrc = `${this.configuration.ux}<%= tenantId %>/sollicitations?<%= queryParams %>`;
    const compiledSollicitationsIframeSrc = _.template(templateSollicitationsIframeSrc);
    const sollicitationsIframeSrc = compiledSollicitationsIframeSrc({
      tenantId: this.configuration.tenant.id,
      queryParams: sollicitationsIframeQueryParams,
    });

    this.sollicitationsIframeSrc = this._sce.trustAsResourceUrl(sollicitationsIframeSrc);
  }

  /**
   * Destroy component
   *
   * @returns {void}
   */
  $onDestroy() {
    this.iFrameCommunicationManager.close();
  }
}

SollicitationsController.$inject = [
  '$sce',
  '$httpParamSerializer',
  '$state',
  '$stateParams',
  '$q',
  '$translate',
  '$timeout',
  'configuration',
  'jwtSessionService',
  'IFrameCommunicationManager',
  'aidesService',
  'justificationsService',
  '$uibModal',
  '$scope',
  'contributionsService',
  'alertsService',
  '$modal',
  '$rootScope',
  'routeUtilsService',
];

angular.module('portailDepotDemandeAide.dashboard').component('sollicitations', {
  controller: SollicitationsController,
  templateUrl: 'dashboard/sollicitations/sollicitations.component.html',
  bindings: {
    isJustificationsLicenceActive: '<',
    hasTeleserviceWithActiveJustification: '<',
  },
});
