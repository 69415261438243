'use strict';
class AidesFilterController {
  constructor($scope, AideSearch, AidesFilterConfiguration, AidesConstant, $translate) {
    this.$scope = $scope;
    this.AideSearch = AideSearch;
    this.configuration = AidesFilterConfiguration;
    this.aidesConstant = AidesConstant;
    this._translate = $translate;
  }

  $onInit() {
    // Map one item with multiple status and with avis
    // This is for on going instruction "En cours d'instruction"
    this.mapOnGoingInstruction = {
      search: [
        this.aidesConstant.demandeStatuts.ACCEPTABLE,
        this.aidesConstant.dossierStatuts.INSTRUCTION,
        this.aidesConstant.dossierStatuts.FININSTRUCTION,
      ],

      mapAvis: { [this.aidesConstant.dossierStatuts.FININSTRUCTION]: this.configuration.avis.FAVORABLE },
    };

    // This is for on ended instruction "Instruction terminée"
    this.mapEndedInstruction = {
      search: [
        this.aidesConstant.demandeStatuts.UNACCEPTABLE,
        this.aidesConstant.dossierStatuts.FININSTRUCTION_DEFAVORABLE,
        this.aidesConstant.dossierStatuts.FININSTRUCTION,
      ],

      mapAvis: { [this.aidesConstant.dossierStatuts.FININSTRUCTION]: this.configuration.avis.DEFAVORABLE },
    };

    // Filters
    this.searchText = '';
    this.searchStatus = [];
    this.searchTeleservice = [];
    this.searchOperatorExercice = this.configuration.operators[0];

    // Data list for kendo multi select
    this.statusList = [];
    this.statusMap = new Map();
    this.teleservicesList = [];

    this.aideSearch = new this.AideSearch({
      text: this.searchText,
      status: this.searchStatus,
      teleservice: this.searchTeleservice,
      operatorExercice: this.searchOperatorExercice.value,
    });

    this.$scope.$on('update-filters', (_ignored, filters) => {
      this.updateFilters(filters);
    });
  }

  /**
   * Update filters selectable values
   *
   * @param {object} filters filters
   * @returns {void}
   */
  updateFilters(filters) {
    // Status list
    let dataSourceStatus;
    try {
      const widgetStatus = $('#kendo-status-filter').getKendoMultiSelect();
      dataSourceStatus = widgetStatus.dataSource;
    } catch (e) {
      // For test purpose
      dataSourceStatus = null;
    }

    const statuses = filters.statuses.sort();
    statuses.forEach((statut) => {
      const title = this._translate.instant(`connected.dashboard.aides.list.state-${statut}`);
      let statutObj = {
        title: title,
        value: statut,
      };

      // Check if status name already in list because we don't want duplicate
      const notAlreadyIn = this.statusList.findIndex((st) => st.title === title) === -1;

      if (notAlreadyIn) {
        this.statusList.push(statutObj);
        // The map is filled with arrays because it's possible to have multiple statuses for the same title
        // It's possible because the title can be personalized allowing to have the same title for different statuses
        this.statusMap.set(title, [statut]);
        if (dataSourceStatus) {
          // add in kendo list
          dataSourceStatus.add(statutObj);
        }
      } else {
        // If we go through this branch, it means there are title duplicates. Thus we store the statuses in order to filter with them,
        // otherwise only one would be kept
        const currentStatusValue = this.statusMap.get(title);
        currentStatusValue.push(statut);
      }
    });

    // teleservices list
    let dataSourceTeleservice;
    try {
      const widgetTeleservice = $('#kendo-teleservice-filter').getKendoMultiSelect();
      dataSourceTeleservice = widgetTeleservice.dataSource;
    } catch (e) {
      // For test purpose
      dataSourceTeleservice = null;
    }

    const teleservices = filters.teleservices.sort();
    teleservices.forEach((teleservice) => {
      const teleserviceObj = {
        title: teleservice,
        value: teleservice,
      };

      this.teleservicesList.push(teleserviceObj);
      if (dataSourceTeleservice) {
        dataSourceTeleservice.add(teleserviceObj);
      }
    });
  }

  /**
   * Update exercice operator filter and retrieve demandes
   *
   * @param {object} op
   */
  changeExerciceOperatorFilter(op) {
    this.searchOperatorExercice = op;
    if (!this.searchOperatorExercice.value) {
      delete this.dateExercice;
    }
    this.searchOnChange();
  }

  /**
   * Retrieve demandes after entering a text in the searchbar
   *
   * @param {object} event
   */
  reloadSearch(event) {
    if (event && event.key === 'Enter') {
      this.searchOnChange();
    }
  }
  /**
   * Retrieve demandes if operators and date of a filter are both defined or undefined
   */
  searchOnChange() {
    const isExerciceFilterValid = this._isFilterValid(this.searchOperatorExercice, this.dateExercice);

    let statusList = this.searchStatus;

    // Check if we have a special status, with a map
    let onGoingInstruction = false;
    let endedInstruction = false;
    let mapAvis = null;
    _.forEach(statusList, (status) => {
      // Check the map for instructions: if one selected status is in a map array, we add all others status in same array.
      if (_.includes(this.mapOnGoingInstruction.search, status)) {
        onGoingInstruction = true;
      }
      if (_.includes(this.mapEndedInstruction.search, status)) {
        endedInstruction = true;
      }
    });
    // Set all status (out of loop)
    if (onGoingInstruction) {
      statusList = statusList.concat(this.mapOnGoingInstruction.search);
      mapAvis = this.mapOnGoingInstruction.mapAvis;
    }
    if (endedInstruction) {
      statusList = statusList.concat(this.mapEndedInstruction.search);
      mapAvis = this.mapEndedInstruction.mapAvis;
    }
    // If both are selected, we cancel the mapAvis
    if (onGoingInstruction && endedInstruction) {
      mapAvis = null;
    }

    for (const [, value] of this.statusMap) {
      // If there are statuses with duplicated title we add them
      if (value.length > 1 && value.some((statusValue) => statusList.includes(statusValue))) {
        statusList.push(...value);
      }
    }

    // Remove duplicates
    statusList = _.uniq(statusList);

    const newSearch = new this.AideSearch({
      text: this.searchText,
      status: statusList,
      teleservice: this.searchTeleservice,
      operatorExercice: this.searchOperatorExercice.value,
      dateExercice: this.dateExercice,
    });

    if (isExerciceFilterValid && !_.isEqual(this.aideSearch, newSearch)) {
      this.aideSearch = newSearch;
      this.aideSearch.mapAvis = mapAvis;
      this.search({ aideSearch: this.aideSearch });
    }
  }

  _isFilterValid(operator, value) {
    return (!operator.value && !value) || (operator.value && value);
  }
}
AidesFilterController.$inject = ['$scope', 'AideSearch', 'AidesFilterConfiguration', 'AidesConstant', '$translate'];

angular.module('aides').component('aidesFilter', {
  templateUrl: 'aides/aides-components/aides-filter/aides-filter.component.html',
  controller: /* @ngInject */ AidesFilterController,
  bindings: {
    search: '&',
  },
});
