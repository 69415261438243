'use strict';
angular.module('aides').component('demandePaiementLink', {
  templateUrl: 'aides/aides-components/demande-paiement-link/demande-paiement-link.component.html',
  controller: demandePaiementLinkController,
  bindings: {
    id: '<',
    demande: '<',
    teleservicePaiement: '<',
    financeurHref: '<',
    dossier: '<',
  },
});

/* @ngInject */
/**
 *
 */
function demandePaiementLinkController() {
  let ctrl = this;
  ctrl.$onInit = () => {
    if (!ctrl.id) {
      ctrl.id = _.get(ctrl, 'demande.reference');
    }

    const teleservicePaiement = _.get(ctrl.teleservicePaiement, 'reference') || ctrl.teleservicePaiement;

    ctrl.demandePaiementOptions = {
      reference: _.get(ctrl, 'demande.reference'),
      aide: _.get(ctrl, 'demande.aide'),
      teleservicePaiement: teleservicePaiement && teleservicePaiement.split('/').pop(),
      dossier: _.get(ctrl, 'dossier'),
    };

    if (ctrl.financeurHref) {
      ctrl.demandePaiementOptions.financeur = ctrl.financeurHref;
    }
  };
}
