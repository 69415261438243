'use strict';
JustificationDonneesChiffreesController.$inject = ['$scope']; // Module declaration
angular.module('portailDepotDemandeAide.depot').component('justificationDonneesChiffreesComponent', {
  templateUrl: 'depot/justification/donnees-chiffrees/justification-donnees-chiffrees.component.html',
  controller: /* @ngInject */ JustificationDonneesChiffreesController,
  bindings: {
    workflow: '<',
    justification: '=',
    print: '<',
    validStep: '<',
    disableNavigation: '<',
    saveJustification: '<',
    stepIndex: '<',
  },
});

/**
 *
 * @param {object} $scope
 */
function JustificationDonneesChiffreesController($scope) {
  var ctrl = this;

  /**
   * Method for the justification-step-wizard-navigation to go to the previous step
   */
  ctrl.previousStep = function () {
    ctrl.validStep(-1);
  };

  /**
   * Method for the justification-step-wizard-navigation to go to the next step
   */
  ctrl.nextStep = function () {
    if (ctrl.isFormValid()) {
      ctrl.validStep();
    }
  };

  /**
   * Method to check if the form is valid
   *
   * @returns {boolean}
   */
  ctrl.isFormValid = function () {
    return $scope.justificationDonneesChiffreesForm && $scope.justificationDonneesChiffreesForm.$valid;
  };

  /**
   * Method for the justification-step-wizard-navigation to save this step
   */
  ctrl.saveStep = function () {
    ctrl.saveJustification();
  };
}
