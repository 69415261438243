class SkipLinksController {
  constructor($scope, $document, $window, $log) {
    this.$scope = $scope;
    this.$document = $document;
    this.$window = $window;
    this.$log = $log;

    this.anchorListener = (event) => event.preventDefault();
  }

  $onInit() {
    if (!this.skipLinksItems) {
      return;
    }

    if (!this.skipLinksItems.every(({ href, label }) => typeof href === 'string' && typeof label === 'string')) {
      this.$log.error('skip-links items are not valid');
      this.skipLinksItems = [];
    }

    const skipLinks = this.$document[0].querySelector('mg-skip-links')?.shadowRoot?.querySelectorAll('a');

    if (skipLinks) {
      Array.from(skipLinks)?.forEach((anchor) => {
        anchor.addEventListener('click', this.anchorListener);
      });
    }

    this.$scope.$on('$destroy', () => {
      const foundSkipLinks = this.$document[0].querySelector('mg-skip-links')?.shadowRoot?.querySelectorAll('a');

      if (foundSkipLinks) {
        Array.from(foundSkipLinks)?.forEach((anchor) => {
          anchor.removeEventListener('click', this.anchorListener);
        });
      }
    });
  }

  /**
   * Go to selected anchor (scroll to position) and set focus on the element
   *
   * @param {object} anchor tag element in dom
   * @returns {void}
   */
  goToAnchor(anchor) {
    const element = this.$document[0].querySelector(anchor);

    const tabIndex = element?.tabIndex;
    // If tabIndex is not defined in the DOM, element.tabIndex is eq to -1 in js
    // Here we "force" to add attribute "tabIndex" to the node element
    if (element && tabIndex === -1) element.tabIndex = -1;

    element?.focus();
    const top = element?.offsetTop;
    this.$window.scrollTo(0, top);
  }
}

SkipLinksController.$inject = ['$scope', '$document', '$window', '$log'];

angular.module('common.components').component('skipLinks', {
  templateUrl: 'common/common-components/skip-links/skip-links.component.html',
  restrict: 'EA',
  replace: true,
  transclude: true,
  bindings: {
    skipLinksItems: '=',
  },
  controller: SkipLinksController,
});
