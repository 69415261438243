'use strict';
StepsWizardContent.$inject = ['$translate']; // Module declaration
angular.module('common.components').component('stepWizardContent', {
  templateUrl: 'common/common-components/steps-wizard/step-wizard-content.component.html',
  controller: /* @ngInject */ StepsWizardContent,
  transclude: true,
  bindings: {
    ns: '<',
  },
});

// Controller
/**
 *
 * @param {object} $translate
 */
function StepsWizardContent($translate) {
  var ctrl = this;
  ctrl.$onInit = function () {
    if (_.isNil(ctrl.ns)) {
      console.error('StepsWizardContent, you are using stepsWizardContent without namespace, please add one', ctrl.ns);
    }
  };

  // HTML builder
  ctrl.contentClasses = function () {
    if (ctrl.shouldDisplayKey(ctrl.ns + '.informations.content')) {
      return 'col-md-9 col-sm-9 col-xs-12';
    }
  };
  // Utils
  ctrl.shouldDisplayKey = function (key) {
    var htmlResult = $translate.instant(key);
    return htmlResult && htmlResult !== key && htmlResult.length > 0;
  };
}
