'use strict';
tenantsService.$inject = ['configuration', '$http'];
angular.module('common.services').factory('tenantsService', tenantsService);

/* @ngInject */
/**
 *
 * @param {object} configuration
 * @param {object} $http
 * @returns {object}
 */
function tenantsService(configuration, $http) {
  return {
    getMonoTenantId: () => {
      return $http.get(configuration.tenantId.url).then((result) => {
        return _.get(result, 'data.tenantId');
      });
    },
  };
}
