'use strict';
angular.module('portailDepotDemandeAide.depot').controller('demandePaiementInformationsGeneralesController', [
  '$scope',
  '$stateParams',
  'paiementService',
  'demandesPaiementService',
  'configuration',
  function demandePaiementInformationsGeneralesController(
    $scope,
    $stateParams,
    paiementService,
    demandesPaiementService,
    configuration
  ) {
    'use strict';

    $scope.initializeChild();

    $scope.pageOptions = $scope.teleserviceConfiguration.workflow.pageInformationsGenerales;

    $scope.cleanNavigate();
    $scope.navigate.ns = $scope.informationsGeneralesConfiguration.ns;
    $scope.viewConfiguration = $scope.informationsGeneralesConfiguration;

    // Update viewConfiguration from teleservice definition
    _.each($scope.teleserviceConfiguration.demandePaiement.fields, function (field) {
      var reference = _.replace(field.reference, 'informations-generales.', '');
      _.set($scope, 'viewConfiguration.fields.' + reference + '.required', field.required);
      _.set($scope, 'viewConfiguration.fields.' + reference + '.hidden', field.hidden);
    });

    // Show condition on montantDemande in order to prevent the field validation to be triggered on submit
    $scope.showMontantDemande = !_.get($scope, 'viewConfiguration.fields.montantDemande.hidden', false);

    // Get typesPaiementAvecDepensesJustifiees setting
    // If dispositif is in typesPaiementAvecDepensesJustifiees array, set showMontantDepensesJustifiees to false,
    // until the type de paiement field is filled
    $scope.showMontantDepensesJustifiees = !_.get(
      $scope,
      'viewConfiguration.fields.montantDepensesJustifiees.hidden',
      false
    );

    $scope.$watch('demandePaiement.typePaiement.title', function (newValue) {
      // Change demande title if the new type paiement title is different from
      // the demande-paiement's
      if (newValue && $scope.demandePaiement.title && !$scope.demandePaiement.title.startsWith(newValue)) {
        const dateReception = moment($scope.demandePaiement.dateReception).format('DD/MM/YYYY');
        $scope.demandePaiement.title = `${newValue} du ${dateReception}`;
      }

      if ($scope.pageOptions.typesPaiementAvecDepensesJustifiees) {
        $scope.showMontantDepensesJustifiees =
          !!$scope.pageOptions.typesPaiementAvecDepensesJustifiees.includes(newValue);
      }

      // Delete value, so that if the field is hidden and shown again, the value won't stay the same
      if (!$scope.showMontantDepensesJustifiees) {
        delete $scope.demandePaiement.montantDepensesJustifiees;
      }
    });

    $scope.informationsGeneralesConfiguration = $scope.viewConfiguration;

    /**
     * Function triggered when we naviguate to the next Step progress
     *
     * @param {object} forget
     * @returns {void}
     */
    $scope.navigate.next = function (forget) {
      if (
        _.get($scope, 'teleserviceConfiguration.workflow.pageInformationsComplementaires.actif') &&
        $scope.displayInfosComplementaires
      ) {
        $scope.goToStep('informations-complementaires', forget);
      } else if ($scope.displayIndicateursRealisation) {
        $scope.goToStep('indicateurs', forget);
      } else if (
        _.get($scope, 'teleserviceConfiguration.workflow.pageDocumentComptable.actif') &&
        demandesPaiementService.getPlanFinancementDepose($scope.demandePaiement)
      ) {
        $scope.goToStep('document-comptable', forget);
      } else {
        $scope.goToStep('domiciliation-bancaire', forget);
      }
    };

    $scope.stepsWizard.steps = $scope.getDemandePaiementSteps();
    $scope.stepsWizard.active = 'informationsGenerales';

    if (!$scope.pageOptions.actif) {
      $scope.navigate.next(true);
    } else {
      $scope.activePage();
    }

    const getPaiementsPromise = $stateParams.dossier
      ? demandesPaiementService.getDemandesPaiementFromDossier($scope.aide, $stateParams.dossier)
      : demandesPaiementService.getDemandesPaiementLignePlanFinancement($scope.aide, $stateParams.financeur);
    getPaiementsPromise.then((demandesPaiements) => {
      $scope.demandesPaiements = demandesPaiements || [];

      $scope.paiementsTable = paiementService.buildPaiementsTable(demandesPaiements);
    });

    //we need to remove the types de paiement which start with mgremb PLAID-103424
    const typesPaimentWithoutMgremb = $scope.typesPaiement.filter(
      (typePaiement) => !typePaiement?.href?.split('/').pop().toLowerCase().startsWith('mgremb')
    );
    // Types de paiement
    $scope.typesPaiementFiltered = _.orderBy(typesPaimentWithoutMgremb, 'title');

    // Initialize 'montant'
    $scope.demandePaiement.montant = $scope.demandePaiement.montant || {};
    $scope.demandePaiement.montantDepensesJustifiees = $scope.demandePaiement.montantDepensesJustifiees || {};

    // Add user infos
    $scope.demandePaiement.user = {
      href: configuration.user.accountManagement + '/users/' + $scope.currentUser.userName,
      title: $scope.currentUser.displayName,
      expand: $scope.currentUser,
    };

    $scope.isRequestedAmountSmallerThanVoted = function () {
      const montantRestantADemander = demandesPaiementService.getMontantRestantADemander(
        ($scope.demandesPaiements || []).filter((demandePaiement) => demandePaiement.statut !== 'EN_COURS'),
        $scope.decisions
      );

      var checkValidity = montantRestantADemander >= _.get($scope, 'demandePaiement.montant.ttc', 0);

      if ($scope.depotForm) {
        // check-validation-field directive has some limitations used with number-field component
        // this line assures that the validation is initialized
        $scope.depotForm.montantDemande.$setValidity('checkValidity', checkValidity);
        // Need to display error when field already filled on load and we go to next
        $scope.depotForm.montantDemande.$dirty = true;
      }

      return checkValidity;
    };

    /**
     * Allow to control typePaiement value and delete if it's not valued
     */
    $scope.onTypePaiementChange = function () {
      if (_.isEmpty($scope.demandePaiement.typePaiement)) {
        delete $scope.demandePaiement.typePaiement;
      }
    };

    $scope.sharedWithChild.automaticSaveActive = true;

    /**
     * Calculate the patches to make on an automatic update
     *
     * @param {object} params parameters
     * @param {object[]} params.patches The list of patches to use
     * @returns {object[]} the list of patches to use for an automatic update
     */
    $scope.sharedWithChild.calculatePatchesForAutomaticUpdate = function ({ patches = [] }) {
      const patchMontant = patches.find(
        (patch) => patch.op !== 'remove' && ['/montant/ttc', '/montant'].includes(patch.path)
      );
      if (!patches.length || !patchMontant) {
        return patches;
      }

      const isMontantValid = $scope.isRequestedAmountSmallerThanVoted();

      if (isMontantValid) {
        return patches;
      }

      // Send everything back except the montant patch
      return patches.filter((patch) => !(patch.path === '/montant/ttc' || patch.path === '/montant'));
    };
  },
]);
