'use strict';
/**
 * @param {object} $state
 * @param {object} $http
 * @param {object} $q
 * @param {object} $log
 * @param {object} configuration
 * @description Interface for a Tiers' pieces
 * @returns {object}
 */
angular.module('common.services').factory('teleservicesService', [
  '$state',
  '$http',
  '$q',
  '$log',
  'configuration',
  function ($state, $http, $q, $log, configuration) {
    'use strict';

    /**
     * get referentiel financement url from configuration
     *
     * @returns {string} referentiel financement url
     */
    function getReferentielFinancementUrl() {
      return configuration.teleservices?.url || '/referentiel-financement';
    }

    /**
     * In a teleservice, replace a list of fields by a hierarchy of fields, sorted by their references
     *
     * @param {object} teleservice Teleservice containing the list of fields
     * @param {string} category Path where we can find the list of fields
     */
    const deepSetFields = function (teleservice, category) {
      const fieldsList = _.get(teleservice, category + '.fields');

      if (fieldsList) {
        // Replacing the list with an empty dictionary
        _.set(teleservice, category, {});
        const fieldsConf = _.get(teleservice, category);
        // For each field, we place it under the name of the page it's in, and 'fields'
        _.each(fieldsList, function (field) {
          _.set(fieldsConf, field.reference.replace(/\.([^.]+)$/, '.fields.$1'), field);
        });
      }
    };

    /**
     * Apply a transformation to a teleservice definition that will make it much easier to use in the portals
     *
     * @param {object} teleservice
     * @returns {object}
     */
    const transform = function (teleservice) {
      // Create an arborescent structure to describe all fields
      _.each(['demandeur', 'beneficiaire', 'aide'], _.partial(deepSetFields, teleservice));

      return teleservice;
    };

    return {
      /**
       * Check if the given tiers have access to the given teleservice
       *
       * @param  {string} tiers                     JSON string representation of the tiers
       * @param  {string} teleserviceConfiguration  JSON string representation of the teleservice configuration
       * @returns {boolean}                          Indicated if the tiers can access to the teleservice
       */
      checkFamilleTeleservice: function (tiers, teleserviceConfiguration) {
        let access = false;

        const tiersFamille = tiers.famille.href;
        if (tiersFamille) {
          const teleserviceFamilles = _.get(teleserviceConfiguration, 'workflow.pageInformationsDemandeur.familles');
          if (!_.isEmpty(teleserviceFamilles)) {
            access = _.find(teleserviceFamilles, function (teleserviceFamille) {
              return tiersFamille === teleserviceFamille.famille.href;
            });
          }
        }

        return access;
      },

      /**
       * Get the teleservices list. If a tiers is already created, the returned list will be filtered with only
       * authorized teleservices for this tiers. If a workflow is given in parameter, the teleservices list will
       * also be filtered for the given workflow
       *
       * @param {object} tiers     JSON string representation of the tiers associated with the connected user
       * @param {string} workflowType  JSON string representation of the workflow
       * @param {Array} sources list of fields to pick
       * @param {object} filters additionnal filters
       * @param {boolean} filters.noInvitation hide the teleservices with "surInvitation" option if true
       * @returns {Promise<Array>}  The teleservices list, filtered by tiers and workflow if requested
       */
      getListTeleServices: (tiers, workflowType = 'simple', sources = [], filters = {}) => {
        const body = {
          from: 0,
          size: 1000,
          sort: 'title:asc',
          sources: ['reference', ...sources],
          workflow: {
            type: workflowType,
          },
        };

        const famille = _.get(tiers, 'famille.href');
        if (famille) {
          body.famille = famille;
        }

        if (filters.noInvitation) {
          body.noInvitation = filters.noInvitation;
        }

        const url = `${getReferentielFinancementUrl()}/teleservices/search`;
        return $http.post(url, body).then(({ data }) => {
          const teleservices = _.get(data, 'hits.hits', []).map(({ _source }) => _source);
          return teleservices;
        });
      },

      /**
       * Returns the teleservice whose id is given in parameter
       *
       * @param {string} teleserviceId The id of the teleservice to fetch
       * @param {boolean} inactive Indicates if we want to fetch inactive teleservice
       * @param {object} queryParams Query params from the request
       * @returns {string | void} JSON string representation of the teleservice
       */
      getTeleService: function (teleserviceId, inactive, queryParams) {
        if (!teleserviceId) return;

        const params = _.assign(
          {
            merged: true,
          },
          queryParams
        );

        if (!inactive) {
          params.active = true;
        }

        const url = `${getReferentielFinancementUrl()}/teleservices/${teleserviceId}`;

        return $http
          .get(url, {
            params: params,
          })
          .then(({ data }) => transform(data))
          .catch((error) => {
            $log.error(error);
            $state.go('app.connected.dashboard.accueil', {
              teleServiceNotFound: true,
            });
          });
      },
      /**
       * Return a teleservice extension by using filter
       *
       * @param {object} filter : hrefTeleservice and hrefGroupeGestion (can be extended)
       * @returns {Promise<object>} The found teleservice
       */
      getTeleserviceExtension: function (filter) {
        const url = `${getReferentielFinancementUrl()}/teleservices-extension`;

        const queryParams = {
          $filter: `teleservice/href eq '${filter.hrefTeleservice}' and groupeGestion/href eq '${filter.hrefGroupeGestion}'`,
        };

        return $http
          .get(url, { params: queryParams })
          .then((response) => _.get(response, 'data._embedded.items[0]'))
          .catch((error) => {
            $log.error(error);
            $state.go('app.connected.dashboard.accueil', {
              teleServiceNotFound: true,
            });
          });
      },
      getDispositif: function (url) {
        if (url) {
          return $http
            .get(url)
            .then(function (response) {
              return response.data;
            })
            .catch(function (error) {
              $log.error(error);
              $state.go('app.connected.dashboard.accueil', {
                teleServiceNotFound: true,
              });
            });
        } else {
          return;
        }
      },
      /**
       * Get the teleservice from the given aide parameter
       *
       * @param  {string} object JSON string representation of an aide
       * @param  {boolean} getLastVersion true if you want the last teleservice version
       * @returns {string} JSON string representation of the teleservice
       */
      getTeleServiceFromObject: function (object, getLastVersion = false) {
        var urlTeleservice = object.teleservice.href;
        if (urlTeleservice) {
          // Retrieve the latest version of the teleservice instead of the revision if the variable 'getLastVersion' is true
          let teleserviceId = urlTeleservice.split('/').pop();
          teleserviceId = getLastVersion ? teleserviceId.split('?date=')[0] : teleserviceId;

          return this.getTeleService(teleserviceId, true).then((teleservice) => {
            // Here the value of the element "echangesActif" is modified to ensure compatibility of behavior between versions.
            // If the 'echangesActif' is undefind all exchange related features should be accessible on the teleservice,
            // so the 'undefined' is changed to true
            if (_.isUndefined(teleservice.echangesActif)) teleservice.echangesActif = true;

            return teleservice;
          });
        } else {
          return $q.when(null);
        }
      },

      /**
       * Checks if the TS Paiement associated to dispositif has 'PUBLIE' status
       *
       * @param {object} teleservicePaiement The teleservice paiement
       * @returns {boolean} Is TS paiement published
       */
      isTSPaiementPublie(teleservicePaiement) {
        const statut = teleservicePaiement?.statut || teleservicePaiement?.expand?.statut;
        return statut === 'PUBLIE';
      },

      /**
       * Returns the specifiques infos from given teleservices, indexed by reference
       *
       * @param  {string} teleservice JSON string representation of the teleservice
       * @returns {string}             JSON string representation of the specifiques infos
       */
      getTeleservicesSpecifiques: function (teleservice) {
        return _.keyBy(teleservice.specifiques, 'reference');
      },
    };
  },
]);
