'use strict';
angular.module('portailDepotDemandeAide').controller('agreementController', [
  '$scope',
  '$rootScope',
  '$stateParams',
  'agreementService',
  function ($scope, $rootScope, $stateParams, agreementService) {
    'use strict';

    $scope.tenantId = $stateParams.tenantId;

    // Récupération des lignes du user AVEC une date de validation (userAgreementsWithValidationDate)
    var userAgreements = _.get($rootScope, 'currentUser.agreements');
    var userAgreementsWithValidationDate = _.filter(userAgreements, function (agreement) {
      return agreement.dateValidation;
    });

    /**
     *  Ajout des abonnements
     *
     * @param {object[]} list : liste mise à jour avec nouveaux abonnements
     * @param {boolean} unchecked : indique que l'on veut décocher l'abonnement
     */
    var ajoutAbonnements = function (list, unchecked) {
      _.forEach($scope.listeAbonnement, function (abonnement) {
        var userAbonnement = {
          type: _.get(abonnement, 'type'),
          id: _.get(abonnement, 'id'),
          checked: _.get(abonnement, 'checked', false),
          dateValidation: new Date().toISOString(),
        };

        if (unchecked) {
          userAbonnement.checked = false;
        }
        list.push(userAbonnement);
      });
    };

    /**
     * Ajout des autorisations
     *
     * @param {object[]} list : liste mise à jour avec nouvelles autorisations
     * @param {boolean} unchecked : indique que l'on veut décocher l'autorisation
     */
    var ajoutAutorisations = function (list, unchecked) {
      _.forEach($scope.listeAutorisation, function (autorisation) {
        var userAutorisation = {
          type: _.get(autorisation, 'type'),
          id: _.get(autorisation, 'id'),
          checked: _.get(autorisation, 'checked', false),
          dateValidation: new Date().toISOString(),
        };

        if (unchecked) {
          userAutorisation.checked = false;
        }
        list.push(userAutorisation);
      });
    };

    // La réponse est enregistrée avec une réponse à "Non" (checked: false)
    $scope.Fermer = function (modal) {
      var userAgreements = _.clone(userAgreementsWithValidationDate);

      // on "force" la décoche des agreements (unchecked = true)
      ajoutAbonnements(userAgreements, true);
      ajoutAutorisations(userAgreements, true);

      agreementService.updateList(_.get($rootScope, 'currentUser.self'), userAgreements);

      modal.$hide();
      $scope.next();
    };

    // Validation des "abonnements" et "autorisations"
    $scope.Valider = function (modal) {
      var userAgreements = _.clone(userAgreementsWithValidationDate);

      ajoutAbonnements(userAgreements);
      ajoutAutorisations(userAgreements);

      agreementService.updateList(_.get($rootScope, 'currentUser.self'), userAgreements);

      // Quelques soit les retours des "patch", on ferme la modal
      modal.$hide();
      $scope.next();
    };
  },
]);
