'use strict';
angular.module('common.directives').directive('ibanField', [
  '$q',
  '$translate',
  'domiciliationService',
  function ($q, $translate, domiciliationService) {
    'use strict';

    return {
      replace: true,
      templateUrl: 'common/common-directives/iban-field/iban-field.html',
      require: '^form',
      scope: {
        viewConfiguration: '=',
        configuration: '=?',
        namespace: '=?',
        name: '=',
        model: '=',
        validationFr: '=',
        remoteValidation: '=?',
        mdm: '<',
        readOnly: '<',
      },

      link: function (scope, element) {
        scope.namespace = scope.namespace || scope.viewConfiguration.ns;
        scope.configuration = scope.configuration || _.get(scope, 'viewConfiguration.fields.' + scope.name);
        scope.bicDisabled = false;
        scope.ibanInput = '';

        // Read remote validation from directive, configuration or viewConfiguration
        if (!scope.remoteValidation) {
          scope.remoteValidation =
            scope.configuration?.remoteValidation ?? scope.viewConfiguration?.remoteValidation ?? false;
        }

        const numero = scope.model?.numero;
        if (numero && numero !== 'incorrect') {
          scope.ibanInput = numero;
          scope.bicDisabled = true;
          ibanChanged(true);
        }

        /**
         * Sets the iban input invalid if there is an error
         *
         * @param {boolean} valid true if the input is valid
         * @param {string} errorKey the i18n key of the error
         */
        function setValidity(valid, errorKey) {
          const input = element.find(`[identifier="${scope.name}"]`);

          if (!valid && input?.[0]) {
            const message = $translate.instant(`${scope.namespace}.${scope.name}.error.${errorKey}`);
            input[0].setError(valid, message);
          }
        }

        /**
         * Search a valid IBAN from banks listed (limited to Banque de France)
         *
         * @param {boolean} init init
         * @returns {Promise} promise bank counter data
         */
        function findFrCounter(init) {
          const idEtabl = scope.model.numero.substr(4, 5);
          const idGuichet = scope.model.numero.substr(9, 5);
          // don't display spinner on init
          scope.waitForFRValidation = !init;
          return domiciliationService
            .verifyIban(idGuichet, idEtabl)
            .then(function (response) {
              scope.waitForFRValidation = false;
              let corresponding = {};
              if (
                idGuichet === response?.data?.idguichet &&
                idEtabl === response?.data?.idetabl &&
                _.has(response, 'data.bic')
              ) {
                corresponding = {
                  bic: response.data.bic,
                  idguichet: response.data.idguichet,
                  idetabl: response.data.idetabl,
                  lbdomicil: response.data.lbdomicil,
                };
              }
              scope.bicDisabled = !_.isEmpty(corresponding);
              return corresponding;
            })
            .catch(function () {
              // Erreur d'appel du referentiel bancaire
              scope.waitForFRValidation = false;
            });
        }

        /**
         *
         * @param {boolean} init init
         * @returns {void}
         */
        function ibanChanged(init = false) {
          _.set(scope, 'model.numero', '');

          const iban = scope.ibanInput.replace(/(\W|_)+/g, '');

          // IBAN is checked when it has at least 2 digits to check if the country is in the SEPA zone
          if (iban.length >= 2 && !IBAN.countries[iban.slice(0, 2)]) {
            return setValidity(false, 'invalid');
          }
          // IBAN is registered only when it has a correct format
          // recovery of the expected number of digits from the country code
          if (iban.length >= 2 && iban.length === IBAN.countries[iban.slice(0, 2)].length) {
            const validIban = IBAN.isValid(iban);
            setValidity(validIban, 'invalid');
            // iban is registered only when correct
            scope.model.numero = validIban ? iban : 'incorrect';
            if (scope.model.numero.slice(0, 2) === 'FR') {
              findFrCounter(init).then(function (corresponding) {
                // Acquire corresponding bic
                scope.model.BIC = _.isEmpty(corresponding) ? scope.model.BIC : corresponding.bic;
                scope.model.domiciliation = _.get(corresponding, 'lbdomicil', '');
                // The value is invalidated if no counter has been found and the validation parameter is set to true
                setValidity(!scope.validationFr || !!corresponding, 'unknown');
                scope.bicDisabled = !_.isEmpty(corresponding);
              });
            } else {
              // On passe par une promesse pour pallier à un problème de sous-scope généré par form
              // Sans cela le scope n'est pas mis à jour directement
              $q(function (resolve) {
                resolve();
              }).then(function () {
                setValidity(true, 'unknown');
                // On initialise le libellé de la domiciliation par le libellé du pays récupéré dans le mdm
                // (nécessaire pour la génération des fichiers PAYMEN)
                const codePays = scope.model.numero.slice(0, 2);
                const listePays = scope.mdm?.pays?.object;
                if (codePays) {
                  const pays = _.find(listePays, ['expand.reference', codePays]);
                  scope.model.domiciliation = pays?.title ?? '';
                }
                scope.bicDisabled = validIban && iban.slice(0, 2) === 'FR';
                if (!init && (!validIban || iban.slice(0, 2) !== 'FR')) {
                  _.unset(scope, 'model.BIC');
                }
              });
            }
          }
          // Empty iban should not be invalid
          else if (iban.length === 0) {
            setValidity(true, 'invalid');
          } else {
            setValidity(false, 'invalid');
          }
        }

        // debounced functions versions (perf gain, IE case)
        scope.ibanChanged = _.debounce(ibanChanged, 250, { maxWait: 1000 });
      },
    };
  },
]);
