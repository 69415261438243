'use strict';
angular.module('aides.services').factory('suiviFinancementService', function () {
  'use strict';

  return {
    /**
     * Méthode qui récupère pour un financeur l'historique des événements survenues
     * sur le dossier de financement
     *
     * @param {object} aide
     * @param {object} financeur
     * @returns {object[]}
     */
    getHistorySuiviFinancementFinanceur: function (aide, financeur) {
      // Sélection de la ligne du plan de financement correspondant à notre financeur
      var lignePlanFinancementFinanceur = {};
      var postesPlanFinancement = _.get(aide, 'planFinancement.0.recette.postes', []);

      _.each(postesPlanFinancement, function (poste) {
        lignePlanFinancementFinanceur = _.find(poste.lignes, function (lignePlanFinancement) {
          return _.get(lignePlanFinancement, 'financement.financeur.href') === financeur.href;
        });
      });

      return _.get(lignePlanFinancementFinanceur, 'financement.history');
    },
    /**
     * Function used to get the Financial Line on planFinancement
     *
     * @param {object} aide
     * @returns {object} financialLine
     */
    getFinancialLine: function (aide) {
      let financialLine;

      if (aide.multiFinanceur && !_.isEmpty(aide.financeurPrincipal)) {
        // When we are in multifinanceurs with principal funder mode; we have to pick the financial line
        // that is not "MGS_LIGNE_FICTIVE" and the funder's href has to be the same as the principal funder.
        const financeurPrincipalHref = aide.financeurPrincipal.href;
        [financialLine] = JSONPath(
          '$..recette...lignes[?(@.financement && ' +
            '@.reference !== "MGS_LIGNE_FICTIVE" && ' +
            '@.financement.financeur && ' +
            `@.financement.financeur.href === '${financeurPrincipalHref}')]`,
          aide.planFinancement
        );

        // Otherwise the financial line is the fictif line.
      } else {
        [financialLine] = JSONPath('$..recette...lignes[?(@.reference === "MGS_LIGNE_FICTIVE")]', aide.planFinancement);
      }

      return financialLine;
    },

    /**
     * Get all lignes fictives in plan financement
     *
     * @param {object} aide
     * @returns {Array}
     */
    getLignesFictivesPlanFinancement: function (aide) {
      return JSONPath(
        '$..recette...lignes[?(@.financement && @.reference.includes("MGS_LIGNE_FICTIVE"))]',
        aide.planFinancement
      );
    },

    /**
     * Get ligne corresponding to specific dossier
     *
     * @param {object} aide
     * @param {string} referenceDossier
     * @returns {object}
     */
    getLigneWithDossier(aide, referenceDossier) {
      const lignesPF = aide.multiFinanceur
        ? this.getLignesWithFinancement(aide)
        : this.getLignesFictivesPlanFinancement(aide);

      return lignesPF.find(
        (ligne) => _.get(ligne, 'financement.source.href', '').split('/').pop() === referenceDossier
      );
    },

    /**
     * Get all lines with a financement.source.href
     *
     * @param {object} aide
     * @returns {object[]} All matching lines
     */
    getLignesWithFinancement(aide) {
      return JSONPath('$..recette..lignes[?(@.financement?.source?.href)]', aide.planFinancement);
    },
  };
});
