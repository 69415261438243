'use strict';
angular.module('common.services').factory('alerteurService', [
  '$window',
  '$http',
  'jwtSessionService',
  '$timeout',
  '$rootScope',
  'socketService',
  function ($window, $http, jwtSessionService, $timeout, $rootScope, socketService) {
    'use strict';

    return {
      countUnreadEchanges: function (tenantId) {
        // EU backend used as middleware to Request
        return $http.get(`/aides/api/tenants/${tenantId}/echanges`).then((results) => {
          return results.data?.count;
        });
      },
      // Called only once when loading alerteur componant
      socketUnreadEchanges: function (tenantId, user, callbackSetNb) {
        const jwtUser = jwtSessionService.user();
        if (tenantId && jwtUser?.echangesActif) {
          const namespace = `${$window.location.origin}/echangesNonLus`;
          const path = '/referentiel-echanges/api/socket.io/';
          const query = {
            tenantId: tenantId,
            userHref: user.self,
            userGroups: [user.groups],
          };

          // If there is already a socket to track "echangesNonLus", it is removed to keep only one
          if ($rootScope.socketAlerteur) {
            $rootScope.socketAlerteur.close();
            delete $rootScope.socketAlerteur;
          }
          $rootScope.socketAlerteur = socketService.open(path, namespace, query);

          $rootScope.socketAlerteur.on('connect', () => {
            $rootScope.socketAlerteur.on('message', () => {
              // avoid request lag
              $timeout(() => {
                return this.countUnreadEchanges(tenantId).then((nb) => {
                  callbackSetNb(nb);
                });
              }, 1500);
            });
          });
        }
      },
    };
  },
]);
