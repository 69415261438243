'use strict';
angular.module('portailDepotDemandeAide.dashboard').directive('recapitulatifAvisPieces', [
  'piecesService',
  function (piecesService) {
    'use strict';

    return {
      replace: true,
      templateUrl: 'dashboard/contributions/contributions-recapitulatif/pieces/recapitulatif-pieces.html',
      scope: {
        contribution: '=',
        piecesConfiguration: '=',
      },

      link: function (scope) {
        scope.viewConfiguration = scope.piecesConfiguration;

        scope.urlFileIcons = './resources/images/file-icons/';

        /**
         * Evaluate conditionAffichage (visible/hidden) setting on a piece
         *
         * @function isPieceVisible
         * @param {object} piece the piece
         * @returns {boolean} the evaluate condition
         */
        scope.isPieceVisible = function (piece) {
          return piecesService.evalConditionPiece(scope, piece, 'conditionAffichage');
        };
      },
    };
  },
]);
