import { iFrameParams } from '@mgdis/eu-domain';

class ContributionsVEFListComponent {
  constructor(
    $sce,
    $httpParamSerializer,
    $state,
    $stateParams,
    configuration,
    jwtSessionService,
    IFrameCommunicationManager,
    routeUtilsService
  ) {
    this.$sce = $sce;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$httpParamSerializer = $httpParamSerializer;
    this.configuration = configuration;
    this.jwtSessionService = jwtSessionService;
    this.iFrameCommunicationManager = new IFrameCommunicationManager('#contributionsVEFIframe');
    this.routeUtilsService = routeUtilsService;
  }

  $onInit() {
    this.activeTab = this.$stateParams.activeTab || 'formationAlreadyStarted';
    this.page = this.$stateParams.page || 1;
    this.activeFilters = this.$stateParams.activeFilters;

    const contributionsVEFIframeQueryParams = this.$httpParamSerializer({
      jwtKey: this.jwtSessionService.getJwtKey(),
      tiersKey: iFrameParams.TIERS_KEY,
      activeTab: this.activeTab,
      page: this.page,
      activeFilters: this.activeFilters,
    });

    const templateContributionsVEFIframeSrc = `${this.configuration.ux}<%= tenantId %>/contributions-vef-view?<%= queryParams %>`;
    const compiledContributionsVEFIframeSrc = _.template(templateContributionsVEFIframeSrc);
    const contributionsVEFListIframeSrc = compiledContributionsVEFIframeSrc({
      tenantId: this.configuration.tenant.id,
      queryParams: contributionsVEFIframeQueryParams,
    });

    this.contributionsVEFListIframeSrc = this.$sce.trustAsResourceUrl(contributionsVEFListIframeSrc);

    this.onIframeEvent = ({ data = {} }) => {
      const { route, action, value } = data;
      if (route === 'contributions-vef-view.ux') {
        switch (action) {
          case 'update-tab':
            this.activeTab = value.tab;
            this.page = value.page?.toString() ?? '1';
            this.activeFilters = value.activeFilters;
            this.$state.go('.', { activeTab: this.activeTab, page: this.page, activeFilters: this.activeFilters });
            break;
          case 'update-page': // update page in url
            this.page = value?.toString();
            this.$state.go('.', { activeTab: this.activeTab, page: this.page, activeFilters: this.activeFilters });
            break;
          case 'update-active-filters':
            this.activeFilters = value;
            this.$state.go('.', { activeTab: this.activeTab, page: this.page, activeFilters: this.activeFilters });
            break;
        }
      }
      if (route === 'entree-formation-recapitulatif-view') {
        switch (action) {
          case 'access':
            this.routeUtilsService.goToEntreeFormation(action, value, {
              fromTab: this.activeTab,
              fromPage: this.page,
              activeFilters: this.activeFilters,
            });
        }
      }
    };
    this.iFrameCommunicationManager.manageEvent(this.onIframeEvent);
  }

  $onDestroy() {
    this.iFrameCommunicationManager.close();
  }
}

ContributionsVEFListComponent.$inject = [
  '$sce',
  '$httpParamSerializer',
  '$state',
  '$stateParams',
  'configuration',
  'jwtSessionService',
  'IFrameCommunicationManager',
  'routeUtilsService',
];

angular.module('contributions-vef.components').component('contributionsVEFListComponent', {
  templateUrl: 'dashboard/contributions-vef/contributions-vef-list.html',
  controller: ContributionsVEFListComponent,
});
