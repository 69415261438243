angular.module('configuration', []).constant('configuration', {
  portalId: 'portail-depot',
  portalPersistence: '/portal-persistence/{{tenantId}}',
  persistence: '/datacollect-persistence/{{tenantId}}',
  timeZone: 'Europe/Paris',
  teleservices: {
    url: '/referentiel-financement/api/tenants/{{tenantId}}',
  },
  i18n: {
    pattern: [
      'resources/i18n/{module}-{lang}.json',
      '/referentiel-ressources/services/portail-depot-demande-aides/tenants/{tenantId}/resources/{module}-{lang}?merge=true',
    ],
    teleservice: {
      id: '',
      pattern: [
        '/referentiel-ressources/services/portail-depot-demande-aides/tenants/{tenantId}/resources/' +
          'teleservice-{workflow}-default-{lang}/extensions/teleservice-{workflow}-{teleservice}-{lang}?merge=true',
      ],
    },
    additionalTranslations: ['/aides/admin/tenants/{tenantId}/publicI18N/{lang}?merge=true'],
    'angular-i18n': {
      path: 'resources/i18n/angular',
    },
    'language-selection': false,
    referentielTiers: '/referentiel-tiers/admin/tenants/{{tenantId}}/publicI18N/fr?merge=true', // change this when "fr" local will not be hardcoded anymore
  },
  'resource-manager': {
    path: ['resources/configuration/'],
  },
  demandesComplementPieces: {
    url: '/gestion-depot-demandes/{{tenantId}}',
  },
  aides: {
    v2: {
      url: '/referentiel-financement/public/v2/#/{{tenantId}}/demandes-financement',
    },
    url: '/referentiel-financement/api/tenants/{{tenantId}}',
    ressourcePath: 'demandes-financement',
    recapitulatif: {
      service: '/referentiel-financement/api/tenants/{{tenantId}}/demandes-financement',
    },
    justification: {
      service: '/aides/api/tenants/{{tenantId}}',
    },
    pagination: {
      itemsPerPage: 10,
    },
  },
  offresStage: {
    url: '/referentiel-financement/api/tenants/{{tenantId}}/offres-stage',
    ressourcePath: 'offres-stage',
  },
  contributions: {
    url: '/referentiel-financement/api/tenants/{{tenantId}}/contributions',
    tenant: '/aides/api/tenants/{{tenantId}}/contributions',
    ressourcePath: 'contributions',
  },
  tiers: {
    url: '/referentiel-tiers/{{tenantId}}',
    ux: '/referentiel-tiers/{{tenantId}}/ux/',
    client: '/referentiel-tiers/client/#/{{tenantId}}/',
    publicSettings: '/referentiel-tiers/{{tenantId}}/admin/publicSettings?merge=true',
    rattachement: {
      url: '/gestion-depot-demandes/{{tenantId}}',
      rattachementConfirmationPath: '/{{tenantId}}/connecte/rattachement/',
    },
    signataires: '/aides/api/tenants/{{tenantId}}/tiers/signataires',
  },
  contact: {
    service: '/gestion-depot-demandes/{{tenantId}}/contactez-nous',
  },
  codePostalVille: {
    nbItems: 20,
  },
  user: {
    includeCredentialsPrefixes: ['/'], // if empty will default to ['/']
    excludeCredentialsPrefixes: ['/dataserver', 'https://mdmmgdis.mgcloud.fr/dataserver', 'api-adresse.data.gouv.fr'],
    accountManagement: '/account-management/{{tenantId}}-demandeurs',
    accountManagementAdmin: '/account-management/admin/tenants/{{tenantId}}-demandeurs',
    accountCreationConfirmationPath: '/{{tenantId}}/',
    accountResetPasswordConfirmationPath: '/{{tenantId}}/user/account-reset-password',
    accountEmailModificationConfirmationPath: '/{{tenantId}}/connecte/dashboard/accueil',
    JWTHeaderExclude: ['api-adresse.data.gouv.fr'],
  },
  mdm: {
    mgdis: 'https://mdmmgdis.mgcloud.fr/dataserver/mdmmgdis/data/',
    proxy: '/aides/api/tenants/{{tenantId}}/proxy/mdm',
    tenant: '/aides/api/tenants/{{tenantId}}/mdm/',
  },
  domiciliationBancaire: {
    banqueDeFrance: true,
    urlVerification: '/referentiel-tiers/{{tenantId}}/iban/verify/',
  },
  'demande-paiement': {
    banqueDeFrance: true,
    tenant: '/api/tenants/{{tenantId}}',
    tenantAdmin: '/api/admin/tenants/{{tenantId}}',
    service: '/referentiel-financement',
  },
  typesPaiement: {
    service: '/referentiel-financement/api/tenants/{{tenantId}}/types-paiement/',
  },
  publicSettingsFinancement: {
    service: '/referentiel-financement/api/admin/tenants/{{tenantId}}/publicSettings?merge=true',
  },
  documentCollect: {
    url: '/document-collect/{{tenantId}}',
  },
  apiban: {
    defaultApi: 'https://api-adresse.data.gouv.fr',
  },
  referentielLocalisation: {
    ux: '/referentiel-localisation/',
  },
  referentielPublication: {
    ux: '/referentiel-publication-documents/public/#/',
  },
  referentielFinancementBourse: {
    ux: '/referentiel-financement-bourse/public/#/',
  },
  echanges: {
    service: '/referentiel-echanges/api/tenants/{{tenantId}}',
    ux: '/referentiel-echanges/{{tenantId}}',
  },
  referentielPiece: {
    url: '/referentiel-piece/{{tenantId}}/piece',
    service: '/referentiel-piece/api/tenants/{{tenantId}}',
  },
  referentielHierarchies: {
    frontUrl: '/referentiel-hierarchies/public/#/',
  },
  //PLAID-12988
  persistenceConfiguration: {
    aide: {
      //Valeurs par défaut
      allowedExtensions: ['pdf', 'png', 'jpg', 'jpeg', 'doc', 'docx'],
      maxDocumentSize: 5,
      maxNumberDocumentsPerPersistence: 50,
    },
    'demande-paiement': {
      //Valeurs par défaut
      allowedExtensions: ['pdf', 'png', 'jpg', 'jpeg', 'doc', 'docx'],
      maxDocumentSize: 5,
      maxNumberDocumentsPerPersistence: 50,
    },
    default: {
      //Valeurs possibles
      allowedExtensions: [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'png',
        'jpg',
        'jpeg',
        'txt',
        'rar',
        'zip',
        'ppt',
        'pptx',
        'odt',
        'odp',
        'ods',
        'odg',
        'gif',
      ],
    },
  },
  publicSettingsGestionDepotDemandes: {
    service: '/gestion-depot-demandes/admin/tenants/{{tenantId}}/publicSettings',
  },
  referentielJustification: {
    service: '/referentiel-justification/api/tenants/{{tenantId}}',
  },
  referentielPlanFinancement: {
    api: '/referentiel-plan-financement/api/tenants/{{tenantId}}',
  },
  referentielThemes: {
    api: '/referentiel-themes/api/public/tenants/{{tenantId}}/themes',
    mainCss: '/referentiel-themes/themes/{{tenantId}}/main/css/variables.css',
  },
  tenantId: {
    url: '/aides/api/tenant-id',
  },
  ux: '/aides/client/#/',
});
