'use strict';
angular.module('aides').constant('AidesConstant', {
  demandeStatuts: {
    REQUESTED: 'REQUESTED',
    REGISTERED: 'REGISTERED',
    TRANSMITTED: 'TRANSMITTED',
    WIP_SUPPORTED: 'WIP_SUPPORTED',
    SUPPORTED: 'SUPPORTED',
    RETURNED: 'RETURNED',
    ACCEPTABLE: 'ACCEPTABLE',
    UNACCEPTABLE: 'UNACCEPTABLE',
    VALIDATED: 'VALIDATED',
    WAITING_FOR_CERTIFICATE: 'WAITING_FOR_CERTIFICATE',
    CLOTURE: 'CLOTURE',
  },

  dossierStatuts: {
    INSTRUCTION: 'INSTRUCTION',
    FININSTRUCTION: 'FININSTRUCTION',
    FININSTRUCTION_DEFAVORABLE: 'FININSTRUCTION_DEFAVORABLE',
    PRETAVOTER: 'PRETAVOTER',
    MISESOUSRAPPORT: 'MISESOUSRAPPORT',
    MISESOUSRAPPORTPREV: 'MISESOUSRAPPORTPREV',
    VOTE: 'VOTE',
    SOLDE: 'SOLDE',
    CLOTURE: 'CLOTURE',
    ORDERED_STATUSES: [
      'INSTRUCTION',
      'FININSTRUCTION_DEFAVORABLE',
      'FININSTRUCTION',
      'MISESOUSRAPPORTPREV',
      'MISESOUSRAPPORT',
      'PRETAVOTER',
      'VOTE',
      'SOLDE',
      'CLOTURE',
    ],
  },

  paiementStatuts: {
    PROPOSE: 'PROPOSE',
    PAYE: 'PAYE',
    PAYE_PARTIELLEMENT: 'PAYE_PARTIELLEMENT',
    MANDATE: 'MANDATE',
    MANDATE_PARTIELLEMENT: 'MANDATE_PARTIELLEMENT',
    LIQUIDE: 'LIQUIDE',
    LIQUIDE_PARTIELLEMENT: 'LIQUIDE_PARTIELLEMENT',
    REFUSE: 'REFUSE',
  },

  demandeComplementPieces: {
    avancement: {
      REPONSE_DEMANDEUR: 'REPONSE_DEMANDEUR',
      CLOTURE: 'CLOTURE',
    },
  },
});
