'use strict';
/**
 * @param {object} $http
 * @param {object} configuration
 * @name tiers.services.tiersThematiquesService
 * @returns {object}
 */
angular.module('tiers.services').factory('tiersThematiquesService', [
  '$http',
  'configuration',

  function ($http, configuration) {
    'use strict';

    /**
     *  @returns {string}
     */
    function _getUrl() {
      return _.get(configuration, 'tiers.url') || '/referentiel-tiers';
    }

    return {
      saveThematique: function (thematiqueId, thematique, tiers) {
        //Copy the inbound object to keep the original data untouched
        var thematiqueCopy = this.cleanThematique(thematiqueId, thematique);

        // Ajout du lien du tiers à la thématique
        thematiqueCopy.reference = thematiqueCopy.reference || tiers.reference;
        thematiqueCopy.tiers = thematiqueCopy.tiers || {
          href: tiers.id,
          title: tiers.title,
        };

        var url = urljoin(_getUrl(), 'thematiques', thematiqueId, thematiqueCopy.reference);

        if (thematiqueCopy.inscriptionRegistre?.date) {
          thematiqueCopy.inscriptionRegistre.date = new Date(thematiqueCopy.inscriptionRegistre.date).toISOString();
        }

        return $http.put(url, thematiqueCopy).then(function (response) {
          return response.data;
        });
      },

      cleanThematique: function (thematiqueId, thematique) {
        var cleanThematique = angular.copy(thematique);

        switch (thematiqueId) {
          case 'association':
            // Unwanted expand
            if (cleanThematique.groupementType) {
              delete cleanThematique.groupementType.expand;
            }

            // If association is not sportive, remove unnecessary fields
            if (!thematique.associationSportiveAgreee) {
              delete cleanThematique.federationAffiliee;
              delete cleanThematique.groupementType;
              delete cleanThematique.nbAssociationsReseau;
              delete cleanThematique.nbLicencieFeminin;
              delete cleanThematique.nbLicencieMasculin;
            }
            break;
          case 'ressources-humaines':
            // remove null values
            _.set(
              cleanThematique,
              'tableauEffectifs.items',
              _.filter(_.get(cleanThematique, 'tableauEffectifs.items'), function (item) {
                return item.valeur !== null;
              })
            );

            break;
          default:
            break;
        }

        return cleanThematique;
      },

      getAllThematiquesAssociation: function () {
        var url = urljoin(_getUrl(), 'thematiquesAssociation');
        return $http.get(url + '?$top=1000').then(function (response) {
          var data = response.data;

          var parsedData = {
            raw: data,
            items: _.map(data, function (item) {
              return {
                href: item.id,
                title: item.title || _.get(item, 'libelle.value'),
                expand: item,
              };
            }),
          };

          return parsedData;
        });
      },
    };
  },
]);
