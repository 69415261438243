'use strict';
angular.module('aides.services').factory('demandesPaiementService', [
  '$http',
  'configuration',
  '$log',
  '$q',
  '$httpParamSerializer',
  'jwtSessionService',
  '$sce',
  'numberService',
  'jsonpatch',
  '$rootScope',
  function (
    $http,
    configuration,
    $log,
    $q,
    $httpParamSerializer,
    jwtSessionService,
    $sce,
    numberService,
    jsonpatch,
    $rootScope
  ) {
    'use strict';

    // '/api/tenants/{{tenant.id}}'
    var tenant = configuration['demande-paiement'].tenant;
    var tenantAdmin = configuration['demande-paiement'].tenantAdmin;
    var service = configuration['demande-paiement'].service;

    return {
      /**
       * Get the paiement demandes associated with the aide parameter
       * and dossierFinancement parameter
       *
       * @param {object} aide
       * @param {string} aide.id id de la demande de financement
       * @param {string} [dossierFinancementHref] id du dossier de financement
       * @returns {Promise<Array<object>>} demandePaiements
       */
      getDemandesPaiementFromDossier: ({ id }, dossierFinancementHref) => {
        const filters = {
          demandeFinancementId: id,
        };

        if (dossierFinancementHref) {
          filters.dossierFinancementReference = dossierFinancementHref.startsWith('/')
            ? dossierFinancementHref.split('/').pop()
            : dossierFinancementHref;
        }

        return $http
          .post(`/referentiel-financement${tenant}/demandes-paiement/business-search?$top=100`, {
            businessSearch: 'liste-usager',
            filters,
          })
          .then((response) => {
            const demandesPaiements = response?.data?.data ?? [];

            const sortyByDate = (a, b) =>
              new Date(_.get(b, 'dateReception', 'history.begin.date', '')) -
              new Date(_.get(a, 'dateReception', 'history.begin.date', ''));

            // Sort by dateReception
            return demandesPaiements.sort(sortyByDate);
          });
      },

      /**
       * Get the paiement demandes associated with the aide parameter
       * and financeur parameter
       *
       * @param  {object} aide
       * @param  {string} financeurHref
       * @returns  {Promise<Array<object>>} demandePaiements
       */
      getDemandesPaiementLignePlanFinancement: function (aide, financeurHref) {
        // DemandesPaiements filter by dossierFinancement's financeur (if financeur exists)
        const financeurs = _.get(aide, 'multiFinancement.financeurs', []);
        const financeur = financeurs.find(({ href }) => href === financeurHref);

        // We get dossier source through ligne fin, because multiFinancement.financeurs only has source for v8 dossier
        const ligneFinancement = this.findLigneFinancementByFinanceur(aide, financeurHref);

        // We get dossier though financeur source or ligne fin
        const dossierFinancementHref =
          _.get(financeur, 'source.href') || _.get(ligneFinancement, 'financement.source.href');
        return this.getDemandesPaiementFromDossier(aide, dossierFinancementHref);
      },

      /**
       * Get a demande paiement
       * Utilisation d'une route d'orchestration afin de ne pas être limité aux accès
       *  via le prp du referentiel-financement (JIRA PLAID-6467)
       *
       * @param {string} reference Demande paiement reference
       * @param {object} config Config
       * @returns {Promise<object>} demande de paiement
       */
      getDemandePaiement: function (reference, config) {
        return $http.get('/aides' + tenant + '/demandes-paiement/' + reference, config || {}).then(function (response) {
          return response.data;
        });
      },

      /**
       * Add a demande paiement
       *
       * @param {object} demandePaiement Demande paiement
       * @returns {Promise<object>} demande de paiement
       */
      createDemandePaiement: function (demandePaiement) {
        const tiersAttributaireUrl = `/aides${tenant}/retrieve-attributaire-from-dossier`;

        // store attributaire family to transmit it after final POST request
        let familleAttributaire;

        return $http
          .get(tiersAttributaireUrl, {
            params: {
              idDossier: demandePaiement.dossierFinancement.href,
              idDemande: demandePaiement.demandeFinancement.href,
            },
          })
          .then(function (response) {
            const dossier = response.data;

            familleAttributaire = dossier.attributaire.famille;

            demandePaiement.attributaire = {
              href: dossier.attributaire.href,
              title: dossier.attributaire.title,
            };
            return $http.post(`/referentiel-financement${tenant}/demandes-paiement`, demandePaiement);
          })
          .then(function (response) {
            const demandePaiementCreated = response.data;

            demandePaiementCreated.attributaire.famille = familleAttributaire;

            return demandePaiementCreated;
          });
      },

      /**
       * Update a demande paiement
       *
       * @param {object} demandePaiement Demande paiement
       * @returns {object} Demande paiement
       */
      updateDemandePaiement: function (demandePaiement) {
        return $http
          .put('/referentiel-financement' + tenant + '/demandes-paiement/' + demandePaiement.reference, demandePaiement)
          .then(function (response) {
            return response.data;
          });
      },

      patchDemandePaiement: function (reference, patches) {
        return $http
          .patch('/referentiel-financement' + tenant + '/demandes-paiement/' + reference, patches)
          .then(function (response) {
            return response.data;
          });
      },
      /**
       * Remove a demande paiement
       *
       * @param {object} demandePaiement Demande paiement
       * @returns {object} Demande paiement
       */
      removeDemandePaiement: function (demandePaiement) {
        return $http
          .delete('/referentiel-financement' + tenant + '/demandes-paiement/' + demandePaiement.reference)
          .then(function (response) {
            return response.data;
          });
      },
      /**
       * Remove expand properties from entity
       *
       * @param {object} entity The entity to clean
       */
      cleanExpand(entity) {
        // No need to clean
        if (typeof entity !== 'object' || !entity) {
          return;
        }

        delete entity.expand;
        Object.values(entity).forEach((value) => this.cleanExpand(value));
      },
      /**
       * Clean Entity demandePaiement
       *
       * @param {object} demandePaiement Demande paiement
       * @returns {object} Demande paiement
       */
      cleanEntity: function (demandePaiement) {
        let demandePaiementCleaned = angular.copy(demandePaiement);

        const propertiesToRemove = ['_links', '_meta', '_metadata', 'date', 'teleservicePaiement.id'];
        propertiesToRemove.forEach((key) => {
          if (_.get(demandePaiementCleaned, key)) {
            _.unset(demandePaiementCleaned, key);
          }
        });

        // Date must be an iso-string
        if (
          demandePaiementCleaned.dateReelleFinOperation &&
          demandePaiementCleaned.dateReelleFinOperation instanceof Date
        ) {
          demandePaiementCleaned.dateReelleFinOperation = demandePaiementCleaned.dateReelleFinOperation.toISOString();
        }

        this.cleanExpand(demandePaiementCleaned);

        return demandePaiementCleaned;
      },

      /**
       * Add or update a demande paiement
       *
       * @param {object} demandePaiement Demande paiement
       * @returns {object} Demande paiement
       */
      saveDemandePaiement: function (demandePaiement) {
        var demandePaiementCleaned = this.cleanEntity(demandePaiement);
        if (demandePaiementCleaned.reference) {
          return this.updateDemandePaiement(demandePaiementCleaned);
        } else {
          return this.createDemandePaiement(demandePaiementCleaned).then(function (newDemandePaiement) {
            // Update the local demandePaiement in scope with the new one
            _.merge(demandePaiement, newDemandePaiement);
            return newDemandePaiement;
          });
        }
      },

      /**
       * Create or patch a demande paiement
       *
       * @param {object} params parameters
       * @param {object} params.demandePaiement Demande paiement to process
       * @param {object[]} [params.patches = []] The patches to make
       * @returns {Promise<object>} Demande paiement
       */
      createOrUpdate: function ({ demandePaiement, patches = [] }) {
        if (!demandePaiement.reference) {
          const demandePaiementCleaned = this.cleanEntity(demandePaiement);
          return this.createDemandePaiement(demandePaiementCleaned).then(function (newDemandePaiement) {
            _.merge(demandePaiement, newDemandePaiement);
            return newDemandePaiement;
          });
        } else if (patches.length) {
          return this.patchDemandePaiement(demandePaiement.reference, patches);
        }

        // No update to make
        return $q.resolve(demandePaiement);
      },

      /**
       * List the patches of update between the two entities
       *
       * @param {object} params parameters
       * @param {object} params.demandePaiementUpdated The demande-paiement updated
       * @param {object} params.originalDemandePaiement The demande-paiement to compare to
       * @returns {object[]} The list of patches
       */
      retrieveUpdatePatches: function ({ demandePaiementUpdated, originalDemandePaiement }) {
        const originalDemandePaiementCleaned = this.cleanEntity(originalDemandePaiement);
        const demandePaiementUpdatedCleaned = this.cleanEntity(demandePaiementUpdated);
        const patches = jsonpatch.compare(originalDemandePaiementCleaned, demandePaiementUpdatedCleaned);

        const piecesNotWantedProperties = [
          '/actif',
          '/displayListDocuments',
          '/envoiPostal',
          '/familles',
          '/isVisible',
          '/isProduct',
          '/modelesDocuments',
          '/obligatoireSurRecevabilite',
          '/pieceDeReference',
          '/typesPaiement',
        ];
        return patches.filter(
          (patch) =>
            !(
              patch.path.includes('/pieces/') &&
              piecesNotWantedProperties.some((pieceNotWantedProperty) => patch.path.includes(pieceNotWantedProperty))
            )
        );
      },

      /**
       * Get line financement if exist
       * Search on postes and sousPoste lines
       *
       * @param {object} aide aide
       * @param {string} financeurHref href financeur
       * @returns {object} line financement
       */
      findLigneFinancementByFinanceur: function (aide, financeurHref) {
        // On recupere la ligne dans le plan de financement
        var listeLignesPlanFinancementFinanceur = JSONPath(
          '$.planFinancement.0.recette..lignes[?(@.financement)]',
          aide
        );

        var ligneFinancement;
        // Si l'on a un href de financeur
        if (financeurHref) {
          // on recupère la ligne en fonction du href du financeur
          ligneFinancement = _.find(
            listeLignesPlanFinancementFinanceur,
            (ligne) => _.get(ligne, 'financement.financeur.href') === financeurHref
          );
        } else {
          // sinon on recupere la ligne de financement de la ligne fictive
          ligneFinancement = _.find(listeLignesPlanFinancementFinanceur, { reference: 'MGS_LIGNE_FICTIVE' });
        }

        return ligneFinancement;
      },

      canCreateDemandePaiement: function (data) {
        return $http.post(service + tenantAdmin + '/authorization/pdp', data).then(function (response) {
          return _.get(response, 'data.decision') === 'permit';
        });
      },

      getDispositif: function (href) {
        return $http.get(href).then(function (response) {
          return response.data;
        });
      },

      /**
       * Get ligne financement dispositif elligible
       *
       * @param {object} aide aide
       * @returns {object} ligne financement dispositif elligible
       */
      findLigneFinancementDispositifEligible: function (aide) {
        const [ligneFinancementDispositifEligible] = JSONPath(
          '$.planFinancement..recette..lignes..[?(@.dispositifEligible &&' +
            '@.dispositifEligible.href && ' +
            '@.financement && ' +
            '@.financement.autorisationDemandesPaiement)]',
          aide
        );

        return ligneFinancementDispositifEligible;
      },

      /**
       * Check if all informations complementaires groups/fields are flagged as agentOnly
       *
       * @param {Array} informationsComplementaires
       * @returns {number}
       */
      mustDisplayInformationsComplementaires: function (informationsComplementaires) {
        // Get informations complementaires form type without agent only property true
        const groupsNotAgentOnly = JSONPath(
          `$.[?(@.status == 'VALIDATED')].groups[?(@.agentOnly != true)].fields[?(@.agentOnly != true)]`,
          informationsComplementaires
        );

        // Get informations complementaires liste/fiche type without agent only property true
        const fieldsNotAgentOnly = JSONPath(
          `$.[?(@.status == 'VALIDATED')].fields[?(@.agentOnly != true)]`,
          informationsComplementaires
        );

        return fieldsNotAgentOnly.length || groupsNotAgentOnly.length;
      },

      /**
       * get paiement's numeroMandat
       *
       * @param {object} demande demande paiement
       * @returns {string} numero mandat
       */
      getNumeroMandat: function (demande) {
        return demande?.paiement?.liquidations?.[0]?.numeroMandat || demande?.paiement?.numeroMandat;
      },

      /**
       * Gets the amount demande from "demande paiement"
       *
       * @param {object} demande The "demande paiement" object
       * @returns {number} the demande amount
       */
      getMontantDemande: function (demande) {
        return demande?.montant?.ttc;
      },
      /**
       * Gets the proposed of amount from "demande paiement"
       *
       * @param {object} demande The "demande paiement" object
       * @returns {number} the proposed amount
       */
      getMontantPropose: function (demande) {
        return demande?.paiement?.montantPropose?.ttc ?? demande?.montantPropose?.ttc;
      },
      /**
       * Gets the paid of amount from "demande paiement"
       *
       * @param {object} demande The "demande paiement" object
       * @returns {number} the paid amount
       */
      getMontantPaye: function (demande) {
        return this.getMontantPaiementByTypeMontant(demande, 'montantPaye');
      },
      /**
       * Gets the liquidated of amount from "demande paiement"
       *
       * @param {object} demande The "demande paiement" object
       * @returns {number} the liquidated amount
       */
      getMontantLiquide: function (demande) {
        return this.getMontantPaiementByTypeMontant(demande, 'montantLiquide');
      },
      /**
       * Gets the mandated of amount from "demande paiement"
       *
       * @param {object} demande The "demande paiement" object
       * @returns {number} the mandated amount
       */
      getMontantMandate: function (demande) {
        return this.getMontantPaiementByTypeMontant(demande, 'montantMandate');
      },
      /**
       * Gets the amount of a typeMontant from "demande paiement"
       *
       * @description get the sum of montant for a specified type of montant
       * @param {object} demande The "demande paiement" object
       * @param {string} typeMontant can be "montantLiquide" "montantMandate" "montantPaye"
       * @returns {number} the liquidation amount
       */
      getMontantPaiementByTypeMontant: function (demande, typeMontant) {
        const liquidations = demande?.paiement?.liquidations || [];
        if (liquidations.length === 0) {
          return demande?.paiement?.[typeMontant]?.ttc;
        }
        const filteredLiquidations = liquidations.filter(
          (liquidation) => !liquidation.dateRejetFinancier && liquidation[typeMontant]?.ttc
        );

        const montant = filteredLiquidations.reduce((acc, liquidation) => {
          if (liquidation[typeMontant]) {
            return _.round(acc + liquidation[typeMontant].ttc, 2);
          }
          return acc;
        }, 0);
        return filteredLiquidations[0] && montant;
      },
      /**
       * Gets the date of a typeMontant from "demande paiement"
       * If multiple date, take the earliest
       *
       * @param {object} demande The "demande paiement" object
       * @param {string} typeDate can be "dateLiquide" "dateMandat" "datePaiement"
       * @returns {string} date liquidation
       */
      getDateByType: function (demande, typeDate) {
        const liquidations = demande?.paiement?.liquidations || [];
        if (liquidations.length === 0) {
          return demande?.paiement?.[typeDate];
        }
        // Filter to only keep the liquidations with a typeMontant then sort the dates in ascending order to get the first date
        return liquidations
          .filter((liquidation) => liquidation[typeDate])
          .map((liquidation) => liquidation[typeDate])
          .sort((dateA, dateB) => new Date(dateA) - new Date(dateB))
          .shift();
      },
      /**
       * Gets the date of paiement from "demande paiement"
       *
       * @param {object} demande The "demande paiement" object
       * @returns {string} the paiement date
       */
      getDatePaiement: function (demande) {
        return this.getDateByType(demande, 'datePaiement');
      },
      /**
       * Gets the date of mandat from "demande paiement"
       *
       * @param {object} demande The "demande paiement" object
       * @returns {string} the mandat date
       */
      getDateMandat: function (demande) {
        return this.getDateByType(demande, 'dateMandat');
      },
      /**
       * Gets the date of liquidation from "demande paiement"
       *
       * @param {object} demande The "demande paiement" object
       * @returns {string} the liquidation date
       */
      getDateLiquidation: function (demande) {
        return this.getDateByType(demande, 'dateLiquidation');
      },
      /**
       * Gets the date of proposed from "demande paiement"
       *
       * @param {object} demande The "demande paiement" object
       * @returns {string} the proposed date
       */
      getDateProposition: function (demande) {
        return demande?.paiement?.dateProposition;
      },
      /**
       * Gets the date of depot from "demande paiement"
       *
       * @param {object} demande The "demande paiement" object
       * @returns {string} the depot date
       */
      getDateDepot: function (demande) {
        return demande?.dateReception;
      },
      /**
       * Gets the financial reject date from "demande paiement"
       *
       * @param {object} demande The "demande paiement" object
       * @returns {string} financial reject date
       */
      getDateRejetFinancier: function (demande) {
        return demande?.paiement?.liquidations?.[0]?.dateRejetFinancier;
      },
      /**
       * Gets the ventilated proposed amount from "demande paiement"
       *
       * @param {object} demande The "demande paiement" object
       * @returns {number} the proposed amount
       */
      getFirstVentilationMontantPropose: function (demande) {
        return demande?.ventilations?.[0]?.montant?.ttc;
      },
      /**
       * Return amount with type name and value for "LePlusAvanceDemandePaiement"
       *
       * @description The most advanced amount on the demande paiement.
       * In the priority order we got : payed amount, mandated amount, liquidated amount,
       * proposed amount, asked amount and the previsional amount
       * @param {object} demande demande de paiement
       * @param {string[]} typesMontantToExclude Array of amount type to ignore when getting amount "le plus avancé" (mutated)
       * @returns {object | void} The amount type and value
       */
      getTypeMontantLePlusAvanceDemandePaiement: function (demande, typesMontantToExclude) {
        if (!demande) return;

        if (!typesMontantToExclude) typesMontantToExclude = [];

        // amount's type should be in the following order (PDA9-304)
        // 1. montantPaye -> 2. montantMandate -> 3. montantLiquide
        // 4. montantPropose -> 5. montantDemande -> 6. montantPrevisionnel
        const typesMontant = this.getTypesMontant();
        let typeFirstMontant;

        typesMontant
          .filter((typeMontant) => {
            return !typesMontantToExclude.includes(typeMontant.type);
          })
          .every((typeMontant) => {
            const value = typeMontant.get(demande);
            if (!_.isNil(value)) {
              typeFirstMontant = {
                type: typeMontant.type,
                value: value,
              };
            }
            // break if first find

            return !typeFirstMontant;
          });

        return typeFirstMontant;
      },
      /**
       * Get all the most advanced montant of the demande
       * (one by engagement on the demande or just one when there are no ventilations in the demande)
       *
       * For example, if a demande has 2 ventilations and one is liquidated for 1 and the other is paid for 2 the result will look like:
       * [
       *   {
       *     type: 'montantLiquide',
       *     value: 1.0,
       *   },
       *   {
       *     type: 'montantPaye',
       *     value: 2.0,
       *   },
       * ]
       *
       * Explanation : We start with a demande with N ventilations then we transform it in N demandes in order to extract the most advanced montant
       *
       * @param {object} demande The demande de paiement to process
       * @param {string[]} typesMontantToExclude A list of type montant to exclude
       * @returns {object[]}
       */
      getAllMostAdvancedMontant(demande, typesMontantToExclude) {
        // Unwind by engagement/ventilation to get the most advanced type montant for each engagement
        const unwindedDemandes = (demande?.ventilations || []).map(({ engagement }) => {
          const dem = _.cloneDeep(demande);
          dem.ventilations = demande.ventilations.filter(
            (ventilation) => ventilation.engagement?.href === engagement?.href
          );

          if (!dem.paiement?.liquidations) {
            return dem;
          }

          dem.paiement.liquidations = demande.paiement.liquidations.filter(
            (liquidation) => liquidation.engagement?.href === engagement?.href
          );

          return dem;
        });

        // When there are no ventilations, only process the demande
        if (unwindedDemandes.length === 0) {
          unwindedDemandes.push(demande);
        }

        // Filter out the demande which have been rejected
        return unwindedDemandes
          .filter((demandePaiement) => !this.getDateRejetFinancier(demandePaiement))
          .map((demandePaiement) =>
            this.getTypeMontantLePlusAvanceDemandePaiement(demandePaiement, typesMontantToExclude)
          )
          .filter((typeMontant) => typeMontant);
      },
      /**
       * Get types montant array
       * amount's type should be in the following order (PDA9-304)
       * 1. montantPaye -> 2. montantMandate -> 3. montantLiquide
       * 4. montantPropose -> 5. montantDemande -> 6. montantPrevisionnel
       *
       * @returns {object[]} list of types montant
       */
      getTypesMontant: function () {
        return [
          {
            type: 'montantPaye',
            get: (demande) => {
              return this.getMontantPaiementByTypeMontant(demande, 'montantPaye');
            },
          },
          {
            type: 'montantMandate',
            get: (demande) => {
              return this.getMontantPaiementByTypeMontant(demande, 'montantMandate');
            },
          },
          {
            type: 'montantLiquide',
            get: (demande) => {
              return this.getMontantPaiementByTypeMontant(demande, 'montantLiquide');
            },
          },
          { type: 'montantProposeVentile', get: this.getFirstVentilationMontantPropose },
          {
            type: 'montantPropose',
            get: function (demande) {
              return demande?.paiement?.montantPropose?.ttc ?? demande?.montantPropose?.ttc;
            },
          },
          {
            type: 'montantDemande',
            get: function (demande) {
              return demande?.montant?.ttc;
            },
          },
          {
            type: 'montantPrevisionnel',
            get: function (demande) {
              return demande?.montantPrevisionnel?.ttc;
            },
          },
        ];
      },
      /**
       * Return true if there is at least one indicator and they are not all irrelevant
       *
       * @param {Array} indicateursPrevisionnel
       * @returns {boolean}
       */
      mustDisplayIndicateursRealisation: function (indicateursPrevisionnel) {
        // Check if there is at least one indicator
        const thereIsAtLeastOneIndicator = indicateursPrevisionnel.length >= 1;
        // Check if all indicators are pertinent : false
        const allIndicatorsAreIrrelevant =
          indicateursPrevisionnel.filter((indicateur) => _.get(indicateur, 'pertinent') === false).length ===
          indicateursPrevisionnel.length;

        return thereIsAtLeastOneIndicator && !allIndicatorsAreIrrelevant;
      },

      /**
       * Return amount "LePlusAvanceDemandePaiement"
       *
       * @description "Le montant le plus avancé valorisé sur la demande de paiement. Dans l’ordre de priorité nous avons : le montant payé, le montant mandaté, le montant liquidé, le montant proposé, le montant prévisionnel puis le montant demandé."
       * @param {object} demande demande de paiement
       * @param {string[]} typesMontantToExclude A list of type montant to exclude
       * @returns {number}
       */
      getMostAdvancedMontantOnDemandePaiement: function (demande, typesMontantToExclude) {
        if (!demande) return 0;

        const mostAdvancedAmounts = this.getAllMostAdvancedMontant(demande, typesMontantToExclude);
        return mostAdvancedAmounts.reduce((sum, typeMontant) => {
          const increment = (typeMontant && typeMontant.value) || 0;
          return _.round(sum + increment, 2);
        }, 0);
      },

      /**
       * get voted amount based on decisions
       *
       * @param {object[]} decisions
       * @returns {number} voted amount
       */
      getVotedAmount(decisions) {
        const votedAmount =
          decisions?.reduce((sum, decision) => {
            if (!decision.cancelation) {
              const amountDecision = decision.montant?.ttc ?? decision.montant?.ht ?? 0;
              return sum + amountDecision;
            }
            return sum;
          }, 0) ?? 0;

        return numberService.round(votedAmount, 2);
      },

      /**
       * Return the remaining to be paid on the voted amount
       *
       * @param {Array} demandesPaiement
       * @param {Array} decisions
       * @returns {number} remaining to be paid
       */
      getMontantRestantAPayer: function (demandesPaiement, decisions) {
        const filteredPaiements = demandesPaiement?.filter((demande) => demande.statut !== 'REJETFINANCIER') ?? [];

        const votedAmount = this.getVotedAmount(decisions);

        const result = filteredPaiements.reduce(
          (summ, demande) => {
            return (
              summ - (this.getFirstMontantPaiementFromTypesMontant(demande, ['montantPaye', 'montantMandate']) || 0)
            );
          },
          numberService.round(votedAmount, 2)
        );

        return numberService.round(result, 2);
      },

      /**
       * Returns the planFinancement with DEPOSE type
       *
       * @param {object} demandePaiement
       * @returns {object}
       */
      getPlanFinancementDepose(demandePaiement) {
        return _.find(demandePaiement.planFinancement || [], (pf) => pf.type === 'DEPOSE');
      },

      /**
       * Build the url for the iframe for the demandePaiement's planFinancement
       *
       * @param {object} demandePaiement
       * @param {boolean} [readOnly=false]
       * @returns {string|null}
       */
      getPlanFinancementIframeUrl(demandePaiement, readOnly) {
        const planFinancementDepose = this.getPlanFinancementDepose(demandePaiement);
        if (!planFinancementDepose) return null;

        const queryParams = $httpParamSerializer({
          entityUrl: _.get(planFinancementDepose, 'href'),
          teleserviceUrl: _.get(demandePaiement, 'teleservicePaiement.href'),
          teleserviceDemandeFinancementUrl: demandePaiement.demandeFinancement?.expand?.teleservice?.href,
          jwtKey: jwtSessionService.getJwtKey(),
          readOnly: readOnly || false,
        });

        const url = `/referentiel-plan-financement/public/#/${configuration.tenant.id}/demandes-paiement/plan-financement?${queryParams}`;
        return $sce.trustAsResourceUrl(url).toString();
      },

      canCreatePaiement: function (dispositifHref) {
        if (_.isEmpty(dispositifHref)) {
          return $q.reject('Missing dispositif href');
        }

        const tenant = _.get(configuration, 'tenant.id');
        return $http
          .post(`/aides/api/tenants/${tenant}/demandes-paiement/can-create`, {
            href: dispositifHref,
          })
          .then((response) => response.data);
      },

      /**
       * Return the first amount from list of typesMontant on paiement
       *
       * @param {object} demandePaiement
       * @param {Array} typesMontant
       * @returns {number | void}
       */
      getFirstMontantPaiementFromTypesMontant(demandePaiement, typesMontant) {
        if (!typesMontant || !Array.isArray(typesMontant)) return;
        for (const type of typesMontant) {
          const firstMontantFromType = this.getMontantPaiementByTypeMontant(demandePaiement, type);
          if (firstMontantFromType) {
            return firstMontantFromType;
          }
        }
      },

      /**
       * Return the remaining to be asked on all demandes
       *
       * @param {Array} demandesPaiement
       * @param {Array} decisions
       * @returns {number} remaining amount
       */
      getMontantRestantADemander: function (demandesPaiement, decisions) {
        const filteredPaiements =
          demandesPaiement?.filter(
            (demandePaiement) =>
              !['REJETE', 'REJETFINANCIER', 'REFUSE', 'UNACCEPTABLE'].includes(demandePaiement.statut)
          ) ?? [];

        const votedAmount = this.getVotedAmount(decisions);

        const remainingAmount = filteredPaiements.reduce(
          (sum, paiement) => {
            return sum - this.getMostAdvancedMontantOnDemandePaiement(paiement);
          },
          numberService.round(votedAmount, 2)
        );

        return numberService.round(remainingAmount, 2);
      },

      /**
       * Filter demandes paiement based on their status
       * Some status should not be displayed, but taken into account
       * for amount calculations.
       *
       * This method should be used for display purpose
       *
       * @param {object[]} demandesPaiement Demandes paiement to filter
       * @returns {object[]} Demandes paiement to display
       */
      getVisiblesDemandesPaiement(demandesPaiement) {
        return demandesPaiement.filter(({ statut }) => statut !== 'PREVISIONNEL');
      },

      /**
       * Returns if the demande-paiement status is EN_COURS
       *
       * @param {object} demande
       * @returns {boolean}
       */
      isEnCours(demande) {
        return demande.statut === 'EN_COURS';
      },

      /**
       * Returns if a demande-paiement is owned by current user
       *
       * @param {object} demandePaiement
       * @returns {boolean}
       */
      isOwnedByCurrentUser(demandePaiement) {
        return $rootScope?.currentUser?.self === demandePaiement?.history?.begin?.user?.href;
      },

      /**
       * Returns if demande-paiement is owned by current user and is enCours
       *
       * @param {object} demandePaiement
       * @returns {boolean}
       */
      canBeShared(demandePaiement) {
        return this.isOwnedByCurrentUser(demandePaiement) && this.isEnCours(demandePaiement);
      },

      /**
       * Check if demande-paiement is shared with current user
       * Currently, only the creator can see the demande before it is transmitted.
       * If aide has no reference, it cannot be shared yet.
       * If user is not creator, it means that the demande is shared with him.
       *
       * @param {object} demandePaiement
       * @returns {boolean}
       */
      isAccessedThroughSharing(demandePaiement) {
        return (
          !_.isNil(demandePaiement?.reference) &&
          !this.isOwnedByCurrentUser(demandePaiement) &&
          this.isEnCours(demandePaiement)
        );
      },
    };
  },
]);
