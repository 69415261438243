class AidePiecesService {
  #piecesService;

  constructor(piecesService) {
    this.#piecesService = piecesService;
  }

  /**
   * Check documents of given aide, depending on teleservice configuration
   *
   * @param {string} aideId - Aide id used as entity-link for pieces
   * @param {object} teleservice - Full teleservice, containing aide configuration
   * @param {string | null} contributionReference - Reference of the current contribution, if any
   * @returns {Promise<object>}
   */
  checkAideDocuments(aideId, teleservice, contributionReference = null) {
    const pathPiecesProperties = ['$.pieces[?(@.fonction == "depot")]'];

    if (teleservice.workflow.simple?.pageDomiciliationBancaire?.actif) {
      pathPiecesProperties.push('$.domiciliationBancaire.pieces[?(@.fonction == "depot")]');
    }

    const entityLink = contributionReference ? `${aideId}?contribution=${contributionReference}` : aideId;
    return this.#piecesService.checkDocumentsPieces(entityLink, pathPiecesProperties);
  }
}

AidePiecesService.$inject = ['piecesService'];

angular.module('aides.services').service('aidePiecesService', AidePiecesService);
