'use strict';
class LockEntitiesService {
  constructor($rootScope, $q, $log, shareEntityService, socketService) {
    this.$rootScope = $rootScope;
    this.$q = $q;
    this.$log = $log;
    this.shareEntityService = shareEntityService;
    this.socketService = socketService;
  }

  /**
   * Get web socket used to lock the entity
   *
   * @param {object} entity
   * @param {string} kind
   * @returns {Promise<object>}
   */
  getLockingSocket(entity, kind) {
    const deferred = this.$q.defer();
    this.shareEntityService.isShared(entity).then((isShared) => {
      if (isShared) {
        // If there is already a "socketLock", it is removed to keep only one
        if (this.$rootScope.socketLock) {
          this.$rootScope.socketLock.close();
          delete this.$rootScope.socketLock;
        }
        this.$rootScope.socketLock = this.socketService.openForLockingEntity(entity.tenant, kind, entity.id);
        this.$rootScope.socketLock.on('connect', () => {
          deferred.resolve(this.$rootScope.socketLock);
        });
        this.$rootScope.socketLock.on('connect_error', (err) => {
          this.$log.error('Error while connecting to entityLock socket', err);
          deferred.reject(err);
        });
      } else {
        deferred.resolve(null);
      }
    });
    return deferred.promise;
  }
}
LockEntitiesService.$inject = ['$rootScope', '$q', '$log', 'shareEntityService', 'socketService'];

/**
 * @name LockEntitiesService
 * @class LockEntitiesService
 * @description Helpers for locking an entity
 */
angular.module('aides.services').service('lockEntitiesService', LockEntitiesService);
