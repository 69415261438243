'use strict';
angular.module('portailDepotDemandeAide.depot').controller('depotSimplePreambuleController', [
  '$scope',
  '$translate',
  function ($scope, $translate) {
    'use strict';
    var isMultiFinPartage =
      _.get($scope, 'teleserviceConfiguration.multiFinanceur.active') &&
      _.get($scope, 'teleserviceConfiguration.multiFinanceur.options.modePreInstruction') === 'PARTAGE';
    $scope.displayPreambuleSpecifique =
      isMultiFinPartage && $scope.teleserviceConfiguration?.workflow?.pagePreambule?.preambule?.content !== undefined;
    const contentPreambule = $translate.instant('teleservice.preambule.content');
    $scope.hasValidDefaultPreambule =
      contentPreambule !== 'teleservice.preambule.content' && contentPreambule.trim() !== '';
    $scope.content = contentPreambule;
    $scope.cleanNavigate();
    $scope.navigate.next = $scope.goToStepFn('criteres');
    $scope.navigate.ns = 'teleservice.preambule';
    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'preambule';
  },
]);
