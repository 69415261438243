'use strict';
/**
 * HTTP requests interceptor to redirect direct calls to the MDM to the server MDM proxy route
 *
 * @param {object} configuration
 * @returns {object}
 */
angular.module('portailDepotDemandeAide').factory('mdmRequestsInterceptor', [
  'configuration',
  (configuration) => {
    return {
      request: function (request) {
        if (request.url.indexOf(configuration.mdm.mgdis) === 0) {
          const source = request.url.split(configuration.mdm.mgdis).pop();
          request.url = urljoin(configuration.mdm.proxy, source);
        }

        return request;
      },
    };
  },
]);
