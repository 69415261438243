'use strict';
angular
  .module('common.services')
  .factory('bourseService', [
    '$http',
    'configuration',
    'jwtSessionService',
    '$sce',
    '$document',
    '$q',
    'IFrameCommunicationManager',
    bourseService,
  ]);

/**
 *
 * @param {object} $http
 * @param {object} configuration
 * @param {object} jwtSessionService
 * @param {object} $sce
 * @param {object} $document
 * @param {object} $q
 * @param {object} IFrameCommunicationManager
 * @returns {object}
 */
function bourseService($http, configuration, jwtSessionService, $sce, $document, $q, IFrameCommunicationManager) {
  'use strict';
  return {
    /**
     * Build formation iframe url
     *
     * @param {string} referenceDemande
     * @param {object} contribution
     * @param {boolean} readOnly
     * @param {boolean} visited
     * @param {boolean} displayHeaderFooter
     * @returns {string}
     */
    generateFormationIframeSrc: function (referenceDemande, contribution, readOnly, visited, displayHeaderFooter) {
      const locale = $document[0].documentElement.lang ?? 'fr';
      const jwtKey = jwtSessionService.getJwtKey();

      let formationIframeSrc = `${configuration.referentielFinancementBourse.ux}${locale}/${configuration.tenant.id}/demandes-financement/${referenceDemande}/formation?jwtKey=${jwtKey}&readOnly=${readOnly}&visited=${visited}&displayHeaderFooter=${displayHeaderFooter}`;

      if (contribution?.reference) {
        formationIframeSrc += `&referenceContribution=${contribution.reference}`;
      }

      if (contribution && contribution.typeContribution === 'REDIRECTION') {
        formationIframeSrc += `&teleserviceRedirectionId=${contribution?.teleservice?.href}`;
      }

      return $sce.trustAsResourceUrl(formationIframeSrc);
    },
    /**
     * Update data with message sent from formation iframe
     *
     * @param {object} msg
     * @param {Function} accept
     * @param {Function} reject
     * @param {object} aide
     */
    updateFormationAide: function (msg, accept, reject, aide) {
      const action = _.get(msg, 'data.action');
      const bourse = _.get(msg, 'data.bourse');
      const state = _.get(msg, 'data.state');

      // Update aide
      if (action === 'updateBourse' && bourse) {
        _.set(aide, 'bourse', bourse);

        if (state === 'ok') {
          accept();
        } else {
          reject();
        }
      }
    },

    /**
     * Fetch the bourse with the given id
     *
     * @param {string} id
     * @returns {Promise}
     */
    getBourseById: (id) => {
      return $http.get(id).then(({ data }) => data);
    },

    /**
     * Save formation suivie data or formation contribution
     *
     * @param {object} teleservice
     * @param {object} aide
     * @param {Function} [beforeSave]
     * @returns {Promise<any>} promise
     */
    saveProgressFormation(teleservice, aide, beforeSave = null) {
      if (
        !teleservice.workflow?.pageInformationsGenerales?.formationSuivie?.actif ||
        aide.history.begin.metadata.step !== 'informations-generales'
      )
        return $q.resolve(true);

      const formationCommunicationManager = new IFrameCommunicationManager('#formationIframe');
      beforeSave?.(formationCommunicationManager);
      return formationCommunicationManager
        .sendEvent({
          action: 'validate',
          options: { saveInProgress: true },
        })
        .manageEventWithPromise((msg, resolve, reject) => {
          return this.updateFormationAide(msg, resolve, reject, aide);
        });
    },
  };
}
