'use strict';
angular
  .module('portailDepotDemandeAide.depot')
  .controller('depotOffresStageInfosController', [
    '$scope',
    '$timeout',
    'offresStageService',
    '$translate',
    'adresseService',
    'alertsService',
    'licenseService',
    'validationService',
    depotOffresStageInfosController,
  ]);

/**
 *
 * @param {object} $scope
 * @param {object} $timeout
 * @param {object} offresStageService
 * @param {object} $translate
 * @param {object} adresseService
 * @param {object} alertsService
 * @param {object} licenseService
 * @param {object} validationService
 */
function depotOffresStageInfosController(
  $scope,
  $timeout,
  offresStageService,
  $translate,
  adresseService,
  alertsService,
  licenseService,
  validationService
) {
  'use strict';

  var ctrl = this;
  ctrl.ns = 'offres-stage';
  ctrl.validationService = validationService;

  $scope.viewConfiguration = {
    ns: 'offres-stage',
    displayCharactersLeft: true,
    fields: {
      numeroDossier: {
        required: true,
        maxlength: 32,
        displayCharactersLeft: true,
        labelWidth: 3,
        fieldWidth: 5,
      },

      numeroMarche: {
        required: true,
        maxlength: 32,
        displayCharactersLeft: true,
        labelWidth: 3,
        fieldWidth: 5,
      },

      raisonSociale: {
        required: true,
        maxlength: 200,
        displayCharactersLeft: true,
        labelWidth: 3,
        fieldWidth: 5,
      },

      descriptionStructure: {
        required: true,
        maxlength: 1000,
        displayCharactersLeft: true,
        labelWidth: 3,
        fieldWidth: 5,
      },

      intitule: {
        required: true,
        maxlength: 200,
        displayCharactersLeft: true,
        labelWidth: 3,
        fieldWidth: 5,
      },

      descriptionMission: {
        required: true,
        maxlength: 2500,
        displayCharactersLeft: true,
        labelWidth: 3,
        fieldWidth: 5,
      },

      descriptionProfil: {
        required: true,
        maxlength: 1000,
        displayCharactersLeft: true,
        labelWidth: 3,
        fieldWidth: 5,
      },

      transport: {
        required: true,
        maxlength: 200,
        displayCharactersLeft: true,
        labelWidth: 3,
        fieldWidth: 5,
      },
    },
  };

  ctrl.offreStage = $scope.offreStage;
  ctrl.cadresDepot = offresStageService.referentiels.cadresDepot;
  ctrl.secteursActivite = offresStageService.referentiels.secteursActiviteStructure;
  ctrl.effectifs = offresStageService.referentiels.effectifsStructure;
  ctrl.typesStage = offresStageService.referentiels.typesStage;
  ctrl.typesContrat = offresStageService.referentiels.typesContrat;
  ctrl.dureesStage = offresStageService.referentiels.dureesStage;
  ctrl.qualifs = offresStageService.referentiels.niveauxQualificationRequis;
  ctrl.SIRET = offresStageService.referentiels.SIRET;
  ctrl.siteWebError = false;
  ctrl.postalVilleError = false;

  ctrl.readOnlySIRET = false;
  if (!_.isEmpty(_.get($scope, 'siret.SIREN'))) {
    _.set(ctrl, 'offreStage.SIRET', $scope.siret);
    ctrl.readOnlySIRET = true;
  }

  /**
   * Get the list of dureesStage according to the selected cadreDepot
   */
  ctrl.setDureesStage = function () {
    ctrl.listDureesStage = offresStageService.getDureesStage(ctrl.offreStage.cadreDepot);
  };

  /**
   * Set the postal code and city in the intership offer
   *
   * @param {*} item The codePostalVille selected from the dropdown list
   */
  ctrl.defineCodePostalVille = function (item) {
    ctrl.offreStage.adresse.codePostal = item.codepostal;
    ctrl.postalVilleError = false;
    ctrl.offreStage.adresse.ville = item.communemaj;
  };

  /**
   * Call the adresseService to get the postal codes from research
   *
   * @param {string} searchString The current searchString in the field
   * @returns {Promise}
   */
  ctrl.searchCodePostalVille = function (searchString) {
    return adresseService.searchCodePostalVille(searchString);
  };

  var remunereYes = $translate.instant(ctrl.ns + '.general.yes');
  var remunereNo = $translate.instant(ctrl.ns + '.general.no');
  ctrl.listYesNo = [
    {
      value: true,
      title: remunereYes,
    },

    {
      value: false,
      title: remunereNo,
    },
  ];

  if (_.get(ctrl, 'offreStage.adresse.codePostal') && _.get(ctrl, 'offreStage.adresse.ville')) {
    ctrl.codePostalVille =
      _.get(ctrl, 'offreStage.adresse.codePostal', '') + ' ' + _.get(ctrl, 'offreStage.adresse.ville', '');
  }

  // Search assistant (with API BAN)
  ctrl.searchApiBan = function (searchValue) {
    return adresseService.searchApiBan(searchValue).catch(function (err) {
      if (_.includes([-1, 404], err.status) && !_.find(ctrl.apibanAlerts, ['type', 'error'])) {
        ctrl.apibanAlerts.push(
          alertsService.getAlertError(ctrl.viewConfiguration.ns + '.apiBanSearch.unreachable', {
            url: _.get(err, 'config.url'),
          })[0]
        );
      }
      return [];
    });
  };

  // Validate a selection from search
  ctrl.fillAddressFromBan = function (item) {
    _.set(ctrl.offreStage, 'adresse.postale.LigneQuatre', item.properties.name);
    _.set(ctrl, 'codePostalVille', item.properties.postcode + ' ' + item.properties.city);
    // Empty adresse
    _.set(ctrl.offreStage, 'adresse.postale.LigneDeux', '');
    _.set(ctrl.offreStage, 'adresse.postale.LigneTrois', '');
    _.set(ctrl.offreStage, 'adresse.postale.LigneCinq', '');

    // Validate in a timeout to let scope update PointRemise validity
    $timeout(ctrl.validateAddress);
    // Use adresseService to get codePostal
    adresseService
      .getCodePostalVilleFromCodes(item.properties.postcode, item.properties.citycode)
      .then(function (codePostalItem) {
        ctrl.defineCodePostalVille(codePostalItem);
      });
  };

  /**
   *
   */
  function initDate() {
    if (ctrl.offreStage.datePrevisionnelleDebut) {
      ctrl.offreStage.datePrevisionnelleDebut = moment(ctrl.offreStage.datePrevisionnelleDebut, [
        'DD/MM/YYYY',
        'YYYY-MM-DD',
      ]).format('DD/MM/YYYY');
    }
    if (ctrl.offreStage.dateReceptionNotification) {
      ctrl.offreStage.dateReceptionNotification = moment(ctrl.offreStage.dateReceptionNotification, [
        'DD/MM/YYYY',
        'YYYY-MM-DD',
      ]).format('DD/MM/YYYY');
    }
  }

  // Il faut au moins une des lignes d'adresses rensignées pour valider le champ adresse
  ctrl.validateAddress = function () {
    var pointRemise = _.get(ctrl, 'offreStage.adresse.postale.LigneDeux');
    var complement = _.get(ctrl, 'offreStage.adresse.postale.LigneTrois');
    var nomVoie = _.get(ctrl, 'offreStage.adresse.postale.LigneQuatre');
    var mentionDistribution = _.get(ctrl, 'offreStage.adresse.postale.LigneCinq');
    if (pointRemise || complement || nomVoie || mentionDistribution) {
      ctrl.adresseRequired = false;
    } else {
      ctrl.adresseRequired = true;
    }
  };

  ctrl.emptyCodePostal = function () {
    return _.isEmpty(ctrl.codePostalVille);
  };

  /**
   *
   */
  function init() {
    /* Reset status to 'requested', so that modifications
     * doesn't go to request and EA, whatever the current status is.
     * The offre get back its 'transmitted' status only when
     * clicking transmit again, at the end of the workflow.
     */
    ctrl.offreStage.statut = 'REQUESTED';

    initDate();
    ctrl.validateAddress();

    ctrl.setDureesStage(ctrl.offreStage.cadreDepot);

    /** Fonction de validation du formulaire au submit */
    $scope.navigate.next = function () {
      // Handle empty siteWeb
      if (ctrl.offreStage.siteWeb === '') {
        delete ctrl.offreStage.siteWeb;
        ctrl.siteWebError = false;
      }

      // If codePostalVille is incorrect
      if (ctrl.postalVilleError) {
        delete ctrl.codePostalVille;
        return;
      }

      // Parsing dates
      ctrl.offreStage.datePrevisionnelleDebut = moment(ctrl.offreStage.datePrevisionnelleDebut, [
        'DD/MM/YYYY',
        'YYYY-MM-DD',
      ]).format('YYYY-MM-DD');
      if (ctrl.offreStage.dateReceptionNotification) {
        ctrl.offreStage.dateReceptionNotification = moment(ctrl.offreStage.dateReceptionNotification, [
          'DD/MM/YYYY',
          'YYYY-MM-DD',
        ]).format('YYYY-MM-DD');
      }

      if (ctrl.offreStage.cadreDepot === 'SUBVENTION') {
        delete ctrl.offreStage.numeroCourrierNotification;
        delete ctrl.offreStage.dateReceptionNotification;
      } else if (ctrl.offreStage.cadreDepot === 'MARCHE') {
        delete ctrl.offreStage.numeroDossierSubvention;
      } else if (ctrl.offreStage.cadreDepot === 'LIBRE') {
        delete ctrl.offreStage.numeroCourrierNotification;
        delete ctrl.offreStage.dateReceptionNotification;
        delete ctrl.offreStage.numeroDossierSubvention;
      }

      //Cas de creation
      if (!ctrl.offreStage.id) {
        offresStageService
          .create(ctrl.offreStage)
          .then(
            function (offreStage) {
              ctrl.siteWebError = false;
              angular.copy(offreStage, $scope.offreStage);
              $scope.goToStep('recapitulatif');
            },
            function (error) {
              error.data.errors.forEach(function (err) {
                if (err.dataPath === '.siteWeb' && err.keyword === 'format') {
                  ctrl.siteWebError = true;
                }
              });
            }
          )
          .finally(initDate);
      } else {
        offresStageService
          .update(ctrl.offreStage)
          .then(
            function (offreStage) {
              ctrl.siteWebError = false;
              angular.copy(offreStage, $scope.offreStage);
              $scope.goToStep('recapitulatif');
            },
            function (error) {
              if (error && error.data && error.data.errors) {
                error.data.errors.forEach(function (err) {
                  if (err.dataPath === '.siteWeb' && err.keyword === 'format') {
                    ctrl.siteWebError = true;
                  }
                });
              }
            }
          )
          .finally(initDate);
      }
    };

    $scope.navigate.ns = 'teleservice.informations';
    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'informations';

    ctrl.apiBan = {
      active: adresseService.isApiBanActive(),
      logo: adresseService.isDefaultApiUsed(),
    };

    ctrl.apibanAlerts = [];
  }
  init();
  getLicense();

  /**
   *
   */
  function getLicense() {
    var licence = ctrl.apiBan.active && licenseService.getPresenceLicence('connecteur-api-ban');
    if (!licence) {
      ctrl.apibanAlerts.push(alertsService.getAlertWarning('offres-stage.alerts.apiBanSearch')[0]);
    }
  }
}
