'use strict';
ContributionsAvisController.$inject = [
  '$state',
  '$rootScope',
  'viewManagerService',
  'contributionsService',
  'contributionsConstants',
]; // Module declaration
angular.module('portailDepotDemandeAide.depot').component('contributionAvisComponent', {
  templateUrl: 'depot/contributions-avis/contributions-avis.component.html',
  controller: /* @ngInject */ ContributionsAvisController,
  bindings: {
    contribution: '=',
    teleserviceConfiguration: '<',
    publicSettingsFinancement: '<',
    demande: '<',
  },
});

/**
 * Controller
 *
 * @param {object} $state
 * @param {object} $rootScope
 * @param {object} viewManagerService
 * @param {object} contributionsService
 * @param {object} contributionsConstants
 */
function ContributionsAvisController(
  $state,
  $rootScope,
  viewManagerService,
  contributionsService,
  contributionsConstants
) {
  // All of the patches done on the contribution, inside the cited components, in this template
  // will send a 'contributionPatched' event which updates the contribution of the parent and the children
  $rootScope.$on('contributionPatched', ($event, contribution) => {
    this.contribution = contribution;
    this.contribution.demande = this.demande;
  });

  this.$onInit = function () {
    this.setup();
    this.buildStepsWizard();
    this.buildConfigurationView();
  };

  /**
   * Use this method to setup all needed properties in onInit
   */
  this.setup = function () {
    // Setup navigation helper
    this.navigationHelper = {
      updateStepWizardDisplay: function () {
        this.stepsWizard.steps = this.stepsWizard.active === 'confirmation' ? [] : this.visibleSteps;

        if (this.contribution.status === contributionsConstants.status.ANSWERED) {
          contributionsService.notifications.alreadyDone();
          return $state.go('app.connected.dashboard.accueil');
        }

        var state = $state.$current;
        state.data = $state.$current.data || {};
        $rootScope.pageConfigurationKey = _.get(this.teleserviceConfiguration, 'libelle.value');
        this.navigationHelper.title = 'teleservice.' + this.stepsWizard.active + '.title';

        // Add exeception for confirmation page
        if (this.stepsWizard.active === 'confirmation') {
          state.data.title = 'contributions.steps.confirmation';
        } else {
          state.data.title = 'teleservice.steps.' + this.stepsWizard.active;
        }

        // Update the step into history
        var patch = [
          {
            op: 'add',
            path: '/history/begin/metadata',
            value: {
              step: this.stepsWizard.active,
            },
          },
        ];

        contributionsService.patch(this.contribution.reference, patch);
      }.bind(this),

      goToNextStep: function () {
        var currentIndex = _.indexOf(this.steps, this.stepsWizard.active);
        if (currentIndex < this.steps.length) {
          this.stepsWizard.active = this.steps[currentIndex + 1];
          this.navigationHelper.updateStepWizardDisplay();
        }
      }.bind(this),

      goToPreviousStep: function () {
        var currentIndex = _.indexOf(this.steps, this.stepsWizard.active);
        if (currentIndex > 0) {
          this.stepsWizard.active = this.steps[currentIndex - 1];
          this.navigationHelper.updateStepWizardDisplay();
        }
      }.bind(this),
    };
  };

  /**
   * Build the step wizard used for all steps in answer to contribution for advice
   */
  this.buildStepsWizard = function () {
    // Build worlflows
    this.preambuleWorkflow = _.get(this.teleserviceConfiguration, 'workflow.pagePreambule');
    if (_.get(this, 'contribution.typeContribution') === 'AVIS_FINANCEMENT') {
      this.avisWorkflow = _.get(this.teleserviceConfiguration, 'workflow.pageContributionAvisFinancement');
    } else {
      this.avisWorkflow = _.get(this.teleserviceConfiguration, 'workflow.pageContributionAvis');
    }
    this.piecesWorkflow = _.get(this.teleserviceConfiguration, 'workflow.pagePieces');
    this.recapitulatifWorkflow = _.get(this.teleserviceConfiguration, 'workflow.pageRecapitulatif');
    this.confirmationWorkflow = _.get(this.teleserviceConfiguration, 'workflow.pageConfirmation');

    // Build steps
    var shouldRemovePiecesStep = !_.get(this.teleserviceConfiguration, 'workflow.pagePieces.actif', false);
    this.steps = ['preambule', 'avis', 'pieces', 'recapitulatif', 'confirmation'];

    // Remove steps if not needed
    if (shouldRemovePiecesStep) {
      _.remove(this.steps, function (step) {
        return step === 'pieces';
      });
    }

    // filter visible steps we don't show page confirmation
    this.visibleSteps = this.steps.filter(function (step) {
      return step !== 'confirmation';
    });

    // Init steps wizard to default value
    this.stepsWizard = {
      steps: this.visibleSteps,
      active: _.get(this.contribution, 'history.begin.metadata.step', 'preambule'),
      labelsPrefix: 'teleservice.steps',
    };

    this.navigationHelper.updateStepWizardDisplay();
  };

  this.buildConfigurationView = function () {
    viewManagerService.getViewConfiguration('contributionAvis').then(
      function (response) {
        this.viewConfigurations = response;
        if (_.get(this, 'contribution.typeContribution') === 'AVIS_FINANCEMENT') {
          _.set(
            this,
            'viewConfigurations.avisFinancement',
            _.get(this, 'teleserviceConfiguration.workflow.pageContributionAvisFinancement')
          );
        }
      }.bind(this)
    );

    var teleserviceDemandeHref = _.get(this.contribution, 'demande.teleservice.href');
    contributionsService.getPersistenceConfigurationTSFinancement(teleserviceDemandeHref).then(
      function (persistence) {
        this.persistenceConfiguration = persistence;
      }.bind(this)
    );
  };
}
