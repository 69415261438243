'use strict';
angular.module('common.services').factory('cmisService', [
  '$http',
  '$q',
  'configuration',
  'userSessionService',
  '$log',
  '$rootScope',
  function ($http, $q, configuration, userSessionService, $log, $rootScope) {
    /**
     * get document collect url from configuration
     *
     * @returns {string} document collect url
     */
    function documentCollectUrl() {
      return configuration.documentCollect?.url || '/document-collect';
    }

    /**
     *  build url
     *
     * @returns {string} url
     */
    function _getUrl() {
      return `${documentCollectUrl()}/root`;
    }

    /**
     * Retourne les informations cmis d'un document
     *
     * @param {string} href Url du document
     * @param {boolean} byPassInterceptor bypass request interceptor
     * @returns {Promise<object>} Properties cmis du document
     */
    function getDocumentProperties(href, byPassInterceptor = false) {
      const options = byPassInterceptor
        ? {
            headers: {
              'X-No-Interceptor': true,
            },
          }
        : {};

      return $http.get(href, options).then((response) => {
        return response;
      });
    }

    /**
     * get Url Documents
     *
     * @param {string} urlDocuments urlDocuments
     * @param {string} objectId objectId
     * @returns {string} urlDocuments
     */
    function getUrlDocuments(urlDocuments, objectId) {
      if (_.includes(urlDocuments, 'document-collect')) {
        urlDocuments = _.first(urlDocuments.split('/root'));
        urlDocuments += '/root?objectId=' + objectId;
      } else {
        urlDocuments += '/' + objectId;
      }

      return urlDocuments;
    }

    /**
     *  Set document cmis properties
     *  ! MUTATE documentPiece
     *
     * @param {string} folder Répertoire du document
     * @param {object} documentPiece document
     * @param {object} response properties du document
     * @returns {object} Retourne le document avec ses propriétés cmis
     */
    function setPropertiesDocument(folder, documentPiece, response) {
      const objectId = _.get(response, 'data.properties.cmis:objectId.value') || response._id;
      documentPiece.expand.properties['cmis:objectId'] = {};
      documentPiece.expand.properties['cmis:objectId'].value = objectId;

      if (response.data?.properties?.['cmis:name']) {
        _.set(documentPiece.expand.properties, 'cmis:name.value', response.data.properties?.['cmis:name']?.value);
      }
      if (response.data?.properties?.['entity:document:originalfilename']?.value) {
        _.set(
          documentPiece.expand.properties,
          'entity:document:originalfilename.value',
          response.data.properties['entity:document:originalfilename'].value
        );
      }
      documentPiece.id = getUrlDocuments(folder, objectId);
      documentPiece.title = response.data?.properties?.['entity:document:originalfilename']?.value;
      documentPiece.href = documentPiece.id + '&cmisselector=object';
      documentPiece.expand.properties['cmis:creationDate'] = {};
      documentPiece.expand.properties['cmis:creationDate'].value = new Date().toISOString();
      documentPiece.expand.isUploaded = true;
      documentPiece.expand.isUploading = false;
      documentPiece.expand.progress = 100;
      return documentPiece;
    }

    /**
     * Build the params arrays to update a document properties
     *
     * Returns an object with propertyId and propertyValue arrays for use in
     * requests to document-collect
     *
     * @param {string} kind kind of entity
     * @param {object} entity entity
     * @returns {object} object with propertyId and propertyValues arrays
     */
    function buildDocumentMetadatasUpdatesQueryParams(kind, entity) {
      if (_.isEmpty(kind)) {
        throw new Error('"kind" is required to update entity metadata');
      }

      if (_.isEmpty(entity)) {
        throw new Error('"entity" is required to update entity metadata');
      }

      const uri = entity.id || _.get(entity, '_links.self.href', '');
      if (_.isEmpty(uri)) {
        throw new Error('"entity" must have either an "id" or a link to itself');
      }

      const user = userSessionService.getUser();
      const updatedProperties = {
        'cmis:secondaryObjectTypeIds': 'entity:metadata',
        'entity:uri': uri,
        'entity:reference': entity.reference || '',
        'entity:referenceAdministrative': entity.referenceAdministrative || '',
        'entity:kind': kind,
        'entity:author': _.get(user, 'displayName', ''),
        'entity:document:date': new Date().toISOString(),
      };

      return propertiesObjectToArrays(updatedProperties);
    }

    /**
     * Transform an object containing properties to two arrays:
     * - propertyId that contains the keys of the properties
     * - propertyValue that contains the values of the properties
     *
     * @param {object} properties properties
     * @returns {object} object with propertyId and propertyValue arrays
     */
    function propertiesObjectToArrays(properties) {
      const groupedInArrays = {
        propertyId: [],
        propertyValue: [],
      };

      for (const property of Object.keys(properties)) {
        groupedInArrays.propertyId.push(property);
        groupedInArrays.propertyValue.push(properties[property]);
      }

      return groupedInArrays;
    }

    /**
     * Expand document
     * ! MUTATE document
     * populate document.expand
     *
     * @param {object} doc
     * @param {boolean} byPassInterceptor bypass request interceptor
     * @returns {Promise<object>} expanded document
     */
    function expandDocument(doc, byPassInterceptor = false) {
      // If the document name is missing, fetch metadatas, else returns it unaltered
      if (_.get(doc, 'expand.properties.name') || _.get(doc, 'expand.properties["cmis:name"].value')) {
        return $q.resolve(doc);
      }

      // Get the data from CMIS
      return getDocumentProperties(doc.href, byPassInterceptor).then(function (docProperties) {
        const properties = docProperties.data && docProperties.data.properties;
        if (properties) {
          doc.expand = {
            ...doc.expand,
            properties,
          };
        }

        return doc;
      });
    }

    return {
      getBaseUrl: function () {
        return _getUrl();
      },

      getUrlDocuments,

      createFolder: function (basePath, folderName) {
        var config = {
          params: {
            cmisaction: 'createFolder',
            'propertyId[0]': 'cmis:name',
            'propertyValue[0]': folderName,
            'propertyId[1]': 'cmis:objectTypeId',
            'propertyValue[1]': 'cmis:folder',
            succinct: true,
          },
        };

        return $http.post(_getUrl() + '/' + basePath, {}, config).then(function (response) {
          return response.data;
        });
      },

      /**
       * Méthode permettant de copier un document dans la ged
       *
       * @param {string} folder Répertoire du document dans la ged
       * @param {object} documentPiece document à copier
       * @param {string} kind Type de l'entité
       * @param {object} entity Entité
       * @returns {object} Retourne la copie de document ou le document si conflit
       */
      copyDocument(folder, documentPiece, kind, entity) {
        const objectId = _.get(documentPiece, 'expand.properties.cmis:objectId.value');
        const metadataUpdateParams = buildDocumentMetadatasUpdatesQueryParams(kind, entity);
        const queryParams = {
          cmisaction: 'createDocumentFromSource',
          sourceId: objectId,
          propertyId: metadataUpdateParams.propertyId,
          propertyValue: metadataUpdateParams.propertyValue,
        };

        // Copy document and update its properties
        return $http
          .post(folder, {}, { params: queryParams })
          .then(function (response) {
            setPropertiesDocument(folder, documentPiece, response);
            documentPiece.expand = _.merge(documentPiece.expand, response.data);
            return documentPiece;
          })
          .catch(function (err) {
            if (err.status === 409) {
              // If document already exists in folder get the properties of the given
              // document instead

              // /!\ Warning: the given document might not be the document that
              // already exists in the aide folder. So Deleting the returned
              // document will delete the given document instead of the document
              // that exists in the folder.
              const documentHref = _.get(documentPiece, 'href');

              return getDocumentProperties(documentHref)
                .then(function (documentCmis) {
                  return setPropertiesDocument(folder, documentPiece, documentCmis);
                })
                .catch(function () {
                  return documentPiece;
                });
            }
            throw err;
          });
      },

      expandDocument,

      /**
       * Expand pieces documents
       * ! MUTATE pieces[*].documents[*]
       * populate pieces[*].documents[*].expand
       *
       * @param {Array<object>} pieces
       * @param {boolean} allowForbiddenError allow forbidden error
       * @returns {Promise<Array<object>>} expanded documents
       */
      expandAllPiecesDocuments(pieces, allowForbiddenError = false) {
        if (!Array.isArray(pieces)) {
          return $q.resolve([]);
        }
        return $q.all(
          // expand documents (porte-documents needs them)
          pieces.flatMap(({ documents }) =>
            documents?.map((doc) =>
              expandDocument(doc, allowForbiddenError).catch((error) => {
                $log.error('(cmis-service) expandAllPiecesDocuments error', doc, error);
                if (!allowForbiddenError || error.status !== 403) {
                  $rootScope.$broadcast('$httpError', error);
                }
              })
            )
          )
        );
      },
    };
  },
]);
