'use strict';
angular.module('common.filters').filter('longDateTime', [
  '$filter',
  '$translate',
  function ($filter, $translate) {
    'use strict';
    // Format dateTime to dd MMMM yyyy à HH:mm:ss
    return function (date) {
      const translateListA = $translate.instant('connected.dashboard.aides.list.a');
      const dateArg = `dd MMMM yyyy ${translateListA} HH:mm:ss`;
      return $filter('date')(date, dateArg);
    };
  },
]);
