'use strict';
contributionsSearchController.$inject = ['$state', 'contributionsService'];
angular.module('portailDepotDemandeAide.dashboard').component('contributionsSearch', {
  templateUrl: 'dashboard/contributions/contributions-search/contributions-search.html',
  controller: contributionsSearchController,
  bindings: {},
});

contributionsSearchController.$inject = ['$state', 'contributionsService'];

/**
 *
 * @param {object} $state
 * @param {object} contributionsService
 */
function contributionsSearchController($state, contributionsService) {
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.contributionsNonTraitees = [];
    ctrl.contributionsTraitees = [];
    ctrl.searchContributionsNonTraitees();
    ctrl.searchContributionsTraitees();
    ctrl.searchTextNonTraitees = '';
    ctrl.searchTextTraitees = '';
    ctrl.pageNonTraitees = 1;
    ctrl.pageTraitees = 1;
    ctrl.itemsPerPage = 20;
  };

  /**
   * Modification du filtre sur le type de contribution dans l'onglet "Non traitées"
   *
   * @param {*} type - valeurs possibles : ['modification', 'avis', null]
   */
  ctrl.changeTypeNonTraitees = function (type) {
    ctrl.typeNonTraitees = type;
  };

  /**
   * Modification du filtre sur le type de contribution dans l'onglet "Traitées"
   *
   * @param {*} type - valeurs possibles : ['modification', 'avis', null]
   */
  ctrl.changeTypeTraitees = function (type) {
    ctrl.typeTraitees = type;
  };

  /**
   * Recherche les contributions
   *
   * @param {*} resetPage - si vrai remet le numéro de page à 1
   */
  ctrl.searchContributionsNonTraitees = function (resetPage) {
    if (resetPage) {
      ctrl.pageNonTraitees = 1;
    }
    contributionsService
      .searchForCurrentUser(ctrl.pageNonTraitees - 1, false, ctrl.typeNonTraitees, ctrl.searchTextNonTraitees, 'asc')
      .then(({ contributions, total }) => {
        ctrl.contributionsNonTraitees = contributions;
        ctrl.totalContributionsNonTraitees = total;
      });
  };

  /**
   * Recherche les contributions
   *
   * @param {*} resetPage - si vrai remet le numéro de page à 1
   */
  ctrl.searchContributionsTraitees = function (resetPage) {
    if (resetPage) {
      ctrl.pageTraitees = 1;
    }
    contributionsService
      .searchForCurrentUser(ctrl.pageTraitees - 1, true, ctrl.typeTraitees, ctrl.searchTextTraitees, 'desc')
      .then(({ contributions, total }) => {
        ctrl.contributionsTraitees = contributions;
        ctrl.totalContributionsTraitees = total;
      });
  };

  ctrl.pageDebut = function (numPage) {
    return (numPage - 1) * ctrl.itemsPerPage + 1;
  };

  ctrl.pageFin = function (numPage, contributions) {
    return (numPage - 1) * ctrl.itemsPerPage + contributions.length;
  };
}
