'use strict';
angular.module('portailDepotDemandeAide.depot').controller('depotSimpleDemandeurRepresentantsController', [
  '$scope',
  'StoreService',
  'tiersService',
  function ($scope, StoreService, tiersService) {
    'use strict';

    tiersService.getPublicSettingsTiers().then((response) => {
      $scope.sectionAdresseContacts = response.sectionAdresseContacts;
    });

    $scope.demandeur = StoreService.demandeur.get();

    $scope.cleanNavigate();
    $scope.navigate.noform = true;

    $scope.navigate.next = () => {
      StoreService.demandeur.set($scope.demandeur);

      $scope.saveDemandeur().then(() => $scope.goToStep('demandeur-complementaire'));
    };
    $scope.navigate.ns = $scope.representantsDemandeurConfiguration.ns;

    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'tiers';

    // Fonctions may have been already set by a previous page
    if (!$scope.fonctionsRepresentants.demandeur) {
      $scope.setFonctionsRepresentants($scope.demandeur.famille.expand, 'demandeur');
    }
  },
]);
