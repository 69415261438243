/**
 * Returns a function that formats a date value according to the specified style and locale.
 *
 * @param {object} dateFormatterService dateFormatterService
 * @returns {Function} A function that takes a date value as its first argument, and an optional style string as its second argument. The function returns a formatted date string.
 */
function asDateFilter(dateFormatterService) {
  return (dateValue, options) => {
    const mergedOptions = { locale: 'fr', ...options };
    return dateFormatterService.asDate(dateValue, mergedOptions);
  };
}

asDateFilter.$inject = ['dateFormatterService'];

export { asDateFilter };
