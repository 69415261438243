'use strict';
/**
 * @param {object} $scope
 * @param {object} $rootScope
 * @param {object} $stateParams
 * @param {object} jwtSessionService
 * @param {object} $httpParamSerializer
 * @param {object} $window
 * @module components.echanges
 * @name echangesAsideController
 * @description
 *
 *   This controller manages a series of page for 'simple' model of procedure
 */
angular.module('components.echanges').controller('echangesAsideController', [
  '$scope',
  '$rootScope',
  '$stateParams',
  'jwtSessionService',
  '$httpParamSerializer',
  '$window',
  function echangesAsideController($scope, $rootScope, $stateParams, jwtSessionService, $httpParamSerializer, $window) {
    'use strict';

    if ($stateParams.fromEchange) {
      const queryParams = $httpParamSerializer({
        jwtKey: jwtSessionService.getJwtKey(),
        echangesActif: _.get($rootScope, 'displayEchangeMenu.value', false),
        theme: `demandeur#sendMessage`,
      });

      $scope.iframeEchangesUrl = `/referentiel-echanges/#/${$stateParams.tenantId}/messagesEchange/${$stateParams.fromEchange}?${queryParams}`;
    } else {
      const queryParams = $httpParamSerializer({
        jwtKey: jwtSessionService.getJwtKey(),
        echangesActif: _.get($rootScope, 'displayEchangeMenu.value', false),
        theme: 'demandeur',
      });

      const refDemande = $stateParams.aide || $stateParams.p;
      $scope.iframeEchangesUrl = `/referentiel-echanges/#/${$stateParams.tenantId}/echangesDemande/${refDemande}?${queryParams}`;
    }

    // Window height - footer height - aside header height
    $scope.calculatedHeight = $window.innerHeight - 50 - 49;
  },
]);
