'use strict';
angular.module('tiers').component('dashboardTiersInfos', {
  templateUrl: 'tiers/tiers-components/dashboard-tiers-infos/dashboard-tiers-infos.component.html',
  controller: DashboardTiersInfosController,
  bindings: {
    tiers: '<',
    referent: '<',
  },
});

/**
 *
 */
function DashboardTiersInfosController() {
  this.$onInit = () => {
    this.nomNaissanceHidden = !_.get(this.tiers, 'famille.expand.infosStandardsComplementaires.NomFamille.visible');
  };
}
