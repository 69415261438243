'use strict';

class LinkedDemandesController {
  /**
   * @param {object} $sce
   * @param {object} $httpParamSerializer
   * @param {object} $state
   * @param {object} $stateParams
   * @param {object} configuration
   * @param {object} jwtSessionService
   */
  constructor($sce, $httpParamSerializer, $state, $stateParams, configuration, jwtSessionService) {
    this._sce = $sce;
    this._httpParamSerializer = $httpParamSerializer;
    this._state = $state;
    this._stateParams = $stateParams;
    this.configuration = configuration;
    this.jwtSessionService = jwtSessionService;
  }

  /**
   * Initialize component
   *
   * @returns {void}
   */
  $onInit() {
    const idCpo = this.aide.idCpo;
    const idToExclude = this.aide.id;

    const linkedDemandesIframeQueryParams = this._httpParamSerializer({
      jwtKey: this.jwtSessionService.getJwtKey(),
      tiersKey: 'current-tiers-ref',
      idCpo,
      idToExclude,
    });

    const templateLinkedDemandesIframeSrc = `${this.configuration.ux}<%= tenantId %>/linked-demandes-financement-view?<%= queryParams %>`;
    const compiledLinkedDemandesIframeSrc = _.template(templateLinkedDemandesIframeSrc);
    const linkedDemandesIframeSrc = compiledLinkedDemandesIframeSrc({
      tenantId: this.configuration.tenant.id,
      queryParams: linkedDemandesIframeQueryParams,
    });

    this.linkedDemandesIframeSrc = this._sce.trustAsResourceUrl(linkedDemandesIframeSrc);
  }
}

LinkedDemandesController.$inject = [
  '$sce',
  '$httpParamSerializer',
  '$state',
  '$stateParams',
  'configuration',
  'jwtSessionService',
];

angular.module('aides').component('recapitulatifLinkedDemandes', {
  controller: LinkedDemandesController,
  templateUrl: 'aides/aides-components/recapitulatif-linked-demandes/recapitulatif-linked-demandes.html',
  bindings: {
    aide: '<',
  },
});
