'use strict';
angular.module('common.model').factory('Conformite', function () {
  'use strict';

  /**
   * Classe Conformite
   *
   * @param {object} data Conformite
   * @class Conformite
   */
  function Conformite(data) {
    data = data || {};

    this.value = data.value || true;
    this.motifs = data.motifs || [];
  }

  return Conformite;
});

angular.module('common.model').factory('NonConformiteType', function () {
  'use strict';
  /**
   * Classe NonConformiteType
   *
   * @param {object} data NonConformiteType
   * @class NonConformiteType
   */
  function NonConformiteType(data) {
    data = data || {};

    this.regle = data.regle;
    // description is LocalizedTextType {lang: , value:}
    this.description = data.description || {};
    this.date = data.date || new Date().toISOString();
  }

  return NonConformiteType;
});
