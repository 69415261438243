'use strict';
angular.module('portailDepotDemandeAide.depot').controller('depotSimpleDemandeurIdentificationController', [
  '$scope',
  'Tiers',
  'tiersService',
  'configuration',
  'StoreService',
  'depotSimpleService',
  function ($scope, Tiers, tiersService, configuration, StoreService, depotSimpleService) {
    'use strict';

    // If sharing mode, set user as null to avoid pre-filled fields
    $scope.demandeurUser = $scope.isAccessedThroughSharing ? null : $scope.currentUser;

    $scope.fullName = depotSimpleService.getFullNameValidity($scope.currentUser && $scope.currentUser.name);

    $scope.pageOptions = $scope.teleserviceConfiguration.workflow.pageInformationsDemandeur || {};
    $scope.demandeur = StoreService.demandeur.get();

    // disable type (famille) and adresseEtrangere fields + SIRET if adresseEtrange is false
    // because those fields are already filled in the demandeur-famille step
    const adresseEtrangere = _.get($scope.demandeur, 'situations[0].etrangere');

    const stepMetadata = _.get($scope, 'aide.history.begin.metadata.stepMetadata') || {};

    $scope.cleanNavigate();
    $scope.identificationDemandeurConfiguration.showErrors = $scope.showErrorsOnNavigate();

    // Vertification de la famille de tiers
    // On n'affiche pas les erreurs si l'on vient de modifier la famille de tiers
    $scope.$watch('demandeur.famille', function (newFamille, oldFamille) {
      if (newFamille) {
        if (newFamille !== oldFamille) {
          $scope.identificationDemandeurConfiguration.showErrors = false;
        }
      }
    });

    $scope.restrictionFamillesTiers = _.map($scope.pageOptions.familles, 'famille.href');

    $scope.navigate.ns = $scope.identificationDemandeurConfiguration.ns;
    $scope.navigate.next = function () {
      /**
       * Test if the next page (demandeur-thematiques) should be visited
       * that is if the page is active and concerns the tiers family of the demandeur
       * if not, send to the page afterward (demandeur-adresse)
       */
      const nextStep = () => {
        const pageThematiquesDemandeur =
          $scope.teleserviceConfiguration.workflow.pageInformationsThematiquesDemandeur || {};
        const typeFamille = _.get($scope.demandeur, 'famille.expand.typeFamille');

        // List of fields and sections set in teleservice
        const teleserviceTypeTiersChamps = _.get(pageThematiquesDemandeur.champsParTypeTiers, typeFamille);
        // Page active if one configuration has been found for current tiers' type
        const pageThematiqueActive = pageThematiquesDemandeur.actif && teleserviceTypeTiersChamps;

        $scope.goToStep(pageThematiqueActive ? 'demandeur-thematiques' : 'demandeur-adresse');
      };

      if ($scope.fullName.valid) {
        // Check if the option for 'rattachement' is active
        const demandeRattachementCompteTiers = _.get(
          configuration,
          'tiers.rattachement.demandeRattachementCompteTiers'
        );

        const rattachementCompteTiers =
          (demandeRattachementCompteTiers && $scope.pageOptions.gestionRattachementCompte) || false;

        // Personnalité juridique
        const personnaliteJuridique = _.get($scope.demandeur, 'famille.expand.personnaliteJuridique');

        StoreService.demandeur.set($scope.demandeur);

        if (
          personnaliteJuridique === 'MORALE' &&
          $scope.demandeur.SIRET &&
          $scope.demandeur.SIRET.SIREN &&
          $scope.demandeur.SIRET.NIC &&
          rattachementCompteTiers === true
        ) {
          const tiers = $scope.demandeur;

          // Request fonctions representants
          $scope.setFonctionsRepresentants(tiers.famille.expand, 'demandeur');

          // Search tiers with same siret
          tiersService.controlerUniciteSIRET(tiers.SIRET.SIREN, tiers.SIRET.NIC).then(function (existingTiers) {
            if (existingTiers.length > 0 && existingTiers[0].accountAdministrator) {
              $scope.goToStep('demandeur-rattachement');
            } else {
              $scope.saveDemandeur().then(nextStep);
            }
          });
        } else {
          $scope.saveDemandeur().then(nextStep);
        }
      }
    };

    $scope.viewConfiguration = _.merge({}, $scope.identificationDemandeurConfiguration, {
      fields: {
        type: {
          readOnly: true,
        },

        adresseEtrangere: {
          readOnly: true,
        },

        SIREN: {
          readOnly: stepMetadata.sirenReadOnly === undefined ? !adresseEtrangere : stepMetadata.sirenReadOnly,
        },

        NIC: {
          readOnly: stepMetadata.nicEditable === undefined ? !adresseEtrangere : !stepMetadata.nicEditable,
        },

        codeRNA: {
          readOnly: stepMetadata.codeRnaReadOnly === undefined ? false : stepMetadata.codeRnaReadOnly,
        },
      },
    });

    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'tiers';
  },
]);
