/**
 * Aides access management controller
 *
 * @property {object} demande - one-way binding <
 * @property {boolean} isRestricted - one-way binding <
 * @property {boolean} isIcon - one-way binding < (computed in $onInit)
 */
class BackgroundAidesAccessManagementController {
  /**
   * Component controller
   *
   * @param {object} $attrs object that contains component properties
   * @param {object} $q $q service
   * @param {object} $scope $scope
   * @param {object} $document $document
   * @param {object} aidesAccessManagementService aides access management service
   * @param {object} IFrameCommunicationManager class to send events to child Iframes
   */
  constructor($attrs, $q, $scope, $document, aidesAccessManagementService, IFrameCommunicationManager) {
    this.$attrs = $attrs;
    this.$q = $q;
    this.$scope = $scope;
    this.$document = $document;
    this.aidesAccessManagementService = aidesAccessManagementService;
    this.IFrameCommunicationManager = IFrameCommunicationManager;
  }

  /**
   * Function trigerred at component initialization
   *
   * @returns {void}
   */
  $onInit() {
    this.initProperties();
    this.loading = false;
    this.isRestricted = this.isRestricted ?? false;
    this.signataireHref = this.demande?.signataire?.href; // Used to exclude if from depot

    // Make "show-modal" binding reactive
    this.watchOpenModal();
  }

  /**
   * Init component variables when initializing context (at modal showing)
   *
   * @returns {void}
   */
  initProperties() {
    this.isModalOpened = false;
    this.restrictedUsers = [];
    this.linkedUsers = [];
    this.userList = [];
    this.errors = {
      retrieve: false,
      update: false,
    };
  }

  /**
   * Opens modal
   *
   * @returns {void}
   */
  openModal() {
    this.fetchUsersAndRestrictions();
    this.lastChoice = this.isRestricted;
    this.isModalOpened = true;
  }

  /**
   * Function trigerred when modal is showing, fetching if demande is restricted, restricted users and linked users
   *
   * @returns {void}
   */
  fetchUsersAndRestrictions() {
    if (this.demande?.id) {
      this.loading = true;
      this.initProperties();

      this.aidesAccessManagementService
        .fetchUsersAndRestrictions(this.demande, this.demande?.demandeur?.reference, this.signataireHref)
        .then(({ isRestricted, linkedUsers, userList, restrictedUsers }) => {
          this.updateRestriction(isRestricted);
          this.linkedUsers = linkedUsers;
          this.userList = userList;
          this.restrictedUsers = restrictedUsers;
        })
        .catch(() => {
          this.errors.retrieve = true;
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.errors.retrieve = true;
    }
  }

  /**
   * Update isRestricted property according to update event from modal
   *
   * @param {boolean} isRestricted true if the saved choice selects some accounts
   * @returns {void}
   */
  updateRestriction(isRestricted) {
    if (isRestricted === this.isRestricted) return;

    this.isRestricted = isRestricted;
    this.lastChoice = this.isRestricted;
  }

  /**
   * Function trigerred when modal is closed by saving restrictions (not cancelling)
   *
   * @param {boolean} isRestricted true if demande is restricted
   * @returns {void}
   */
  onModalRestrictionsSave(isRestricted) {
    this.updateRestriction(isRestricted);

    this.sendIframeUpdateEvent();
  }

  /**
   * Reset choice when we cancel modal
   *
   * @returns {void}
   */
  resetChoice() {
    this.isRestricted = this.lastChoice;
  }

  /**
   * Handles on-hide event from modal
   *
   * @returns {void}
   */
  onHide() {
    this.showModal = false;
    this.resetChoice();
  }

  /**
   * Send an event to VueJS front IFrame, to update list and add "restricted" tag on the concerned demande.
   *
   * @returns {void}
   */
  sendIframeUpdateEvent() {
    // Need to send event when restriction choice changed
    const iframe = this.$document[0].getElementsByTagName('iframe');

    if (iframe[0]) {
      const iframeManager = new this.IFrameCommunicationManager(`#${iframe[0].id}`);

      if (iframeManager) {
        iframeManager.sendEvent({
          action: 'demande-financement/update-restrictions',
          id: this.demande.id,
          isRestricted: this.isRestricted,
        });
      }
    }
  }

  /**
   * Watcher on "showModal" binding. This way, the modal can be trigerred "manually" by changing a boolean value.
   *
   * @returns {void}
   */
  watchOpenModal = () => {
    this.$scope.$watch('$ctrl.showModal', (showModal) => {
      if (showModal) {
        this.isRestricted = this.demande?.isRestricted ?? false;
        this.openModal();
      }
    });
  };
}

BackgroundAidesAccessManagementController.$inject = [
  '$attrs',
  '$q',
  '$scope',
  '$document',
  'aidesAccessManagementService',
  'IFrameCommunicationManager',
];

angular.module('aides').component('backgroundAidesAccessManagement', {
  templateUrl:
    'aides/aides-components/aides-restrictions/background-aides-access-management/background-aides-access-management.component.html',
  controller: BackgroundAidesAccessManagementController,
  bindings: {
    demande: '<',
    isRestricted: '<',
    showModal: '=',
  },
});
