'use strict';
/**
 * @name common.services.numberService
 * @requires $window
 * @description Manages numbers
 */
angular.module('common.services').factory('numberService', function () {
  'use strict';
  return {
    /**
     * Rounds the given number with the given decimal digits
     *
     * @param {number} number
     * @param {number} digits
     * @returns {number}
     */
    round: (number, digits) => {
      return parseFloat(Number(number).toFixed(digits));
    },
  };
});
