/* eslint-disable angular/document-service */

'use strict';
angular.module('common.services').factory('documentService', [
  function () {
    'use strict';

    return {
      /**
       * Returns document name.
       *
       * @param {object}document document
       * @returns {string} document name
       */
      getDocumentName: function (document) {
        return (
          document?.expand?.properties?.['entity:document:originalfilename']?.value ??
          document?.object?.properties?.['entity:document:originalfilename']?.value ??
          document?.expand?.properties?.['entity:originalfilename']?.value ??
          document?.object?.properties?.['entity:originalfilename']?.value ??
          document?.expand?.properties?.['cmis:name']?.value?.replace(/(^[a-z0-9-]{32})(_)/, '') ??
          document?.object?.properties?.['cmis:name']?.value?.replace(/(^[a-z0-9-]{32})(_)/, '') ??
          document?.title
        );
      },
    };
  },
]);
