'use strict';
depotSimpleDemandeurComplementaireController.$inject = [
  '$scope',
  '$timeout',
  'tiersService',
  'viewsService',
  'StoreService',
  'IFrameCommunicationManager',
  '$q',
  'jsonpatch',
];
angular
  .module('portailDepotDemandeAide.depot')
  .controller('depotSimpleDemandeurComplementaireController', depotSimpleDemandeurComplementaireController);

/**
 *
 * @param {object} $scope
 * @param {object} $timeout
 * @param {object} tiersService
 * @param {object} viewsService
 * @param {object} StoreService
 * @param {object} IFrameCommunicationManager
 * @param {object} $q
 * @param {object} jsonpatch
 */
function depotSimpleDemandeurComplementaireController(
  $scope,
  $timeout,
  tiersService,
  viewsService,
  StoreService,
  IFrameCommunicationManager,
  $q,
  jsonpatch
) {
  $scope.pageOptions = $scope.teleserviceConfiguration.workflow.pageInformationsDemandeur;
  $scope.pageOptions.controleCompletudeDepot = $scope.teleserviceConfiguration.controleCompletudeDepot;
  $scope.cleanNavigate();
  $scope.navigate.ns = $scope.informationsComplementairesDemandeurConfiguration.ns;

  $scope.demandeur = StoreService.demandeur.get();
  $scope.initialDemandeurViews = _.cloneDeep($scope.demandeur.views);

  $scope.informationsComplementairesDemandeurConfiguration.showErrors = $scope.showErrorsOnNavigate();

  if ($scope.isDemandeurReadOnly) {
    /**
     * If this page is read only because of sharing mode, we don't want to block the user.
     * Disable the "completude" for this page. In any case, "completude" will be checked at the end of the workflow.
     *
     * ? This overrides the default behavior from "createConfiguration" function in "depot-simple-controller"
     */
    $scope.informationsComplementairesDemandeurConfiguration.remoteValidation = true;
  }

  const iFrameCommunicationManagers = [];
  const viewsCommunicationManager = new IFrameCommunicationManager('#viewsInfosComps');
  iFrameCommunicationManagers.push(viewsCommunicationManager);
  const updateStateViewsId = 'updateStateViews';

  /**
   * Update the tiers
   *
   * @param {object} tiers the tiers
   * @returns {void}
   */
  function updateTiers(tiers) {
    if ($scope.tiers) {
      $scope.tiers = tiers;
    }

    StoreService.demandeur.set(tiers);
    _.set($scope, 'aide.demandeur.expand', tiers);
  }

  /**
   * Get the changes on the views properties since the screen was loaded as an
   * array of jsonpatch changes
   *
   * @returns {object[]} jsonpatch changes made on the views
   */
  function getViewsPatches() {
    return jsonpatch.compare({ views: $scope.initialDemandeurViews }, { views: $scope.demandeur.views });
  }

  /**
   * Check if the data set on the tiers views are valid and wait for them to be updated on the tiers in memory
   *
   * Done only if views are active and on first depot. If not on first depot, the tiers "views" infos comp are displayed on another screen
   *
   * @param {boolean} ignoreAllFields option to ignore errors on fields
   * @returns {Promise} promise that resolves when the views are updated or immediatly if they don't need to be updated
   */
  function validateViewsAndWaitForUpdate(ignoreAllFields) {
    if ($scope.tiers || !$scope.actifViews) {
      return $q.resolve();
    }

    viewsCommunicationManager.sendEvent({
      action: 'validViews',
      options: { skipRequiredErrors: ignoreAllFields, showAllErrors: !ignoreAllFields },
    });

    return viewsCommunicationManager.manageUniqueEventWithPromise({
      type: updateStateViewsId,
      callback: (msg, resolve, reject) => {
        viewsService.updateStateViewsTiers($scope, msg, resolve, reject);
      },
    });
  }

  $scope.navigate.beforePrevious = () => {
    return validateViewsAndWaitForUpdate(true);
  };

  $scope.navigate.afterSave = () => {
    $scope.initialDemandeurViews = _.cloneDeep($scope.demandeur.views);
  };

  $scope.navigate.next = () => {
    // Get configuration option to the validation
    const ignoreAllFields = _.get($scope, 'teleserviceConfiguration.controleCompletudeDepot', false);
    // Send event to valid the data-schemas iframe content. We wait the response to update the tiers.
    return validateViewsAndWaitForUpdate(ignoreAllFields)
      .then(() => {
        const patches = StoreService.tiers.patches
          .get()
          .filter((patch) => !patch.path.startsWith('/views'))
          .concat(getViewsPatches());

        if (!_.isEmpty(patches)) {
          // When tiers infos-comp are active, referentiel-tiers will add the views property
          // according to the famille
          return tiersService.patchTiers($scope.demandeur.reference, patches, $scope.mdm).then((tiers) => {
            const familleExpand = _.get($scope.demandeur, 'famille.expand');

            tiers.famille.expand = familleExpand;

            StoreService.tiers.patches.clean();
            updateTiers(tiers);
          });
        }
        return $q.resolve();
      })
      .then(() => $scope.goToStep('demandeur-beneficiaire'))
      .catch(() => {
        viewsCommunicationManager.closeUniqueEvent({ type: updateStateViewsId });
      });
  };

  const { isEntrepriseOrAssociationAndActive, areInfosCompActive } = tiersService.areInfosCompTiersActive(
    $scope.pageOptions,
    $scope.teleserviceConfiguration,
    $scope.demandeur,
    $scope.tiers
  );

  $scope.isEntrepriseOrAssociationAndActive = isEntrepriseOrAssociationAndActive;

  // if tiers is defined, views were displayed previously so we can skip the page if there is nothing else
  if (!areInfosCompActive) {
    // navigate but don't remember the page we are leaving
    $scope.goToStep('demandeur-beneficiaire', true);
  }

  $scope.stepsWizard.steps = $scope.getSimpleSteps();
  $scope.stepsWizard.active = 'tiers';

  // If the tiers is defined, this means the infos-comp of the tiers were displayed in the recapitulatif
  // Thus we don't need to display it in this case
  if (!$scope.tiers) {
    // lock navigation during famille fetch to avoid being able to skip the step if it is required
    $scope.navigate.lockNavigation();

    const familleHref = $scope.demandeur?.famille?.href;
    // Get famille views and merge it with tiers views
    tiersService.getFamille(familleHref, ['views.schema']).then((familleConfig) => {
      // We merge tiers views with famille views
      $scope.demandeur.views = viewsService.mergeTiersViewsWithFamilleViews(
        familleConfig.views ?? [],
        $scope.initialDemandeurViews ?? []
      );

      $scope.actifViews = tiersService.hasActifViews($scope.demandeur);
      $scope.viewsIframeSrc = tiersService.getViewsIframeSrcFromTiers($scope.demandeur);

      $scope.navigate.unlockNavigation();

      // iFrame needs to be resized
      $timeout(() => {
        iFrameResize(
          {
            heightCalculationMethod: 'taggedElement',
            checkOrigin: false,
            inPageLinks: true,
          },

          '#viewsInfosComps'
        );
      }, 0);

      $scope.$watch(
        'demandeur',
        (newValue) => {
          if (newValue) {
            const viewsIframe = angular.element('#viewsInfosComps');
            if (viewsIframe && viewsIframe[0]) {
              viewsIframe[0].contentWindow.postMessage(
                {
                  action: 'updateContextExpressions',
                  contextExpressions: JSON.parse(JSON.stringify($scope.demandeur)),
                },

                '*'
              );
            }
          }
        },
        true
      );
    });

    const updateViewsTiers = (msg) => {
      viewsService.updateViewsEntity($scope, msg, $scope.demandeur, '#viewsInfosComps', {
        showAllErrors: $scope.informationsComplementairesDemandeurConfiguration.showErrors,
      });
    };

    viewsCommunicationManager.manageEvent(updateViewsTiers);

    $scope.$on('$destroy', () => {
      _.each(iFrameCommunicationManagers, (manager) => {
        manager.close();
      });
    });
  }
}
