'use strict';
angular.module('portailDepotDemandeAide.dashboard').controller('dashboardOffresStageRecapitulatifController', [
  '$scope',
  'offreStage',
  'piecesConfiguration',
  function ($scope, offreStage, piecesConfiguration) {
    $scope.offreStage = offreStage;
    $scope.pieces = $scope.offreStage.pieces;
    $scope.viewConfiguration = piecesConfiguration;
    $scope.urlDocuments = $scope.offreStage._links['mgs:documents'].href;
    // Directory of file's icons
    $scope.urlFileIcons = './resources/images/file-icons/';
    $scope.recapitulatif = 'connected.dashboard.offresStage.recapitulatif.';

    $scope.adresseLignes = {
      // ligneUne used only for tiers name display
      ligneDeux: $scope.offreStage.adresse?.postale?.LigneDeux,
      ligneTrois: $scope.offreStage.adresse?.postale?.LigneTrois,
      ligneQuatre: $scope.offreStage.adresse?.postale?.LigneQuatre,
      ligneCinq: $scope.offreStage.adresse?.postale?.LigneCinq,
    };

    $scope.adressePostale = {
      codePostal: $scope.offreStage.adresse?.codePostal,
      ville: $scope.offreStage.adresse?.ville ?? '',
    };
  },
]);
