'use strict';

angular
  .module('portailDepotDemandeAide.depot')
  .controller('depotOffresStagePreambuleController', [
    '$scope',
    'offresStageService',
    depotOffresStagePreambuleController,
  ]);

/**
 *
 * @param {object} $scope
 * @param {object} offresStageService
 */
function depotOffresStagePreambuleController($scope, offresStageService) {
  var ctrl = this;
  ctrl.ns = 'offres-stage';
  ctrl.preambuleHTMLContent = _.get($scope, 'workflow.pagePreambule.content');
  ctrl.offreStage = $scope.offreStage;

  /**
   *
   */
  function init() {
    $scope.cleanNavigate();
    $scope.navigate.next = $scope.navigate.ns = 'teleservice.preambule';
    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'preambule';

    $scope.navigate.next = function () {
      // Parsing dates
      if (ctrl.offreStage.datePrevisionnelleDebut) {
        ctrl.offreStage.datePrevisionnelleDebut = moment(ctrl.offreStage.datePrevisionnelleDebut, [
          'DD/MM/YYYY',
          'YYYY-MM-DD',
        ]).format('YYYY-MM-DD');
      }
      if (ctrl.offreStage.dateReceptionNotification) {
        ctrl.offreStage.dateReceptionNotification = moment(ctrl.offreStage.dateReceptionNotification, [
          'DD/MM/YYYY',
          'YYYY-MM-DD',
        ]).format('YYYY-MM-DD');
      }

      //Cas de creation
      if (!ctrl.offreStage.id) {
        offresStageService.create(ctrl.offreStage).then(function (offreStage) {
          angular.copy(offreStage, $scope.offreStage);
          $scope.goToStep('informations');
        });
      } else {
        offresStageService.update(ctrl.offreStage).then(function (offreStage) {
          angular.copy(offreStage, $scope.offreStage);
          $scope.goToStep('informations');
        });
      }
    };
  }
  init();
}
