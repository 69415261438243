'use strict';
angular.module('tiers.model').factory('Telephone', [
  function () {
    'use strict';

    /**
     *
     * @param {object} data
     */
    function Telephone(data) {
      data = data || {};
      this.lang = data.lang;
      this.value = data.value;
      this.TYPE = data.TYPE;
    }

    return Telephone;
  },
]);
