'use strict';
/**
 * @description Directive that displays more information of a tiers
 * @example
 * <informations-complementaires-form view-configuration="informationsComplementairesDemandeurConfiguration"
 *   tiers="demande.demandeur.expand">
 * </informations-complementaires-form>
 */
angular.module('tiers').directive('informationsComplementairesForm', informationsComplementairesForm);

informationsComplementairesForm.$inject = ['jsonpatch', 'StoreService', 'configuration', 'DateHelper'];

/**
 *
 * @param {object} jsonpatch
 * @param {object} StoreService
 * @param {object} configuration
 * @param {object} DateHelper
 * @returns {object}
 */
function informationsComplementairesForm(jsonpatch, StoreService, configuration, DateHelper) {
  return {
    replace: true,
    transclude: true,
    templateUrl: 'tiers/tiers-directives/informations-complementaires-form/informations-complementaires-form.html',
    scope: {
      edit: '=',
      tiers: '=',
      viewConfiguration: '=',
      readOnly: '<',
      controleCompletudeDepot: '<',
      pageOptions: '<',
    },

    link: function (scope, formController) {
      // We made an object only with properties affected by this screen
      const selectedProperties = _.pick(scope.tiers, [
        'capitalSocial',
        'activitePrincipale',
        'appartenanceGroupe',
        'raisonSocialeGroupe',
        'comptesConsolides',
      ]);

      scope.entityToUpdate = _.cloneDeep(selectedProperties);
      if (scope.tiers.dateImmatriculation) {
        scope.dateImmatriculation = moment(scope.tiers.dateImmatriculation)
          .tz(configuration.timeZone)
          .format('YYYY-MM-DD');
      }

      scope.dateMin = '1800-01-01';
      scope.dateMax = moment().tz(configuration.timeZone).format('YYYY-MM-DD');
      scope.state = {
        dateImmatriculation: false,
      };

      // Form instance
      scope.form = formController;

      /**
       * Handle date change event
       *
       * @param {object} event
       * @returns {void}
       */
      scope.onDateChange = (event) => {
        if (!event.detail) return;

        // Save the date without hours (e.g. 2023-01-18T00:00:00.000Z)
        const newValue = DateHelper.ignoreTimeZoneOffset(moment(event.detail).tz(configuration.timeZone).toISOString());

        const patches = jsonpatch.compare(
          {
            dateImmatriculation: scope.tiers.dateImmatriculation,
          },
          {
            dateImmatriculation: newValue,
          }
        );

        patches.forEach((patch) => {
          StoreService.tiers.patches.add(patch.op, patch.path, patch.value);
        });
        scope.tiers.dateImmatriculation = newValue;
      };

      scope.$watch(
        'entityToUpdate',
        (newValue) => {
          if (newValue) {
            if (!newValue.appartenanceGroupe) {
              // Appartenance au groupe
              scope.entityToUpdate.raisonSocialeGroupe = undefined;
              scope.entityToUpdate.comptesConsolides = undefined;
            }

            const patches = jsonpatch.compare(selectedProperties, scope.entityToUpdate);

            patches.forEach((patch) => {
              StoreService.tiers.patches.add(patch.op, patch.path, patch.value);
            });
            _.merge(scope.tiers, scope.entityToUpdate);
          }
        },
        true
      );
    },
  };
}
