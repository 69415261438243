'use strict';
angular.module('aides.model').factory('Complement', function () {
  'use strict';

  /**
   * Classe Complement. Objet canonique Aide
   *
   * @param {object} data Données issues de GDD
   * @class Complement
   * @classdesc Demande de complément pour une aide
   */
  function Complement(data) {
    data = data || {};

    this.id = data.id;
    this.user = data.user || {};
    this.avancement = data.avancement;
    this.dateDemande = data.dateDemande;
    this.dateCloture = data.dateCloture;
    this.dateReponse = data.dateReponse;
    this.agent = data.agent || {};
    this.pieces = data.pieces || [];
    this.notification = data.notification || {};
    this.caracteristiques = data.caracteristiques || {};
  }

  return Complement;
});
