'use strict';
angular.module('tiers').directive('identificationInformationsEiForm', [
  '$timeout',
  'adresseService',
  'validationService',
  'accountManagementService',
  'StoreService',
  'tiersService',
  'tiersValidationService',
  'tiersFacts',
  (
    $timeout,
    adresseService,
    validationService,
    accountManagementService,
    StoreService,
    tiersService,
    tiersValidationService,
    tiersFacts
  ) => {
    return {
      restrict: 'E',
      replace: true,
      templateUrl:
        'tiers/tiers-directives/identification-form/includes/identification-informations-entrepreneur-individuel-form/identification-informations-entrepreneur-individuel.html',
      require: '^form',
      scope: {
        tiers: '<',
        mdm: '=',
        edit: '=',
        viewConfiguration: '=',
        user: '=',
        civilites: '=',
        filteredFormesJuridiques: '=',
        fullName: '=',
      },

      link: (scope, element, attrs, formController) => {
        //filtered famille configured civilites
        const familleCivilites = scope.tiers?.famille?.expand?.titresCivilites;
        const mdmCivilites = scope.mdm?.civilites?.array;

        if (familleCivilites && mdmCivilites) {
          scope.listeCivilites = mdmCivilites.filter((civilite) => {
            return familleCivilites.some((filteredCivilite) => {
              return civilite.href === filteredCivilite.href;
            });
          });
        } else {
          // defaut civilites
          scope.listeCivilites = scope.civilites || scope.mdm?.civilites?.array;
        }

        scope.form = formController;
        // famille facts
        scope.familleFacts = tiersFacts.famille(scope.tiers);
        // format nomComplet
        scope.nomCompletValid = scope.fullName.valid;
        // Check if email respect RFCs
        scope.validationService = validationService;
        scope.dateNaissancePickerConfig = {
          min: '01/01/1900',
          max: new Date(),
          culture: 'fr-FR',
        };

        // prefill informations from user (if those are empty)
        scope.tiers.fillInformationsTiersWithUser(scope.user, scope.mdm, true);

        // Initialize dateNaissance
        scope.identification = {};

        const userBirthDate = scope?.user?.birthdate;
        const userBirthPlaceName = scope?.user?.birthplaceName;
        const userBirthPlace = scope?.user?.birthplace;
        const individuDateNaissance = scope?.tiers?.individu?.Naissance?.DateNaissance;

        accountManagementService.getPublicSettings().then(function (publicSettings) {
          // check if provider user exist in list oidcProviders
          // if exist set readOnly value in the scope for birthdate and birthplace
          const userProviderId = scope.user?.providerId;
          const oidcProviders = publicSettings?.data?.oidcProviders || [];
          const provider = oidcProviders.filter((oidcProvider) => oidcProvider.providerId === userProviderId);
          if (provider.length && provider[0].isTrusted) {
            scope.isBirthplaceReadOnly = !_.isEmpty(userBirthPlaceName);
            scope.isBirthdateReadOnly = !_.isEmpty(userBirthDate);
          }
        });

        if (userBirthDate || individuDateNaissance) {
          // scope.user.birthdate is defined when user come from FranceConnect. format is YYYY-MM-DD
          // (see https://franceconnect.gouv.fr/fournisseur-service)
          // moment().toDate to keep the good day without considering timezone
          let dateNaissanceDisplay;
          if (userBirthDate) {
            dateNaissanceDisplay = moment(userBirthDate);
            _.set(scope, 'tiers.individu.Naissance.DateNaissance', new Date(userBirthDate));
            _.set(scope, 'tiers.individu.Naissance.DateNaissanceDisplay', dateNaissanceDisplay.format('DD/MM/YYYY'));
          }
          if (individuDateNaissance) {
            dateNaissanceDisplay = moment(individuDateNaissance);
            _.set(scope, 'tiers.individu.Naissance.DateNaissance', new Date(individuDateNaissance));
            _.set(scope, 'tiers.individu.Naissance.DateNaissanceDisplay', dateNaissanceDisplay.format('DD/MM/YYYY'));
          }
        }

        const birthplace = scope?.tiers?.individu?.Naissance?.LieuNaissance?.Localite?.value;
        if (!birthplace && userBirthPlaceName) {
          _.set(scope, 'tiers.individu.Naissance.LieuNaissance.Localite.value', userBirthPlaceName);
        } else if (userBirthDate && !birthplace && userBirthPlace) {
          adresseService.searchCodeInseeVille(userBirthPlace).then((ville) => {
            _.set(scope, 'tiers.individu.Naissance.LieuNaissance.Localite.value', ville);
          });
        }

        // Manage phones
        const getPhone = (phones, type) => {
          // Find phone by type
          const index = _.findIndex(phones, (phone) => {
            return phone.TYPE === type;
          });
          if (index > -1) {
            return phones[index].value;
          }
          // Backward compatibility, may be there is no type so 0 = home, 1 = cell and 2 = fax
          const position = type === 'home' ? 0 : type === 'cell' ? 1 : type === 'fax' ? 2 : -1;
          if (typeof phones[position] !== 'undefined' && !phones[position].TYPE) {
            return phones[position].value;
          }
          return '';
        };

        scope.phone = {};
        scope.phone.home = scope?.tiers?.telephones && getPhone(scope.tiers.telephones, 'home');
        scope.phone.cell = scope?.tiers?.telephones && getPhone(scope.tiers.telephones, 'cell');
        scope.phone.fax = scope?.tiers?.telephones && getPhone(scope.tiers.telephones, 'fax');

        /**
         * Allow to control civilite value and delete if it's not valued
         */
        scope.onCiviliteChange = () => {
          if (_.isEmpty(scope.tiers.civilite)) {
            delete scope.tiers.civilite;
          }
        };

        //Get custom label if tiers sans SIRET
        tiersService.getReferentielTiersI18n().then((result) => {
          scope.viewConfiguration.fields.noSiretReason.label = result.tiers.sansSiret.motifAbsence;
        });

        // If next/previous, display existing tiers in vignette
        scope.existingTiers = StoreService.existingTiers.get();
        // If SIRET does not correspond to existing tiers in store, clean existing tiers
        if (
          scope.existingTiers &&
          scope.existingTiers.SIRET &&
          scope.existingTiers.SIRET.SIREN !== scope.tiers.SIRET.SIREN &&
          scope.existingTiers.SIRET.NIC !== scope.tiers.SIRET.NIC
        ) {
          StoreService.existingTiers.clean();
          delete scope.existingTiers;
        }

        /**
         * Function called when SIRET field loses focus
         * Allows field status modifications
         *
         * @param {string} fieldName
         */
        scope.blurFieldsSiret = function (fieldName) {
          if (scope.form[fieldName].$invalid) {
            scope.fields[fieldName].status = 'error';
          } else {
            scope.fields[fieldName].status = 'success';
          }
        };

        // Method that verifies SIRET
        scope.checkSiretValidity = tiersValidationService.checkSiretValidity;

        // If SIRET or NIC can be edited, check for tiers with same SIRET and display vignette
        scope.searchExistingTiers = function (siren, nic) {
          if (scope.checkSiretValidity(siren, nic)) {
            tiersService.controlerUniciteSIRET(siren, nic).then((existingTiers) => {
              if (existingTiers.length > 0) {
                // We save the existingTiers in case of next/previous
                StoreService.existingTiers.set(existingTiers[0]);
                scope.existingTiers = existingTiers[0];
              } else {
                StoreService.existingTiers.clean();
                delete scope.existingTiers;
              }
            });
          } else {
            delete scope.existingTiers;
          }
        };

        /**
         * manage copy paste in siret (siren) field
         *
         * @param {object} event
         */
        scope.pasteSiret = function (event) {
          const pastedData = event.originalEvent.clipboardData.getData('text');
          const cleanedData = pastedData.replace(/\s/g, '');
          const toSIREN = cleanedData.substr(0, 9);
          const toNIC = cleanedData.substr(9, 5) || '';
          _.set(scope.tiers, 'SIRET.SIREN', toSIREN);
          _.set(scope.tiers, 'SIRET.NIC', toNIC);
          scope.form['SIREN'].$setDirty(true);
          scope.form['NIC'].$setDirty(true);
          $timeout(() => {
            scope.blurFieldsSiret('SIREN');
            scope.blurFieldsSiret('NIC');
            scope.searchExistingTiers(toSIREN, toNIC);
          });
        };

        // Management of the SIREN and NIC fields
        scope.fields = {
          SIREN: {
            status: undefined,
          },

          NIC: {
            status: undefined,
          },
        };

        // We use timeout for update 'scope.fields' data
        // If user press 'next' and the siret is not valid
        // The error will be displayed
        $timeout(function () {
          // the siret is composed of siren and nic
          const fields = ['SIREN', 'NIC'];
          fields.forEach((field) => {
            if (scope.form[field].$invalid) {
              scope.fields[field].status = 'error';
            } else {
              scope.fields[field].status = 'success';
            }
          });
        });

        scope.onChangeFormeJuridique = function () {
          if (scope.tiers.formeJuridique === null) {
            scope.tiers.formeJuridique = undefined;
          }
        };

        /**
         *
         * @param {object} publicSettingsTiers
         * @param {boolean} etrangere
         */
        function updateNafRequired(publicSettingsTiers, etrangere) {
          scope.nafRequired = _.get(publicSettingsTiers, 'naf.required', false) && !etrangere;

          // Gestion du NAF
          if (_.has(scope.viewConfiguration, 'fields.NAFNiv732')) {
            if (scope.nafRequired) {
              scope.viewConfiguration.fields.NAFNiv732.required = true;
              scope.viewConfiguration.fields.NAFNiv732.hidden = false;
            } else {
              scope.viewConfiguration.fields.NAFNiv732.required = false;
              if (_.isNil(scope.viewConfiguration.fields.NAFNiv732.hidden)) {
                scope.viewConfiguration.fields.NAFNiv732.hidden = false;
              }
            }
          }
        }

        if (_.has(scope.viewConfiguration, 'fields.NAFNiv732')) {
          tiersService.getPublicSettingsTiers().then(function (publicSettingsTiers) {
            scope.situationPrincipale = _.find(scope.tiers && scope.tiers.situations, { principale: true });
            const etrangere = scope.situationPrincipale && scope.situationPrincipale.etrangere;
            updateNafRequired(publicSettingsTiers, etrangere);
            scope.$watch('situationPrincipale.etrangere', (etrangere) => {
              updateNafRequired(publicSettingsTiers, etrangere);
            });
          });
        }

        scope.$watchGroup(['phone.home', 'phone.cell', 'phone.fax'], (newPhoneValue) => {
          if (scope.tiers) {
            scope.tiers.telephones = newPhoneValue
              .map((phone, index) => {
                return {
                  TYPE: index === 0 ? 'home' : index === 1 ? 'cell' : 'fax',
                  value: phone,
                };
              })
              .filter((phone) => {
                return !_.isEmpty(phone.value);
              });
          }
        });
      },
    };
  },
]);
