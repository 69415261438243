'use strict';
tiersService.$inject = [
  '$rootScope',
  '$http',
  '$q',
  '$log',
  'Tiers',
  'Coordonnees',
  'configuration',
  'Piece',
  'jwtSessionService',
  'viewsService',
  'jsonpatch',
  'tiersThematiquesService',
  'StoreService',
  'tiersFacts',
];
/**
 * @name tiers.services.tiersService
 */
angular.module('tiers.services').factory('tiersService', tiersService);

/**
 * @param {object} $rootScope
 * @param {object} $http
 * @param {object} $q
 * @param {object} $log
 * @param {object} Tiers
 * @param {object} Coordonnees
 * @param {object} configuration
 * @param {object} Piece
 * @param {object} jwtSessionService
 * @param {object} viewsService
 * @param {object} jsonpatch
 * @param {object} tiersThematiquesService
 * @param {object} StoreService
 * @param {object} tiersFacts
 * @returns {object}
 */
function tiersService(
  $rootScope,
  $http,
  $q,
  $log,
  Tiers,
  Coordonnees,
  configuration,
  Piece,
  jwtSessionService,
  viewsService,
  jsonpatch,
  tiersThematiquesService,
  StoreService,
  tiersFacts
) {
  let i18n;
  let getAllCurrentUserTiersCache;
  let publicSettings;

  /**
   * @returns {string}
   */
  function _getUrl() {
    return _.get(configuration, 'tiers.url') || '/referentiel-tiers';
  }

  /**
   * @param {object} data
   * @returns {object}
   */
  function _transformMasterData(data) {
    const parsedData = {
      raw: data,
      items: _.map(data, function (item) {
        // TODO
        // This slice is temporary! We sould get rid of these useless libelle.value as soon as we can.
        if (item.libelle && !item.libelle.value) {
          item.libelle = {
            value: item.libelle,
          };
        }

        return {
          href: item.id || item.href,
          title: item.title || _.get(item, 'libelle.value'),
          expand: item,
        };
      }),
    };

    return parsedData;
  }

  /**
   *
   * @param {string} source
   * @returns {Promise}
   */
  function _getMasterData(source) {
    return $http.get(_getUrl() + '/' + source + '?$top=1000&$orderby=title').then(function (response) {
      return _transformMasterData(response.data);
    });
  }

  /**
   * Get the famille
   *
   * @param {string} href the href
   * @param {Array<string>} expand array of property to expand
   * @returns {Promise<object>} the famille
   */
  function _getFamille(href, expand = []) {
    const url = expand.length ? `${href}?expand=${expand.join(',')}` : href;
    return $http
      .get(url, {
        headers: {
          Accept: 'application/vnd.mgdis.tiers-3.19.0+json',
          'Cache-Control': 'no-cache',
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  return {
    TIERS_KNOWN_STATUSES: Object.freeze(['SUPPORTED', 'BLOCKED', 'OBSOLETE', 'TRANSMITTED']),

    /**
     * Get the tiers id based on its reference
     *
     * @param {string} reference tiers reference
     * @returns {string} tiers id
     */
    buildIdFromReference(reference) {
      return _getUrl() + '/tiers/' + reference;
    },

    /**
     * Define a new tiers
     *
     * @param {object} tiers
     * @param {object} user
     * @param {object} mdm
     * @param {object} masterdata
     */
    changeFamilleTiers: function (tiers, user, mdm, masterdata) {
      // Keeping the already selected situation
      const situationEtrangere = _.get(tiers, 'situations[0].etrangere');
      _.extend(tiers, {
        famille: tiers.famille,
      });

      // Automatic creation of an address if the tiers doesn't have
      if (!tiers.situations || tiers.situations.length === 0) {
        tiers.situations.push(new Coordonnees());
      }

      if (user) {
        tiers.fillEmail(user);
        if (_.get(tiers, 'famille.expand.personnaliteJuridique') === 'PHYSIQUE') {
          tiers.fillInformationsTiersWithUser(user, mdm, masterdata);
        }
      }

      _.set(tiers, 'situations[0].etrangere', situationEtrangere);

      if (tiers.formeJuridique && tiers.formeJuridique.href && tiers.famille) {
        if (!tiers.famille.expand) {
          throw new Error(
            'changeFamilleTiers - tiers famille should have been expanded - ' +
              tiers.reference +
              ' - ' +
              JSON.stringify(tiers.famille)
          );
        } else if (!_.find(tiers.famille.expand.formesJuridiques, { href: tiers.formeJuridique.href })) {
          // Forme juridique doesn't exist in new famille
          delete tiers.formeJuridique;
        }
      }
      // update famille facts
      tiersFacts.famille(tiers);
    },

    getTitleTiers: function (tiers) {
      let titleTiers;
      if (tiers.raisonSociale) {
        titleTiers = tiers.raisonSociale;
      } else {
        titleTiers = _.trim(
          _.get(tiers, 'individu.Prenoms.PrenomUsuel', '') + ' ' + _.get(tiers, 'individu.Noms.NomUsage', '')
        );
      }

      return titleTiers;
    },

    /**
     * Récupération d'un tiers en fonction de sa référence
     *
     * @param {string} reference
     * @param {object} mdm Master data management
     * @param {string} expand
     * @returns {Promise}
     */
    getTiersByReference: function (reference, mdm, expand) {
      return this.getTiersById(_getUrl() + '/tiers/' + reference, mdm, expand);
    },

    /**
     * Récupération du tiers selectionné en localStorage
     *
     * @param {object} mdm Master data management
     * @param {string} expand string
     * @returns {Promise} get tiers promise
     */
    getCurrentTiers: function (mdm = {}, expand = '') {
      // Always use current recorded tiers reference in localstorage
      // when preparing our request.
      const currentTiersRef = StoreService.currentTiersRef.get(true);
      if (!currentTiersRef) {
        return $q.resolve();
      }

      const config = {
        params: {
          expand: expand || 'famille,formeJuridique',
        },
      };

      return $http
        .get(_getUrl() + '/tiers/' + currentTiersRef, config)
        .then(function (response) {
          // set cached reference
          StoreService.currentTiersRef.set(currentTiersRef);
          return new Tiers(response.data, mdm);
        })
        .catch((err) => {
          // unset invalid tiers
          StoreService.currentTiersRef.unset(true);
          // catch the error if the tiers is not found (to keep same behavior as before)
          // rethrow it otherwise
          if (err.status !== 404) {
            throw err;
          }
        });
    },

    /**
     * Get Tier by userName
     *
     * @param {string} userName
     * @returns {Promise<Array>} tiers list
     */
    getTiersByUserName(userName) {
      if (!userName) throw new Error('userName is not set');
      const config = {
        params: {
          expand: 'famille',
          $filter:
            "linkedUsers/href eq '" +
            urljoin(configuration.user.accountManagement, 'users', userName) +
            "' and (linkedUsers/form eq 'ADMINISTRATOR' or linkedUsers/form eq 'CONTRIBUTOR') and status ne 'RETURNED' and status ne 'MERGED'",
        },
      };

      return $http
        .get(_getUrl() + '/tiers', config)
        .then(function (response) {
          if (response.status !== 200) {
            throw new Error(response);
          }
          return response.data;
        })
        .catch(function (err) {
          $log.error(err);
          throw new Error('Cannot find this tier - ' + err);
        });
    },

    /**
     * Get tiers count by famille
     *
     * @returns {Promise<object>} tiers count by famille
     */
    getFamilleCount() {
      return $http
        .get(_getUrl() + '/tiers/stats/count-by-type-famille')
        .then(function (response) {
          if (response.status !== 200) {
            throw new Error(response);
          }
          StoreService.famillesTiersCount.set(response.data);
          return response.data;
        })
        .catch(function (err) {
          $log.error(err);
          throw new Error('Cannot get used familles count - ' + err);
        });
    },

    /**
     * Search tiers in referentiel (can use odata query filters)
     *
     * @param {object} params query params (can be odata query filters)
     * @returns {Promise}
     */
    findTiers(params) {
      const config = params && { params };
      return $http.get(`${_getUrl()}/tiers`, config).then(function (response) {
        return response.data;
      });
    },

    /**
     *
     * @param {string} tiersId
     * @returns {Promise<Array>} The signataires with their email
     */
    getSignataires(tiersId) {
      if (!tiersId) throw new Error('tiersId is required');
      const options = {
        params: {
          tiersId,
        },
      };

      return $http.get(configuration.tiers.signataires, options).then((response) => response.data);
    },

    cleanPatches: function (patches) {
      const invalidPaths = ['/specificData', '/dateImmatriculationDisplay', '/thematiquesLiees'];

      const validPatches = _.filter(patches, function (patch) {
        const isInvalid = _.some(invalidPaths, function (invalidPath) {
          return _.startsWith(patch.path, invalidPath);
        });
        return !isInvalid;
      });

      return validPatches;
    },

    patchTiers: function (reference, patches, mdm) {
      const cleanedPatches = this.cleanPatches(patches);

      if (reference) {
        const tiersUrl = _getUrl() + '/tiers/' + reference;
        return $http.patch(tiersUrl, cleanedPatches).then(function (response) {
          return new Tiers(response.data, mdm);
        });
      }
      throw new Error('patchTiers - tiers reference is required');
    },
    /**
     * Enregistre le tiers au niveau du serveur
     *
     * @param {object} tiers Tiers
     * @param {object} mdm Le mdm
     * @param {object} [options={}] options
     * @param {object} [options.headers] custom headers to use
     * @returns {Promise}
     */
    saveTiers: function (tiers, mdm, options = {}) {
      // Récupération d'un objet tiers nettoyé
      const tiersEntity = tiers.getCleanEntity();

      if (tiersEntity.reference) {
        // Tiers modification => PATCH
        const tiersUrl = _getUrl() + '/tiers/' + tiers.reference;
        return $http.put(tiersUrl, tiersEntity, options).then(function (response) {
          return new Tiers(response.data, mdm);
        });
      } else {
        // Tiers creation => POST
        return $http.post(_getUrl() + '/tiers', tiersEntity, options).then(function (response) {
          return new Tiers(response.data, mdm);
        });
      }
    },

    /**
     * Verify the uniqueness of tiers SIRET in existing tiers
     * Return an array of objects corresponding to the requested SIRET
     *
     * @param {string} SIREN Siren
     * @param {string} NIC  Nic
     * @param {string} famille Famille href
     * @returns {Promise}
     */
    controlerUniciteSIRET: function (SIREN, NIC, famille) {
      const query = {
        method: 'POST',
        url: urljoin(_getUrl(), '/tiers/siret'),
        data: {
          SIRET: {
            SIREN,
            NIC,
          },

          famille,
          status: 'SUPPORTED',
        },

        json: true,
      };

      return $http(query).then(function (response) {
        return response.data;
      });
    },

    getMasterData: function (sources) {
      if (typeof sources === 'string') {
        sources = [sources];
      }

      const props = {};

      _.each(sources || [], function (source) {
        props[source] = _getMasterData(source);
      });

      return Promise.props(props);
    },

    getCivilites: function () {
      return _getMasterData('titresCivilites');
    },

    getTribunauxInstances: function () {
      return _getMasterData('tribunauxInstance');
    },

    getFamilles: function () {
      return _getMasterData('familles');
    },

    /**
     * Get famille by href
     *
     * @param {string} href href
     * @param {Array<string>} expand expand
     * @returns {object} famille
     */
    getFamille: function (href, expand) {
      return _getFamille(href, expand);
    },

    /**
     * Fetch a famille, extract views from it
     *
     * @param {object} tiers the tiers
     * @param {boolean} readOnly the readOnly option
     * @param {boolean} displaySaveButton the display save button option
     * @param {number} [titleLevel=5] section legend title level
     * @returns {string} viewsIframeSrc
     */
    getViewsIframeSrcFromTiers: (tiers, readOnly, displaySaveButton, titleLevel = 5) => {
      // We don't want data-schema to manage entity if patch is sent by portail-depot
      // If displaySaveButton is truthy, we want data-schema to patch the entity
      const patchViewsTiers = !!displaySaveButton;

      const viewsIframeSrc = viewsService.getViewsIframeUrlForTiers(
        tiers,
        'demandeur',
        readOnly,
        false,
        titleLevel,
        displaySaveButton,
        ['Accept:application/vnd.mgdis.tiers-3.19.0+json', 'Content-Type:application/vnd.mgdis.tiers-3.19.0+json'],
        {
          'referentiel-tiers': [
            'Accept:application/vnd.mgdis.tiers-3.19.0+json',
            'Content-Type:application/vnd.mgdis.tiers-3.19.0+json',
          ],
        },

        patchViewsTiers
      );

      return viewsIframeSrc;
    },

    /**
     * Return true if there is at least one view active in the "tiers"
     *
     * @param {object} tiers the tiers
     * @returns {boolean} actifViews
     */
    hasActifViews: (tiers) => {
      return (tiers.views || []).some((view) => view.actif);
    },

    /**
     * Patch de la liste des pièces
     *
     * @param {string} reference
     * @param {object} tiers
     * @returns {Promise}
     */
    patchPieces: function (reference, tiers) {
      tiers.pieces = _.map(tiers.pieces || [], function (piece) {
        return new Piece(piece).getCleanEntity();
      });

      const patches = [
        {
          op: 'add',
          path: '/pieces',
          value: tiers.pieces,
        },
      ];

      return $http.patch(_getUrl() + '/tiers/' + tiers.reference, patches).then(function (response) {
        return response.data;
      });
    },
    /**
     * Allow to search a financeur privilegie
     *
     * @param {string} searchLibelle
     * @returns {Promise}
     */
    findFinanceursPrivilegie(searchLibelle) {
      // Escape quote for the odata $filter param
      searchLibelle = searchLibelle.replace(/'/g, "''");
      const config = {
        params: {
          $filter:
            `substringof(thematiquesLiees/financeur/financeurPrivilegie/libelleCourt, '${searchLibelle}') ` +
            'and thematiquesLiees/financeur/financeurPrivilegie/groupeGestion/href ne undefined',
          $orderby: 'libelleCourt asc',
          $top: 30,
        },
      };

      return $http.get(_getUrl() + '/financeurs', config).then(function (response) {
        return response.data;
      });
    },
    /**
     * Allow to get a tiers financeur by groupe gestion
     *
     * @param {string} href href of the financeur
     * @returns {Promise}
     */
    getTiersFinanceurPrivilegieByGroupeGestionHref: function (href) {
      return $http
        .get(
          _getUrl() +
            `/financeurs?filter=substringof(thematiquesLiees/financeur/financeurPrivilegie/groupeGestion/href, '${href}')`
        )
        .then(function (response) {
          return response.data[0];
        });
    },

    /**
     * Get public settings from tiers
     * ! Uses cache
     *
     * @returns {object} publicSettings
     */
    getPublicSettingsTiers: function () {
      if (publicSettings) {
        return $q.resolve(publicSettings);
      }
      return $http
        .get(configuration?.tiers?.publicSettings)
        .then(function (response) {
          publicSettings = response.data;
          return publicSettings;
        })
        .catch((err) => {
          console.error(
            '[TiersService] getPublicSettingsTiers - Error while fetching referentiel-tiers publicSettings',
            err
          );
        });
    },
    /**
     * Returns cached publicSettings from ref-tiers
     * ! Must fetch publicSettings before
     *
     * @returns {object} cached publicSettings
     */
    getCachedPublicSettings: () => {
      if (!publicSettings)
        throw new Error('[TiersService] getCachedPublicSettings - publicSettings must be fetched before');

      return publicSettings;
    },

    /**
     * Generates a href for an iframe page
     *
     * @param {string} reference tiers reference
     * @param {string} page page name (route)
     * @param {object} [options] other options to overwrite defaults
     * @returns {string}
     */
    getIframeUrl: function (reference, page, options) {
      const defaultOptions = {
        ref: reference,
        theme: !_.isNil($rootScope.specificTheme) ? 'specific' : 'demandeur',
        namespace: 'usagers.tiers',
        usagers: true,
        titleLevel: 4,
        jwtKey: jwtSessionService.getJwtKey(),
        readOnly: 'false',
      };

      const allOptions = _.merge({}, defaultOptions, options);
      const queryparams = $.param(allOptions);

      const url = configuration.tiers.ux + '#/' + page + '?' + queryparams;
      return url;
    },

    getFamilleAssociationIdentification(tiers) {
      if (_.has(tiers, 'famille.expand.associationIdentification')) {
        return $q.resolve(_.get(tiers, 'famille.expand.associationIdentification'));
      } else {
        return $http.get(`${tiers.famille.href}?merge=true`).then((response) => {
          return _.get(response, 'data.associationIdentification');
        });
      }
    },

    /**
     * Call has-similar route to verify if a tiers has doublons
     *
     * @param {object} tiers tiers object
     * @returns {Promise}
     */
    hasSimilar(tiers) {
      const url = `${_getUrl()}/tiers/${tiers.reference}/has-similar`;
      return $http.get(url).then(({ data }) => data);
    },

    /**
     * Check if it is possible to create directly a tiers 'SUPPORTED'
     *
     * @param {object} tiers tiers of the demande
     * @param {object} teleservice teleservice of the demande
     * @param {object} lastTeleservice last teleservice revision (some conditions need it)
     * @returns {Promise<boolean>} true if it is possible
     */
    canCreateSupportedTiers(tiers, teleservice, lastTeleservice) {
      const priseEnChargeAuto = _.get(lastTeleservice || teleservice, 'priseEnChargeAuto');
      const isDepotDelegue = _.get(teleservice, 'workflow.depotDelegue.actif');
      const isMultiFinanceurs = _.get(teleservice, 'multiFinanceur.active');
      const isTiersStatusValid = tiers.status === 'TEMPORARY' || tiers.status === 'TRANSMITTED';

      // fetch famille if not expanded
      return (tiers.famille.expand ? $q.resolve(tiers.famille.expand) : _getFamille(tiers.famille.href)).then(
        (famille) => {
          tiers.famille.expand = famille;

          // get famille facts
          const familleFacts = tiersFacts.famille(tiers);
          let result =
            priseEnChargeAuto &&
            !isDepotDelegue &&
            !isMultiFinanceurs &&
            familleFacts.isPhysique &&
            !familleFacts.isEI &&
            isTiersStatusValid;

          const checkIfHasSimilar = (lastTeleservice ?? teleservice)?.priseEnChargeAutoSimilitudeDemandeur;
          if (result && checkIfHasSimilar) {
            return this.hasSimilar(tiers)
              .then(({ hasSimilar }) => {
                // ! we can create a tiers in supported, ONLY if there is no doublons (similar)
                result = result && !hasSimilar;
                return result;
              })
              .catch((error) => {
                console.error('[canCreateSupportedTiers] hasSimilar', error);
                return false;
              });
          }
          return result;
        }
      );
    },

    /**
     * The documents of a tiers may be different from the configuration
     * of his famille. Thus if there are differences we correct them
     *
     * @param {object} tiers
     * @returns {object} updated pieces and value to update
     */
    mergePiecesFamilleInTiers: (tiers) => {
      // Keeping a copy of the tiers to compare it with the patch
      const clonedTiers = _.cloneDeep(tiers);

      if (_.isEmpty(clonedTiers.pieces)) {
        clonedTiers.pieces = [];
      }

      // remove expands on piece because we don't want to patch them
      clonedTiers.pieces = clonedTiers.pieces.map((piece) => {
        if (Array.isArray(piece.documents)) {
          piece.documents = piece.documents.map(({ href, id, rel }) => ({ href, id, rel }));
        }
        return piece;
      });
      const observer = jsonpatch.observe(clonedTiers);

      return _getFamille(tiers.famille.href).then((famille) => {
        const piecesFamilles = famille.pieces;
        const piecesToAdd = [];

        //? UPDATE
        // Updates the value of the libelle and the modele
        piecesFamilles.forEach((pieceFamille) => {
          _.unset(pieceFamille, 'modele.expand');

          const pieceTiers = _.find(clonedTiers.pieces, (piece) => piece.reference === pieceFamille.reference);
          if (pieceTiers) {
            const libelle = _.get(pieceFamille, 'libelle.value');
            _.set(pieceTiers, `libelle.value`, libelle);
            pieceTiers.modele = pieceFamille.modele;

            if (_.get(pieceFamille, 'description.value')) {
              pieceTiers.description = pieceFamille.description;
            } else if (pieceTiers.description) {
              delete pieceTiers.description;
            }
            pieceTiers.nature = pieceFamille.nature;
          } else {
            // pieceTiers === undefined means that pieceFamille is a new
            // piece that was added to the familly
            piecesToAdd.push(pieceFamille);
          }
        });

        //? DELETE
        // Pieces may have been removed from the familly. We have to remove them
        // from the tiers if no document has been uploaded
        clonedTiers.pieces = clonedTiers.pieces.filter((piece) => {
          const isPieceInFamilly = _.find(piecesFamilles, (pieceFamille) => piece.reference === pieceFamille.reference);
          const hasDocuments = !_.isEmpty(piece.documents);

          if (!isPieceInFamilly) {
            delete piece.modele;
          }

          return isPieceInFamilly || hasDocuments;
        });

        //? ADD
        // Pieces added to the familly, we have to add them to the tiers and to clear them
        piecesToAdd.forEach((pieceFamille) => {
          // Unset piece description if empty
          // (on the tiers, the description is not required but it must not be empty if set)
          if (!_.get(pieceFamille, 'description.value')) {
            delete pieceFamille.description;
          }
          delete pieceFamille.pieceDeReference;
          delete pieceFamille.envoiPostal;

          clonedTiers.pieces.push(pieceFamille);
        });

        let patches = jsonpatch.generate(observer);

        // add the pieces array if undefined
        if (_.isEmpty(tiers.pieces)) {
          patches = _.concat(
            [
              {
                op: 'add',
                path: '/pieces',
                value: [],
              },
            ],

            patches
          );
        }

        if (!_.isEmpty(patches)) {
          return $http.patch(tiers.id, patches).then((response) => {
            const patchedTiers = new Tiers(response.data);

            // set documents to avoid expand issues
            patchedTiers.pieces.forEach((piece) => {
              if (!piece.documents) {
                return;
              }
              const originalPiece = _.find(tiers.pieces, { reference: piece.reference });
              if (originalPiece) {
                // Copy original piece documents to get expand
                piece.documents = originalPiece.documents;
              }
            });

            return patchedTiers.pieces;
          });
        }

        return tiers.pieces;
      });
    },
    /**
     * Copy pieces from famille to tiers
     *
     * @param {object} tiers
     */
    addFamillePiecesToTiers: (tiers) => {
      const famillePieces = _.get(tiers.famille, 'expand.pieces');
      tiers.pieces = _.map(famillePieces, (piece) => {
        return _.pick(piece, ['reference', 'libelle', 'description', 'fonction', 'obligatoire', 'modele']);
      });
    },

    /**
     * Save a tiers and its thematiques
     *
     * The thematiquesLiees property does not exist in the tiers contract and is filled on fetch
     * For ease of use, it is edited as part of the tiers but it needs to be saved apart
     *
     * This method saves the tiers and its thematiques and update the thematiquesLiees property
     * on the tiers with the saved thematiques
     *
     * @param {*} tiers tiers to save
     * @returns {Promise} a promise with the saved tiers and its updated thematiques
     */
    saveTiersAndThematiques: function (tiers) {
      const thematiques = tiers.thematiquesLiees;
      delete tiers.thematiquesLiees;

      return this.saveTiers(tiers).then((savedTiers) => {
        // We loop into thematiques, generating an http call by thematique type
        const thematiquesSavePromises = _.map(thematiques, (thematiqueValue, thematiqueKey) => {
          // The api only accepts thematiques in kebabCase (currently they are in camelCase)
          const collectionName = _.kebabCase(thematiqueKey);
          return tiersThematiquesService
            .saveThematique(collectionName, thematiqueValue, savedTiers)
            .then((savedThematique) => {
              thematiques[thematiqueKey] = savedThematique;
            });
        });

        return $q.all(thematiquesSavePromises).then(() => {
          savedTiers.thematiquesLiees = thematiques;
          return savedTiers;
        });
      });
    },

    /**
     * Checking if informations-complementaires are active
     * We display them if there is at least one field to display, it can be infos-comp spec (association/entreprise)
     * or infos-comp per famille (views)
     *
     * @param {object} pageOptions
     * @param {object} teleserviceConfiguration
     * @param {object} tiers
     * @param {object} secondDemande
     * @param {string} identity
     * @returns {object}
     */
    areInfosCompTiersActive: (pageOptions, teleserviceConfiguration, tiers, secondDemande, identity = 'demandeur') => {
      if (!tiers.famille.expand) {
        throw new Error('[areInfosCompTiersActive] tiers.famille.expand must exists');
      }

      tiersFacts.famille(tiers);
      const tiersFamilleType = tiers.famille?.expand?.typeFamille;
      const isAssociation = tiersFacts.facts.famille.isAsso;
      const isEntreprise = tiersFacts.facts.famille.isEntreprise;

      // Old infos complementaire for association and entreprise
      const infosCompActive = !!pageOptions.complementaires?.actif;
      const sousSectionAssociationInfosCompActive = pageOptions.complementaires.association?.actif ?? true; // undefined is set by default, which means true
      const sousSectionEntrepriseInfosCompActive = pageOptions.complementaires.entreprise?.actif ?? true; // undefined is set by default, which means true

      // New infos complementaire with view for all tiers
      const familleHasViews = tiers.famille?.expand?.views?.some((view) => view.actif) ?? false;

      // if active entreprise with active section info-comp or association with active section info-comp display infos-comp
      // don't display for other kinds of tiers
      let isEntrepriseOrAssociationAndActive =
        ((sousSectionAssociationInfosCompActive && isAssociation) ||
          (sousSectionEntrepriseInfosCompActive && isEntreprise)) &&
        infosCompActive;

      // if there are infos-comp specs, we need to check that there is at least one field to display
      if (isEntrepriseOrAssociationAndActive) {
        const infosComplementairesFields = {
          TIERS_ENTREPRISE: ['companyCreationDate', 'capitalAmount', 'mainActivity', 'groupMembership'],
          TIERS_ASSOCIATION: ['associationCreationDate'],
        };

        const isThereAFieldToDisplay = (fields) => {
          const atLeastOneVisibleField = fields.some((field) => {
            const fieldConfiguration =
              teleserviceConfiguration?.[identity]?.[`${identity}-complementaire`]?.fields?.[field] || {};
            return !fieldConfiguration.hidden;
          });

          return atLeastOneVisibleField;
        };

        isEntrepriseOrAssociationAndActive =
          isEntrepriseOrAssociationAndActive && isThereAFieldToDisplay(infosComplementairesFields[tiersFamilleType]);
      }

      const areInfosCompActive =
        infosCompActive && (isEntrepriseOrAssociationAndActive || (familleHasViews && !secondDemande));

      return {
        isEntrepriseOrAssociationAndActive,
        areInfosCompActive,
      };
    },

    getReferentielTiersI18n: () => {
      if (i18n) {
        return $q.resolve(i18n);
      }

      return $http.get(configuration.i18n.referentielTiers).then(({ data }) => {
        i18n = data;
        return data;
      });
    },

    /**
     * Fetch the tiers referent if the option is enabled
     *
     * @param {string} tiersId id of the tiers
     * @returns {Promise<object|null>}
     */
    getTiersReferent(tiersId) {
      return this.getPublicSettingsTiers().then((publicSettings) => {
        if (!publicSettings.displayTiersReferentUsager.active) {
          return;
        }

        return $http
          .get(`${tiersId}/referent`)
          .then(({ data }) => data)
          .catch((err) => {
            if (err.status !== 404) {
              throw err;
            }
          });
      });
    },

    /**
     * Get creation steps for demandeur
     *
     * @returns {Array<string>}
     */
    getDemandeurCreationSteps() {
      return [
        'demandeur-famille',
        'demandeur-identification',
        'demandeur-adresse',
        'demandeur-representant-legal',
        'demandeur-representants',
        'demandeur-thematiques',
        'demandeur-complementaire',
      ];
    },

    /**
     * Get creation steps for beneficiaire
     *
     * @returns {Array<string>}
     */
    getBeneficiaireCreationSteps() {
      return [
        'beneficiaire-famille',
        'beneficiaire-identification',
        'beneficiaire-adresse',
        'beneficiaire-representant-legal',
        'beneficiaire-representants',
        'beneficiaire-thematiques',
        'beneficiaire-complementaire',
      ];
    },

    /**
     * Get all the tiers the user is linked to
     *
     * @param {boolean} refresh force refreshing cahed return value
     * @returns {Promise<object[]>} list of tiers the user is linked to
     */
    getAllCurrentUserTiers(refresh = false) {
      if (!getAllCurrentUserTiersCache || refresh) {
        const options = {
          from: 0,
          size: 1000,
          select: ['id', 'famille', 'reference', 'SIRET'],
          expand: ['famille'],
        };

        getAllCurrentUserTiersCache = $http.post(_getUrl() + '/tiers/mine', options).then((results) => {
          tiersFacts.multitiers(results.data.tiers);
          return results.data.tiers;
        });
      }
      return getAllCurrentUserTiersCache;
    },
    /**
     * Get tiers using its id
     *
     * @param {string} id tiers'id
     * @param {object} mdm Master data management
     * @param {object} expand tiers'properties to expand
     * @returns {Promise<object>}
     */
    getTiersById: function (id, mdm, expand) {
      const config = {
        params: {
          expand: expand || 'famille,formeJuridique',
        },
      };

      return $http.get(id, config).then(function (response) {
        return new Tiers(response.data, mdm);
      });
    },

    /**
     * Check if current user is allowed to modify tiers
     *
     * @param {string} tiersId
     * @returns {Promise<boolean>}
     */
    canModifyTiers(tiersId) {
      return $http
        .post(_getUrl() + '/admin/authorization/pdp', {
          action: 'PUT',
          resource: tiersId,
        })
        .then(function (response) {
          return _.get(response, 'data.decision') === 'permit';
        });
    },

    /**
     * Forward edit tiers event to identification-tiers iframe in order to sync displayed information
     *
     * @param {object} msg msg from child iframe
     */
    forwardEditEventsToIdentificationTiersIframe(msg) {
      const tiersIframe = angular.element('#identification-tiers')?.[0]?.contentWindow;
      if (tiersIframe) {
        const action = msg?.data?.action;

        switch (action) {
          case 'savedTiers':
            if (msg.data?.tiers) {
              tiersIframe.postMessage(
                {
                  action: 'tiersChanged',
                  tiers: msg.data.tiers,
                },
                '*'
              );
            }
            break;
          case 'thematiqueAssociationChanged':
            if (msg.data?.thematiqueAssociation) {
              tiersIframe.postMessage(
                {
                  action: 'thematiqueAssociationChanged',
                  thematiqueAssociation: msg.data.thematiqueAssociation,
                },
                '*'
              );
            }
            break;
          case 'thematiqueEtablissementChanged':
            if (msg.data?.thematiqueEtablissement) {
              tiersIframe.postMessage(
                {
                  action: 'thematiqueEtablissementChanged',
                  thematiqueEtablissement: msg.data.thematiqueEtablissement,
                },
                '*'
              );
            }
            break;
        }
      }
    },

    /**
     * Check if tiers is TEMPORARY
     *
     * @param {object} tiers
     * @returns {boolean}
     */
    isTiersTemporary: (tiers) => _.get(tiers, 'status') === 'TEMPORARY',
  };
}
