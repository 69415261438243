'use strict';
caracteristiquesController.$inject = ['$scope', 'aidesService'];
/**
 * @description Composant permettant de saisir des caractéristiques sociales
 */
angular.module('aides').component('caracteristiquesSociales', {
  require: {
    model: 'ngModel',
  },

  bindings: {
    name: '@',
    typeCaracteristique: '<',
  },

  templateUrl:
    'aides/aides-directives/types-caracteristiques-sociales/caracteristiques-sociales/caracteristiques-sociales.html',
  controller: caracteristiquesController,
});

caracteristiquesController.$inject = ['$scope', 'aidesService'];

/**
 *
 * @param {object} $scope
 * @param {object} aidesService
 */
function caracteristiquesController($scope, aidesService) {
  $scope.viewConfiguration = this.viewConfiguration;

  let vm = this;
  vm.currentValues = [];
  vm.availableValues = [];

  vm.$onInit = function () {
    vm.name = vm.name ?? 'caracteristiqueSociale';

    // Get data from v-model
    vm.model.$render = function () {
      vm.currentValues = vm.model.$viewValue.map((elt) => ({ ...elt, value: false }));
      if (!vm.currentValues) {
        vm.updateValue([]);
      }
    };

    vm.fetchAvailableValues().then(() => {
      if (vm.currentValues.length === 0) {
        // Init values to available values
        this.updateValue(vm.availableValues);
      } else {
        // Init values with "ng-model" data and set selected data based on href
        this.updateValue(
          vm.availableValues.map((elt) => ({ ...elt, value: !!vm.currentValues.find(({ href }) => href === elt.href) }))
        );
      }
    });
  };

  /**
   * Get the reference (the last element of the href)
   *
   * @param {string} href
   * @returns {string | undefined} refrerence extracted from href
   */
  vm.getReferenceFromHref = function (href) {
    return href.split('/').pop();
  };

  /**
   * Fetch available values
   *
   * @returns {Promise} Get available promise
   */
  vm.fetchAvailableValues = function () {
    return aidesService.getCaracteristiquesSociales(vm.typeCaracteristique).then(function (result) {
      vm.availableValues = _.orderBy(result, 'title').map(({ href, title }) => ({ title, value: false, href }));
    });
  };

  /**
   * Update the local state and handle "ng-model" change
   *
   * @param {object[]} value Array of values
   */
  vm.updateValue = function (value) {
    vm.currentValues = value;

    // Only set array with selected values (based on "value" property)
    const onlySelectedCurrentValues = vm.currentValues.filter(({ value }) => !!value);
    // We keep only title and href properties
    const toSetViewValue = onlySelectedCurrentValues.map(({ title, href }) => ({ title, href }));

    vm.model.$setViewValue([].concat(toSetViewValue));
  };

  /**
   * Handle the value change
   *
   * @param {object} evt Event from the mg-input-checkbox
   */
  vm.onValueChange = function (evt) {
    vm.updateValue(evt.detail);
  };
}
