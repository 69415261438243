'use strict';
angular.module('portailDepotDemandeAide.depot').service('depotSimpleService', [
  '$log',
  'configuration',
  'contributionsService',
  'userSessionService',

  function ($log, configuration, contributionsService, userSessionService) {
    /**
     * Allow to get base simple steps
     *
     * @param {object} teleserviceConfiguration
     * @returns {object}
     */
    this.getSimpleSteps = function (teleserviceConfiguration) {
      var steps = [];

      if (_.get(teleserviceConfiguration, 'workflow.pageFinanceur.actif')) {
        steps.push('financeur');
      }

      steps.push('preambule');
      if (_.get(teleserviceConfiguration, 'workflow.pageCritereEligibilite.actif')) {
        steps.push('criteresEligibilite');
      }
      steps.push('tiers');
      if (_.get(teleserviceConfiguration, 'workflow.depotDelegue.actif')) {
        steps.push('beneficiaire');
      }
      steps.push('dossier');
      steps.push('recapitulatif');

      return steps;
    };

    this.isStepAlreadyVisited = function (workflowHistory, step) {
      return _.get(workflowHistory, 'begin.metadata.stepsVisited', []).includes(step);
    };

    /**
     * Allow to get workflow step for contribution
     *
     * @param {object} teleserviceConfiguration
     * @param {Array<string>} pagesARevoir
     * @param {string} typeContribution
     * @returns {Array<object>}
     */
    this.getSimpleStepsForContribution = function (
      teleserviceConfiguration,
      pagesARevoir = [],
      typeContribution = 'MODIFICATION'
    ) {
      const steps = [
        {
          name: 'preambule',
          // contribution redirection steps should not have a specific icon
          icon: typeContribution !== 'REDIRECTION' && pagesARevoir.includes('pagePreambule'),
        },
      ];

      if (_.get(teleserviceConfiguration, 'workflow.pageCritereEligibilite.actif')) {
        steps.push({
          name: 'criteresEligibilite',
          icon: typeContribution !== 'REDIRECTION' && pagesARevoir.includes('pageCritereEligibilite'),
        });
      }

      steps.push({
        name: 'tiers',
        icon: false,
      });

      if (_.get(teleserviceConfiguration, 'workflow.depotDelegue.actif')) {
        steps.push({
          name: 'beneficiaire',
          icon: typeContribution !== 'REDIRECTION' && pagesARevoir.includes('pageInformationsBeneficiaire'),
        });
      }

      const iconDossier =
        typeContribution !== 'REDIRECTION' &&
        (pagesARevoir.includes('pageInformationsGenerales') ||
          pagesARevoir.includes('pageDocumentComptable') ||
          pagesARevoir.includes('pageFinancementAnterieur') ||
          pagesARevoir.includes('pageDomiciliationBancaire') ||
          pagesARevoir.includes('pageIndicateurs'));

      steps.push({
        name: 'dossier',
        icon: iconDossier,
      });

      steps.push({
        name: 'recapitulatif',
        icon: false,
      });

      return steps;
    };

    // Standard configurations
    this.createConfiguration = function (entity, step, simpleConfiguration, teleserviceConfiguration) {
      return _.merge(
        {
          ns: 'teleservice.' + step,
          displayCharactersLeft: true,
          remoteValidation: _.get(teleserviceConfiguration, 'controleCompletudeDepot', false),
        },

        simpleConfiguration[step],
        _.get(teleserviceConfiguration, entity + '.' + step)
      );
    };

    /**
     * Initialize the aide first history step using workflow information
     *
     * @param {object} aide
     * @param {object} initialeStep
     */
    this.initializeAideHistory = function (aide, initialeStep) {
      aide.history.begin = {
        summary: 'Creation of the entity - Status ' + aide.status,
        user: {
          href: configuration.user.accountManagement + /users/ + _.get(userSessionService.getUser(), 'userName'),
          method: 'GET',
        },

        date: new Date(),
        metadata: {
          step: initialeStep,
          stepsStack: [],
          stepsMetaStack: [],
          stepsVisited: [],
        },
      };
    };

    // === PLAID-9436 === //
    /*
     * Initiation of plan financement fields before getting to the specific page
     * We are now able to see untransmitted demandes from portail-agent if
     * an exchange has been started.
     * To display the plan financement fields properly, we have to set it from here.
     */
    this.initPlanFinancementFields = function (aide, teleserviceConfiguration) {
      var pageOptionsDocumentComptable = teleserviceConfiguration.workflow.pageDocumentComptable;

      /**
       * Select the set of data to initialize the accounting document
       * Please note that this is an early version, with a lot of arbitrary choices.
       * We intend to implement a choice of document's type(s) later.
       *
       * @param {object} pageOptionsDocumentComptable
       * @param {object} planFinancementAvance
       * @returns {object}
       */
      const createAccountingDocument = (pageOptionsDocumentComptable, planFinancementAvance) => {
        // As of 2015 february 20th, we only manage planFinancement type
        const planFinancement = angular.copy(
          _.get(pageOptionsDocumentComptable, 'typeDocumentComptable.planFinancement')
        );

        // Add typeMontant for planFinancementAvance
        if (
          (planFinancementAvance && pageOptionsDocumentComptable.typeMontant) ||
          !pageOptionsDocumentComptable.actif
        ) {
          planFinancement.typeMontant = pageOptionsDocumentComptable.typeMontant === 'HT' ? 'HT' : 'TTC';
        }
        // Remove default details in planFinancement avancé
        _.map(JSONPath(`$..lignes[?(@.details)]`, planFinancement), (ligne) => {
          _.unset(ligne, 'details');
        });
        return planFinancement;
      };

      /**
       *
       * @param {object} pf
       * @returns {boolean}
       */
      function hasOnlyFictiveFinancialLine(pf) {
        return (
          !pf.depense &&
          _.get(pf, 'recette.postes', []).length === 1 &&
          _.get(pf, 'recette.postes.0.reference') === 'MGS_POSTE_FICTIF' &&
          _.get(pf, 'recette.postes.0.lignes', []).length === 1 &&
          _.get(pf, 'recette.postes.0.lignes.0.reference') === 'MGS_LIGNE_FICTIVE'
        );
      }

      /**
       *
       * @param {object} documentComptable
       */
      function setLignes(documentComptable) {
        _.each(documentComptable.postes, function (poste) {
          _.each(poste.lignes, function (ligne) {
            if (angular.isUndefined(ligne.visible)) {
              ligne.visible = !ligne.optionnel;
            }
          });
        });
      }

      if (!aide.planFinancement[0]) {
        aide.planFinancement[0] = createAccountingDocument(
          pageOptionsDocumentComptable,
          teleserviceConfiguration.planFinancementAvance
        );
      } else if (hasOnlyFictiveFinancialLine(aide.planFinancement[0])) {
        aide.planFinancement[0] = createAccountingDocument(
          pageOptionsDocumentComptable,
          teleserviceConfiguration.planFinancementAvance
        );
      } else if (_.get(teleserviceConfiguration, 'workflow.pageFinanceur.actif', false) && aide.financeurPrincipal) {
        // Allow to change the "financeurPrincipal" if we select a new one on the pageFinanceur
        var pageOptionsFinanceurComptable = teleserviceConfiguration.workflow.pageFinanceur;
        const sousPosteFinanceurPrincipal = _.get(pageOptionsFinanceurComptable, 'posteRecette');
        const sousPostesFinanceurPrincipal = JSONPath(
          `$.recette..sousPostes[?(@.reference=="${sousPosteFinanceurPrincipal}")]`,
          aide.planFinancement[0]
        );

        _.forEach(sousPostesFinanceurPrincipal, (sousPoste) => {
          if (_.isEmpty(sousPoste.lignes)) {
            sousPoste.lignes = [
              {
                commentaire: '',
                cumulable: true,
                financement: {
                  financeur: aide.financeurPrincipal,
                },

                libelle: { value: aide.financeurPrincipal.title },
                montant: {},
                obligatoire: true,
                optionnel: false,
                reference: shortid.gen(),
                visible: true,
              },
            ];
          } else {
            _.set(sousPoste.lignes, '0.financement.financeur', aide.financeurPrincipal);
            _.set(sousPoste.lignes, '0.libelle.value', aide.financeurPrincipal.title);
          }
        });
      }

      if (aide.planFinancement[0].depense) {
        setLignes(aide.planFinancement[0].depense);
      }
      if (aide.planFinancement[0].recette) {
        setLignes(aide.planFinancement[0].recette);
      }
    };

    /**
     * Get fullName validity
     *
     * @param {object} name
     * @returns {object}
     */
    this.getFullNameValidity = (name = {}) => {
      return {
        valid: ['honorificPrefix', 'givenName', 'familyName'].every((key) => name[key]),
      };
    };
  },
]);
