'use strict';
angular.module('aides.recapitulatif').directive('recapitulatifTiers', [
  function () {
    'use strict';

    return {
      replace: true,
      templateUrl: 'aides/aides-directives/recapitulatif/tiers/recapitulatif-tiers.html',
      scope: {
        user: '=',
        tiers: '=',
        namespace: '=',
        sectionAdresseContacts: '<',
        hideFranceCountry: '<',
      },

      link: function (scope) {
        scope.viewConfiguration = {
          ns: scope.namespace,
        };

        scope.activeRepresentants = _.filter(scope.tiers.representants, { actif: true });
      },
    };
  },
]);
