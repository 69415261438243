'use strict';
angular.module('contributions.constants', []).constant('contributionsConstants', {
  status: {
    WAITING_FOR_CERTIFICATE: 'WAITING_FOR_CERTIFICATE',
    ANSWERED: 'ANSWERED',
    REQUESTED: 'REQUESTED',
  },
  type: {
    MODIFICATION: 'MODIFICATION',
  },
});
