('use strict');

/**
 * @param {object} types
 */
function contributionsRecapitulatifController(types) {
  this.$onInit = () => {
    this.SollicitationsTypes = types.SollicitationsTypes;
  };

  this.isPiecesJustificativesActive = () => {
    return this.teleservice?.workflow?.contributionAvis?.pagePieces?.actif;
  };
}

contributionsRecapitulatifController.$inject = ['types'];

angular.module('portailDepotDemandeAide.dashboard').component('contributionsRecapitulatif', {
  templateUrl: 'dashboard/contributions/contributions-recapitulatif/contributions-recapitulatif.html',
  controller: contributionsRecapitulatifController,
  bindings: {
    contribution: '=',
    contributionConfiguration: '=',
    avisConfiguration: '=',
    piecesConfiguration: '=',
    teleservice: '=',
  },
});
