'use strict';

/**
 * @module common
 * @name userMenu
 * @description
 *
 *   Menu user / tiers
 * @example
 *
 *   <user-menu></user-menu>
 */
class UserMenuController {
  constructor(
    $timeout,
    $rootScope,
    $scope,
    $state,
    $q,
    $document,
    configuration,
    sessionServiceAPI,
    accountManagementAPI,
    tiersServiceAPI,
    types,
    AUTHENTICATION_EVENTS
  ) {
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.$q = $q;
    this.$document = $document;
    this.timeZone = configuration.timeZone;
    this.sessionServiceAPI = sessionServiceAPI;
    this.accountManagementAPI = accountManagementAPI;
    this.tiersServiceAPI = tiersServiceAPI;
    this.types = types;
    this.AUTHENTICATION_EVENTS = AUTHENTICATION_EVENTS;
  }

  /**
   * Initialize component
   *
   * @returns {void}
   */
  $onInit() {
    this.loaded = false;
    this.formattedTiers = {};

    /**
     * Close the user-menu
     *
     * @returns {void}
     */
    const closeMenu = () => {
      // toggle the expanded state to close it
      this.expanded = true;
      this.$timeout(() => {
        this.expanded = false;
      });
    };

    /**
     * Select all links and buttons from the user-menu
     *
     * @returns {Array<object>} array of links and buttons
     */
    const getInteractiveElements = () => {
      return [
        ...this.$document[0].getElementsByTagName('a'),
        ...this.$document[0].getElementsByTagName('mg-button'),
      ].filter(({ id }) => id.startsWith('user-menu-'));
    };

    this.$q.all([this.getCurrentUser(), this.getFormattedTiers()]).finally(() => {
      this.setAccountIllustration();
      this.loaded = true;

      this.$timeout(() => {
        // Add event listener on all user-menu links to close the menu on click
        getInteractiveElements().forEach((element) => element.addEventListener('click', closeMenu));
      });
    });
    this.$rootScope.$on('update-tiers', () => this.getCurrentTiers(true));

    this.expanded = false;

    this.$scope.$on('$destroy', () => {
      getInteractiveElements().forEach((element) => element.removeEventListener('click', closeMenu));
    });
  }

  /**
   * Get formatted tiers
   *
   * @returns {Promise<void>}
   */
  getFormattedTiers() {
    let cleanedTiers;
    if (this.tiers) {
      const familleExpand = this.tiers.famille.expand;
      cleanedTiers = this.tiers.getCleanEntity();
      // add famille expand that is required by getCurrentTiersInfos
      cleanedTiers.famille.expand = familleExpand;
    }

    // format tiers
    return this.tiersServiceAPI
      .getCurrentTiersInfos(cleanedTiers, this.famillesCount)
      .then(({ response: formattedTiers }) => {
        this.formattedTiers = formattedTiers;
      });
  }

  /**
   * Get current user
   *
   * @returns {void}
   */
  getCurrentUser() {
    return this.accountManagementAPI.getCurrentUserInfos().then(({ response: user }) => {
      if (user) {
        this.user = user;
      }
    });
  }

  /**
   * Get current tiers
   *
   * @param {boolean} [update=false]
   * @returns {void}
   */
  getCurrentTiers(update = false) {
    return this.tiersServiceAPI.getCurrentTiersInfos().then(({ response: formattedTiers }) => {
      this.formattedTiers = formattedTiers;
      if (update) this.$scope.$apply();
    });
  }

  /**
   * Logout
   *
   * @returns {void}
   */
  logout() {
    // Pass the token trought the logout event so the portail controller can deal with it
    return this.sessionServiceAPI.getToken().then((token) => {
      this.sessionServiceAPI.logout().then(() => {
        this.$rootScope.$broadcast(this.AUTHENTICATION_EVENTS.logoutSuccess, { token });
      });
    });
  }

  /**
   * Go to tiers-selection route
   *
   * @returns {void}
   */
  changeTiers() {
    this.$state.go('app.connected.tiers-selection', {
      redirectTo: this.$state.current?.name,
      redirectParams: JSON.stringify(this.$state.params),
      redirectFrom: 'portal',
    });
  }

  /**
   * Set illustration to be displayed
   *
   * @returns {void}
   */
  setAccountIllustration() {
    this.isImg = true;
    if (this.user.franceConnect) {
      this.illustration = './resources/images/fc-avatar.svg';
      return;
    }
    if (this.user.providerInfos?.logoUser) {
      this.isImg = true;
      this.illustration = this.user.providerInfos?.logoUser;
      return;
    }

    this.isImg = false;
    if (
      !this.formattedTiers?.personnaliteJuridique ||
      this.formattedTiers.personnaliteJuridique === this.types.TiersPersonnaliteJuridique.PHYSIQUE
    ) {
      this.illustration = 'common/common-components/user-menu/svg/tiers-physique.svg';
      return;
    }

    this.illustration = 'common/common-components/user-menu/svg/tiers-moral.svg';
  }
}

UserMenuController.$inject = [
  '$timeout',
  '$rootScope',
  '$scope',
  '$state',
  '$q',
  '$document',
  'configuration',
  'sessionServiceAPI',
  'accountManagementAPI',
  'tiersServiceAPI',
  'types',
  'AUTHENTICATION_EVENTS',
];

angular.module('common.components').component('userMenu', {
  templateUrl: 'common/common-components/user-menu/user-menu.component.html',
  controller: UserMenuController,
  bindings: {
    tiers: '<',
    famillesCount: '<',
  },
});
