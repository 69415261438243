'use strict';
angular.module('portailDepotDemandeAide.dashboard').controller('dashboardDemandesComplementPiecesController', [
  '$scope',
  '$rootScope',
  '$modal',
  '$log',
  'aide',
  'teleservice',
  'persistences',
  'aidesService',
  'complementsService',
  'alertsService',
  'dataCollectPersistenceService',
  'aideComplementsConfiguration',
  'Piece',
  '$state',
  'publicSettingsFinancement',
  'tiersService',
  '$stateParams',
  'previousState',
  'piecesService',
  '$q',
  'cmisService',
  function (
    $scope,
    $rootScope,
    $modal,
    $log,
    aide,
    teleservice,
    persistences,
    aidesService,
    complementsService,
    alertsService,
    dataCollectPersistenceService,
    aideComplementsConfiguration,
    Piece,
    $state,
    publicSettingsFinancement,
    tiersService,
    $stateParams,
    previousState,
    piecesService,
    $q,
    cmisService
  ) {
    'use strict';

    // set setting parameter for 1 document by piece
    $scope.unDocumentParPiece = _.get(publicSettingsFinancement, 'unDocumentParPiece', false);

    // Disable button when a document is uploading
    $scope.isUploadInProgress = false;

    $scope.hasBeenValidated = false;

    $scope.goBack = function () {
      switch (_.get(previousState, 'name')) {
        case 'app.connected.dashboard.recapitulatif':
        case 'app.connected.dashboard.sollicitations':
          $state.go(previousState.name, previousState.params);
          break;
        default:
          //We reload the parent state to refresh the aides list and its demandes complements
          $state.go(
            'app.connected.dashboard.aides.demandesAides',
            { page: $stateParams.page },
            { reload: 'app.connected.dashboard.aides' }
          );
      }
    };

    // Acquiring complements
    $scope.complements = _.map(aide.demandesComplementPieces, 'expand');

    $scope.updateAide = function (patches) {
      aidesService.patchAide(aide, patches);
    };

    // List of complements persistences
    var complementsPersistences = _.filter(persistences, {
      configurationId: 'complement',
      state: 'active',
    });

    var teleserviceWorkflow = teleservice.workflow[teleservice.workflow.type];

    /**
     * Prepares the persistence to fill the complement request with new files
     *
     * @param  {object} complement
     */
    $scope.fillComplement = function (complement) {
      // Getting or creating the persistence associated to this complement
      var persistence = _.find(complementsPersistences, function (persistence) {
        return _.get(persistence, 'content.complementId') === complement.id;
      });
      if (!persistence) {
        persistence = {
          configurationId: 'complement',
          content: {
            complementId: complement.id,
          },
        };

        // Copying aides' pieces into the persistence
        persistence.content.pieces = _.map(complement.pieces, function (piece) {
          var pieceVal = _.find(aide.pieces, {
            reference: piece.reference,
          });

          var pieceConf = _.find(teleserviceWorkflow.pagePieces.modelesPieces, {
            reference: piece.reference,
          });

          return new Piece(_.merge({}, pieceConf, pieceVal));
        });

        // The persistence need to be registered in order to have an id
        dataCollectPersistenceService.progressOrCreate(persistence, 'complement').then(function () {
          complementsPersistences.push(persistence);
          $scope.openModalComplement(persistence);
        });
      } else {
        $scope.openModalComplement(persistence);
      }
    };

    // Disable the validate button when a documents is uploading
    $scope.disableValidateButton = function (disable) {
      $scope.isUploadInProgress = disable;
    };

    /**
     * Open the modal of piece-form to fill the complement
     *
     * @param  {object} persistence
     */
    $scope.openModalComplement = function (persistence) {
      // Disable button to prevent double clic
      $scope.hasBeenValidated = false;

      const scopeModal = $scope.$new();

      // Configuration and functions
      scopeModal.aide = aide;
      scopeModal.persistence = persistence;
      scopeModal.complementsConfiguration = angular.copy(aideComplementsConfiguration);
      scopeModal.complementsConfiguration.ns = 'connected.dashboard.aides.complements.fill-modal';
      scopeModal.disableValidateButton = $scope.disableValidateButton;

      // The size limit has to be converted into Mb for the directive
      scopeModal.complementsConfiguration.maxDocumentSize =
        scopeModal.complementsConfiguration.maxDocumentSize / 1024 / 1024;
      scopeModal.complementsConfiguration.allowedExtensions = scopeModal.complementsConfiguration.allowedExtension;
      scopeModal.persistenceConfiguration = _.get(
        teleservice,
        'persistenceConfiguration.expand',
        scopeModal.complementsConfiguration
      );

      scopeModal.mdm = $scope.mdm;
      scopeModal.updatePiece = $scope.updatePiece;
      scopeModal.tiers = _.get(aide, 'beneficiaires[0].expand');
      scopeModal.sendComplements = $scope.sendComplements;
      scopeModal.onDocumentUploaded = $scope.onDocumentUploaded;

      scopeModal.updateAide = $scope.updateAide;

      // Url where to upload files
      scopeModal.urlDocuments = aide._links['mgs:documents'].href;

      // Directory of file's icons
      scopeModal.urlFileIcons = './resources/images/file-icons/';

      // set setting parameter for 1 document by piece
      scopeModal.unDocumentParPiece = _.get(publicSettingsFinancement, 'unDocumentParPiece', false);

      scopeModal.unDocumentParPieceAlert = [];
      if (scopeModal.unDocumentParPiece) {
        // Display alert if Activated
        scopeModal.unDocumentParPieceAlert.push(
          alertsService.getAlertWarning('aides.depotpieces.informationsLimitaionDocument.content')[0]
        );
      }

      // Opens the modal
      $modal({
        scope: scopeModal,
        template: 'dashboard/aides/demandes-complement-pieces/modal/modal-complements.html',
        backdrop: 'static',
      });
    };

    $scope.hrefDocumentsAdded = [];
    $scope.onDocumentUploaded = function (doc) {
      if (doc) $scope.hrefDocumentsAdded.push(doc.href);
    };

    /**
     * On the update of a complement
     *
     * @param {object} persistence
     */
    $scope.updatePiece = function (persistence) {
      dataCollectPersistenceService.progressOrCreate(persistence, persistence.configurationId);
    };

    /**
     * clone and save piece in the tiers if the piece exist in the 'famille'
     *
     * @returns {Promise | void}
     */
    function copyPiecesIntoTiers() {
      if (_.isEmpty($scope.hrefDocumentsAdded)) {
        return;
      }
      return tiersService
        .getCurrentTiers({}, 'famille')
        .then((tiers) => piecesService.copyAidePiecesInTiersPieces(aide, tiers, $scope.hrefDocumentsAdded))
        .then((tiers) => {
          $scope.hrefDocumentsAdded = [];
          return tiersService.saveTiers(tiers);
        });
    }

    /**
     * Copy the new files into aide
     *
     * @param {object[]} contentPieces
     */
    function copyPersistencePieceInAide(contentPieces) {
      contentPieces.forEach((piece) => {
        if (piece.documents && piece.documents.length > 0) {
          aide.pieces.forEach((TSpiece) => {
            if (TSpiece.reference === piece.reference) {
              TSpiece.documents = piece.documents;
            }
          });
        }
      });
    }
    /**
     * Validate the selected files
     *
     * @param {object} persistence
     * @param {object} modal
     */
    $scope.sendComplements = function (persistence, modal) {
      $scope.hasBeenValidated = true;

      // Replacing aides' pieces with ones from the complement's persistence
      var complement = _.find(aide.demandesComplementPieces, function (complementLink) {
        return _.get(complementLink, 'expand.id') === persistence.content.complementId;
      }).expand;
      complement.caracteristiques = complement.caracteristiques || {};
      complement.caracteristiques.referenceEntite = aide.reference;

      // Updating complement
      complementsService
        .putAvancement(complement, 'REPONSE_DEMANDEUR')
        .then(function (updatedComplement) {
          _.remove($scope.complements, {
            id: complement.id,
          });

          $scope.complements.push(updatedComplement);
          return dataCollectPersistenceService.submit(persistence._id, 'complement', persistence.content);
        })
        .then(function () {
          copyPersistencePieceInAide(persistence.content.pieces);

          return copyPiecesIntoTiers();
        })
        .then(function () {
          modal.$hide();
          $scope.$broadcast(
            'alerts',
            alertsService.getAlertSuccess('connected.dashboard.aides.complements.validation-success'),
            'complements-success'
          );
          $rootScope.$broadcast('refresh-menu');
        })
        .catch(function (e) {
          $log.error('[dashboardDemandesComplementPiecesController] sendComplements', e);
          modal.$broadcast(
            'alerts',
            alertsService.getAlertError('connected.dashboard.aides.complements.fill-modal.error.unknow'),
            'complements-error'
          );
        });
    };

    /**
     * Méthode d'affichage de la date de la demande en fonction du statut
     *
     * @param {object} demandeComplement
     * @returns {string}
     */
    $scope.getDateAffichage = function (demandeComplement) {
      var avancement = _.get(demandeComplement, 'avancement');
      var dateReponse = _.get(demandeComplement, 'dateReponse');
      var dateCloture = _.get(demandeComplement, 'dateCloture');
      var dateDemande = _.get(demandeComplement, 'dateDemande');

      return avancement === 'REPONSE_DEMANDEUR' ? dateReponse : avancement === 'CLOTURE' ? dateCloture : dateDemande;
    };

    this.$onInit = () => {
      const tiersPieces = aide?.beneficiaires?.[0]?.expand?.pieces;
      if (!Array.isArray(tiersPieces)) {
        return $q.resolve();
      }
      return $q
        .all(
          // expand documents (porte-documents needs them)
          cmisService.expandAllPiecesDocuments(tiersPieces)
        )
        .catch((error) => {
          $log.error('(demande-complement-pieces-controller) $onInit error', error);
        });
    };
  },
]);
