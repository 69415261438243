'use strict';
ContributionAvisFinancementStepComponent.$inject = [
  '$scope',
  '$translate',
  'aidesService',
  'contributionsService',
  'jsonpatch',
]; // Module declaration
angular.module('portailDepotDemandeAide.depot').component('contributionAvisFinancementStepComponent', {
  templateUrl: 'depot/contributions-avis/avis-financement/contributions-avis-financement-step.component.html',
  controller: /* @ngInject */ ContributionAvisFinancementStepComponent,
  bindings: {
    contribution: '<',
    navigationHelper: '<',
    viewConfiguration: '<',
    teleserviceConfiguration: '<',
  },
});

/**
 * Controller
 *
 * @param {object} $scope
 * @param {object} $translate
 * @param {object} aidesService
 * @param {object} contributionsService
 * @param {object} jsonpatch
 */
function ContributionAvisFinancementStepComponent($scope, $translate, aidesService, contributionsService, jsonpatch) {
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.disableNavigation = false;
    // Define navigationDelegate
    ctrl.navigationDelegate = {
      nextButton: {
        title: $translate.instant('contributions.common.next'),
        action: ctrl.nextStep,
      },

      previousButton: {
        title: $translate.instant('contributions.common.previous'),
        action: ctrl.previousStep,
      },
    };

    // Define default values
    $scope.viewConfiguration = ctrl.viewConfiguration;

    ctrl.montantDemande =
      _.get(ctrl.contribution, 'montantDemande.ttc') || _.get(ctrl.contribution, 'montantDemande.ht');
    const demande = _.get(ctrl.contribution, 'demande');

    // we can have a duration only if we are in multi-year shared multi-financers funding request
    // (cannot use the usual check because we do not have the deposit teleservice to get its config)
    ctrl.isPluriannuelleMultiFinanceurPartage = _.isNumber(demande.duree);

    ctrl.contributionAnswer = {
      date: _.get(ctrl.contribution, 'avis.date'),
      montant: _.get(ctrl.contribution, 'avis.montant.ttc') || _.get(ctrl.contribution, 'avis.montant.ht'),
      valeur: _.get(ctrl.contribution, 'avis.valeur'),
      motif: _.get(ctrl.contribution, 'avis.motif.href'),
      commentaire: _.get(ctrl.contribution, 'avis.commentaire'),
    };

    if (ctrl.isPluriannuelleMultiFinanceurPartage) {
      ctrl.contributionAnswer.accordPluriannuel = true;

      const cpoFields = ['exercice', 'accordPluriannuel'];

      if (Array.isArray($scope.viewConfiguration.fields)) {
        cpoFields.forEach((fieldReference) => {
          // if there is no value for the field, add default value
          const hasField = $scope.viewConfiguration.fields.some(({ reference }) => reference === fieldReference);
          if (!hasField) {
            $scope.viewConfiguration.fields.push({
              reference: fieldReference,
              label: $translate.instant(`contributions.avisFinancement.${fieldReference}`),
            });
          }
        });
      }
    }

    // add date formatt,er
    if (_.get(ctrl.contribution, 'avis.date')) {
      ctrl.contributionAnswer.date = new Date(ctrl.contribution.avis.date);
      ctrl.contributionAnswer.dateDisplay = moment(ctrl.contribution.avis.date).format('DD/MM/YYYY');
    }

    // Values for form
    ctrl.contributionAvis = [
      {
        name: 'RECEVABLE',
        value: 'FAVORABLE',
      },

      {
        name: 'IRRECEVABLE',
        value: 'DEFAVORABLE',
      },
    ];

    ctrl.accordPluriannuel = [
      { name: $translate.instant('contributions.common.yes'), value: true },
      { name: $translate.instant('contributions.common.no'), value: false },
    ];

    contributionsService.getMotifsForContribution(ctrl.contribution).then(function (motifsAvis) {
      ctrl.motifsAvis = motifsAvis;
      ctrl.onAvisChange();
    });

    ctrl.datePickerConfig = {
      min: '01/01/1900',
      max: new Date(),
      culture: 'fr-FR',
    };

    ctrl.viewConfiguration.fields = _.reduce(
      ctrl.viewConfiguration.fields,
      function (property, field) {
        property[field.reference] = field;
        return property;
      },
      {}
    );

    _.set(ctrl, 'viewConfiguration.fields.commentaire.help', $translate.instant('teleservice.avis.commentaire.rgpd'));
    _.set(ctrl, 'viewConfiguration.fields.commentaire.helpTooltip', true);

    // If the funding request is multi-year with multiple financers, the accordPluriannuel field is required
    if (ctrl.isPluriannuelleMultiFinanceurPartage) {
      _.set(ctrl, 'viewConfiguration.fields.accordPluriannuel.required', true);
    }
  };

  // Navigation delegate
  ctrl.previousStep = function () {
    // we stop process if loading in progress
    if (ctrl.disableNavigation) return;
    ctrl.disableNavigation = true;

    // we use debounce to render immediatly loading value in the template and disable the submit button
    _.debounce(() => {
      ctrl
        .save()
        .then(function () {
          ctrl.navigationHelper.goToPreviousStep();
        })
        .catch((err) => {
          ctrl.disableNavigation = false;
          throw err;
        });
    }, 250)();
  };

  ctrl.nextStep = function () {
    if ($scope.contributionAvisFinancementForm.$valid && ctrl.contributionAnswer.montant <= ctrl.montantDemande) {
      // we stop process if loading in progress
      if (ctrl.disableNavigation) return;
      ctrl.disableNavigation = true;

      // we use debounce to render immediatly loading value in the template and disable the submit button
      _.debounce(() => {
        ctrl
          .save()
          .then(function () {
            ctrl.navigationHelper.goToNextStep();
          })
          .catch((err) => {
            ctrl.disableNavigation = false;
            throw err;
          });
      }, 250)();
    }
  };

  ctrl.save = function () {
    // Apply answer edit to new contribution
    const entity = _.cloneDeep(ctrl.contributionAnswer);
    const observe = jsonpatch.observe(entity);

    _.set(entity, 'avis.user', entity.user);

    if (ctrl.contribution.montantDemande.ttc) {
      entity.avis.montant = { ttc: ctrl.contributionAnswer.montant };
    } else {
      entity.avis.montant = { ht: ctrl.contributionAnswer.montant };
    }

    entity.avis.valeur = ctrl.contributionAnswer.valeur;
    entity.avis.date = ctrl.contributionAnswer.date;
    entity.avis.commentaire = ctrl.contributionAnswer.commentaire;
    entity.avis.accordPluriannuel = ctrl.contributionAnswer.accordPluriannuel;
    const patches = jsonpatch.generate(observe);
    return contributionsService.updateContributionAndSendResultToParent(ctrl.contribution.reference, patches);
  };

  // Events
  ctrl.onAvisChange = function () {
    if (ctrl.contribution.valeur === 'FAVORABLE') {
      ctrl.currentMotifsAvis = _.get(ctrl, 'motifsAvis.motifsAvisFavorable', []);
    }
    if (ctrl.contribution.valeur === 'DEFAVORABLE') {
      ctrl.currentMotifsAvis = _.get(ctrl, 'motifsAvis.motifsAvisDefavorable', []);
    }

    // Update configuration validation for motif
    const motifsConfiguration = _.get(ctrl.viewConfiguration.fields, 'motif');
    _.set(motifsConfiguration, 'dataType', 'enumeration');
    const restrictions = [
      {
        enumeration: {
          item: ctrl.currentMotifsAvis,
        },
      },
    ];

    _.set(motifsConfiguration, 'restrictions', restrictions);
  };
}
