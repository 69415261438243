'use strict';
/**
 * @param {object} $http
 * @param {object} $log
 * @param {object} $q
 * @param {object} cmisService
 * @param {object} dataDocService
 * @param {object} Piece
 * @param {object} aidesService
 * @param {object} contributionsService
 * @param {object} configuration
 * @param {object} translateService
 * @name common.services.piecesService
 * @description Interface for a Tiers' pieces
 * @returns {object}
 */
angular.module('common.services').factory('piecesService', [
  '$http',
  '$log',
  '$q',
  'cmisService',
  'dataDocService',
  'Piece',
  'aidesService',
  'contributionsService',
  'configuration',
  'translateService',
  function (
    $http,
    $log,
    $q,
    cmisService,
    dataDocService,
    Piece,
    aidesService,
    contributionsService,
    configuration,
    translateService
  ) {
    'use strict';

    const baseUrlReferentielPiece = configuration.referentielPiece.service;

    /**
     * Utils function dedicated to integration which permit to find a property in a collection by his reference
     * Use case : find(aide.specifiques, 'reference', 'RefDeMonChamp').value
     * (NB : .value  if it's an object to get the value)
     *
     * @param {object[]} collection
     * @param {string} property
     * @param {string} value
     * @returns {object}
     */
    var find = function (collection, property, value) {
      return _.find(collection, function (item) {
        return _.get(item, property) === value;
      });
    };

    /**
     * Utils function dedicated to integration which permit to get a specific field or not in an array
     * Use case : getSpecificValue(aide.views, 'REFDEMONFORMULAIRE', 'RefDeMonChamp').value
     * (NB : if it's an object: ".value" to get the value, ".title" to get the title, etc..)
     *
     * @param {object[]} collection
     * @param {string} viewReference
     * @param {string} fieldReference
     * @returns {object}
     */
    var getSpecificValue = function (collection, viewReference, fieldReference) {
      if (!collection || !viewReference || !fieldReference) return undefined;
      return _.get(
        _.find(collection, function (item) {
          return (
            _.get(item, 'expand.reference ') === viewReference ||
            _.get(item, 'schema.href', '?').split('?').shift().split('/').pop() === viewReference
          );
        }),
        'values[0].' + fieldReference
      );
    };

    /**
     * Initialize piece persistence.
     *
     * @param {object} pieceConfiguration piece configuration
     * @param  {Array} piecesPersistence   Pieces in persistence
     * @returns {object} Piece
     */
    const initializePiecePersistence = function (pieceConfiguration, piecesPersistence) {
      // Find the piece in persistence corresponding to this piece's configuration
      const piecePersistence = piecesPersistence?.find((piece) => piece.reference === pieceConfiguration.reference);

      if (piecePersistence && pieceConfiguration.typesPaiement) {
        piecePersistence.typesPaiement = angular.copy(pieceConfiguration.typesPaiement);
      }

      const pieceParametree = new Piece(_.merge(piecePersistence, pieceConfiguration));
      // Récupération du paramétrage de l'envoi postal depuis le paramétrage du téléservice
      pieceParametree.envoiPostal = pieceConfiguration.envoiPostal || false;

      return pieceParametree;
    };

    return {
      /**
       * Return the url of service documents persistence
       *
       * @param {string} persistenceId
       * @param {string} configurationId
       * @returns {Promise}
       */
      getUrlUploadDocumentsPersistence: function (persistenceId, configurationId) {
        return dataDocService.getUrlServiceDocumentsPersistence(persistenceId, configurationId);
      },

      initializePiecePersistence,

      /**
       * Initialize pieces persistence.
       *
       * @param  {Array} piecesConfiguration Pieces's configuration
       * @param  {Array} piecesPersistence   Pieces in persistence
       * @returns {Array}                     Pieces
       */
      initializePiecesPersistence: function (piecesConfiguration, piecesPersistence) {
        const pieces = piecesConfiguration?.map((pieceConfiguration) =>
          initializePiecePersistence(pieceConfiguration, piecesPersistence)
        );
        // The alphabetic sort is no longer applied here
        return pieces;
      },

      /**
       * List of all the piece's references which are not on the demande but on the teleservice
       *
       * @param {Array<object>} piecesFromTS pieces on pagePiece
       * @param {Array<object>} piecesFromDemande pieces on the demande
       * @returns {Array<string>} list of piece references
       */
      piecesNotInDemandeButInTS: function (piecesFromTS, piecesFromDemande) {
        // warning : do not filter on 'fonction "depot"' because we need to create all pieces in the demande
        const piecesFromTSReferences = piecesFromTS.map((piece) => piece.reference);

        const piecesFromDemandeReferences = piecesFromDemande.map((piece) => piece.reference);

        return piecesFromTSReferences.filter((piece) => !piecesFromDemandeReferences.includes(piece));
      },

      /**
       * Function who return the list documents, document who are not attach to a piece.
       *
       * @param  {Array} pieces           Pieces
       * @param  {string} urlDocuments    Url for get the list of documents and use for delete an orphan document
       * @returns {Promise}                Promise for recover a list of id of document to delete
       */
      getDocumentsPersistenceToDelete: function (pieces, urlDocuments) {
        var documentsIdPieces = [];
        _.each(pieces, function (piece) {
          _.each(piece.documents, function (documentPiece) {
            documentsIdPieces.push(_.get(documentPiece, "expand.properties['cmis:objectId'].value"));
          });
        });

        var documentsId = [];
        return $http.get(urlDocuments).then(function (response) {
          // Datacollect-persistence
          _.each(response.data.results, function (documentPersistence) {
            documentsId.push(documentPersistence._id);
          });

          return _.difference(documentsId, documentsIdPieces);
        });
      },

      /**
       * Function who return the list documents which are not attached to a piece.
       *
       * @param  {Array} pieces           Pieces
       * @param  {string} urlDocuments    Url to get the list of documents and use it to delete an orphan document
       * @returns {Promise}                Promise to recover a list of documents' id to delete
       */
      getDocumentsUrlPersistenceToDelete: function (pieces, urlDocuments) {
        return $http.get(urlDocuments).then(function (response) {
          // Document-collect
          var documentsId = [];
          _.each(response.data.objects, function (documentPersistence) {
            var documentName = decodeURIComponent(_.get(documentPersistence, 'object.properties.cmis:name.value'));
            var objectId = _.get(documentPersistence, 'object.properties.cmis:objectId.value');
            documentsId.push(urlDocuments + '/' + documentName);
            documentsId.push(cmisService.getUrlDocuments(urlDocuments, objectId));
          });

          var documentsIdPieces = [];
          _.each(pieces, function (piece) {
            _.each(piece.documents, function (documentPiece) {
              documentsIdPieces.push(decodeURIComponent(documentPiece.id));
            });
          });

          return _.difference(documentsId, documentsIdPieces);
        });
      },

      /**
       * Evaluate display or required condition for a piece
       *
       * @param {object} scope scope for evaluation condition
       * @param {object} piece Piece to evaluate
       * @param {string} typeCondition property to check : "conditionObligatoire" or "conditionAffichage"
       * @param {boolean} isDemandePaiement is piece for a "demande de paiement"
       * @returns {boolean} is piece match condition
       */
      evalConditionPiece: function (scope, piece, typeCondition, isDemandePaiement) {
        var condition;
        // If is "demande de paiement", check if its dossier is linked to a v8
        var hasCorrelationsV8 = false;
        if (isDemandePaiement) {
          hasCorrelationsV8 = _.find(
            _.get(scope, 'demandePaiement.demandeFinancement.expand.correlations', []),
            function (correlation) {
              return correlation.value.startsWith('/connecteur-aides-v9v8');
            }
          );
        }
        condition = _.get(piece, typeCondition);
        // If dossier is linked to a v8
        if (condition && hasCorrelationsV8) {
          // if one of the conditions contains a link with "dossierFinancement" then no condition is interpreted
          if (condition.includes('dossierFinancement')) condition = null;
        }

        if (!condition) {
          switch (typeCondition) {
            // Pour la rétro compatibilité et la factoristion on renvoi le caractère obligatoire de la pièce
            // dans le cas ou l'on est sur les conditions d'obligations d'une pièce
            case 'conditionObligatoire':
              return piece.obligatoire || false;
            default:
              return true;
          }
        }
        // Récupération du scope courant
        var currentScope = scope;
        // Ajout de librairies utilitaires
        currentScope.moment = moment;
        currentScope._ = _;
        // Exemple d'utilisation : JSONPath('$.planFinancement.0.recette..ligne[?(@.financement)]', aide)
        currentScope.JSONPath = JSONPath;

        // Ajout des fonctions utilitaires permettant la simplification de calcul des conditions sur une pièce
        currentScope.find = find;
        currentScope.getSpecificValue = getSpecificValue;

        var isOK = false;
        try {
          isOK = currentScope.$eval(condition);
        } catch (e) {
          const message = translateService.translateContent(
            `teleservice.pieces.error.conditionNotExecutable.${typeCondition}`
          );
          $log.warn(`${message} : ${condition} on piece ${piece.reference}, ${e}`);
        }
        return isOK;
      },
      /**
       * Method to set pieces persistance for not used pieces in this screen
       *
       * @param {Array} displayedPieces original pieces
       * @param {Array} piecesToFilter pieces to filter
       * @returns {Array}
       */
      setPiecesPersistance: function (displayedPieces, piecesToFilter) {
        var screenPieces = displayedPieces || [];
        return _.filter(piecesToFilter, function (piece) {
          var isInScreen = _.find(screenPieces, { reference: piece.reference });
          if (!isInScreen) return piece;
        });
      },

      /**
       * If the piece should not be visible BUT documents are saved, we have to delete those documents.
       * Bug exemple: 1. piece has been uploaded
       *              2. then the user changes a field that impacts the condition
       *              3. the piece is not supposed to be visible anymore but the uploded document stays
       *
       * @param {object} aide
       * @param {object} contribution
       * @param {object} piece Piece to evaluate
       * @returns {boolean} true if the clean was necessary
       */
      cleanNotVisiblePieces: function (aide, contribution, piece) {
        // documents in this piece
        if (!_.isEmpty(_.get(piece, 'documents', [])) && _.isNil(piece.handled)) {
          // we do this operation only once per piece
          piece.handled = true;
          // Documents found for this piece that is not visible
          return this.emptyPieceFromEntity(aide, contribution, piece);
        }

        // No documents to delete
        return $q.resolve(false);
      },

      /**
       * Delete all documents of a piece in database AND GED
       *
       * @param {object} aide The demande-financement
       * @param {object} contribution The contribution
       * @param {object} piece The document to remove
       * @returns {Promise}
       */
      emptyPieceFromEntity: function (aide, contribution, piece) {
        const defaultUrlDocuments = aide._links?.['mgs:documents']?.href;
        // Remove from GED
        const promises = _.get(piece, 'documents', []).map((documentPiece) => {
          // Remove ALL documents of this piece
          return this.deleteDocumentOnGED(documentPiece, defaultUrlDocuments);
        });

        // Remove all documents from GED
        return $q
          .all(promises)
          .then(() => {
            // Remove all documents from aide for this piece
            const newAide = _.cloneDeep(aide);
            const pieces = aide.pieces;
            const indexPiece = _.findIndex(pieces, (p) => p.reference === piece.reference);
            if (indexPiece > -1) {
              _.set(newAide, `pieces[${indexPiece}].documents`, []);

              const updateEntity = contribution
                ? contributionsService.saveContribution(newAide, contribution)
                : aidesService.update(newAide);

              // Save aide or contribution
              return updateEntity
                .then(() => true)
                .catch((err) => {
                  $log.error('[emptyPieceFromEntity] - error updating aide', err);
                  return false;
                });
            } else {
              $log.error('[emptyPieceFromEntity] - error indexPiece = -1');
              return false;
            }
          })
          .catch((err) => {
            $log.error('[emptyPieceFromEntity] - error deleting docs from GED', err);
            return false;
          });
      },

      /**
       * Delete piece from GED
       *
       * @param {object} documentPiece The document to remove
       * @param {string} defaultUrlDocuments
       * @returns {Promise}
       */
      deleteDocumentOnGED: function (documentPiece, defaultUrlDocuments) {
        var query = {};
        switch (documentPiece.rel) {
          case 'cmis':
            query = {
              method: 'POST',
              url: documentPiece.id,
              params: {
                cmisaction: 'delete',
              },
            };

            break;
          default:
            query = {
              method: 'POST',
              url: defaultUrlDocuments,
              params: {
                cmisaction: 'delete',
                objectId: _.get(documentPiece, "expand.properties['cmis:objectId'].value", ''),
              },
            };

            break;
        }

        return $http(query);
      },

      /**
       * Copy aide pieces in tiers pieces if the pieces are in the famille of the tiers
       *
       * @param {object} aide source data
       * @param {object} tiers target data
       * @param {Array<string>} hrefDocumentsAdded list of added documents
       * @returns {Promise<object>} updated tiers
       */
      copyAidePiecesInTiersPieces(aide, tiers, hrefDocumentsAdded) {
        tiers.pieces = tiers.pieces || [];
        const copyDocumentsOnTiers = [];
        const modelesPiecesTeleservice = aide.teleservice.expand?.workflow?.simple?.pagePieces?.modelesPieces ?? [];

        aide.pieces?.forEach((piece) => {
          const byReference = ({ reference }) => reference === piece.reference;

          const modelePiece = modelesPiecesTeleservice.find(byReference);
          if (!modelePiece) return;

          const famillePiece = tiers.famille?.expand?.pieces?.find(byReference);
          let tiersPiece = tiers.pieces.find(byReference);

          if (famillePiece && !tiersPiece) {
            tiersPiece = angular.copy(piece);

            tiersPiece.documents = [];
            delete tiersPiece.conditionAffichage;
            delete tiersPiece.conditionObligatoire;
            delete tiersPiece.obligatoireSurRecevabilite;

            tiers.pieces.push(tiersPiece);
          }
          // See aidesService#copyAideDocumentsOnTiers behavior, we don't copy documents on the tiers if it already has documents
          if (tiersPiece?.documents?.length > 0) return;

          // Copy all documents from the aide for this piece on the tiers
          // If we reference a document from 'porte-document' is href contains 'tiers'
          piece.documents?.forEach((documentAide) => {
            if (
              documentAide.expand?.properties?.['entity:uri']?.value?.includes('demandes-financement') &&
              !documentAide.origin?.includes('tiers') &&
              hrefDocumentsAdded.includes(documentAide.href) &&
              tiersPiece
            ) {
              const documentTiers = angular.copy(documentAide);
              copyDocumentsOnTiers.push(
                cmisService
                  .copyDocument(`${cmisService.getBaseUrl()}/tiers/${tiers.reference}`, documentTiers, 'tiers', tiers)
                  .then((document) => {
                    if (!tiersPiece.documents) tiersPiece.documents = [];
                    tiersPiece.documents.push(document);
                  })
              );
            }
          });
        });

        return $q.all(copyDocumentsOnTiers).then(() => tiers);
      },
      /**
       * Gives the URL of document creation.
       *
       * @returns {string} url
       */
      getUrlCreateDocumentPiece() {
        return `${baseUrlReferentielPiece}/entity-pieces/document`;
      },

      /**
       * Function who return the list documents, document who are not attach to a piece.
       *
       * @param  {string} entityId - Url of the entity
       * @param  {Array} pathPropertiesPieces - Path of the properties in jsonpath format to retrieve pieces
       * @returns {Promise} an object with result status to "ok" or "ko" when rules on piecs are verified
       */
      checkDocumentsPieces: function (entityId, pathPropertiesPieces) {
        const query = {
          method: 'POST',
          url: `${baseUrlReferentielPiece}/entity-pieces/verify/checkDocuments`,
          data: {
            entityLink: entityId,
            pathPropertiesPieces,
          },
        };
        return $http(query)
          .then((response) => response?.data)
          .catch((error) => {
            $log.error('[checkDocumentsPieces] - error when check documents for pieces', error);
            throw error;
          });
      },
    };
  },
]);
