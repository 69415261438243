'use strict';
angular.module('tiers.helpers').factory('tiersFacts', () => {
  'use strict';

  let instance;

  class TiersFacts {
    constructor() {
      // we can stores our facts here.
      this.facts = {
        famille: {
          isMoral: false,
          isPhysique: false,
          isAsso: false,
          isEntreprise: false,
          isEI: false,
          shouldHaveSIRET: false,
          shouldHaveRNA: false,
        },

        multitiers: {
          hasOneTiersParticulier: false,
          hasOneTiersEI: false,
        },
      };
    }

    /**
     * Centralize some common tiers facts by famille.
     * This will be called regulary so keep it light !
     *
     * @param {object} tiers full tiers Object to evaluate.
     * @param {object} famille alternate famille Object to evaluate.
     * @returns {object} famille facts
     */
    famille(tiers, famille) {
      const facts = { ...this.facts.famille };
      if (!tiers && !famille) {
        return facts;
      }

      famille = tiers.famille ? tiers.famille : famille;
      const personnaliteJuridique = famille?.expand?.personnaliteJuridique;
      const typeFamille = famille?.expand?.typeFamille;

      facts.isMoral = personnaliteJuridique === 'MORALE';
      facts.isPhysique = personnaliteJuridique === 'PHYSIQUE';
      facts.isAsso = typeFamille === 'TIERS_ASSOCIATION';
      facts.isEntreprise = typeFamille === 'TIERS_ENTREPRISE';
      facts.isEI = typeFamille === 'TIERS_ENTREPRENEUR_INDIVIDUEL';
      facts.shouldHaveSIRET = facts.isMoral || facts.isEI || facts.isAsso;
      facts.shouldHaveRNA = facts.isMoral && facts.isAsso;

      this.facts.famille = facts;
      return facts;
    }

    /**
     * Centralize some common multitiers facts.
     * This will be called regulary so keep it light !
     *
     * @param {Array} tiersList Array of tiers
     * @returns {object} multitiers facts
     */
    multitiers(tiersList) {
      const facts = { ...this.facts.multitiers };

      facts.hasOneTiersParticulier = tiersList.some((tiers) => {
        const typeFamille = tiers.famille?.expand?.typeFamille;
        return typeFamille === 'TIERS_PARTICULIER';
      });
      facts.hasOneTiersEI = tiersList.some((tiers) => {
        const typeFamille = tiers.famille?.expand?.typeFamille;
        return typeFamille === 'TIERS_ENTREPRENEUR_INDIVIDUEL';
      });

      this.facts.multitiers = facts;
      return facts;
    }
  }

  if (!instance) {
    instance = new TiersFacts();
  }
  return instance;
});
