'use strict';
class CriteresService {
  constructor() {
    this.critereCodeCampagneRegex = /\/referentiel-financement-bourse\/api\/tenants\/.*\/campagnes\/est-eligible/;
  }

  /**
   * Filters given criteres to return those who are not code campagne
   *
   * @param {object[]} criteres
   * @returns {object[]}
   */
  excludeCodeCampagne(criteres) {
    return criteres.filter((critere) => !this.isCodeCampagneCritere(critere));
  }

  /**
   * Check that the given critere is code campagne
   *
   * @param {object} critere
   * @returns {boolean}
   */
  isCodeCampagneCritere(critere) {
    const apiCritere = critere.urlApiExterne;
    return critere.type === 'STRING' && apiCritere && this.critereCodeCampagneRegex.test(apiCritere);
  }
}

angular.module('aides.services').service('criteresService', CriteresService);
