'use strict';
ContributionAvisStepComponent.$inject = ['$scope', '$translate', '$q', 'contributionsService', 'jsonpatch']; // Module declaration
angular.module('portailDepotDemandeAide.depot').component('contributionAvisStepComponent', {
  templateUrl: 'depot/contributions-avis/avis/contributions-avis-step.component.html',
  controller: /* @ngInject */ ContributionAvisStepComponent,
  bindings: {
    contribution: '=',
    navigationHelper: '<',
    viewConfiguration: '<',
    workflow: '<',
  },
});

/**
 * Controller
 *
 * @param {object} $scope
 * @param {object} $translate
 * @param {object} $q
 * @param {object} contributionsService
 * @param {object} jsonpatch
 */
function ContributionAvisStepComponent($scope, $translate, $q, contributionsService, jsonpatch) {
  var ctrl = this;

  ctrl.$onInit = function () {
    ctrl.disableNavigation = false;
    // Define navigationDelegate
    ctrl.navigationDelegate = {
      nextButton: {
        title: $translate.instant('contributions.common.next'),
        action: ctrl.nextStep,
      },

      previousButton: {
        title: $translate.instant('contributions.common.previous'),
        action: ctrl.previousStep,
      },
    };

    // Define default values
    ctrl.viewConfiguration.ns = 'teleservice.avis';
    ctrl.contributionAnswer = {
      user: ctrl.contribution.contact,
      kMontant: _.get(ctrl.contribution, 'avis.montant.ttc'),
      montant: _.get(ctrl.contribution, 'avis.montant.ttc'),
      valeur: _.get(ctrl.contribution, 'avis.valeur'),
      motif: _.get(ctrl.contribution, 'avis.motif.href'),
      commentaire: _.get(ctrl.contribution, 'avis.commentaire'),
    };

    // add date formatt,er
    if (_.get(ctrl.contribution, 'avis.date')) {
      ctrl.contributionAnswer.date = new Date(ctrl.contribution.avis.date);
      ctrl.contributionAnswer.dateDisplay = moment(ctrl.contribution.avis.date).format('DD/MM/YYYY');
    }

    // Values for form
    ctrl.contributionAvis = [
      {
        name: $translate.instant('contributions.common.FAVORABLE'),
        value: 'FAVORABLE',
      },

      {
        name: $translate.instant('contributions.common.DEFAVORABLE'),
        value: 'DEFAVORABLE',
      },
    ];

    contributionsService.getMotifsForContribution(ctrl.contribution).then(function (motifsAvis) {
      ctrl.motifsAvis = motifsAvis;
      ctrl.onAvisChange();
    });

    ctrl.datePickerConfig = {
      min: '01/01/1900',
      max: new Date(),
      culture: 'fr-FR',
    };

    // Extract infos form workflow
    _.merge(ctrl.viewConfiguration.fields, ctrl.workflow);
  };

  ctrl.previousStep = function () {
    // we stop process if loading in progress
    if (ctrl.disableNavigation) return;
    ctrl.disableNavigation = true;

    // we use debounce to render immediatly loading value in the template and disable the submit button
    _.debounce(() => {
      ctrl
        .saveAndPerform()
        .then(function () {
          ctrl.navigationHelper.goToPreviousStep();
        })
        .catch((err) => {
          ctrl.disableNavigation = false;
          throw err;
        });
    }, 250)();
  };

  ctrl.nextStep = function () {
    if ($scope.contributionAvisForm.$valid) {
      // we stop process if loading in progress
      if (ctrl.disableNavigation) return;
      ctrl.disableNavigation = true;

      // we use debounce to render immediatly loading value in the template and disable the submit button
      _.debounce(() => {
        ctrl
          .saveAndPerform()
          .then(function () {
            ctrl.navigationHelper.goToNextStep();
          })
          .catch((err) => {
            ctrl.disableNavigation = false;
            throw err;
          });
      }, 250)();
    }
  };

  ctrl.saveAndPerform = function () {
    // 0 - Create an entity with old values
    var entity = _.cloneDeep(ctrl.contribution);
    // 1 - Attach the observer
    var observer = jsonpatch.observe(entity);

    // 2 - Assign the newest values
    _.unset(entity, 'avis.ctrl');
    _.unset(entity, 'avis.kMontant');
    _.unset(entity, 'avis.dateDisplay');

    _.set(entity, 'avis.commentaire', ctrl.contributionAnswer.commentaire);
    _.set(entity, 'avis.user', ctrl.contributionAnswer.user); //Dès que le demandeur modifie la contrib, c'est lui qui est stocké dans user
    _.set(entity, 'avis.valeur', ctrl.contributionAnswer.valeur);
    //si le montant est nul ou <0
    if (_.get(ctrl.contributionAnswer, 'kMontant') === null || _.get(ctrl.contributionAnswer, 'kMontant') < 0) {
      _.unset(entity, 'avis.montant');
    } else {
      _.set(entity, 'avis.montant', { ttc: ctrl.contributionAnswer.kMontant, devise: 'EUR' });
    }
    if (_.get(ctrl.contributionAnswer, 'date')) {
      _.set(entity, 'avis.date', ctrl.contributionAnswer.date.toISOString());
    }
    // Because select can't handle complex object for value , we have to stock only the href and then update the model
    if (!_.get(ctrl, 'contributionAnswer.motif')) {
      _.unset(entity, 'avis.motif');
    } else {
      var motifFound = _.find(ctrl.currentMotifsAvis, { id: ctrl.contributionAnswer.motif });
      if (motifFound) {
        _.set(entity, 'avis.motif', { title: motifFound.libelle, href: motifFound.id });
      }
    }

    // 3 - Generate patches
    var patches = jsonpatch.generate(observer);
    if (patches.length === 0) return $q.resolve();
    return contributionsService.updateContributionAndSendResultToParent(ctrl.contribution.reference, patches);
  };

  // Events
  ctrl.onAvisChange = function () {
    if (ctrl.contributionAnswer.valeur === 'FAVORABLE') {
      ctrl.currentMotifsAvis = _.get(ctrl, 'motifsAvis.motifsAvisFavorable', []);
    }
    if (ctrl.contributionAnswer.valeur === 'DEFAVORABLE') {
      ctrl.currentMotifsAvis = _.get(ctrl, 'motifsAvis.motifsAvisDefavorable', []);
    }

    // Update configuration validation for motif
    var motifsConfiguration = _.get(ctrl.viewConfiguration.fields, 'motif');
    _.set(motifsConfiguration, 'dataType', 'enumeration');
    var restrictions = [
      {
        enumeration: {
          item: ctrl.currentMotifsAvis,
        },
      },
    ];

    _.set(motifsConfiguration, 'restrictions', restrictions);
  };
}
