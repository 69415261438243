'use strict';
angular.module('portailDepotDemandeAide.depot').controller('depotSimpleMinimisController', [
  '$scope',
  'StoreService',
  function ($scope, StoreService) {
    'use strict';

    $scope.demandeur = StoreService.demandeur.get();
    $scope.pageOptions = $scope.teleserviceConfiguration.workflow.pageFinancementAnterieur;
    $scope.cleanNavigate();

    $scope.navigate.next = $scope.goToStepFn('domiciliation-bancaire');
    $scope.navigate.ns = $scope.minimisConfiguration.ns;

    $scope.aide.financementAnterieur = $scope.aide.financementAnterieur || [];
    $scope.aide.financementAnterieur = new kendo.data.ObservableArray($scope.aide.financementAnterieur);

    // Récupère l'option, ou initialise l'exercice à l'année en cours
    $scope.exerciceCampagne = parseInt($scope.teleserviceConfiguration.exerciceReference);

    // mise à jour de la liste des financeurs à partir du mdm en ne gardant que les libellés
    $scope.$watch('mdm.financeursAides', function (sourceFinanceursAides) {
      $scope.listeFinanceursAides = _.map(sourceFinanceursAides.array, 'expand.libelle.value');
    });

    // If the page is hidden directly navigate to next page and forget this one in the history
    if (!$scope.pageOptions.actif) {
      $scope.navigate.next(true);
    }
    // Affichage de la page uniquement si la famille du bénéficiaire est de type entreprise ou association
    // Test sur le demandeur dans le cas ou c'est le même que le bénéficaire (i.e. pas de dépôt délégué)
    else if (!_.isEmpty($scope.aide.beneficiaires[0])) {
      var typeFamilleBeneficiaire = $scope.aide.beneficiaires[0].expand.famille.expand.typeFamille;
      if (typeFamilleBeneficiaire !== 'TIERS_ENTREPRISE' && typeFamilleBeneficiaire !== 'TIERS_ASSOCIATION') {
        $scope.navigate.next(true);
      }
    } else {
      var typeFamilleDemandeur = $scope.demandeur.famille.expand.typeFamille;
      if (typeFamilleDemandeur !== 'TIERS_ENTREPRISE' && typeFamilleDemandeur !== 'TIERS_ASSOCIATION') {
        $scope.navigate.next(true);
      }
    }

    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'dossier';

    // On découple les deux collections entre celle de l'entité et celle de kendo
    // car on va stocker uniquement la version triée dans l'entité
    // pour avoir un meilleur rendu dans les éditions
    $scope.financementAnterieurNotSorted = angular.copy($scope.aide.financementAnterieur);
    $scope.$watch(
      'financementAnterieurNotSorted',
      function (val) {
        if (val) {
          $scope.aide.financementAnterieur = _.sortBy($scope.financementAnterieurNotSorted, function (minimis) {
            return minimis.exercice;
          });
        }
      },
      true
    );
  },
]);
