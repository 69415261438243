/**
 * @typedef {{ 'givenName': string, 'familyName': string, 'href': string, 'deletable': boolean, 'roles': Array<string> }} UserItem
 */

/**
 * Restriction choice component controller
 *
 * @property {boolean} restricted - two-way binding =
 * @property {Array<UserItem>} userList - one-way binding <
 * @property {Array<string>} restrictedUsers - two-way binding =
 * @property {string} notRestrictedRadioItem value for radio-button when not restricting demande
 * @property {string} restrictedRadioItem value for radio-button when restricting demande only for some users
 */
class RestrictionChoiceController {
  /**
   * Class constructor
   *
   * @param {Function} $timeout function to delay an action
   * @param {object} translateService app translate service
   */
  constructor($timeout, translateService) {
    this.$timeout = $timeout;
    this.translateService = translateService;
  }

  /**
   * Called on component initialization
   *
   * @returns {void}
   */
  $onInit() {
    // Init radio buttons items
    this.notRestrictedRadioItem = {
      title: this.translateService.translateContent(
        'connected.dashboard.aides.accessManagement.modal.radio.notRestricted'
      ),
      value: 'notRestricted',
    };
    this.restrictedRadioItem = {
      title: this.translateService.translateContent(
        'connected.dashboard.aides.accessManagement.modal.radio.restricted'
      ),
      value: 'restricted',
    };
    this.radioItems = [this.notRestrictedRadioItem, this.restrictedRadioItem];

    // Search input filter value
    this.filterValue = '';
    this.displayPopover = false;
  }

  /**
   * Currently selected user list
   *
   * @returns {Array<UserItem>} the list of selected users
   */
  get selectedUsers() {
    const mandatoryUsers = this.userList?.filter(({ roles }) => roles.length > 0);
    this.alphabeticalSortUsers(mandatoryUsers);

    const manualUsers = this.userList?.filter(({ roles }) => roles.length === 0);
    this.alphabeticalSortUsers(manualUsers);

    const sortedUsers = mandatoryUsers.concat(manualUsers);

    return sortedUsers?.filter((userItem) => this.restrictedUsers?.includes(userItem.href)) ?? [];
  }

  /**
   * Use this list to display users to search
   *
   * @returns {Array<UserItem>} the list of users which can be searched
   */
  get userSearchList() {
    // Filter on already selected items
    const selectedUserHref = this.selectedUsers.map(({ href }) => href);
    let searchList = this.userList?.filter((userItem) => !selectedUserHref.includes(userItem.href)) ?? [];

    // Filter on search input filter value
    if (this.filterValue)
      searchList = searchList.filter((userItem) =>
        this.getTitle(userItem)?.toLowerCase().includes(this.filterValue?.toLowerCase())
      );

    // Alphabetical sorting
    this.alphabeticalSortUsers(searchList);

    return searchList;
  }

  /**
   * Computed the radio input value choice
   *
   * @returns {string} the radio button input value
   */
  get restrictedChoice() {
    return this.restricted ? this.restrictedRadioItem.value : this.notRestrictedRadioItem.value;
  }

  /**
   * True if restricted choice have been selected
   *
   * @returns {boolean} true if restricted
   */
  get hasRestrictedChoice() {
    return this.restrictedChoice === this.restrictedRadioItem.value;
  }

  /**
   * Method to toggle displayPopover props
   *
   * @param {boolean} newValue the new displayPopover value
   * @returns {void}
   */
  setDisplayPopover(newValue) {
    this.displayPopover = newValue;
  }

  /**
   * Set radio button choice
   *
   * @param {string} restrictedChoice radio button value
   * @returns {void}
   */
  setRestrictedChoice(restrictedChoice) {
    this.restricted = restrictedChoice === this.restrictedRadioItem.value;
  }

  /**
   * Set search input filter value
   *
   * @param {string} filterValue the search value of the user
   * @returns {void}
   */
  setFilterValue(filterValue) {
    this.filterValue = filterValue;
  }

  /**
   * Get formatted name with both family name and givenName
   *
   * @param {UserItem} userItem the user item from which to display name
   * @returns {string} the name
   */
  getTitle(userItem) {
    return `${userItem.givenName} ${userItem.familyName}`;
  }

  /**
   * Get formatted roles for specific user
   *
   * @param {UserItem} userItem the user item from which to display roles
   * @returns {string} the formatted roles
   */
  getRoles(userItem) {
    return userItem.roles?.reduce((roles, role) => `${roles} (${role})`, '') ?? '';
  }

  /**
   * Computes if a given user can be deleted
   *
   * @param {UserItem} user the user we want to know if it is deletable
   * @returns {boolean} true if the user is deletable
   */
  isDeletable(user) {
    return user?.deletable ?? true;
  }

  /**
   * Adds a user to the restricted list
   *
   * @param {UserItem} userItem the user item we want to add to the user list
   * @returns {void}
   */
  addUser(userItem) {
    this.restrictedUsers.push(userItem.href);
    this.filterValue = '';
    this.setDisplayPopover(false);
  }

  /**
   * Deletes a user from the restricted list (and refreshes the search list)
   *
   * @param {string} userHref the href of the user item we want to delete
   * @returns {void}
   */
  deleteUser(userHref) {
    this.restrictedUsers = this.restrictedUsers.filter((user) => user !== userHref);
  }

  /**
   * Sort the user list to make it alphabetical ordered with user name
   * ! Mutates userList parameter
   *
   * @param {Array<UserItem>} userList the list of users
   * @returns {void}
   */
  alphabeticalSortUsers(userList) {
    userList.sort((user1, user2) => {
      const familyNameCompare = user1.familyName?.localeCompare(user2.familyName);

      if (familyNameCompare === 0) return user1.givenName?.localeCompare(user2.givenName);
      return familyNameCompare;
    });
  }
}

RestrictionChoiceController.$inject = ['$timeout', 'translateService'];

angular.module('aides').component('restrictionChoice', {
  templateUrl: 'aides/aides-components/aides-restrictions/modal/restriction-choice/restriction-choice.html',
  controller: RestrictionChoiceController,
  bindings: {
    restricted: '=',
    userList: '<',
    restrictedUsers: '=',
  },
});
