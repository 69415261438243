'use strict';
/**
 * @description Directive for display tiers informations on recapitulatif
 * @example
 * <div identification-recapitulatif tiers="tiers" edit-href="'#/user/tiers-edit'"></div>
 * @returns {object}
 */
angular.module('tiers').directive('identificationRecapitulatif', [
  function () {
    'use strict';

    return {
      replace: true,
      transclude: true,
      templateUrl: 'tiers/tiers-directives/identification-recapitulatif/identification-recapitulatif.html',
      scope: {
        tiers: '=',
        editHref: '=',
        readOnly: '=',
        title: '=identificationRecapitulatifTitle',
      },

      link: function (scope) {
        scope.nomNaissanceHidden = !_.get(
          scope,
          'tiers.famille.expand.infosStandardsComplementaires.NomFamille.visible'
        );

        if (scope.tiers?.famille?.expand?.d) {
          scope.tiers.famille.expand = scope.tiers.famille.expand.d;
        }
      },
    };
  },
]);
