'use strict';
choixFinanceurController.$inject = ['tiersService'];
/**
 * @module components.autorisations
 * @name autorisationsComponent
 * @description
 *
 *   Allow to select a "financeur" before starting the "depot"
 * @param {object} tenantId - tenantId
 * @example
 *
 * <choix-financeur teleservice-configuration="$scope.teleserviceConfiguration" financeur-selected="$scope.financeurSelected(financeur)"></choix-financeur>
 */
angular.module('portailDepotDemandeAide.depot').component('choixFinanceur', {
  templateUrl: 'depot/simple/financeur/component/choix-financeur.component.html',
  controller: choixFinanceurController,
  require: {
    form: '?^^form',
  },

  bindings: {
    teleserviceConfiguration: '<',
    ns: '<',
    onFinanceurSelected: '&',
    financeurPrincipal: '<',
  },
});

choixFinanceurController.$inject = ['tiersService'];

/**
 *
 * @param {object} tiersService
 */
function choixFinanceurController(tiersService) {
  const ctrl = this;

  // list of "financeurs" to display on the list box
  let financeursPossibles = [];

  ctrl.$onInit = function () {
    financeursPossibles = _.get(ctrl.teleserviceConfiguration, 'workflow.pageFinanceur.financeurs', []);
    financeursPossibles = _.forEach(financeursPossibles, (financeur) => (financeur.id = financeur.href));

    if (ctrl.financeurPrincipal && !ctrl.selectedFinanceur) {
      tiersService
        .getTiersFinanceurPrivilegieByGroupeGestionHref(_.get(ctrl, 'financeurPrincipal.groupesGestion[0].href'))
        .then(function (financeurs) {
          if (financeurs) {
            ctrl.selectedFinanceur = _.get(financeurs, 'thematiquesLiees.financeur[0]');
            ctrl.selectFinanceur(ctrl.selectedFinanceur);
          }
        });
    }
  };

  /**
   * Allow to search financeur
   *
   * @param {string} searchText
   * @returns {Promise}
   */
  ctrl.searchFinanceurs = function (searchText) {
    return tiersService.findFinanceursPrivilegie(searchText).then(function (financeurs) {
      let filteredFinanceurs = _.intersectionBy(financeurs, financeursPossibles, 'id');
      filteredFinanceurs = _.map(filteredFinanceurs, 'thematiquesLiees.financeur.0');
      return filteredFinanceurs;
    });
  };

  /**
   * When a new financeur is selected
   *
   * @param {object} financeur
   */
  ctrl.selectFinanceur = function (financeur) {
    ctrl.onFinanceurSelected && ctrl.onFinanceurSelected({ financeur });
  };
}
