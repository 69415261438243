'use strict';
/**
 * Gère l'affichage des informations complémentaires du tiers (pour tiers moral)
 * Exemples :
 * - date de création
 * - tableau des effectifs (donneesExercice)
 * - Iframes du referentiel-tiers pour les agréments&labels, les relations et les moyens humains
 */
angular.module('aides.recapitulatif').component('recapitulatifInformationsComplementaires', {
  templateUrl: 'aides/aides-directives/recapitulatif/tiers/recapitulatif-informations-complementaires.html',
  controller: recapitulatifInformationsComplementairesCtrl,
  restrict: 'EA',
  replace: true,
  bindings: {
    tiers: '=',
    fieldsOptions: '=',
    user: '=',
    page: '@',
    pageOptions: '=',
    namespace: '=',
    configuration: '=',
    isEntrepriseOrAssociation: '<?',
  },
});

recapitulatifInformationsComplementairesCtrl.$inject = [
  '$translate',
  'configuration',
  '$q',
  'tiersService',
  '$timeout',
  '$window',
];

/**
 *
 * @param {object} $translate
 * @param {object} configuration
 * @param {object} $q
 * @param {object} tiersService
 * @param {object} $timeout
 * @param {object} $window
 */
function recapitulatifInformationsComplementairesCtrl($translate, configuration, $q, tiersService, $timeout, $window) {
  'use strict';

  var ctrl = this;

  ctrl.$onInit = function () {
    ctrl.viewConfiguration = {
      ns: ctrl.namespace,
    };

    var tiers = ctrl.tiers;
    ctrl.donneesExercice = calculatedTables(tiers);

    if (_.get(ctrl.tiers, 'reference')) {
      // Vérifie que le tiers a déjà été validé car il n'est pas poussé dasn le ref-tiers avant
      initIFrame();
    }

    // The fields are not registered in the tiers with the same name they have in the configuration
    // Which means: we have to "translate" them
    var valuesNames = {
      companyCreationDate: 'dateImmatriculation',
      associationCreationDate: 'dateImmatriculation',
      capitalAmount: 'capitalSocial',
      mainActivity: 'activitePrincipale',
      groupMembership: 'appartenanceGroupe',
      raisonSocialeGroupe: 'raisonSocialeGroupe',
      comptesConsolides: 'comptesConsolides',
    };

    // Default fields (for moral structures)
    var allFields = _.transform(
      ctrl.configuration.fields,
      function (fieldsList, field, reference) {
        field.reference = reference;
        field.value = ctrl.tiers[valuesNames[reference]];
        fieldsList.push(field);
      },
      []
    );

    ctrl.fields = allFields;

    // Have to do that manually too (so much for modularity)
    var companyCreationDate = _.find(ctrl.fields, { reference: 'companyCreationDate' });
    companyCreationDate.dataType = 'date';
    var groupMembership = _.find(ctrl.fields, { reference: 'groupMembership' });
    groupMembership.dataType = 'boolean';
    var comptesConsolides = _.find(ctrl.fields, { reference: 'comptesConsolides' });
    comptesConsolides.dataType = 'boolean';
    var capitalAmount = _.find(ctrl.fields, { reference: 'capitalAmount' });
    capitalAmount.dataType = 'decimal';
    capitalAmount.subType = 'currency';
    var associationCreationDate = _.find(ctrl.fields, { reference: 'associationCreationDate' });
    associationCreationDate.dataType = 'date';

    if (_.get(ctrl.tiers.famille, 'expand.typeFamille') === 'TIERS_ASSOCIATION') {
      ctrl.fields = [associationCreationDate];
    } else {
      ctrl.fields = allFields;
      _.remove(ctrl.fields, associationCreationDate);
    }

    // Hide group fields if tiers do not belong to a group
    if (!groupMembership.value) {
      _.remove(ctrl.fields, function (field) {
        return _.includes(['raisonSocialeGroupe', 'comptesConsolides'], field.reference);
      });
    }
  };

  /**
   * Affichage des agréments, relations et effectifs via des iframes
   * Si celles-ci ont des données saisies
   * (Pour le tableau d'effectifs, le 0 est considéré comme une information)
   * Affichage que si le tiers est déjà créé (pas la première demande)
   */
  function initIFrame() {
    ctrl.showAgrements = false;
    ctrl.showRelations = false;
    ctrl.showEffectifs = false;

    var routeThematiquesLieesAssociation = _.get(ctrl.tiers, 'thematiquesLiees.association');
    var tableauEffectifs = _.get(ctrl.tiers, 'thematiquesLiees.ressources-humaines.tableauEffectifs.items');

    ctrl.showAgrements = _.has(routeThematiquesLieesAssociation, 'agrements.0'); //au moins 1 agrement
    ctrl.showRelations =
      _.has(routeThematiquesLieesAssociation, 'affiliation') ||
      _.has(routeThematiquesLieesAssociation, 'adherentMoral') ||
      _.has(routeThematiquesLieesAssociation, 'associationSportiveAgreee');
    ctrl.showEffectifs = _.without(_.map(tableauEffectifs, 'valeur'), undefined, NaN, null, '').length > 0;

    //url des iframes
    ctrl.agrementsUrl = tiersService.getIframeUrl(ctrl.tiers.reference, 'agrementstiersassociation', {
      readOnly: true,
      richtext: false,
    });

    ctrl.unionsFederationsReseauxUrl = tiersService.getIframeUrl(
      ctrl.tiers.reference,
      'unionsfederationsreseauxtiersassociation',
      { readOnly: true, richtext: false }
    );

    ctrl.ressourcesHumainesUrl = tiersService.getIframeUrl(ctrl.tiers.reference, 'ressourceshumaines', {
      readOnly: true,
      richtext: false,
    });

    // display the iframe of the informations complementaires of the famille
    ctrl.actifViews = tiersService.hasActifViews(ctrl.tiers);
    ctrl.familleViewsIframeSrc = tiersService.getViewsIframeSrcFromTiers(ctrl.tiers, true);
    $timeout(() => {
      setIframeResizer('#viewsFamilleInfosComps');
    });
  }

  /**
   *
   * @param {object} tiers
   * @returns {object}
   */
  function calculatedTables(tiers) {
    // Tables for exercices data
    var donneesExercice = {};
    _.each(['effectifs', 'donneesFinancieres', 'effectifsGroupe', 'donneesFinancieresGroupe'], function (typeDonnees) {
      var table = {
        exercices: [],
        cles: [],
        donnees: {},
      };

      if (_.get(tiers, typeDonnees + '.item.length')) {
        donneesExercice[typeDonnees] = table;

        var item = tiers[typeDonnees].item;
        // For each item, we add its exercice and key into the list, and its value in the table's matrix
        _.each(item, function (value) {
          if (!_.includes(table.exercices, value.exercice)) {
            table.exercices.push(value.exercice);
            table.donnees[value.exercice] = {};
          }
          if (!_.includes(_.map(table.cles, 'ref'), value.cle)) {
            table.cles.push({
              ref: value.cle,
              label: value.libelle,
            });
          }
          table.donnees[value.exercice][value.cle] = value.valeur;
        });
      }
    });
    return donneesExercice;
  }

  /**
   *
   * @param {string} id
   */
  function setIframeResizer(id) {
    iFrameResize(
      {
        heightCalculationMethod: 'taggedElement',
        checkOrigin: false,
        inPageLinks: true,
      },

      id || '.referentiel-tiers-iframe-recap'
    );
  }

  $window.addEventListener('message', function (msg) {
    if (_.get(msg, 'data.action', '') === 'ready') {
      setIframeResizer();
    }
  });
}
