'use strict';
class RecapitulatifDecisionsMultiDossiersController {
  /**
   * Initialize component
   *
   * @returns {void}
   */
  $onInit() {
    const decisionsWithDossier = this.decisions.filter(({ dossierFinancement }) => dossierFinancement);
    const decisionsByDossier = _.groupBy(decisionsWithDossier, 'dossierFinancement.referenceAdministrative');
    const dossierRefs = Object.keys(decisionsByDossier).sort();
    this.dossiersFinancementWithDecisions = dossierRefs.map((ref) => ({
      title: decisionsByDossier[ref][0].dossierFinancement.title,
      referenceAdministrative: ref,
      decisions: decisionsByDossier[ref],
    }));
  }
}

RecapitulatifDecisionsMultiDossiersController.$inject = [];

angular.module('aides.recapitulatif').component('recapitulatifDecisionsMultiDossiers', {
  templateUrl: 'aides/aides-components/recapitulatif/decisions/recapitulatif-decisions-multi-dossiers.html',
  controller: RecapitulatifDecisionsMultiDossiersController,
  bindings: {
    namespace: '<',
    decisions: '<',
  },
});
