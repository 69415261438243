'use strict';
angular.module('portailDepotDemandeAide.dashboard').controller('dashboardDemandesPaiementController', [
  '$scope',
  '$state',
  '$modal',
  '$stateParams',
  'demandesPaiementService',
  'suiviFinancementService',
  'alertsService',
  'aide',
  'teleservicePaiement',
  'demandesPaiement',
  'decisions',
  '$timeout',
  'demandesAidesService',
  '$rootScope',
  '$log',
  'dossiersFinancementAPI',
  '$translate',
  'teleservicesService',
  function (
    $scope,
    $state,
    $modal,
    $stateParams,
    demandesPaiementService,
    suiviFinancementService,
    alertsService,
    aide,
    teleservicePaiement,
    demandesPaiement,
    decisions,
    $timeout,
    demandesAidesService,
    $rootScope,
    $log,
    dossiersFinancementAPI,
    $translate,
    teleservicesService
  ) {
    /**
     * Method which allows to display paiement button
     *
     * @returns {boolean} true when create paiement button should be displayed
     */
    function canDisplayPaiementBtn() {
      return Boolean(
        // we check if aide have at least one event with type DECISION and avis FAVORABLE like it said in PDA9-275 RG-01
        // we check if DECISION are actives (not CLOTURE)
        $scope.ligneDossier &&
          dossiersFinancementAPI.isAideHaveAtLeastOneActiveDecisionFavorable($scope.ligneDossier) &&
          // next we do somme authorization validations
          $scope.autorisationDemandePaiement &&
          !$scope.aide?.cloture?.value &&
          $scope.canCreateDemandePaiement &&
          teleservicesService.isTSPaiementPublie($scope.teleservicePaiement)
      );
    }

    /**
     * method that checks if user has creation rights on demande paiement
     * and display creation button if needed
     * if there's no demande paiement yet, we're creating one
     * if there's at least one demande, we just display the list
     *
     * @returns {void}
     */
    function canCreateDemandePaiement() {
      demandesPaiementService
        .canCreateDemandePaiement({
          action: 'POST',
          resource: '/referentiel-financement/api/tenants/' + $stateParams.tenantId + '/demandes-paiement',
          environment: {
            body: {
              teleservicePaiement: $scope.teleservicePaiement,
              demandeFinancement: {
                href: $scope.aide.id,
              },

              dossierFinancement: {
                href: $scope.dossier?.href,
              },
            },
          },
        })
        .then((response) => {
          $scope.canCreateDemandePaiement = response;
          $scope.displayPaiementBtn = canDisplayPaiementBtn();
        })
        .finally(() => {
          if ($scope.displayPaiementBtn && demandesPaiement.length === 0 && $scope.hasARemainingAmountToAsk()) {
            // Added $timeout function so that redirection works
            // Found details here: http://stackoverflow.com/questions/28208893/ui-router-state-go-is-not-redirecting
            $timeout(function () {
              $state.go('app.connected.config.depot.demandePaiement', {
                configurationId: teleservicePaiement.reference,
                referenceAide: aide.reference,
                financeurHref: $scope.financeurHref,
                dossier: $stateParams.dossier,
              });
            });
          }
        });
    }

    /**
     * get ligne financement by:
     * 1. refDossier -> get ligne by dossier href
     * 2. refFinanceur -> get ligne by financeur
     * 3. otherwise -> get the first ligne associated with a dossier
     *
     * @param {object} aide demande financement
     * @param {string} refDossier reference dossier
     * @param {string} refFinanceur reference financeur
     * @returns {object} ligne
     */
    function getLigneFinancement(aide, refDossier, refFinanceur) {
      if (refDossier) {
        return suiviFinancementService.getLigneWithDossier(aide, refDossier);
      } else if (refFinanceur) {
        return demandesAidesService.findLigneFinancementFinanceur(aide, refFinanceur);
      }

      return demandesAidesService.findLigneFinancement(aide);
    }

    $scope.aide = aide;
    $scope.referenceDossier = $stateParams.dossier;
    $scope.financeurHref = $stateParams?.financeur;

    // get financial line associated with current dossier or financeur
    $scope.ligneDossier = getLigneFinancement($scope.aide, $scope.referenceDossier, $scope.financeurHref);

    // get dossier informations from line
    $scope.dossier = $scope.ligneDossier?.financement?.source;
    $scope.dossierTitle = $scope.dossier?.title || $scope.ligneDossier?.financement?.financeur?.title;

    // Do not display demande paiement "en cours" if the owner is not the current user
    $scope.demandesPaiement =
      demandesPaiement?.filter((demande) => {
        demande.tiersAuthorized = $rootScope.currentUser?.self === demande.history?.begin?.user?.href;
        return demande.tiersAuthorized || demande.statut !== 'EN_COURS';
      }, []) ?? [];

    $scope.teleservicePaiement = teleservicePaiement;
    $scope.configurationId = teleservicePaiement?.reference;

    // demande de compléments
    demandesPaiement.forEach((demandePaiement) => {
      const nbTransmittedDemandesComplements =
        demandePaiement.demandesComplementPieces?.reduce(
          (sum, demComp) => (demComp.avancement === 'TRANSMIS' ? sum + 1 : sum),
          0
        ) ?? 0;

      demandePaiement.nbComplements = nbTransmittedDemandesComplements;
      demandePaiement.hasComplements = nbTransmittedDemandesComplements > 0;
    });

    // Multi-synchro case (ect)
    // we still need to maintain this mode for the moment
    if (aide.multiFinanceur && _.isEmpty(aide.financeurPrincipal)) {
      $scope.autorisationDemandePaiement =
        $scope.teleservicePaiement &&
        ($scope.ligneDossier?.financement?.autorisationDemandesPaiementDemandeur ?? false);

      canCreateDemandePaiement();
    } else {
      // mono and multi-partage with financeur principal case
      demandesPaiementService
        .canCreatePaiement($scope.ligneDossier?.financement?.dispositif?.href)
        .then(({ ouvertureDemandePaiementSurDecisionDemandeur, teleservicePaiement }) => {
          $scope.autorisationDemandePaiement = ouvertureDemandePaiementSurDecisionDemandeur;
          $scope.teleservicePaiement = teleservicePaiement;

          // In case v8 we don't have an expand property
          $scope.configurationId =
            teleservicePaiement?.expand?.reference ?? teleservicePaiement?.href?.split('/').pop();
        })
        .catch((error) => {
          $log.warn('Dispositif unreachable', error);
          $scope.autorisationDemandePaiement =
            $scope.ligneDossier?.financement?.autorisationDemandesPaiementDemandeur ?? false;
        })
        .then(() => {
          canCreateDemandePaiement();
        });
    }

    /**
     * Affichage d'une fenêtre modale demandant la confirmation de la suppression d'une demande
     *
     * @param {object} demandePaiement
     */
    $scope.deleteDemandePaiement = function (demandePaiement) {
      const scopeModal = $scope.$new();
      scopeModal.demandePaiement = demandePaiement;

      $modal({
        scope: scopeModal,
        template: 'dashboard/aides/demandes-paiement/modal/modal-delete-demande.html',
      });
    };

    // We need a comparator to order demand list with Date & hour (without a comparator, it only order on date)
    $scope.dateTimeComparator = function (date1, date2) {
      return moment(_.get(date1, 'value.begin.date')).isAfter(moment(_.get(date2, 'value.begin.date'))) ? 1 : -1;
    };

    /**
     * Suppression d'une demande
     *
     * @param  {object} modal       Modal to hide after the persistence has been deleted
     * @param  {object} demandePaiement Demande paiement
     */
    $scope.confirmDeleteDemande = function (modal, demandePaiement) {
      modal.$hide();

      demandesPaiementService
        .removeDemandePaiement(demandePaiement)
        .then(function () {
          // Supprimer l'élément de la liste sans recharger pour autant.
          $scope.demandesPaiement = _.filter($scope.demandesPaiement, function (p) {
            return demandePaiement.reference !== p.reference;
          });

          $scope.alerts = alertsService.getAlertSuccess(
            $translate.instant('connected.dashboard.aides.demandesAides.deleted')
          );
        })
        .catch(function (error) {
          $scope.alerts = alertsService.getAlertError(`${$translate.instant('common.error.happened')} ${error.data}`);
        });
    };

    /**
     * Checks if there is still an amount to request on the voted amount
     *
     * @returns {boolean} true when there's a remaining amount to ask
     */
    $scope.hasARemainingAmountToAsk = function () {
      return demandesPaiementService.getMontantRestantADemander($scope.demandesPaiement, decisions) > 0;
    };

    /**
     * Checks if the status is in progress to modify the status of the label
     *
     * @param  {object} demandePaiement Demande paiement
     * @returns {string} demande's libelle
     */
    $scope.libelleDemandePaiement = function (demandePaiement) {
      const libelleEnCours =
        demandePaiement.title.split('du')[0] +
        $translate.instant('connected.dashboard.aides.demandesPaiement.demandePaiement.status.REQUESTED').toLowerCase();
      return demandePaiement.statut === 'EN_COURS' ? libelleEnCours : demandePaiement.title;
    };

    /**
     * Get demandes paiement to display
     *
     * @returns {object[]} Demandes paiement to display
     */
    $scope.displayedDemandesPaiement = function () {
      return demandesPaiementService.getVisiblesDemandesPaiement($scope.demandesPaiement);
    };

    /**
     * Check if demande-paiement is shared with current user
     *
     * @returns {boolean} true if it's accessed through sharing
     */
    $scope.isAccessedThroughSharing = function () {
      return demandesPaiementService.isAccessedThroughSharing($scope.demandesPaiement);
    };

    /**
     * Returns if demande-paiement is owned by current user and is enCours
     *
     * @param {object} demandePaiement
     * @returns {boolean} true if demande paiement can be shared
     */
    $scope.canDemandePaiementBeShared = function (demandePaiement) {
      return demandesPaiementService.canBeShared(demandePaiement);
    };
  },
]);
