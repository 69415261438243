'use strict';
angular.module('common.services').factory('anonymizationService', [anonymizationService]);

/**
 * @returns {object}
 */
function anonymizationService() {
  'use strict';
  /**
   * Concat character
   *
   * @param {number} length number of X to concat
   * @returns {string} of X
   */
  const concatXchar = (length) => {
    return 'X'.repeat(length);
  };

  /**
   * Anonymize IBAN
   *
   * @param {string} iban to be anonymized
   * @returns {string} anonymized IBAN if iban is a string and length >= 7
   */
  const transformIban = (iban) => {
    if (typeof iban !== 'string' || iban.length < 7) {
      return iban;
    }
    return iban.substring(0, 3) + concatXchar(iban.length - 6) + iban.substring(iban.length, iban.length - 3);
  };

  /**
   * anonymize either SEPA or non-SEPA Iban
   *
   * @param {object} domiciliationBancaire
   * @returns {object} domicialication Bancaire anonymized (IBAN only if it exists is object)
   */
  const anonymizeDomiciliationBancaire = (domiciliationBancaire) => {
    // SEPA
    if (domiciliationBancaire?.IBAN?.numero) {
      domiciliationBancaire.IBAN.numero = transformIban(domiciliationBancaire.IBAN.numero);
      //NOT SEPA
    } else if (Array.isArray(domiciliationBancaire?.horsSEPA)) {
      const horsSepaObject = domiciliationBancaire.horsSEPA.find((elem) => elem.cle === 'numero');
      if (horsSepaObject) {
        const index = domiciliationBancaire.horsSEPA.indexOf(horsSepaObject);
        domiciliationBancaire.horsSEPA[index].valeur = transformIban(horsSepaObject.valeur);
      }
    }
    return domiciliationBancaire;
  };

  return {
    transformIban,
    anonymizeDomiciliationBancaire,
  };
}
