'use strict';
angular.module('tiers').directive('identificationInformationsPersonnellesForm', [
  'adresseService',
  'validationService',
  'accountManagementService',
  (adresseService, validationService, accountManagementService) => {
    return {
      restrict: 'E',
      replace: true,
      templateUrl:
        'tiers/tiers-directives/identification-form/includes/identification-informations-personnelles-form/identification-informations-personnelles.html',
      scope: {
        tiers: '<',
        mdm: '=',
        edit: '=',
        viewConfiguration: '=',
        user: '=',
        civilites: '=',
        filteredFormesJuridiques: '=',
        fullName: '=',
        isAccessedThroughSharing: '<',
        isBeneficiaire: '<',
      },

      link: (scope) => {
        // Civilites
        scope.listeCivilites = scope.civilites || _.get(scope.mdm, 'civilites.array');

        // format nomComplet
        scope.nomCompletValid = scope.fullName.valid;

        // Check if email respect RFCs
        scope.validationService = validationService;

        scope.dateNaissancePickerConfig = {
          min: '01/01/1900',
          max: new Date(),
          culture: 'fr-FR',
        };

        scope.tiers.fillInformationsTiersWithUser(scope.user, scope.mdm);

        // Initialize dateNaissance
        scope.identification = {};

        const userBirthDate = scope?.user?.birthdate;
        const userBirthPlaceName = scope?.user?.birthplaceName;
        const userBirthPlace = scope?.user?.birthplace;
        const individuDateNaissance = scope?.tiers?.individu?.Naissance?.DateNaissance;

        accountManagementService.getPublicSettings().then(function (publicSettings) {
          // check if provider user exist in list oidcProviders
          // if exist set readOnly value in the scope for birthdate and birthplace
          const userProviderId = _.get(scope, 'user.providerId');
          const oidcProviders = _.get(publicSettings, 'data.oidcProviders', []);
          const provider = oidcProviders.filter((oidcProvider) => oidcProvider.providerId === userProviderId);
          if (provider.length && provider[0].isTrusted) {
            scope.isBirthplaceReadOnly = !_.isEmpty(userBirthPlaceName);
            scope.isBirthdateReadOnly = !_.isEmpty(userBirthDate);
          }
        });

        if (userBirthDate || individuDateNaissance) {
          // scope.user.birthdate is defined when user come from FranceConnect. format is YYYY-MM-DD
          // (see https://franceconnect.gouv.fr/fournisseur-service)
          // moment().toDate to keep the good day without considering timezone
          let dateNaissanceDisplay;
          if (userBirthDate) {
            dateNaissanceDisplay = moment(userBirthDate);
            _.set(scope, 'tiers.individu.Naissance.DateNaissance', new Date(userBirthDate));
            _.set(scope, 'tiers.individu.Naissance.DateNaissanceDisplay', dateNaissanceDisplay.format('DD/MM/YYYY'));
          }
          if (individuDateNaissance) {
            dateNaissanceDisplay = moment(individuDateNaissance);
            _.set(scope, 'tiers.individu.Naissance.DateNaissance', new Date(individuDateNaissance));
            _.set(scope, 'tiers.individu.Naissance.DateNaissanceDisplay', dateNaissanceDisplay.format('DD/MM/YYYY'));
          }
        }

        const birthplace = _.get(scope, 'tiers.individu.Naissance.LieuNaissance.Localite.value');
        if (!birthplace && userBirthPlaceName) {
          _.set(scope, 'tiers.individu.Naissance.LieuNaissance.Localite.value', userBirthPlaceName);
        } else if (userBirthDate && !birthplace && userBirthPlace) {
          adresseService.searchCodeInseeVille(userBirthPlace).then((ville) => {
            _.set(scope, 'tiers.individu.Naissance.LieuNaissance.Localite.value', ville);
          });
        }

        // Manage phones
        const getPhone = (phones, type) => {
          // Find phone by type
          const index = _.findIndex(phones, (phone) => {
            return phone.TYPE === type;
          });
          if (index > -1) {
            return phones[index].value;
          }
          // Backward compatibility, may be there is no type so 0 = home, 1 = cell and 2 = fax
          const position = type === 'home' ? 0 : type === 'cell' ? 1 : type === 'fax' ? 2 : -1;
          if (typeof phones[position] !== 'undefined' && !phones[position].TYPE) {
            return phones[position].value;
          }
          return '';
        };

        scope.phone = {};
        scope.phone.home = _.get(scope, 'tiers.telephones') && getPhone(scope.tiers.telephones, 'home');
        scope.phone.cell = _.get(scope, 'tiers.telephones') && getPhone(scope.tiers.telephones, 'cell');
        scope.phone.fax = _.get(scope, 'tiers.telephones') && getPhone(scope.tiers.telephones, 'fax');

        /**
         * Allow to control civilite value and delete if it's not valued
         */
        scope.onCiviliteChange = () => {
          if (_.isEmpty(scope.tiers.civilite)) {
            delete scope.tiers.civilite;
          }
        };

        scope.$watchGroup(['phone.home', 'phone.cell', 'phone.fax'], (newPhoneValue) => {
          if (scope.tiers) {
            scope.tiers.telephones = newPhoneValue
              .map((phone, index) => {
                return {
                  TYPE: index === 0 ? 'home' : index === 1 ? 'cell' : 'fax',
                  value: phone,
                };
              })
              .filter((phone) => {
                return !_.isEmpty(phone.value);
              });
          }
        });

        // check if birthname should be displayed
        scope.displayBirthname =
          scope.viewConfiguration.fields.birthname &&
          !scope.viewConfiguration.fields.birthname.hidden &&
          scope.tiers.famille.expand.personnaliteJuridique === 'PHYSIQUE' &&
          scope.tiers.famille.expand.infosStandardsComplementaires?.NomFamille?.visible;
        // In sharing mode, hide name and email text/inputs (PLAID-66072 RG 2-05)

        // check if name & email should be displayed as text
        const personnaliteJuridique = _.get(scope.tiers, 'famille.expand.personnaliteJuridique');
        scope.displayReadOnlyNameAndEmail =
          !scope.isAccessedThroughSharing && scope.user && (personnaliteJuridique === 'MORALE' || !scope.edit);
        //list of elements that are displayable for non-physical person
        let personalInformationTiersMoralFields = ['email', 'phone', 'mobile', 'fax'];
        //check if an information for the personal information is marked as visible
        const atLeastOnePhysicalFieldToDisplay = personalInformationTiersMoralFields.some(
          (fieldName) => scope.viewConfiguration.fields[fieldName] && !scope.viewConfiguration.fields[fieldName].hidden
        );

        scope.displayPersonalInformations = true;
        // PLAID-84965 : Hide section during depot delegue for tiers MORALE when all informations are hidden by the administrator
        if (scope.isBeneficiaire && personnaliteJuridique === 'MORALE' && !atLeastOnePhysicalFieldToDisplay) {
          scope.displayPersonalInformations = false;
        }

        //check if name form should be displayed
        scope.displayNameForm =
          !scope.isAccessedThroughSharing && personnaliteJuridique === 'PHYSIQUE' && (!scope.user || scope.edit);

        // check if email form should be displayed
        scope.displayEmailForm = !scope.isAccessedThroughSharing && (!scope.user || scope.edit);
      },
    };
  },
]);
