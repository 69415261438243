'use strict';
angular.module('common.directives').directive('modelChange', function () {
  return {
    link: function (scope, element, attrs) {
      scope.$watch(attrs.model, function () {
        scope.$parent.$eval(attrs.modelChange);
      });
    },
  };
});
