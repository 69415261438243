'use strict';

angular.module('common.directives').directive('captcha', [
  'vcRecaptchaService',

  function (vcRecaptchaService) {
    return {
      replace: true,
      transclude: false,
      templateUrl: 'common/common-directives/captcha/captcha.html',
      require: '^form',
      scope: {
        model: '=',
        config: '=',
      },

      link: function (scope, attrs, element, formController) {
        scope.form = formController;

        scope.$on('reload-captcha', function () {
          vcRecaptchaService.reload(scope.widgetId);
        });

        scope.$watch('form.attempted', function () {
          scope.error = !!_.get(scope.form, '$error.recaptcha') && scope.form.attempted;
        });

        scope.setWidgetId = function (widgetId) {
          scope.widgetId = widgetId;
        };

        scope.setResponse = function (response) {
          scope.model = response;
          scope.error = false;
        };

        scope.cbExpiration = function () {
          console.info('Captcha expired !');
          scope.model = null;
          scope.error = false;
        };
      },
    };
  },
]);
