'use strict';

/**
 * @typedef {import('@mgdis/eu-domain').ITiersAccountService} ITiersAccountService
 * @typedef {import('@mgdis/eu-domain').Tiers} Tiers
 * @typedef {import('@mgdis/eu-domain').UserInfos} UserInfos
 * @typedef {import('@mgdis/eu-domain').TiersLinkedAccountExpanded} TiersLinkedAccountExpanded
 * @typedef {Function} OnInvited
 * @param {{accounts: TiersLinkedAccountExpanded[]}}
 */

class AccountsTiersInvitationComponent {
  /** @type {Tiers} **/
  tiers;
  /** @type {UserInfos} **/
  user;
  /** @type {TiersLinkedAccountExpanded[]} **/
  accounts;

  /** @type {OnInvited} **/
  onInvited;

  /**
   *
   * @param {object} $translate AngularJS translate
   * @param {ITiersAccountService} tiersAccountServiceAPI tiersAccountServiceAPI
   * @param {object} $log $log
   */

  constructor($translate, tiersAccountServiceAPI, $log) {
    this.$translate = $translate;
    this.tiersAccountServiceAPI = tiersAccountServiceAPI;
    this.$log = $log;
    this.inviteAccountModal = {
      show: false,
      email: '',
      valid: false,
      submitDisabled: false,
    };

    this.inviteEmailFieldRef = null;
    this.inviteFormRef = null;
  }

  handleEmailChange(event) {
    this.inviteAccountModal.email = event.detail ?? '';
  }

  handleValidityChanged(event) {
    this.inviteAccountModal.valid = event.detail ?? false;
  }

  async handleAccountFormSubmit() {
    try {
      if (this.inviteAccountModal.submitDisabled) {
        return;
      }
      if (this.inviteAccountModal.valid) {
        const accountAlreadyAttached = this.accounts.find((account) =>
          account?.expand?.emails?.some((email) => email.toLowerCase() === this.inviteAccountModal.email.toLowerCase())
        );
        if (accountAlreadyAttached) {
          this.inviteEmailFieldRef[0].setError(
            false,
            this.$translate.instant('tiers.accounts-tiers.invite.error.alreadyAttached')
          );
        } else {
          this.inviteAccountModal.submitDisabled = true;
          const result = await this.tiersAccountServiceAPI.attachUserToTiers(this.tiers, this.inviteAccountModal.email);
          this.accounts = result.accounts;
          this.hideModal();
          if (result.accountExists) {
            this.sendNotificationSuccess('tiers.accounts-tiers.invite.added');
          } else {
            this.sendNotificationSuccess('tiers.accounts-tiers.invite.success');
          }
          this.onInvited({ accounts: this.accounts });
        }
      } else {
        this.inviteFormRef[0].displayError();
      }
    } catch (error) {
      this.$log.error('[account-tiers] Error handleAccountFormSubmit', error);
      this.hideModal();
      this.sendNotificationError('tiers.accounts-tiers.invite.error.failed');
    }
  }

  showModal() {
    this.inviteAccountModal.show = true;
    this.inviteAccountModal.email = '';
    this.inviteAccountModal.valid = false;
    this.inviteAccountModal.submitDisabled = false;
  }

  hideModal() {
    this.inviteAccountModal.show = false;
  }

  /**
   *
   * @param {string} i18nKey
   */
  sendNotificationSuccess(i18nKey) {
    NotificationCenter.postMessage({
      context: 'accounts-tiers-invitation',
      content: this.$translate.instant(i18nKey),
      variant: 'success',
    });
  }

  /**
   *
   * @param {string} i18nKey
   */
  sendNotificationError(i18nKey) {
    NotificationCenter.postMessage({
      context: 'accounts-tiers-invitation',
      content: this.$translate.instant(i18nKey),
      variant: 'danger',
    });
  }
}

AccountsTiersInvitationComponent.$inject = ['$translate', 'tiersAccountServiceAPI', '$log'];
angular.module('tiers').component('accountsTiersInvitation', {
  templateUrl: 'tiers/tiers-components/accounts-tiers/accounts-tiers-invitation.html',
  bindings: {
    user: '<',
    tiers: '<',
    accounts: '<',
    onInvited: '&',
  },
  controller: AccountsTiersInvitationComponent,
});
