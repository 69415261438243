'use strict';
angular.module('portailDepotDemandeAide.depot').controller('demandePaiementDocumentComptableController', [
  'demandesPaiementService',
  '$q',
  '$scope',
  '$timeout',
  'IFrameCommunicationManager',
  function demandePaiementDocumentComptableController(
    demandesPaiementService,
    $q,
    $scope,
    $timeout,
    IFrameCommunicationManager
  ) {
    $scope.initializeChild();

    $scope.pageOptions = _.get($scope, 'teleserviceConfiguration.workflow.pageDocumentComptable', {
      actif: false,
    });

    $scope.cleanNavigate();

    $scope.navigate.ns = $scope.documentComptableConfiguration.ns;
    $scope.stepsWizard.steps = $scope.getDemandePaiementSteps();
    $scope.stepsWizard.active = 'informationsGenerales';
    $scope.isWaitingForSaveResponse = false;

    const iFrameCommunicationManager = new IFrameCommunicationManager('#iframeDocumentComptablePaiement');
    // If page is not active
    // or if demande paiement doesn't have planFinancement depose
    // we just go next
    if ($scope.pageOptions.actif && demandesPaiementService.getPlanFinancementDepose($scope.demandePaiement)) {
      $scope.activePage();
    } else {
      $scope.navigate.next(true);
    }

    this.$onInit = function () {
      // Get iframe's URL
      $scope.iframeDocumentComptablePaiement = demandesPaiementService.getPlanFinancementIframeUrl(
        $scope.demandePaiement,
        false,
        true
      );
    };

    $scope.$on('$destroy', function () {
      iFrameCommunicationManager.close();
    });

    /**
     * Update the plan-financement, if the update is automatic no message of update must be displayed
     *
     * @param {object} params parameters
     * @param {boolean} [params.displayUpdateMessages = true] True if we must display a message after update
     * @returns {Promise<void>}
     */
    $scope.savePlanFinancement = function ({ displayUpdateMessages = true } = {}) {
      const options = { displayUpdateMessages };

      // Action we are asking
      // the response has to have the same route and action
      const eventToUpdatePlanFinancement = {
        route: 'demandePaiement',
        action: 'savePlanFinancement',
        options,
      };

      return $q((resolve) => {
        // Listen for a response from the iframe
        iFrameCommunicationManager.manageEvent(({ data }) => {
          if (
            data.route === eventToUpdatePlanFinancement.route &&
            data.action === eventToUpdatePlanFinancement.action
          ) {
            resolve(data);
          }
        });

        // Post the message to the iframe to do the save
        iFrameCommunicationManager.sendEvent(eventToUpdatePlanFinancement);
      });
    };

    $scope.navigate.next = function (forget) {
      // Fast skip if we are already waiting
      if ($scope.isWaitingForSaveResponse) {
        return;
      }

      // Disable the navigation
      $scope.navigate.lock++;
      $scope.isWaitingForSaveResponse = true;

      return $scope.savePlanFinancement().then(function (dataSendedByUpdate) {
        // Enable the navigation
        $scope.navigate.lock--;
        $scope.isWaitingForSaveResponse = false;
        if (dataSendedByUpdate.success) {
          $scope.goToStep('domiciliation-bancaire', forget);
        }
      });
    };

    $timeout(function () {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },

        '#iframeDocumentComptablePaiement'
      );
    }, 0);

    $scope.sharedWithChild.automaticSaveActive = true;
    $scope.sharedWithChild.beforeAutomaticSave = function () {
      return $scope.savePlanFinancement({ displayUpdateMessages: false });
    };

    /**
     * Update the plan-financement before we go back to the previous step
     *
     * @returns {Promise<void>}
     */
    $scope.sharedWithChild.beforePrevious = $scope.sharedWithChild.beforeAutomaticSave;
  },
]);
