'use strict';
angular.module('aides.recapitulatif').directive('recapitulatifPieces', [
  'piecesService',
  function (piecesService) {
    'use strict';

    return {
      replace: true,
      templateUrl: 'aides/aides-directives/recapitulatif/pieces/recapitulatif-pieces.html',
      scope: {
        aide: '=',
      },

      link: function (scope) {
        scope.viewConfiguration = {
          ns: 'teleservice.pieces',
        };

        scope.urlFileIcons = './resources/images/file-icons/';

        /**
         * Evaluate conditionAffichage (visible/hidden) setting on a piece
         *
         * @function isPieceVisible
         * @param {object} piece the piece
         * @returns {boolean} the evaluate condition
         */
        scope.isPieceVisible = function (piece) {
          return piecesService.evalConditionPiece(scope, piece, 'conditionAffichage');
        };
      },
    };
  },
]);
