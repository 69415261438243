'use strict';
JustificationController.$inject = [
  '$state',
  '$rootScope',
  '$log',
  '$stateParams',
  '$translate',
  'justificationService',
  'alertsService',
  'JustificationsConstant',
]; // Module declaration
angular.module('portailDepotDemandeAide.depot').component('justificationComponent', {
  templateUrl: 'depot/justification/justification.component.html',
  controller: /* @ngInject */ JustificationController,
  bindings: {
    justification: '<',
    piecesExpanded: '<',
    teleserviceJustification: '<',
    demandeFinancement: '<',
  },
});

/**
 *
 * @param {object} $state
 * @param {object} $rootScope
 * @param {object} $log
 * @param {object} $stateParams
 * @param {object} $translate
 * @param {object} justificationService
 * @param {object} alertsService
 * @param {object} JustificationsConstant
 */
function JustificationController(
  $state,
  $rootScope,
  $log,
  $stateParams,
  $translate,
  justificationService,
  alertsService,
  JustificationsConstant
) {
  var ctrl = this;

  ctrl.$onInit = function () {
    // Allow navigation on init
    ctrl.disableNavigation = false;

    // set print configuration
    ctrl.setPrint();
    // set recap view
    ctrl.setRecap();
    ctrl.navigationHelper = {};
    ctrl.stepsWizard = {};
    ctrl._justificationsConstant = JustificationsConstant;

    // Store original justification
    ctrl.justificationOriginal = _.cloneDeep(ctrl.justification);

    // Build step wizard
    ctrl.buildStepsWizard();
    // Set active step
    setActiveStep();
    // Set persistence configuration
    ctrl.persistenceConfiguration = {
      allowedExtensions: ctrl.teleserviceJustification.allowedExtensions,
      maxDocumentSize: ctrl.teleserviceJustification.maxDocumentSize,
      maxNumberDocuments: ctrl.teleserviceJustification.maxNumberDocuments,
    };

    // Get plan financement model from demande financement telervice
    ctrl.planFinancementModel = _.get(
      ctrl.demandeFinancement,
      'teleservice.expand.workflow.simple.pageDocumentComptable.typeDocumentComptable.planFinancement'
    );
  };

  ctrl.setPrint = function () {
    ctrl.print = $stateParams.print === 'true';
  };

  ctrl.setRecap = function () {
    ctrl.recap = $stateParams.recap === 'true';
  };

  /**
   * Validate step and go to the next one
   *
   * @param {number} [stepIncrement]
   * @returns {Promise<void> | void}
   */
  ctrl.validStep = function (stepIncrement = 1) {
    // we stop process if loading in progress
    if (ctrl.disableNavigation) return;
    ctrl.disableNavigation = true;

    return ctrl
      .updateJustification()
      .then(() => {
        ctrl.justificationOriginal = _.cloneDeep(ctrl.justification);
        ctrl.goToStep(stepIncrement);
      })
      .catch(catchSaveOrUpdateError)
      .finally(() => {
        ctrl.disableNavigation = false;
      });
  };

  ctrl.checkStatus = function () {
    // If the justification is in a 'NEED_UPDATE' statut, set the statut to 'REQUESTED' on the first step validation
    if (ctrl.justification.statut === ctrl._justificationsConstant.justificationSubStatuts.NEED_UPDATE) {
      ctrl.justification.statut = ctrl._justificationsConstant.justificationSubStatuts.REQUESTED;
      ctrl
        .updateJustification()
        .then(() => {
          ctrl.justificationOriginal = _.cloneDeep(ctrl.justification);
        })
        .catch(catchSaveOrUpdateError);
    }
  };

  const catchSaveOrUpdateError = (err) => {
    ctrl.displayError(err, 'justification.common.alerts.error.default');
  };

  ctrl.displayError = (error, message) => {
    $rootScope.$broadcast('alerts', alertsService.getAlertError(message));
    $log.error(error);
  };

  ctrl.updateJustification = function () {
    return justificationService.updateJustification(ctrl.justificationOriginal, ctrl.justification);
  };

  ctrl.saveJustification = function () {
    ctrl
      .updateJustification()
      .then(() => {
        ctrl.justificationOriginal = _.cloneDeep(ctrl.justification);
        $rootScope.$broadcast('alerts', alertsService.getAlertSuccess('justification.common.alerts.success.default'));
      })
      .catch(catchSaveOrUpdateError);
  };

  /**
   * go to next or previous step
   *
   * @param {number} n number of step to go
   * @returns {void}
   */
  ctrl.goToStep = (n) => {
    $rootScope.$broadcast('hideAlerts');
    const currentIndex = ctrl.getStepIndex(ctrl.stepsWizard.active.page);
    if (currentIndex < ctrl.stepsWizard.steps.length) {
      ctrl.stepsWizard.active = ctrl.stepsWizard.steps[currentIndex + n];
      setActiveStep();
    }
  };

  /**
   * Get step index from page name
   *
   * @param {string} pageName
   * @returns {number} page index
   */
  ctrl.getStepIndex = (pageName) => {
    const pages = ctrl.stepsWizard.steps.map((step) => step.page);
    return pages.indexOf(pageName);
  };

  /**
   * Build the step wizard
   */
  ctrl.buildStepsWizard = function () {
    // build Workflow

    // preambule page
    ctrl.workflowPreambule = {
      wizard: 'preambule',
      page: 'preambule',
      workflow: _.get(ctrl.teleserviceJustification, 'workflow.pagePreambule'),
    };

    // informations générales page
    ctrl.workflowInformationsGenerales = {
      wizard: _.get(ctrl.justification, 'kind') === 'NRA' ? 'informationsNonRealisation' : 'informationsRealisation',
      page: 'informationsGenerales',
      workflow: _.get(ctrl.teleserviceJustification, 'workflow.pageStandard'),
    };

    // informations complémentaires page
    ctrl.workflowInformationsComplementaires = {
      wizard: 'informationsRealisation',
      page: 'informationsComplementaires',
      workflow: _.get(ctrl.teleserviceJustification, 'workflow.pageSpecifique'),
    };

    // set 'informations complémentaires' worflow actif to false if no active views
    if (_.get(ctrl.workflowInformationsComplementaires.workflow, 'actif', false)) {
      ctrl.workflowInformationsComplementaires.workflow.actif =
        _.filter(_.get(ctrl.justification, 'views', []), 'actif').length > 0;
    }

    // moyens humains page
    ctrl.workflowMoyensHumains = {
      wizard: 'informationsRealisation',
      page: 'moyensHumains',
      workflow: _.get(ctrl.teleserviceJustification, 'workflow.pageMoyensHumains'),
    };

    // Tableau de synthèse dépenses page
    ctrl.workflowTableauSynthese = {
      wizard: 'tableauSynthese',
      page: 'tableauSynthese',
      workflow: _.get(ctrl.teleserviceJustification, 'workflow.pageSynthese'),
    };

    // données chiffrées page
    ctrl.workflowDonneesChiffrees = {
      wizard: 'donneesChiffrees',
      page: 'donneesChiffrees',
      workflow: _.get(ctrl.teleserviceJustification, 'workflow.pageDonneesChiffrees'),
    };

    // pieces page
    ctrl.workflowPieces = {
      wizard: 'pagePieces',
      page: 'pagePieces',
      workflow: _.get(ctrl.teleserviceJustification, 'workflow.pagePieces'),
    };

    // recapitulatif page
    ctrl.workflowRecapitulatif = {
      wizard: 'pageRecapitulatif',
      page: 'pageRecapitulatif',
      workflow: _.get(ctrl.teleserviceJustification, 'workflow.pageRecapitulatif'),
    };

    // recapitulatif pdf
    ctrl.workflowRecapPdf = {
      wizard: 'pageRecapPdf',
      page: 'pageRecapPdf',
    };

    // confirmation page
    ctrl.workflowConfirmation = {
      wizard: 'pageConfirmation',
      page: 'pageConfirmation',
      workflow: _.get(ctrl.teleserviceJustification, 'workflow.pageConfirmation'),
    };

    // Worlflow steps, we only kept the active ones
    ctrl.allSteps = [
      ctrl.workflowPreambule,
      ctrl.workflowInformationsGenerales,
      ctrl.workflowMoyensHumains,
      ctrl.workflowInformationsComplementaires,
      ctrl.workflowTableauSynthese,
      ctrl.workflowDonneesChiffrees,
      ctrl.workflowPieces,
      ctrl.workflowRecapitulatif,
      ctrl.workflowConfirmation,
    ];

    const activeSteps = _.filter(ctrl.allSteps, function (step) {
      return _.get(step, 'workflow.actif', false);
    });

    //Remove help text for print
    if (ctrl.print || ctrl.recap) {
      _.forEach(activeSteps, function (step) {
        _.forEach(_.get(step, 'workflow.fields'), function (field) {
          _.unset(field, 'help');
        });
      });
    }

    const wizard = _.filter(
      _.uniq(
        _.map(activeSteps, function (step) {
          return step.wizard;
        })
      ),

      function (step) {
        // we don't keep step pageConfirmation in header
        return step !== 'pageConfirmation';
      }
    );

    const wizardMap = new Map([
      ['preambule', 'preambule'],
      ['informationsRealisation', 'crf'],
      ['informationsNonRealisation', 'nra'],
      ['tableauSynthese', 'synthese'],
      ['donneesChiffrees', 'donneesChiffrees'],
      ['pagePieces', 'pieces'],
      ['pageRecapitulatif', 'recapitulatif'],
    ]);

    const wizardsNames = wizard.map((name) => {
      // default name in trad file
      const defaultName = $translate.instant(`justification.steps.${name}`);
      // custom name in database
      const key = wizardMap.get(name);
      return _.get(ctrl.teleserviceJustification, `workflow.steps.${key}.label`, defaultName);
    });

    const steps = _.map(activeSteps, function (step) {
      return { wizard: step.wizard, page: step.page, customTitle: step.workflow.title };
    });

    // If the justification is in a 'NEED_UPDATE' statut, set the history reference to the first step page
    if (ctrl.justification.statut === ctrl._justificationsConstant.justificationSubStatuts.NEED_UPDATE) {
      _.set(ctrl.justification, 'history.begin.metadata.reference', steps[0].page);
    }

    // Init steps wizard to default value
    const activeStep = ctrl.print
      ? ctrl.workflowRecapPdf
      : steps?.find((step) => step.page === ctrl.justification.history.begin?.metadata?.reference) ?? steps[0];

    ctrl.stepsWizard = {
      wizard,
      steps,
      active: activeStep,
      wizardsNames,
    };
  };

  var setActiveStep = function () {
    // update i18n
    const state = $state.$current;
    const defaultTitle = _.get(ctrl.stepsWizard, 'active.page');
    state.data = $state.$current.data || {};
    ctrl.navigationHelper.customTitle = _.get(ctrl.stepsWizard, 'active.customTitle');
    state.data.title = ctrl.navigationHelper.customTitle || 'justification.steps.' + defaultTitle;
    $rootScope.pageConfigurationKey = _.get(ctrl.teleserviceJustification, 'title');
    ctrl.navigationHelper.title = 'justification.' + _.get(ctrl.stepsWizard, 'active.wizard') + '.title';
    ctrl.navigationHelper.smallTitle = 'justification.' + defaultTitle + '.title';

    // we don't patch if page doesn't exist in workflow
    var activeWorkflow =
      _.find(ctrl.allSteps || [], function (step) {
        return step.page === _.get(ctrl.stepsWizard, 'active.page');
      }) || {};

    if (!activeWorkflow.workflow) return;

    // save step
    const patches = [
      {
        op: 'replace',
        path: '/history/begin/metadata/reference',
        value: ctrl.stepsWizard.active.page,
      },

      {
        op: 'replace',
        path: '/history/begin/metadata/type',
        value: 'step',
      },

      {
        op: 'replace',
        path: '/history/begin/metadata/date',
        value: new Date(),
      },
    ];

    if (!ctrl.justification.history.begin?.metadata) {
      patches.unshift({ op: 'add', path: '/history/begin/metadata', value: {} });
    }
    if (!ctrl.justification.history.events) {
      patches.push({ op: 'add', path: '/history/events', value: [] });
    }

    justificationService.patch(ctrl.justification.id, patches).then(() => {
      ctrl.checkStatus();
    });
  };
}
