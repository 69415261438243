'use strict';
angular.module('common.model').factory('Piece', [
  'Document',
  'Link',
  'LocalizedText',
  function (Document, Link, LocalizedText) {
    'use strict';

    /**
     *
     * @param {object} data
     */
    function Piece(data) {
      data = data || {};
      this.reference = data.reference;
      if (data.libelle && _.isNil(data.libelle.value)) {
        data.libelle = {
          value: data.libelle,
        };
      }
      if (data.libelle) {
        this.libelle = new LocalizedText(data.libelle);
      }
      this.actif = data.actif || true;
      if (data.commentaire) {
        this.commentaire = new LocalizedText(data.commentaire);
      }
      if (data.description) {
        this.description = new LocalizedText(data.description);
      }
      this.expiration = data.expiration;
      this.modeTransmission = data.modeTransmission || 'DEPOSEE';
      this.nature = data.nature;
      if (_.has(data, 'conforme')) {
        this.conforme = data.conforme;
      }
      this.fonction = data.fonction;
      this.obligatoire = data.obligatoire;
      this.displayListDocuments = data.displayListDocuments;
      this.envoiPostal = data.envoiPostal;
      this.familles = data.familles;
      this.typesPaiement = data.typesPaiement;
      this.conditionAffichage = data.conditionAffichage;
      this.conditionObligatoire = data.conditionObligatoire;
      this.obligatoireSurRecevabilite = data.obligatoireSurRecevabilite;

      // Documents
      if (Array.isArray(data.documents)) {
        this.documents =
          _.map(data.documents, function (doc) {
            return new Link({
              title: doc.title,
              expand: new Document(doc.expand),
              href: doc.href,
              id: doc.id,
              rel: doc.rel,
              origin: doc.origin,
              type: doc.type,
              error: doc.error,
            });
          }) || [];
      }

      // Uniquement utilisé dans le cadre d'une demande en multi-financeurs synchrone
      if (_.has(data, 'conformitesParFinanceur')) {
        this.conformitesParFinanceur = data.conformitesParFinanceur;
      }
    }

    Piece.prototype.getCleanEntity = function () {
      const cleanPiece = _.cloneDeep(this);
      delete cleanPiece.actif;
      delete cleanPiece.displayListDocuments;
      delete cleanPiece.envoiPostal;
      delete cleanPiece.familles;
      delete cleanPiece.typesPaiement;

      // Documents
      if (cleanPiece?.documents?.length > 0) {
        _.each(cleanPiece.documents, function (doc) {
          delete doc.expand;
          delete doc.error;
        });

        // Remove empty documents
        _.remove(cleanPiece.documents, function (doc) {
          return doc && doc.href === undefined;
        });
      }
      return cleanPiece;
    };

    return Piece;
  },
]);
