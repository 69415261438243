'use strict';
/**
 * @param {object} configuration
 * @param {object} $http
 * @param {object} $location
 * @name tiers.services.tiersRattachementsService
 * @description Attachment of a user to a tiers (require configuration, $http, $location)
 * @returns {object}
 */
angular.module('tiers.services').factory('tiersRattachementService', [
  'configuration',
  '$http',
  '$location',

  function (configuration, $http, $location) {
    'use strict';

    let rattachementsConfig;

    /**
     * @returns {string}
     */
    function _getUrl() {
      return configuration.tiers.rattachement.url;
    }

    /**
     * Function allowing to retrieve base url for the documents of the rattachemnte tiers process by a user
     *
     * @returns {string}
     */
    function _getDocumentUploadUrl() {
      return `${configuration.documentCollect?.url}/root/rattachements`;
    }

    return {
      getUrlConfirmationRattachement: function () {
        // Url for accept or reject the rattachement
        var actionUrl = null;
        if (_.get(configuration, 'tiers.rattachement.rattachementConfirmationPath')) {
          actionUrl = $location
            .absUrl()
            .replace($location.url(), configuration.tiers.rattachement.rattachementConfirmationPath);
        }

        return actionUrl;
      },

      getDemandeRattachement: function (demandeRattachementId) {
        return $http.get(_getUrl() + '/tiers/rattachements/' + demandeRattachementId);
      },

      /**
       * Creation of a rattachement request
       *
       * @param {object} user
       * @param {object} individu
       * @param {object} coordonnees
       * @param {string} siret
       * @param {object[]} pieces
       * @param {string} actionUrl
       * @param {string} familleTiers
       * @returns {Promise}
       */
      addDemandeRattachement: function (user, individu, coordonnees, siret, pieces, actionUrl, familleTiers) {
        var demandeRattachement = {
          user: user,
          individu: individu,
          coordonnees: coordonnees,
          siret: siret,
          pieces: pieces,
          famille: familleTiers,
        };

        return $http.post(_getUrl() + '/tiers/rattachements', demandeRattachement, {
          params: {
            actionUrl: actionUrl,
          },
        });
      },

      /**
       * Accept a rattachement
       *
       * @param {string} demandeRattachement Rattachement object
       * @returns {Promise}
       */
      validDemandeRattachement: function (demandeRattachement) {
        var demandeRattachementId = demandeRattachement.id;
        return $http.put(_getUrl() + '/tiers/rattachements/' + demandeRattachementId, demandeRattachement);
      },

      /**
       * List of rattachements
       *
       * @returns {Promise}
       */
      listDemandesRattachement: function () {
        return $http.get(_getUrl() + '/tiers/rattachements/');
      },

      /**
       * Get rattachements config from gestion-depot-demandes public settings
       * (documents allowed extensions, max size, etc)
       *
       * @returns {Promise}
       */
      getRattachementsConfig() {
        if (!rattachementsConfig) {
          rattachementsConfig = $http
            .get(configuration.publicSettingsGestionDepotDemandes.service)
            .then(function (response) {
              return _.get(response, 'data.rattachements');
            });
        }

        return rattachementsConfig;
      },

      /**
       * Get URL to upload rattachement document for a user
       *
       * @param {string} userId - Login of the connected user
       * @returns {string} return base url to manage document
       */
      getDocumentUploadUrl(userId) {
        return `${_getDocumentUploadUrl()}/${userId ?? ''}`;
      },

      /**
       * Get URL to upload rattachement document
       *
       * @param {string} userId - Login of the connected user
       * @returns {string}
       */
      getEntityUriTiersRattachements(userId) {
        return `${_getUrl()}/tiers/rattachements/documents/${userId ?? ''}`;
      },
    };
  },
]);
