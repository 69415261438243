'use strict';
angular.module('portailDepotDemandeAide.depot').controller('depotSimpleBeneficiaireRepresentantLegalController', [
  '$scope',
  'Representant',
  'StoreService',
  'tiersService',
  function ($scope, Representant, StoreService, tiersService) {
    'use strict';

    $scope.beneficiaire = StoreService.beneficiaire.get();

    tiersService.getPublicSettingsTiers().then((response) => {
      $scope.sectionAdresseContacts = response.sectionAdresseContacts;
    });

    // Initialisation du représentant légal
    // Correspond au premier représentant du tableau des représentants au niveau de l'objet tiers
    if ($scope.beneficiaire.representants.length === 0) {
      $scope.beneficiaire.representants.push(
        new Representant({
          principal: true,
        })
      );
    }

    $scope.representantLegal = _.find($scope.beneficiaire.representants, { principal: true });
    $scope.situationPrincipale = _.find($scope.beneficiaire.situations, { principale: true });

    // store if the user is the representant and if he has the same address than the tiers in metadatas
    // it enables us to keep track of this if we come back on this screen later
    const metadatas = $scope.aide.history.begin.metadata;
    if (!metadatas.representantLegalBeneficiaire) {
      metadatas.representantLegalBeneficiaire = _.pick($scope.representantLegal, ['adresseCommuneTiers']);
    } else {
      _.merge($scope.representantLegal, metadatas.representantLegalBeneficiaire);
    }

    $scope.$watch('representantLegal.adresseCommuneTiers', (newValue) => {
      metadatas.representantLegalBeneficiaire.adresseCommuneTiers = newValue;
    });

    // Fonctions may have been already set by a previous page
    if (!$scope.fonctionsRepresentants.beneficiaire) {
      $scope.setFonctionsRepresentants($scope.beneficiaire.famille.expand, 'beneficiaire');
    }

    $scope.cleanNavigate();
    $scope.navigate.next = () => {
      StoreService.beneficiaire.set($scope.beneficiaire);

      $scope.saveBeneficiaire().then(() => {
        $scope.goToStep('beneficiaire-representants');
      });
    };
    $scope.navigate.ns = $scope.representantLegalBeneficiaireConfiguration.ns;

    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'beneficiaire';
  },
]);
