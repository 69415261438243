'use strict';
angular.module('tiers.model').factory('Rattachement', [
  'Siret',
  'Individu',
  'Coordonnees',

  function (Siret, Individu, Coordonnees) {
    'use strict';

    /**
     * Classe Rattachement. Représente un rattachement d'un individu à un tiers.
     *
     * @param {object} data Rattachement
     */
    function Rattachement(data) {
      data = data || {};
      this.SIRET = new Siret(data.SIRET);
      if (data.individu) {
        this.individu = new Individu(data.individu);
      }
      if (data.coordonnees) {
        this.coordonnees = new Coordonnees(data.Coordonnees);
      }
    }

    Rattachement.prototype.getCleanEntity = function () {
      return _.cloneDeep(this);
    };

    return Rattachement;
  },
]);
