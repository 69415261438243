'use strict';
angular.module('common.directives').directive('alerteur', [
  '$state',
  '$stateParams',
  '$rootScope',
  'jwtSessionService',
  '$sce',
  '$popover',
  '$http',
  '$httpParamSerializer',
  'alerteurService',
  'contributionsService',
  'contributionsConstants',
  '$timeout',
  'configuration',
  'alertsService',
  '$transitions',
  'StoreService',
  '$window',
  function (
    $state,
    $stateParams,
    $rootScope,
    jwtSessionService,
    $sce,
    $popover,
    $http,
    $httpParamSerializer,
    alerteurService,
    contributionsService,
    contributionsConstants,
    $timeout,
    configuration,
    alertsService,
    $transitions,
    StoreService,
    $window
  ) {
    return {
      scope: true,
      replace: true,
      templateUrl: 'common/common-directives/alerteur/alerteur.html',
      link: function ($scope, element) {
        /**
         * The number of unread sollicitations for the connected user
         */
        $scope.unreadSollicitations = -1; // -1 by default to detect bugs

        /**
         * The number of unread echanges for the connected user
         */
        $scope.unreadEchanges = -1; // -1 by default to detect bugs

        /**
         * Boolean to display the correct tab (and iframe)
         */
        $scope.affichageSollicitations = false;
        $scope.affichageEchanges = true; // Echanges by default

        const iframeEchangesUrlParams = $httpParamSerializer({
          jwtKey: jwtSessionService.getJwtKey(),
          theme: 'demandeur',
          currentTiers: StoreService.currentTiersRef.get(),
        });

        $scope.iframeEchangesUrl = $sce.trustAsResourceUrl(
          '/referentiel-echanges/#/' + $stateParams.tenantId + '/echangesNonLus?' + iframeEchangesUrlParams
        );

        $scope.echangesActif = _.get(jwtSessionService.user(), 'echangesActif');

        $scope.afficherOngletSollicitations = function () {
          $scope.affichageSollicitations = true;
          $scope.affichageEchanges = false;
        };

        $scope.afficherOngletEchanges = function () {
          $scope.affichageSollicitations = false;
          $scope.affichageEchanges = true;
        };

        $scope.canDisplayEchanges = function () {
          return $scope.affichageEchanges && $scope.echangesActif && $scope.unreadEchanges > 0;
        };

        $scope.canDisplaySollicitations = function () {
          return $scope.affichageSollicitations && $scope.unreadSollicitations > 0;
        };

        $transitions.onSuccess({ to: 'app.connected.dashboard.echanges.centreEchanges' }, function () {
          $scope.alerteurPopover.$promise.then($scope.alerteurPopover.hide);
        });

        // Prepare popover
        const linkElem = element[0];
        $scope.alerteurPopover = $popover($(linkElem), {
          template: 'common/common-directives/alerteur/alerteur-popover.html',
          placement: 'bottom-right',
          trigger: 'manual',
          autoClose: true,
          scope: $scope,
        });

        $scope.closePopover = function () {
          $scope.alerteurPopover.hide();
        };

        // Open alerteur popover from controller
        $scope.openPopover = function () {
          // Prevent from opening popover when it's already opened
          const toggle = $scope.alerteurPopover.$isShown;
          if (!toggle) {
            // If there is no echange but only sollicitations, we display first the sollicitations tab
            if ($scope.unreadEchanges <= 0 && $scope.unreadSollicitations > 0) {
              $scope.afficherOngletSollicitations();
            }
            $scope.alerteurPopover.$promise.then($scope.alerteurPopover.show);
          }
        };

        /**
         *
         * @param {object} contribution
         */
        function removeContribution(contribution) {
          if (contribution) {
            _.remove($scope.listContributions, ['reference', contribution.reference]);
          }
          if ($scope.unreadSollicitations > 0) {
            $scope.unreadSollicitations -= 1;
          }
        }

        $rootScope.$on('refresh-alerteur-contributions', function (event, contribution) {
          // optimistic update (PLAID-22794)
          // we delete the contribution only when status is ANSWERED
          if (contribution && contribution.statut === contributionsConstants.status.ANSWERED) {
            removeContribution(contribution);
          } else {
            $timeout(refreshContributions, 2000);
          }
        });

        /**
         *
         */
        function refreshContributions() {
          //Init contribution
          contributionsService.searchNonTraiteeForCurrentUser().then(({ contributions, total }) => {
            $scope.listContributions = contributions;
            $scope.unreadSollicitations = total;
          });
        }

        /**
         *
         */
        function countUnreadEchanges() {
          //Init echanges
          alerteurService.countUnreadEchanges($stateParams.tenantId).then(function (total) {
            $scope.unreadEchanges = total;
          });
        }

        /**
         *
         */
        function resizeIframeEchanges() {
          iFrameResize(
            {
              heightCalculationMethod: 'taggedElement',
              checkOrigin: false,
              inPageLinks: true,
              maxHeight: 400,
              scrolling: true,
            },

            '#echangesTabIframe'
          );
        }

        // Event emitted by child iframe when reading notifications
        /**
         *
         * @param {object} msg
         */
        function listenIframeMessages(msg) {
          if (msg.data && msg.data.from === 'iframeEchangesNonLus' && msg.data.action === 'ready') {
            // Event sent when the iframe component is init
            resizeIframeEchanges();
          }
          if (msg.data && msg.data.action === 'goToEchange') {
            if ($scope.alerteurPopover) {
              // Hide popover asynchronously so that it can change page
              $timeout($scope.alerteurPopover.hide, 0);
              if (!_.isEmpty(msg.data.demandeRef)) {
                gestionLinkDemande(
                  _.get(msg, 'data.demandeRef'),
                  _.get(msg, 'data.echangeRef'),
                  _.get(msg, 'data.isAuthorized')
                );
              }
            }
          }
        }

        /**
         *
         * @param {string} demandeRef
         * @param {string} echangeRef
         * @param {boolean} isAuthorized
         */
        function gestionLinkDemande(demandeRef, echangeRef, isAuthorized) {
          if (isAuthorized) {
            $http.get(configuration.aides.recapitulatif.service + '/' + demandeRef).then(function (res) {
              if (res.data) {
                if (res.data.status === 'REQUESTED') {
                  $state.go('app.connected.config.depot.simple', {
                    configurationId: res.data.teleservice.href.split('/').pop(),
                    p: demandeRef,
                    fromEchange: echangeRef,
                  });
                } else {
                  $state.go('app.connected.dashboard.recapitulatif', {
                    aide: demandeRef,
                    fromEchange: echangeRef,
                  });
                }
              }
            });
          } else {
            $rootScope.$broadcast(
              'alerts',
              alertsService.getAlertError('connected.dashboard.alerteur.echanges.error'),
              'general-error-alert'
            );
          }
        }

        $window.addEventListener('message', listenIframeMessages, false);

        $scope.$on('$destroy', function () {
          $window.removeEventListener('message', listenIframeMessages);
        });

        /**
         *
         */
        function init() {
          refreshContributions();
          alerteurService.socketUnreadEchanges($stateParams.tenantId, $rootScope.currentUser, function (result) {
            $scope.unreadEchanges = result;
          });

          // When the panel "echange" is close, count how many unread message do we still have.
          $scope.$watch(
            function () {
              return angular.element('#echangesDemande').is(':visible');
            },
            function (value) {
              if (!value) {
                countUnreadEchanges();
              }
            }
          );
        }

        init();
      },
    };
  },
]);
