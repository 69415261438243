'use strict';

import { TeleservicesStatut } from '@mgdis/eu-domain';

/**
 * @typedef {import('@mgdis/eu-domain').ITiersAccountService} ITiersAccountService
 * @typedef {import('@mgdis/eu-domain').Tiers} Tiers
 * @typedef {import('@mgdis/eu-domain').UserInfos} UserInfos
 * @typedef {import('@mgdis/eu-domain').TiersLinkedAccountExpanded} TiersLinkedAccountExpanded
 * @typedef {import('@mgdis/eu-domain').TiersAccountRestriction} TiersAccountRestriction
 * @typedef {TiersAccountRestriction & {computed: object}} FilteredAccount
 * @typedef {import('@mgdis/eu-domain').ITeleserviceService} ITeleserviceService
 * @typedef {import('@mgdis/eu-domain').Teleservice} Teleservice
 */

class AccountsTiersComponent {
  /** @type {Tiers} **/
  tiers;
  /** @type {UserInfos} **/
  user;
  /** @type {TiersLinkedAccountExpanded[]} **/
  accounts;
  /** @type {FilteredAccount[]} **/
  filteredAccounts;
  /** @type {TiersAccountRestriction[]} **/
  accountsRestrictions;
  /** @type {Teleservice[]} **/
  teleservices;
  /** @type {ITeleserviceService} **/
  teleserviceAPI;

  /**
   *
   * @param {object} $log AngularJS $log
   * @param {object} $translate AngularJS translate
   * @param {object} $q AngularJS $q
   * @param {ITiersAccountService} tiersAccountServiceAPI tiersAccountServiceAPI
   * @param {ITeleserviceService} teleserviceAPI teleserviceAPI
   */

  constructor($log, $translate, $q, tiersAccountServiceAPI, teleserviceAPI) {
    this.$log = $log;
    this.$translate = $translate;
    this.$q = $q;
    this.tiersAccountServiceAPI = tiersAccountServiceAPI;
    this.teleserviceAPI = teleserviceAPI;
    this.filteredAccounts = [];
    this.detachModal = {
      show: false,
      submitDisabled: false,
      account: null,
    };
    this.invitationAllowed = false;
    this.isAllowedToManageDemandeApprenantRestrictions = false;
    this.accountsRestrictions = [];
    this.teleservices = [];
    this.loading = true;
  }

  $onInit() {
    this.invitationAllowed = this.tiersAccountServiceAPI.isAllowedToAddAccount(this.tiers, this.user, this.accounts);

    this.tiersAccountServiceAPI
      .isAllowedToManageDemandeApprenantRestrictions(this.tiers, this.user, this.accounts)
      .then((canManageDemandeApprenantRestrictions) => {
        if (canManageDemandeApprenantRestrictions) {
          return this.$q
            .all([
              this.tiersAccountServiceAPI.listTiersAccountRestrictions(),
              this.teleserviceAPI.listAideFormationTeleservices(),
            ])
            .then(([accountsRestrictions, teleservices]) => {
              this.accountsRestrictions = accountsRestrictions;
              this.teleservices = teleservices;

              const isRestrictionToDisplay = this.teleservices.length > 0;
              this.isAllowedToManageDemandeApprenantRestrictions =
                canManageDemandeApprenantRestrictions && isRestrictionToDisplay;
            });
        }
      })
      .catch((error) => {
        this.$log.error('[accounts-tiers.component] $onInit', error);
      })
      .finally(() => {
        this.onAccountsChanged(this.accounts);
        this.loading = false;
      });
  }

  /**
   *
   * @param {TiersLinkedAccountExpanded} account
   * @param {CustomEvent} event
   * @returns {void}
   */
  onRestrictionChanged(account, event) {
    if (!Array.isArray(event.detail)) {
      // Filtering an mg-input-checkbox trigger a value-change event https://gitlab.mgdis.fr/core/core-ui/core-ui/-/issues/476
      return;
    }
    account.computed.restrictions = event.detail ?? [];
    return this.saveRestrictions();
  }

  saveRestrictions() {
    if (!this.isAllowedToManageDemandeApprenantRestrictions) {
      return;
    }
    /** @type {TiersAccountRestriction[]} **/
    const newAccountRestrictions = [];
    for (const account of this.filteredAccounts) {
      if (this.shouldShowRestrictionsForAccount(account)) {
        const accountRestrictions = account.computed.restrictions
          .filter((restriction) => restriction.value === true)
          .map((restriction) => restriction.id);

        if (accountRestrictions.length > 0) {
          newAccountRestrictions.push({
            userId: account.href,
            allowedTeleservices: accountRestrictions,
          });
        }
      }
    }

    // Skip API calling if nothing has changed
    // This can happen during $onInit
    if (_.isEqual(this.accountsRestrictions, newAccountRestrictions)) {
      return;
    }
    this.tiersAccountServiceAPI
      .updateTiersAccountRestrictions(this.tiers.id, newAccountRestrictions)
      .then((updatedRestriction) => {
        this.accountsRestrictions = updatedRestriction;
        // Do not call onAccountsChanged
        // * This Can cause synchro issue if API does not answer correctly. Should not be an issue most of the time
        // * This cause refresh issue. Configuration tooltip of Teleservices closed on re-render
        // this.onAccountsChanged(this.accounts);
        this.sendNotificationSuccess('tiers.accounts-tiers.restrictions.success');
      })
      .catch((error) => {
        this.$log.error('[accounts-tiers.component] Error updating tiers account restrictions', error);
        this.sendNotificationError('tiers.accounts-tiers.restrictions.error.save');
      });
  }

  /**
   *
   * @param {TiersLinkedAccountExpanded[]} accounts
   */
  onAccountsChanged(accounts) {
    this.accounts = accounts;
    this.filteredAccounts = this.tiersAccountServiceAPI.filterAndSortAccounts(this.accounts).map((account) => {
      return this.formatAccount(account);
    });
    this.invitationAllowed = this.tiersAccountServiceAPI.isAllowedToAddAccount(this.tiers, this.user, this.accounts);
  }

  /**
   *
   * @param {TiersLinkedAccountExpanded} account
   */
  showModal(account) {
    this.detachModal.show = true;
    this.detachModal.submitDisabled = false;
    this.detachModal.account = account;
  }

  hideModal() {
    this.detachModal.show = false;
    this.detachModal.account = null;
  }

  detachAccount() {
    if (this.detachModal.submitDisabled || !this.detachModal.account) {
      return;
    }
    this.detachModal.submitDisabled = true;
    this.tiersAccountServiceAPI
      .detachAccount(this.detachModal.account, this.tiers)
      .then(({ accounts }) => {
        this.onAccountsChanged(accounts);
        this.hideModal();
        this.sendNotificationSuccess('tiers.accounts-tiers.removeAccount.success');
      })
      .catch((error) => {
        this.$log.error('[accounts-tiers] Error during detachAccount', error);
        this.hideModal();
        this.sendNotificationError('tiers.accounts-tiers.removeAccount.error');
      });
  }

  handleActionClick(event, account) {
    const actionName = event?.detail;
    if (actionName === 'unlink' && account) {
      this.showModal(account);
    }
  }

  /**
   * @param {TiersLinkedAccountExpanded} account
   * @returns {boolean} shouldShowAccount
   */
  shouldShowAccount(account) {
    return account?.expand?.userName || false; // We should never have a SIGNATAIRE from here, it should be already filtered
  }

  /**
   * @param {TiersLinkedAccountExpanded} account
   * @returns {boolean} shouldShowAccount
   */
  shouldShowEmail(account) {
    return account?.expand?.emails?.[0] || false;
  }

  /**
   * @param {TiersLinkedAccountExpanded} account
   * @returns {boolean} shouldShowRestrictionsForAccount
   */
  shouldShowRestrictionsForAccount(account) {
    return this.isAllowedToManageDemandeApprenantRestrictions && !this.tiersAccountServiceAPI.isAdministrator(account);
  }

  /**
   *
   * @param {string} i18nKey
   */
  sendNotificationSuccess(i18nKey) {
    NotificationCenter.postMessage({
      context: 'accounts-tiers',
      content: this.$translate.instant(i18nKey),
      variant: 'success',
    });
  }

  /**
   *
   * @param {string} i18nKey
   */
  sendNotificationError(i18nKey) {
    NotificationCenter.postMessage({
      context: 'accounts-tiers',
      content: this.$translate.instant(i18nKey),
      variant: 'danger',
    });
  }

  /**
   * @param {TiersLinkedAccountExpanded} account
   * @returns {string} card name
   */
  formatCardName(account) {
    const cardNameParts = [];
    if (account.expand.name.honorificPrefix) {
      cardNameParts.push(account.expand.name.honorificPrefix);
    }
    if (account.expand.name.givenName) {
      cardNameParts.push(account.expand.name.givenName);
    }
    if (account.expand.name.familyName) {
      cardNameParts.push(account.expand.name.familyName);
    }

    // \u0020 is the unicode for Non-breaking space
    return cardNameParts.join('\u0020');
  }

  /**
   * @param {TiersLinkedAccountExpanded} account
   * @returns {{icon: string} | undefined} card icon
   */
  getIcon(account) {
    const franceConnect = this.tiersAccountServiceAPI.isFranceConnect(account);
    if (!franceConnect) {
      return {
        icon: 'user',
      };
    }
    return undefined;
  }

  /**
   * @param {TiersLinkedAccountExpanded} account
   * @returns {{src: string} | undefined} card logo
   */
  getLogo(account) {
    const franceConnect = this.tiersAccountServiceAPI.isFranceConnect(account);
    if (franceConnect) {
      return {
        src: './resources/images/fc-avatar.svg',
      };
    }
    return undefined;
  }

  getActions(account) {
    const links = [];
    const isAllowedToDetachAccount = this.tiersAccountServiceAPI.isAllowedToRemoveAccount(
      account,
      this.user,
      this.accounts
    );
    if (isAllowedToDetachAccount) {
      links.push({
        id: 'unlink',
        label: this.$translate.instant('tiers.accounts-tiers.removeAccount.title'),
        icon: 'unlink',
        disabled: false,
      });
    }

    return links;
  }

  getAccountInformationToDisplay(account) {
    const values = [];
    if (this.shouldShowAccount(account)) {
      values.push({
        icon: 'user-outline',
        value: account.expand.userName,
      });
    }
    if (this.shouldShowEmail(account)) {
      values.push({
        icon: 'mail-outline',
        link: `mailto:${account.expand.emails[0]}`,
        value: account.expand.emails[0],
      });
    }
    return [values];
  }

  getTags(account) {
    const tags = [];
    if (this.tiersAccountServiceAPI.isAdministrator(account)) {
      tags.push({
        content: this.$translate.instant('tiers.accounts-tiers.administrator'),
        icon: 'key',
        soft: true,
        variant: 'primary',
      });
    }
    if (this.tiersAccountServiceAPI.isSignataire(account, this.accounts)) {
      tags.push({
        content: this.$translate.instant('tiers.accounts-tiers.signataire'),
        icon: 'pen-fancy',
        soft: true,
        variant: 'primary',
      });
    }
    return tags;
  }

  formatAccount(account) {
    const restrictions = this.shouldShowRestrictionsForAccount(account)
      ? this.teleservices
          .map((teleservice) => {
            const restrictions = this.accountsRestrictions.find((restriction) => restriction?.userId === account?.href);
            if (restrictions && Array.isArray(restrictions?.allowedTeleservices)) {
              const isTeleserviceConfigured = restrictions.allowedTeleservices.includes(teleservice.id);

              // Show DESACTIVE teleservice, only if a restriction is configured
              if (teleservice.statut === TeleservicesStatut.DESACTIVE && !isTeleserviceConfigured) {
                return null;
              }

              return {
                title: teleservice.title,
                id: teleservice.id,
                value: isTeleserviceConfigured,
              };
            }

            // Do not show DESACTIVE teleservices
            if (teleservice.statut === TeleservicesStatut.DESACTIVE) {
              return null;
            }

            return {
              title: teleservice.title,
              id: teleservice.id,
              value: false,
            };
          })
          .filter((teleservice) => teleservice !== null)
      : [];
    const result = {
      ...account,
      computed: {
        id: account.href.split('/').pop(),
        icon: this.getIcon(account),
        tags: this.getTags(account),
        logo: this.getLogo(account),
        values: this.getAccountInformationToDisplay(account),
        actions: this.getActions(account),
        title: this.formatCardName(account),
        restrictions,
      },
    };
    const current = this.filteredAccounts.find((acc) => acc?.computed?.id === result.computed.id);
    // Preserve object reference if nothing has changed. Prevent mg-input-checkbox on-value-change event that trigger an HTTP request
    if (_.isEqual(current, result)) {
      return current;
    }
    return result;
  }
}

AccountsTiersComponent.$inject = ['$log', '$translate', '$q', 'tiersAccountServiceAPI', 'teleserviceAPI'];
angular.module('tiers').component('accountsTiers', {
  templateUrl: 'tiers/tiers-components/accounts-tiers/accounts-tiers.html',
  bindings: {
    user: '<',
    tiers: '<',
    accounts: '<',
  },
  controller: AccountsTiersComponent,
});
