'use strict';
angular.module('aides.services').factory('complementsService', [
  '$http',
  'configuration',
  'Complement',
  function ($http, configuration, Complement) {
    'use strict';

    return {
      putAvancement: function (complement, avancement) {
        // Getting complement's ID and updating its status
        let complementId = complement.id;
        complement.avancement = avancement;
        const complementJson = angular.toJson(complement);

        return $http.put(`${complementId}/avancement`, complementJson).then((result) => new Complement(result.data));
      },
    };
  },
]);
