'use strict';
class StringService {
  /**
   * Remove accents from a string
   *
   * @param {string} str
   * @returns {string}
   */
  removeAccents(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  /**
   * Apply a callback on each object of an array to sort it alphabetically by the result
   *
   * @param {object[]} array
   * @param {Function} callback
   * @returns {object[]} sorted array
   */
  alphabeticSortByCallback(array, callback) {
    return array.sort((a, b) => {
      const aSortValue = this.removeAccents(callback(a).toLowerCase());
      const bSortValue = this.removeAccents(callback(b).toLowerCase());
      if (aSortValue > bSortValue) {
        return 1;
      } else if (bSortValue > aSortValue) {
        return -1;
      } else {
        return 0;
      }
    });
  }
}

/**
 * @name stringService
 * @class StringService
 * @description Helpers used to handle strings
 */
angular.module('common.services').service('stringService', StringService);
