'use strict';
/**
 * @param {object} $modal
 * @param {object} configuration
 * @description Directive who display an address.
 * @example
 * <coordonnees-fiche
        tiers="tiers"
        coordonnees="tiers.situations[0]"
        view-configuration="userTiersEditAdresseConfiguration"
        read-only="false"
        validate="saveTiers()"
        mdm="mdm"
        hide-france-country="hideFranceCountry">
    </coordonnees-fiche>
 * @returns {object}   
 */
angular.module('tiers').directive('coordonneesFiche', [
  '$modal',
  'configuration',

  function ($modal, configuration) {
    'use strict';

    return {
      replace: true,
      transclude: true,
      templateUrl: 'tiers/tiers-directives/coordonnees-fiche/coordonnees-fiche.html',
      scope: {
        coordonnees: '=',
        tiers: '=',
        viewConfiguration: '=',
        readOnly: '=',
        hideMap: '=',
        validate: '&',
        mdm: '=',
        modified: '=',
        hideFranceCountry: '=',
      },

      link: function (scope) {
        scope.map = {};

        // Help tooltips
        scope.tooltips = {
          edit: {
            title: 'tiers.coordonnees-fiche.actions.edit',
          },
        };

        /**
         * Affichage de la page d'édition des coordonnées du tiers
         *
         * @param {object} coordonnees
         */
        scope.editCoordonnees = function (coordonnees) {
          if (configuration.tiers.modificationIdentificationTiersParComptes === true) {
            var scopeModal = scope.$new();
            scopeModal.viewConfiguration = scope.viewConfiguration;
            scopeModal.updateCoordonnees = scope.updateCoordonnees;
            scopeModal.coordonnees = angular.copy(scope.coordonnees);
            scopeModal.index = _.findIndex(scope.tiers.situations, function (situation) {
              return situation === coordonnees;
            });
            scopeModal.mdm = scope.mdm;
            $modal({
              scope: scopeModal,
              template: 'tiers/tiers-directives/coordonnees-fiche/modal/modal-coordonnees.html',
              backdrop: 'static',
            });
          } else {
            $modal({
              template: 'tiers/tiers-directives/warning-tiers/modal/modal-warning-modifications.html',
              backdrop: 'static',
            });
          }
        };

        /**
         * Validation des modifications de coordonnées
         *
         * @param {object} modal Fenêtre modale contenant le formulaire de modification des coordonnées
         * @param {number} index Index de l'élément coordonnée au niveau du tableau
         * @param {object} coordonnees Coordonnées du tiers
         */
        scope.updateCoordonnees = function (modal, index, coordonnees) {
          if (scope.tiers && coordonnees) {
            scope.tiers.situations[index] = coordonnees;
          }

          // Mise à jour de la carte avec les nouvelles coordonnées
          if (!scope.hideMap) {
            scope.updateMap(scope.coordonnees);
          }

          // Fermeture de la fenêtre modale
          scope.validate();
          modal.$hide();
        };

        // watch hideFranceCountry props because it changes asynchronously
        scope.$watch('hideFranceCountry', function () {
          // hide france if hideFranceCountry setting is true and country is FRANCE
          const hideFranceCountry = scope.hideFranceCountry && !scope.coordonnees?.etrangere;
          // Check if the country should be displayed
          scope.displayCountryValue = !hideFranceCountry && scope.coordonnees?.geographique?.Pays?.code?.length === 2;
        });
      },
    };
  },
]);
