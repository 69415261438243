class MoyensHumainsController {
  constructor($translate) {
    this.$translate = $translate;
  }

  // Initialisation
  $onInit() {
    this.moyensHumainsValues = [];
    this.moyensHumains = this.moyensHumains || {};

    this.moyensHumainsConfiguration = this.pageOptions?.moyensHumains ?? {};
    this.moyensHumainsTeleservice = this.moyensHumainsConfiguration?.moyensHumains ?? {};

    // Récupération des libellé personnalisés
    this.nbEtptTeleservice = this.getElementByReference(this.moyensHumainsConfiguration.fields, 'nbEtpt');
    this.nbPersonnesTeleservice = this.getElementByReference(this.moyensHumainsConfiguration.fields, 'nbPersonnes');
    this.etptTotalTeleservice = this.getElementByReference(this.moyensHumainsConfiguration.fields, 'etptTotal');
    this.descriptionTeleservice = this.getElementByReference(this.moyensHumainsConfiguration.fields, 'description');
    this.recrutementTeleservice = this.moyensHumainsConfiguration?.recrutement ?? {};
    this.htmlHautTeleservice = this.moyensHumainsConfiguration?.htmlHaut ?? '';
    this.htmlBasTeleservice = this.moyensHumainsConfiguration?.htmlBas ?? '';
    this.titleTeleservice = this.moyensHumainsConfiguration?.title ?? '';

    this.viewConfiguration.fields.moyensHumains = {
      label: this.descriptionTeleservice.label,
      required: this.descriptionTeleservice.required,
      maxlength: 32500,
      displayCharactersLeft: true,
      hidden: this.descriptionTeleservice.hidden,
    };

    for (const moyenHumainTeleservice of Object.values(this.moyensHumainsTeleservice)) {
      const moyenHumain = {
        premierNiveau: {
          title: moyenHumainTeleservice.premierNiveau.title,
          href: moyenHumainTeleservice.premierNiveau.href,
          nbPersonnesTotal: null,
          nbEtptTotal: null,
        },

        deuxiemesNiveaux: this.initDeuxiemeNiveau(moyenHumainTeleservice),
      };

      if (_.isEmpty(moyenHumain.deuxiemesNiveaux)) {
        moyenHumain.premierNiveau.nbPersonnes = this.getNbValueByHref(
          this.moyensHumains.content,
          moyenHumainTeleservice.premierNiveau.href,
          'nbPersonnes'
        );

        moyenHumain.premierNiveau.nbETPT = this.getNbValueByHref(
          this.moyensHumains.content,
          moyenHumainTeleservice.premierNiveau.href,
          'nbETPT'
        );
      }

      this.moyensHumainsValues.push(moyenHumain);
    }

    // Ajout des valeurs du tableau dans le modèle
    this.moyensHumains.content = [];
    for (const moyenHumainValues of this.moyensHumainsValues) {
      if (!_.isEmpty(moyenHumainValues.deuxiemesNiveaux)) {
        this.updateNbEtptTotal(moyenHumainValues);
        this.updateNbPersonnesTotal(moyenHumainValues);
        for (const moyenHumain2 of moyenHumainValues.deuxiemesNiveaux) {
          this.moyensHumains.content.push(moyenHumain2);
        }
      } else {
        this.moyensHumains.content.push(moyenHumainValues.premierNiveau);
      }
    }

    // The legend is displayed if there is at least one field to display after it
    this.hasSomethingToDisplay =
      !this.descriptionTeleservice.hidden || this.moyensHumainsValues.length > 0 || this.recrutementTeleservice.visible;
  }

  /**
   * Retourne le moyen-humain au bon format
   *
   * @param {object} moyenHumain
   * @returns {object} deuxiemesNiveaux
   */
  initDeuxiemeNiveau(moyenHumain) {
    const deuxiemesNiveaux = [];

    for (const deuxiemeNiveau of Object.values(moyenHumain.deuxiemesNiveaux ?? {})) {
      deuxiemesNiveaux.push({
        title: deuxiemeNiveau.title,
        href: deuxiemeNiveau.href,
        nbPersonnes: this.getNbValueByHref(this.moyensHumains.content, deuxiemeNiveau.href, 'nbPersonnes'),
        nbETPT: this.getNbValueByHref(this.moyensHumains.content, deuxiemeNiveau.href, 'nbETPT'),
      });
    }

    return deuxiemesNiveaux;
  }

  /**
   * Retourne la valeur passée en paramètre du moyen humain
   *
   * @param {Array<object>} moyensHumainsValues
   * @param {string} href
   * @param {string} element
   * @returns {number | undefined} value
   */
  getNbValueByHref(moyensHumainsValues, href, element) {
    let value;

    _.forEach(moyensHumainsValues, function (moyenHumain) {
      if (moyenHumain.href === href) {
        if (element === 'nbETPT') {
          value = Number(moyenHumain.nbETPT);
        }
        if (element === 'nbPersonnes') {
          value = Number(moyenHumain.nbPersonnes);
        }
      }
    });

    return value;
  }

  /**
   * Retourne l'élément correspondant à la référence passée en paramètre
   *
   * @param {Array<object>} fields
   * @param {string} reference
   * @returns {object} get element corresponding to the given reference
   */
  getElementByReference(fields, reference) {
    return fields.find((field) => field.reference === reference);
  }

  /**
   * Mise à jour du total de nombre de personnes
   *
   * @param {object} moyenHumain
   * @returns {void}
   */
  updateNbPersonnesTotal(moyenHumain) {
    moyenHumain.premierNiveau.nbPersonnesTotal = _.sumBy(moyenHumain.deuxiemesNiveaux, function (deuxiemeNiveau) {
      return Number(deuxiemeNiveau.nbPersonnes || 0);
    });
  }

  /**
   * Mise à jour du total de nombre de ETPT
   *
   * @param {object} moyenHumain
   * @returns {void}
   */
  updateNbEtptTotal(moyenHumain) {
    moyenHumain.premierNiveau.nbEtptTotal = _.sumBy(moyenHumain.deuxiemesNiveaux, function (deuxiemeNiveau) {
      return Number(deuxiemeNiveau.nbETPT || 0);
    });
  }

  /**
   * Mise à jour des pourcentages
   *
   * @param {number} numerateur
   * @param {number} denominateur
   * @returns {number} percentage
   */
  updatePourcentage = function (numerateur, denominateur) {
    numerateur = Number(numerateur);
    return (numerateur * 100) / denominateur;
  };

  /**
   * Initialisation des ETPT
   *
   * @returns {void}
   */
  reinitETPT() {
    this.moyensHumains.recrutementNbETPT = null;
  }

  /**
   * Get the reference (the last element of the href)
   *
   * @param {string} href
   * @returns {string | undefined} refrerence extracted from href
   */
  getReferenceFromHref(href) {
    return href.split('/').pop();
  }

  /**
   * Compute which title should be displayed
   *
   * @returns {string} the section title
   */
  getMoyensHumainsTitle() {
    return this.titleTeleservice || this.$translate.instant('teleservice.informations-generales.moyens-humains.legend');
  }
}

MoyensHumainsController.$inject = ['$translate'];

angular.module('aides').component('moyensHumainsDescription', {
  templateUrl: 'aides/aides-components/moyens-humains/moyens-humains.component.html',
  require: {
    form: '?^^form',
  },

  bindings: {
    moyensHumains: '=',
    pageOptions: '<',
    teleservice: '<',
    viewConfiguration: '<',
  },

  controller: MoyensHumainsController,
});
