'use strict';
class DossiersService {
  constructor(aidesConstant, aidesService, AidesFilterConfiguration) {
    this.aidesConstant = aidesConstant;
    this.aidesService = aidesService;
    this.AidesFilterConfiguration = AidesFilterConfiguration;
  }

  /**
   * Get the last avis d'instruction in the dossier
   *
   * @param {object} dossier
   * @returns {object} event in history
   */
  getAvisInstruction(dossier) {
    const events = [...(dossier?.history?.events ?? [])];
    return events.reverse().find(({ type }) => type === 'AVIS_INSTRUCTION');
  }

  /**
   * Compute dossier status to display from current status and history
   *
   * @param {Array} ligneFromPF
   * @returns {string}
   */
  getStatusToDisplay(ligneFromPF) {
    const dossier = ligneFromPF.financement;
    let status = dossier.statut;

    // If dossier history contains a DECISION followed by an AVENANT
    // We consider the dossier as VOTE
    const shouldCheckHistory = ![
      this.aidesConstant.dossierStatuts.VOTE,
      this.aidesConstant.dossierStatuts.SOLDE,
    ].includes(status);

    if (status === this.aidesConstant.dossierStatuts.FININSTRUCTION) {
      const avisInstruction = this.getAvisInstruction(dossier);
      if (avisInstruction && avisInstruction.avis === this.AidesFilterConfiguration.avis.DEFAVORABLE) {
        return this.aidesConstant.dossierStatuts.FININSTRUCTION_DEFAVORABLE;
      }
    }

    if (shouldCheckHistory) {
      if (this.aidesService.isLigneFictiveHaveActiveDecisions(ligneFromPF)) {
        status = this.aidesConstant.dossierStatuts.VOTE;
      }
    }

    return status;
  }
}
DossiersService.$inject = ['aidesConstant', 'aidesService', 'AidesFilterConfiguration'];

DossiersService.$inject = ['AidesConstant', 'aidesService', 'AidesFilterConfiguration'];

angular.module('dossiers').service('dossiersService', DossiersService);
