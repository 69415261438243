'use strict';
angular.module('common.model').factory('LinkedUser', [
  'User',
  'Link',

  function (User, Link) {
    'use strict';

    /**
     *
     * @param {object} data
     */
    function LinkedUser(data) {
      data = data || {};

      Link.call(this, {
        title: data.title,
        expand: new User(data.expand),
        rel: data.rel,
        href: data.href,
        email: data.email,
      });

      this.organization = data.organization;
      this.businessUnit = data.businessUnit;
      this.division = data.division;
      this.department = data.department;
      this.organizationLabel = data.organizationLabel;
      this.costCenterLabel = data.costCenterLabel;
      this.divisionLabel = data.divisionLabel;
      this.departmentLabel = data.departmentLabel;
      this.annotation = data.annotation;

      // Status of the user
      this.form = data.form || 'CONTRIBUTOR';
    }

    LinkedUser.prototype = Object.create(Link.prototype);

    return LinkedUser;
  },
]);
