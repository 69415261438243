import { AppLoader } from '@mgdis/eu-loader';

angular
  .module('domain', [])
  .constant('menuAPI', AppLoader.getMenuInstance())
  .constant('sessionServiceAPI', AppLoader.getSessionServiceInstance())
  .constant('tiersServiceAPI', AppLoader.getTiersServiceInstance())
  .constant('tiersAccountServiceAPI', AppLoader.getTiersAccountServiceInstance())
  .constant('accountManagementAPI', AppLoader.getAccountManagementInstance())
  .constant('teleserviceAPI', AppLoader.getTeleserviceInstance())
  .constant('dossiersFinancementAPI', AppLoader.getDossiersFinancementInstance())
  .constant('dateFormatterService', AppLoader.getDateFormatterInstance())
  .constant('demandesFinancementAPI', AppLoader.getDemandesFinancementInstance())
  .constant('demandesFinancementFormatter', AppLoader.getDemandesFinancementFormatterInstance())
  .constant('publicSettingsService', AppLoader.getPublicSettingsInstance())
  .constant('types', AppLoader.getTypes());
