'use strict';

angular
  .module('common.services')
  .factory('viewsService', [
    'configuration',
    '$timeout',
    '$modal',
    '$stateParams',
    '$httpParamSerializer',
    '$http',
    '$rootScope',
    '$q',
    '$log',
    '$translate',
    viewsService,
  ]);

/**
 *
 * @param {object} configuration
 * @param {object} $timeout
 * @param {object} $modal
 * @param {object} $stateParams
 * @param {object} $httpParamSerializer
 * @param {object} $http
 * @param {object} $rootScope
 * @param {object} $q
 * @param {object} $log
 * @param {object} $translate
 * @returns {object}
 */
function viewsService(
  configuration,
  $timeout,
  $modal,
  $stateParams,
  $httpParamSerializer,
  $http,
  $rootScope,
  $q,
  $log,
  $translate
) {
  'use strict';
  return {
    /**
     * Create or update forms common values in data-schemas
     *
     * @param {string} entityLink the entity link
     * @param {object} currentFormsCommonValues current forms-common-values
     * @param {object} values values of forms common
     * @returns {Promise<object>} return the created or updated forms-common-values
     */
    createOrUpdateFormsCommonValues(entityLink, currentFormsCommonValues, values) {
      const associatedForms = currentFormsCommonValues.associatedForms.map(({ href }) => ({ href }));

      // ------ Updating an existing forms-common-values ------
      if (currentFormsCommonValues.id) {
        // Set last updated date in header (to avoid conflict)
        const config = {
          headers: { 'If-Unmodified-Since': currentFormsCommonValues.date },
        };

        // Set new values
        currentFormsCommonValues.values = { ...currentFormsCommonValues.values, ...values };

        // clean associatedForms before register forms-common-values
        currentFormsCommonValues.associatedForms = associatedForms;

        // Update forms-common-values
        return $http.put(currentFormsCommonValues.id, currentFormsCommonValues, config);
      }

      // ------ Create a forms-common-values ------
      // Build the new forms-common-values
      const newFormsCommonValues = {
        entityLink,
        values,
        tenant: configuration.tenant.id,
        associatedForms,
      };

      // Create forms-common-values
      return $http.post(
        `/data-schemas/api/tenants/${configuration.tenant.id}/views/forms-common-values`,
        newFormsCommonValues,
        {}
      );
    },
    /**
     * Method allows to manage the FormsCommonValues
     *
     * @param {string} entityLink the entity link
     * @param {object} currentFormsCommonValues current forms-common-values
     * @param {object} newViews array of new views
     * @returns {Promise<object> | void} forms-common-values created or updated
     */
    manageFormsCommonValues(entityLink, currentFormsCommonValues, newViews = []) {
      // Find all formsCommon on the views
      const formsCommonArray = newViews.filter((view) => view.href.includes('/forms-common/'));

      // Extract data in forms-common
      const arrayOfValues = formsCommonArray.map((view) => view.data[0]);

      const values = arrayOfValues.reduce((val, acc) => {
        // iterate on acc for remove undefined or null value
        // if we have two same common fields in different common forms
        // and the first field contains a value and the second is undefined
        // The second field overwrites the value of the first
        Object.keys(acc).forEach((key) => {
          if (acc[key] === undefined) {
            delete acc[key];
          }
        });

        return Object.assign(val, acc);
      }, {});

      if (Object.keys(values).length > 0 && !_.isEqual(currentFormsCommonValues.values, values)) {
        // Create or update a forms-common-values in data-schemas
        return this.createOrUpdateFormsCommonValues(entityLink, currentFormsCommonValues, values)
          .then((response) => {
            const formsCommonValues = response?.data;
            if (this.viewsIframe) {
              $timeout(() => {
                this.viewsIframe[0].contentWindow.postMessage(
                  {
                    action: 'updateFormsCommonValues',
                    formsCommonValues,
                  },
                  '*'
                );
              }, 0);
            }
            return formsCommonValues;
          })
          .catch((err) => {
            if (err.data?.code === 'ERR_DUPLICATE') {
              $rootScope.$broadcast('$httpError', {
                ...err,
                data: { ...err.data, message: $translate.instant('common.error.reload') },
              });
            }
            $log.error(err);
            throw err;
          });
      }
      return $q.resolve();
    },

    /**
     * Manage the filteredViews Event
     * Set filtered views in scope and update the formsCommonValues with the fitered views
     *
     * @param {string} entityLink entityLink
     * @param {object} msg message
     * @param {Function} resolve the Promise
     * @param {Array<object>} viewsFromEntity views from the entity
     * @param {Array<object>} viewsFromScope views from the scope
     * @param {string} iframeName format '#nameOfTheIframe'
     * @returns {void}
     */
    manageFilteredViewsEvent(entityLink, msg, resolve, viewsFromEntity, viewsFromScope, iframeName) {
      if (msg.data?.action === 'filteredViews') {
        const viewsFromEvent = msg.data?.views;
        const formsCommonValues = msg.data?.formsCommonValues;
        const views = _.cloneDeep(viewsFromEntity);

        viewsFromEvent.forEach((viewFromEvent) => {
          // Retrieve the corresponding view in scope and in the entity
          const viewScopeToUpdate = viewsFromScope?.find((demView) => demView.schema.href === viewFromEvent.href);
          const correspondingViewFromEntity = viewsFromEntity?.find(
            (demView) => demView.schema.href === viewFromEvent.href
          );

          if (viewScopeToUpdate) {
            const isFormsCommon = viewScopeToUpdate.schema.href.includes('/forms-common/');
            const dataArray = viewFromEvent.dataArrayAfterFilter;

            if (!isFormsCommon) {
              viewScopeToUpdate.values = dataArray;
            }

            views.values = dataArray;

            // For each errors we find the old value from the entity and the forms-common-values to reassign it
            viewFromEvent.dataArrayInError?.forEach((dataInError, index) => {
              Object.keys(dataInError).forEach((key) => {
                if (!isFormsCommon) {
                  const entityValue = correspondingViewFromEntity?.values?.[index]?.[key];
                  if (entityValue !== undefined) {
                    _.set(viewScopeToUpdate, `values.${index}.${key}`, entityValue);
                  }
                } else {
                  const formsCommonValue = formsCommonValues.values?.[key];
                  if (formsCommonValue !== undefined) {
                    _.set(views, `values.${index}.${key}`, formsCommonValue);
                  }
                }
              });
            });
          }

          viewFromEvent.data = views.values || [];
        });

        // Manage forms-common-values
        this.manageFormsCommonValues(entityLink, formsCommonValues, viewsFromEvent)
          .then((updatedFormsCommonValues) => {
            // Indicate the change to the data-schemas iframe
            if (updatedFormsCommonValues) {
              const viewsIframe = angular.element(iframeName);
              if (viewsIframe) {
                viewsIframe[0]?.contentWindow?.postMessage(
                  {
                    action: 'updateFormsCommonValues',
                    formsCommonValues: updatedFormsCommonValues,
                  },
                  '*'
                );
              }
            }
          })
          .finally(() => {
            resolve();
          });
      }
    },

    /**
     * Create the views iframe URL for a given set of views
     *
     * @param {object} params parameters
     * @param {object} params.entity entity
     * @param {object[]} params.views views to display
     * @param {boolean} params.readOnly Display views in readonly
     * @param {boolean} params.hideExplanation hide explanation in views fields
     * @param {string} params.theme theme
     * @param {number} params.topTitleLevel Initialize the title level value to the highest in the iFrame.
     * @param {string} params.fieldsSelectionParameterName Custom select fields name
     * @param {object} params.contextHeaders Specific headers for context
     * @returns {string} views iframe URL
     */
    getViewsIframeUrl({
      entity,
      views,
      readOnly,
      hideExplanation = false,
      theme,
      topTitleLevel,
      fieldsSelectionParameterName,
      contextHeaders,
    }) {
      // Get display entity url
      let urlEntity = entity.id;
      let urlEntityLink;
      let contributionId = $stateParams.c ?? $stateParams.contributionRef;

      if (contributionId && entity.kind !== 'TIERS') {
        // If we have a contribution, we display the "aide" merged to the contribution
        urlEntity = `${urlEntity}?contribution=${contributionId}`;
        urlEntityLink = `/referentiel-financement/api/tenants/${configuration.tenant.id}/contributions/${contributionId}`;
      }

      const queryParams = {
        urlEntity,
        indexes: [],
        urls: [],
        jwtKey: `jwt-${configuration.tenant.id}-portail-depot-demande-aides`,
        theme: !_.isNil($rootScope.specificTheme) ? 'specific' : theme,
        readOnly: readOnly || false,
        hideExplanation,
        displaySaveButton: false,
        topTitleLevel: topTitleLevel || 4,
        fieldsSelectionParameterName,
        patchViewsEntity: false,
        contextHeaders,
      };

      if (urlEntityLink) {
        queryParams.urlEntityLink = urlEntityLink;
      }

      const originalViews = entity.views || [];
      _.sortBy(views, 'section').forEach((view) => {
        const index = originalViews.indexOf(view);
        const url = _.get(view, 'schema.href');

        if (view.actif) {
          queryParams.indexes.push(index);
          queryParams.urls.push(url);
        }
      });

      const serializedQueryParams = $httpParamSerializer(queryParams);
      return `/data-schemas/#/${configuration.tenant.id}/views?${serializedQueryParams}`;
    },

    /**
     * Method that builds url to load
     * views for a specific "demande d'aide"
     *
     * @param {object} params parameters
     * @param {object} params.entity entity
     * @param {string} params.page page
     * @param {string} params.theme Graphic theme to apply to the iframe
     * @param {boolean} params.readOnly Display views in readonly
     * @param {boolean} params.hideExplanation hide explanation in views fields
     * @param {number} params.topTitleLevel Initialize the title level value to the highest in the iFrame.
     * @param {string} params.fieldsSelectionParameterName Custom select fields name
     * @param {object} params.contextHeaders Specific headers for context
     * @returns {string} views iframe URL
     */
    getPageViewsIframeUrl: function ({
      entity,
      page,
      theme,
      readOnly,
      hideExplanation = false,
      topTitleLevel,
      fieldsSelectionParameterName,
      contextHeaders,
    }) {
      const viewsToDisplay = entity.views?.filter((view) => view.page === page);
      return this.getViewsIframeUrl({
        entity,
        views: viewsToDisplay,
        readOnly,
        hideExplanation,
        theme,
        topTitleLevel,
        fieldsSelectionParameterName,
        contextHeaders,
      });
    },

    /**
     * Building an iFrame url with the all the views
     *
     * @param {object} tiers The entity from which the url is built
     * @param {string} theme
     * @param {boolean} readOnly
     * @param {boolean} remoteValidation
     * @param {number} topTitleLevel
     * @param {boolean} displaySaveButton
     * @param {object} headersEntity Specific headers for entity
     * @param {object} contextHeaders Specific headers for context
     * @param {boolean} patchViewsEntity
     * @returns {string} iframe src
     */
    getViewsIframeUrlForTiers: (
      tiers,
      theme,
      readOnly,
      remoteValidation,
      topTitleLevel,
      displaySaveButton = false,
      headersEntity,
      contextHeaders,
      patchViewsEntity = false
    ) => {
      // Check if entityToPatch.id is not empty
      if (!tiers || _.isEmpty(tiers.id)) {
        throw new Error('allViewsIframeUrl - "tiers" parameter must have an "id" property');
      }

      const queryParams = {
        indexes: [],
        urls: [],
        jwtKey: `jwt-${configuration.tenant.id}-portail-depot-demande-aides`,
        theme: !_.isNil($rootScope.specificTheme) ? 'specific' : theme,
        readOnly: readOnly || false,
        remoteValidation: remoteValidation || false,
        topTitleLevel: topTitleLevel || 4,
        displaySaveButton: displaySaveButton,
        headersEntity,
        contextHeaders,
        patchViewsEntity,
        page: 'pageInfosComp',
        urlEntity: tiers.id,
      };

      const originalViews = tiers.views ?? [];

      _.sortBy(originalViews, ['section']).forEach((view) => {
        const index = originalViews.findIndex((originalView) => originalView.schema.href === view.schema.href);
        queryParams.indexes.push(index);
        queryParams.urls.push(view.schema.href);
      });

      const serializedQueryParams = $httpParamSerializer(queryParams);
      return `/data-schemas/#/${configuration.tenant.id}/views?${serializedQueryParams}`;
    },

    /**
     * Loading the views of the iframe
     *
     * @param {object} scope scope
     * @param {object} msg message
     * @param {object} entity business entity
     * @param {string} iframeName format '#nameOfTheIframe'
     * @param {object} options options to be passed to data-schema
     * @param {boolean} options.showErrors errors if present during completness check
     * @param {boolean} options.saveInProgress bypass required fields for form validation
     */
    updateViewsEntity: function (scope, msg, entity, iframeName, options) {
      if (msg?.data?.action === 'viewsUpdated') {
        const index = msg?.data?.index;
        const values = msg?.data?.values;
        if (!_.get(entity, `views.${index}.schema.href`)?.includes('/forms-common/')) {
          _.set(entity, `views.${index}.values`, values);
        }
        scope.viewEntity = entity;
        scope.$apply();
      } else if (msg?.data?.source === 'data-schemas.views' && msg?.data?.action === 'ready') {
        this.viewsIframe = angular.element(iframeName);

        // Display the error if the user commes back on the page
        if (options.showAllErrors && this.viewsIframe[0]) {
          // Send a message which enable the validation of the Liste/Fiche/Formulaires
          $timeout(() => {
            this.viewsIframe[0].contentWindow.postMessage(
              {
                action: 'validViews',
                options,
              },

              '*'
            );
          }, 0);
        }
      } else if (_.startsWith(_.get(msg, 'data.action', ''), 'modal')) {
        scope.$apply(() => {
          scope.$emit(_.get(msg, 'data.action'));
        });
      }
    },

    /**
     * Checking that the iframe form is valid
     *
     * @param {object} scope scope
     * @param {object} msg message
     * @param {Function} accept accept
     * @param {Function} reject reject
     * @param {object} teleserviceConfiguration
     */
    updateStateViews: function (scope, msg, accept, reject, teleserviceConfiguration) {
      // Ensure message has the correct structure and properties before accessing them
      if (msg.data?.action !== 'updateStateViews') return; // not involved with the event

      if (msg.data?.state === 'ok') {
        // Manage forms-common-values
        this.manageFormsCommonValues(
          msg.data?.formsCommonValues?.entityLink ?? scope.viewEntity?.id,
          msg.data?.formsCommonValues,
          msg.data?.views
        )
          .then(() => {
            accept();
          })
          .catch((err) => {
            reject(err);
          });
      } else if (teleserviceConfiguration?.controleCompletudeDepot || msg?.data?.state === 'error') {
        if (!msg.data?.alerts || msg.data?.alerts?.length === 0) {
          reject(new Error('updateStateViews - iFrame validation rejected'));
          return;
        }
        var scopeModal = scope.$new();
        this.manageFormsCommonValues(
          msg.data?.formsCommonValues?.entityLink ?? scope.viewEntity?.id,
          msg.data?.formsCommonValues,
          msg.data?.views
        )
          .then(() => {
            scopeModal.confirmNext = function () {
              accept();
            };
            scopeModal.views = msg.data?.views || [];
            $modal({
              scope: scopeModal,
              template: 'depot/simple/informations-generales/modal/alerts-views.html',
            }).catch(() => {
              reject(new Error('updateStateViews - iFrame validation rejected'));
            });
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        reject(new Error('updateStateViews - iFrame validation rejected'));
      }
    },
    /**
     * Checking that the iframe form is valid then go to the next step
     *
     * @param {object} scope scope
     * @param {object} msg message
     * @param {Function} accept accept
     * @param {Function} reject reject
     */
    updateStateViewsTiers: function (scope, msg, accept, reject) {
      const action = msg?.data?.action;

      if (action === 'updateStateViews') {
        const state = msg?.data?.state;
        if (state === 'ok') {
          this.manageFormsCommonValues(scope.viewEntity.id, msg.data?.formsCommonValues, msg.data?.views)
            .then(() => {
              accept();
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          if (!_.isEmpty(msg?.data?.alerts)) {
            const scopeModal = scope.$new();
            scopeModal.views = msg?.data?.views || [];
            $modal({
              scope: scopeModal,
              template: 'depot/simple/informations-generales/modal/alerts-views.html',
            });
          }
          reject();
        }
      }
    },

    /**
     * Checking that the iframe form is valid
     *
     * @param {object} scope scope
     * @param {object} msg message
     * @param {Function} accept accept
     * @param {Function} reject reject
     */
    updateStateViewsPaiement: function (scope, msg, accept, reject) {
      if (_.get(msg, 'data.action') === 'updateStateViews') {
        // If the iframe send a state ok then we go to next step
        if (_.get(msg, 'data.state') === 'ok') {
          this.manageFormsCommonValues(scope.demandePaiement.id, msg.data?.formsCommonValues, msg.data?.views)
            .then(() => {
              accept();
            })
            .catch((err) => {
              reject(err);
            });
        } else if (_.get(msg, 'data.state') === 'error') {
          // Display errors in a modal only if there's some transmitted alerts
          if (!_.isEmpty(_.get(msg, 'data.alerts'))) {
            var scopeModal = scope.$new();
            this.manageFormsCommonValues(scope.demandePaiement.id, msg.data?.formsCommonValues, msg.data?.views)
              .then(() => {
                scopeModal.confirmNext = function () {
                  accept();
                };
                scopeModal.views = _.get(msg, 'data.views', []);
                $modal({
                  scope: scopeModal,
                  template: '/depot/demande-paiement/informations-complementaires/modal/alerts-views.html',
                }).catch(() => {
                  reject(new Error('updateStateViews - iFrame validation rejected'));
                });
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            reject(new Error('updateStateViews - iFrame validation rejected'));
          }
        } else {
          reject(new Error('updateStateViews - iFrame validation rejected'));
        }
      }
    },

    /**
     * update state views from justification
     *
     * @param {object} scope scope
     * @param {object} msg message
     * @param {Function} goNextStep fonction to reach next step
     */
    updateStateViewsJustification: function (scope, msg, goNextStep) {
      if (_.get(msg, 'data.action') !== 'updateStateViews') {
        return;
      }
      // If the iframe send a state ok then we go to next step
      if (_.get(msg, 'data.state') === 'ok') {
        this.manageFormsCommonValues(
          scope.viewEntity?.id || msg.data?.urlEntity,
          msg.data?.formsCommonValues,
          msg.data?.views
        ).then(() => {
          goNextStep();
        });
      } else if (_.get(msg, 'data.state') === 'error') {
        // Display errors in a modal only if there's some transmitted alerts
        if (_.isEmpty(_.get(msg, 'data.alerts'))) {
          return;
        }
        var scopeModal = scope.$new();
        this.manageFormsCommonValues(
          scope.viewEntity?.id || msg.data?.urlEntity,
          msg.data?.formsCommonValues,
          msg.data?.views
        ).then(() => {
          scopeModal.confirmNext = function () {
            goNextStep();
          };
          scopeModal.views = _.get(msg, 'data.views', []);
          $modal({
            scope: scopeModal,
            template: '/depot/justification/informations-complementaires/modal/alert-views.html',
          });
        });
      }
    },

    /**
     * Get views in "familleViews" and views values in current "tiersViews"
     * so views configuration is up to date and ready for validation
     *
     * @param {Array<object>} familleViews familleViews
     * @param {Array<object>} tiersViews tiersViews
     * @returns {Array<object>} merged views
     */
    mergeTiersViewsWithFamilleViews(familleViews, tiersViews) {
      const viewsInFamille = familleViews.map((view) => {
        // if the view "view.schema.href" doesn't exist set the "viewHrefToFind" with unmatchable href to avoid the match with an other null or undefined href
        const viewHrefToFind = view.schema.href.split('?date=')[0];
        const viewInTierAndFamille = tiersViews.find(
          (viewCurrentTier) => viewHrefToFind === viewCurrentTier.schema?.href.split('?date=')[0]
        );
        // If the form is a forms-common, we add the date
        if (view.schema.href.includes('/forms-common/')) {
          view.schema.href += `?date=${view.schema.expand?.date}`;
        }
        // Remove view expand
        delete view.schema.expand;
        // Add values if they exist
        if (viewInTierAndFamille?.values) {
          return { ...view, values: viewInTierAndFamille.values };
        }
        return { ...view };
      });

      return viewsInFamille;
    },

    /**
     * Merge two forms common values
     *
     * @param {string} entityLinkMergeTo "forms-common-values" entityLink that will be updated
     * @param {string} entityLinkMergeFrom "forms-common-values" entityLink with values to keep
     * @returns {object} merged "forms-common-values"
     */
    mergeFormsCommonValues(entityLinkMergeTo, entityLinkMergeFrom) {
      const url = `/data-schemas/api/tenants/${configuration.tenant.id}/views/forms-common-values/merge`;
      const body = {
        entityLinkMergeTo,
        entityLinkMergeFrom,
      };
      return $http.post(url, body, {});
    },
  };
}
