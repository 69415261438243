'use strict';
angular.module('offresStage.model').factory('OffreStage', [
  'Link',
  'User',
  function (Link, User) {
    'use strict';

    const OFFRE_STAGE_PROPERTIES = [
      'classifications',
      'correlations',
      'annotations',
      'teleservice',
      'cadreDepot',
      'dossierRattachement',
      'dateReceptionModification',
      'raisonSociale',
      'description',
      'secteurActivite',
      'effectif',
      'siteWeb',
      'courriel',
      'typeStage',
      'mission',
      'profil',
      'dureeStage',
      'niveauQualification',
      'remunere',
      'datePrevisionnelleDebut',
      'niveauQualificationCandidatRetenu',
      'numeroDossierSubvention',
      'numeroCourrierNotification',
      'dateReceptionNotification',
      'adresse',
      'dateDemarrage',
      'genre',
      'age',
      'handicap',
      'residence',
      'pieces',
      'accesTransport',
      'SIRET',
    ];

    /**
     * Classe OffreStage. Objet OffreStage
     *
     * @param {object} data Données d'une offre de stage en persistance utilisée pour initialiser l'objet
     * @class OffreStage
     * @classdesc OffreStage canonique
     */
    function OffreStage(data) {
      /*eslint complexity:0 */
      /*eslint max-statements:0 */

      data = data || {};

      this.id = data.id;
      this.kind = data.kind;
      this.tenant = data.tenant;
      this.origin = data.origin;

      this.title = data.title;
      this.active = data.active;
      this.reference = data.reference;
      this.referenceAdministrative = data.referenceAdministrative;
      this.date = data.date;
      this.version = data.version;

      this.user = new Link({
        title: _.get(data, 'user.displayName'),
        rel: 'user',
        href: _.get(data, 'user.href'),
        expand: new User(_.get(data, 'user.expand')),
      });

      this.statut = data.statut;

      this.history = data.history || {
        begin: {},
        events: [],
        end: {},
      };

      for (const property of OFFRE_STAGE_PROPERTIES) {
        if (data[property] !== undefined) {
          this[property] = data[property];
        }
      }
    }

    return OffreStage;
  },
]);
