/* eslint-disable angular/document-service */
'use strict';

/**
 * @module common
 * @name portalHeader
 * @description
 *
 *   Header du portail
 * @example
 *
 *   <portal-header></portal-header>
 */
class PortalHeaderController {
  constructor(
    $timeout,
    $state,
    $rootScope,
    $scope,
    $translate,
    $window,
    menuAPI,
    jwtSessionService,
    sessionServiceAPI
  ) {
    this.$timeout = $timeout;
    this.$state = $state;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$translate = $translate;
    this.$window = $window;
    this.menuAPI = menuAPI;
    this.jwtSessionService = jwtSessionService;
    this.sessionServiceAPI = sessionServiceAPI;
  }

  /**
   * Initialize component
   *
   * @returns {void}
   */
  $onInit() {
    this.menu = [];
    this.sessionServiceAPI.setCurrentTiersKey('current-tiers-ref');
    this.sessionServiceAPI.setJwtKey(this.jwtSessionService.getJwtKey()).then(() => {
      //? getMenu function needs publicSettings
      //! wait 500ms, at this point domain is already fetching publicSettings but the store is not updated yet
      //! otherwise a double call to fetch publicSettings will be done
      this.$timeout(() => {
        this.getMenu();
      }, 500);
    });

    this.$scope.itemmore = { size: 'large' }; // Needed to display responsive menu with large items

    this.$scope.currentUser = this.$rootScope.currentUser;

    this.$rootScope.$on('refresh-menu', () => {
      this.getMenuCount();
    });

    // on location change
    this.$rootScope.$on('$locationChangeSuccess', () => {
      // if menu exist
      if (this.menu) {
        // loop over menu items
        this.menu.forEach((entry) => {
          // if menu item as a submenu dropdown, and it's expanded
          if (entry.subMenu && document?.getElementsByClassName(entry.class)?.[0]?.expanded) {
            // toggle the menu expanded state to close it
            entry.expanded = true;
            this.$timeout(() => {
              entry.expanded = false;
            });
          }
        });
      }
    });
  }

  /**
   * Get Menu
   *
   * @returns {void}
   */
  getMenu() {
    this.menuAPI.getMenu().then(({ response }) => {
      this.menu = response.map((entryToUpdate, index) => {
        const entry = { ...entryToUpdate };
        // translate menu labels
        entry.displayedId = `entry-menu-${entry.id}`;
        entry.label = this.$translate.instant(`connected.menu.${entry.id}`);
        entry.class = `portal-header__menu-sub-item-${index}`;
        entry.expanded = false;
        if (entry.route) entry.href = this.$state.href(entry.route, entry.params);

        // Specific => re-use old trads from ref-resources
        if (entry.id === 'aides') entry.label = this.$translate.instant('connected.dashboard.aides.title');

        entry.subMenu = entry.subMenu?.map((subEntryToUpdate) => {
          const subEntry = { ...subEntryToUpdate };
          subEntry.displayedId = `${entry.displayedId}-${subEntry.id}`;
          subEntry.label = this.$translate.instant(`connected.menu.subMenus.${entry.id}.${subEntry.id}`);
          if (subEntry.route) subEntry.href = this.$state.href(subEntry.route, subEntry.params);

          // Specific => re-use old trads from ref-resources
          if (entry.id === 'aides') {
            if (subEntry.id === 'access') subEntry.label = this.$translate.instant('connected.dashboard.aides.suivi');
            if (subEntry.id === 'depot') subEntry.label = this.$translate.instant('connected.dashboard.aides.new');
          }

          return subEntry;
        });

        return entry;
      });

      this.getMenuCount();
    });
  }

  /**
   * Get Menu Count
   *
   * @returns {void}
   */
  getMenuCount() {
    this.menuAPI.getMenuCount().then(({ response }) => {
      for (const countKey in response) {
        switch (countKey) {
          case 'contributionsDemandesApprenants': {
            const menuEntry = this.menu.find(({ id }) => id === 'demandes-apprenants');
            const subMenuEntry = menuEntry?.subMenu?.find(({ id }) => id === 'contributions');
            if (subMenuEntry) {
              subMenuEntry.count = response[countKey];
            }
            break;
          }
          default: {
            const menuEntry = this.menu.find(({ id }) => id === countKey);
            if (menuEntry) {
              menuEntry.count = response[countKey];
            }
            break;
          }
        }
      }

      // Scope apply needed to avoid refresh problem
      this.$scope.$apply();
    });
  }

  /**
   * Check if entry route is current route
   *
   * @param {object} entry menu entry
   * @returns {boolean} true if given route is active
   */
  isRouteActive(entry) {
    if (entry.subMenu) {
      const routes = entry.subMenu.map(({ route }) => route);
      return routes.some((route) => this.$state.current?.name?.includes(route));
    } else {
      return entry.route && this.$state.current?.name?.includes(entry.route);
    }
  }
}
PortalHeaderController.$inject = [
  '$timeout',
  '$state',
  '$rootScope',
  '$scope',
  '$translate',
  '$window',
  'menuAPI',
  'jwtSessionService',
  'sessionServiceAPI',
];

angular.module('common.components').component('portalHeader', {
  templateUrl: 'common/common-components/portal-header/portal-header.component.html',
  controller: PortalHeaderController,
  bindings: {
    tiers: '<',
    famillesCount: '<',
  },
});
