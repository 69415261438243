'use strict';
angular.module('tiers').directive('informationsComplementaires', informationsComplementaires);

/**
 * @returns {object}
 */
function informationsComplementaires() {
  return {
    replace: true,
    transclude: true,
    templateUrl: 'tiers/tiers-directives/informations-complementaires/informations-complementaires.html',
    scope: {
      tiers: '=',
      pageOptions: '=',
      viewConfiguration: '=',
      exerciceReference: '=',
      mdm: '=',
      readOnly: '<',
    },

    link: function (scope) {
      // Récupère l'option, ou initialise à l'année en cours
      scope.exerciceCampagne = parseInt(scope.exerciceReference, 10) || new Date().getFullYear();

      // Initialisation du specificData pour binding
      if (!scope.tiers.specificData) {
        scope.tiers.specificData = {};
      }

      // Initialisation des variables pour la visibilité des sections
      scope.visibilityStatusDonneesFinancieres = 'show';
      scope.visibilityStatusDonneesFinancieresGroupe = 'show';
      scope.visibilityStatusEffectifs = 'show';
      scope.visibilityStatusEffectifsGroupe = 'show';

      // Get the year of the entreprise creation or initialize it with the current year
      scope.exerciceInitial = null;

      scope.$watch('tiers.dateImmatriculation', function (newValue) {
        const data = typeof newValue === 'string' ? new Date(newValue) : newValue;

        // effectif and donnees financières should be updated with the reference date
        if (_.isDate(data)) {
          scope.exerciceInitial = data.getFullYear();
        }
      });

      // Options according to the family
      const typeFamille = _.get(scope.tiers, 'famille.typeFamille', _.get(scope.tiers, 'famille.expand.typeFamille'));
      if (typeFamille === 'TIERS_ENTREPRISE') {
        scope.familleOptions = scope.pageOptions.complementaires.entreprise;
      } else if (typeFamille === 'TIERS_ASSOCIATION') {
        scope.familleOptions = scope.pageOptions.complementaires.association;
      }

      // Setting the complementary data specific to the family, or the default ones
      const familleConfiguration = _.find(scope.pageOptions.familles, ['famille.href', scope.tiers.famille.href]);
      const informationsComplementairesLinks = familleConfiguration
        ? familleConfiguration.informationsComplementaires
        : _.get(scope.pageOptions, 'complementaires.informationsComplementairesDefaut.presentation');

      if (informationsComplementairesLinks && !_.isEmpty(informationsComplementairesLinks)) {
        // TODO : Since there still isn't any schema for presentations, we only take its URI as a reference in the local presentation
        // Later, this should be replaced by a retrieving of the presentation directly in expand
        scope.informationsComplementaires = _.map(informationsComplementairesLinks, 'href');
      }
    },
  };
}
