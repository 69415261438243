'use strict';
angular.module('common.services').factory('resolveService', [
  function () {
    'use strict';

    return {
      /**
       * Replace resolve in $state found by its token with the value provided
       *
       * @param {object} $state $state
       * @param {string} token token looked for
       * @param {*} value value for replacement
       * @returns {void}
       */
      replaceResolveByToken: function ($state, token, value) {
        let currentState = $state.$current;
        let resolve;

        // Find parent for searched token in nested object
        do {
          resolve = currentState.resolvables.find((resolvable) => resolvable.token === token);
          if (!resolve) {
            currentState = currentState.parent;
          }
        } while (!resolve && currentState.parent);

        resolve.data = value;
      },
    };
  },
]);
