'use strict';
angular.module('aides.services').factory('controleCompletudeService', [
  '$http',
  function ($http) {
    'use strict';

    return {
      controlStep: function (aide, step, contribution) {
        var href = _.get(contribution, 'id') || _.get(aide, 'id');
        return $http.get(href + '/completude/' + step).then(function (response) {
          return response.data;
        });
      },
    };
  },
]);
