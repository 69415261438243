'use strict';

class ShareEntityModal {
  constructor(
    userSessionService,
    accountManagementService,
    validationService,
    $translate,
    $element,
    $scope,
    $rootScope,
    ComponentsHelper
  ) {
    this.$translate = $translate;
    this.validationService = validationService;
    this.accountManagementService = accountManagementService;
    this.userSessionService = userSessionService;
    this.$element = $element;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.ComponentsHelper = ComponentsHelper;
  }

  /**
   * Initialize component
   *
   * @returns {void}
   */
  $onInit() {
    this.modalTitle = this.$translate.instant('connected.dashboard.aides.sharing.modal.title');

    this.$rootScope.$on('open-sharing-modal', (_event, sharings, entity) => {
      if (this.entity?.id === entity?.id) this.openModal(sharings);
    });

    this.$rootScope.$on('entity-shared', () => {
      this.isSaving = false;
    });
  }

  /**
   * Set open modal
   *
   * @param {Array} sharings sharings entities
   * @returns {void}
   */
  openModal(sharings) {
    this.sharings = sharings ?? [];
    this.sharingsEdited = false;
    this.emailTouched = false;
    this.error = false;
    this.data = {
      email: '',
      sharedEmails: [...this.sharings],
    };
    this.isDemandeurEmailInList;

    this.viewConfiguration = {
      fields: {
        email: {
          label: this.$translate.instant('connected.dashboard.aides.sharing.modal.labels.email'),
        },
      },
    };

    const userId = this.userSessionService.getUser().id;
    this.accountManagementService.getAccount(userId).then((user) => {
      this.demandeurEmail = user?.emails[0];
      this.isDemandeurEmailInList = this.sharings.includes(this.demandeurEmail);
    });

    this.setModalTitle();
    this.showModal = true;
  }

  /**
   * Set title value
   *
   * @returns {void}
   */
  setModalTitle() {
    const entitySharedKind = this.entity?.id.split('/').slice(-2, -1)[0];
    switch (entitySharedKind) {
      case 'demandes-paiement':
        this.modalTitle = this.$translate.instant('connected.dashboard.aides.sharing.modal.titlePaiement');
        break;
      default:
        this.modalTitle = this.$translate.instant('connected.dashboard.aides.sharing.modal.title');
        break;
    }
  }

  /**
   * Set email input value
   *
   * @param {string} email the email value
   * @returns {void}
   */
  setEmailValue(email) {
    this.error = false;
    this.data.email = email;
  }

  /**
   * Set form validity
   *
   * @param {boolean} $event - true if form is valid
   */
  setAddEmailFormValid($event) {
    this.isAddEmailFormValid = $event;
  }

  /**
   * Check if email can be added, set error otherwise
   *
   * @param {object} email email input
   * @returns {void}
   */
  checkEmailValidity(email) {
    const input = this.$element.find(`[identifier="email"]`)[0];
    const sharedEmails = this.data.sharedEmails.map((sharedEmails) => sharedEmails.toLowerCase());
    if (!this.isAddEmailFormValid) this.error = true;
    else if (email) {
      if (!this.data.email) {
        // Remove error if input is empty and set $touched to false to not show error on each key input
        this.error = false;
        this.emailTouched = false;
      } else if (this.data.email.toLowerCase() === this.demandeurEmail?.toLowerCase()) {
        this.error = true;
        input.setError(
          false,
          this.$translate.instant('connected.dashboard.aides.sharing.modal.errors.DEMANDEUR_EMAIL')
        );
      } else if (sharedEmails.includes(this.data.email.toLowerCase())) {
        this.error = true;
        input.setError(false, this.$translate.instant('connected.dashboard.aides.sharing.modal.errors.ALREADY_ADDED'));
      } else if (this.emailTouched && !this.validationService.verifyEmail(this.data.email)) {
        this.error = true;

        // If format error, check if length is too long
        const [userPart, domainPart] = this.data.email.split('@');

        const emailWithoutUserPart = `test@${domainPart}`;

        if (
          domainPart &&
          userPart &&
          this.validationService.verifyEmail(emailWithoutUserPart) &&
          userPart.length > 64
        ) {
          this.error = true;
          input.setError(false, this.$translate.instant('connected.dashboard.aides.sharing.modal.errors.TOO_LONG'));
        }
      } else {
        this.error = false;
      }
    }
  }

  /**
   * Add email to sharedEmails list
   *
   * @param {object} email with email input
   * @returns {void}
   */
  addEmail(email) {
    // We first check if email is valid, needed here if form is sent by clicking return
    this.emailTouched = true;
    this.checkEmailValidity(email);
    if (!this.error) {
      // Add email to sharedEmails list then empty the input
      this.data.sharedEmails.push(this.data.email);
      delete this.data.email;
      this.emailTouched = false;

      this.sharingsEdited = !_.isEqual(this.data.sharedEmails.sort(), this.sharings.sort());
      this.ComponentsHelper.focusElement(`#email`, this.$element);
    }
  }

  /**
   * Remove email from sharedEmails list
   *
   * @param {string} email email to remove
   * @returns {void}
   */
  removeEmail(email) {
    const currentEmailIndex = this.data.sharedEmails.indexOf(email);
    this.data.sharedEmails = this.data.sharedEmails.filter((item) => item !== email);
    this.sharingsEdited = !_.isEqual(this.data.sharedEmails.sort(), this.sharings.sort());
    if (this.isDemandeurEmailInList && email === this.demandeurEmail) this.isDemandeurEmailInList = false;

    const previousEmailIndex = currentEmailIndex - 1;
    const nextEmailIndex = currentEmailIndex;
    // Set focus to the previous element if present, if not, to the next one, if no email, set focus to the input
    if (this.data.sharedEmails.length === 0) {
      this.ComponentsHelper.focusElement('#email', this.$element);
    }
    if (previousEmailIndex > -1) {
      this.ComponentsHelper.focusElement(`#emailsList-${previousEmailIndex}`, this.$element);
    } else if (nextEmailIndex > -1) {
      this.ComponentsHelper.focusElement(`#emailsList-${nextEmailIndex}`, this.$element);
    }
  }

  /**
   * Send sharedEmails list to parent component
   *
   * @returns {void}
   */
  save() {
    this.isSaving = true;
    this.$scope.$emit('save-sharings', this.data.sharedEmails);
    this.closeModal();
  }

  cancel() {
    if (!this.isSaving) {
      this.$scope.$emit('close-sharings-modal');
      this.closeModal();
    }
  }

  /**
   * Close the modal
   *
   * @returns {void}
   */
  closeModal() {
    this.showModal = false;
    // Delete error message when user close the modal
    const input = this.$element.find(`[identifier="email"]`)[0];
    if (input) {
      input.setError(true, 'remove error');
    }
  }
}

ShareEntityModal.$inject = [
  'userSessionService',
  'accountManagementService',
  'validationService',
  '$translate',
  '$element',
  '$scope',
  '$rootScope',
  'ComponentsHelper',
];

angular.module('aides').component('shareEntityModal', {
  controller: ShareEntityModal,
  templateUrl: 'aides/aides-components/share-entity/modal/share-entity-modal.html',
  bindings: {
    showModal: '=',
    sharings: '=',
    entity: '=',
  },
});
